import { z } from 'zod';

const ErrorSchema = z.object({
    code: z.string(),
    debugDetails: z.string(),
    field: z.string(),
    resource: z.string(),
});

export const ErrorsSchema = z.object({
    errors: z.array(ErrorSchema),
});

const SenderSchema = z.object({
    id: z.number(),
    type: z.union([z.literal('staff'), z.literal('customer'), z.literal('system')]),
    name: z.string(),
});

export const MessageSchema = z.object({
    id: z.string(),
    sender: SenderSchema,
    sentAt: z.string(),
    tags: z.array(z.string()),
    type: z.union([
        z.literal('assigned'),
        z.literal('standard'),
        z.literal('ended'),
        z.literal('unassigned'),
        z.literal('typing'),
    ]),
    content: z
        .object({
            text: z.string(),
        })
        .optional(),
    chatId: z.string().optional(),
});

export const ChatStatusSchema = z.object({
    typeId: z.string(),
    universe: z.string(),
    templates: z.object({
        messageTemplates: z.array(z.string()),
        scheduleStart: z.union([z.string(), z.undefined(), z.null()]),
        scheduleEnd: z.union([z.string(), z.undefined(), z.null()]),
    }),
    who: z.object({
        id: z.union([z.number(), z.undefined(), z.null()]),
        type: z.union([z.literal('staff'), z.literal('customer'), z.literal('system')]),
        name: z.string(),
    }),
    lastUpdatedDate: z.string(),
});

export const InputMessageSchema = z.object({
    sender: SenderSchema,
    sentAt: z.string(),
    tags: z.array(z.string()),
    type: z.union([
        z.literal('assigned'),
        z.literal('standard'),
        z.literal('ended'),
        z.literal('unassigned'),
        z.literal('typing'),
    ]),
    content: z
        .object({
            text: z.string(),
        })
        .optional(),
});

export const TemplateSchema = z.object({
    templates: z.object({
        messageTemplates: z.array(z.string()),
        scheduleStart: z.string().optional(),
        scheduleEnd: z.string().optional(),
    }),
});

export const SuccessChatsDataResponseSchema = z.object({
    id: z.string(),
    universeId: z.string(),
    messages: z.array(MessageSchema),
    participants: z.array(
        z.object({
            role: z.string(),
            account: SenderSchema,
        })
    ),
});

export type ChatsTemplatesSuccessResponseType = z.infer<typeof TemplateSchema>;
export type GetChatsSuccessResponseType = z.infer<typeof SuccessChatsDataResponseSchema>;
export type ChatMessageType = z.infer<typeof MessageSchema>;
export type ChatStatusType = z.infer<typeof ChatStatusSchema>;
export type ErrorMessageType = z.infer<typeof ErrorsSchema>;
export type ChatSenderType = z.infer<typeof SenderSchema>;

export type GetChatDataResponseType =
    | { responseStatus: 'success'; data: GetChatsSuccessResponseType }
    | { responseStatus: 'error'; data: string }
    | { responseStatus: 'httpError'; status: number; body: string };
export type ChatTemplateDataResponseType =
    | { responseStatus: 'success'; data: ChatsTemplatesSuccessResponseType }
    | { responseStatus: 'error'; data: string }
    | { responseStatus: 'httpError'; status: number; body: string };
export type ChatSendMessageDataResponseType =
    | { responseStatus: 'success'; data: ChatMessageType }
    | { responseStatus: 'error'; data: ErrorMessageType }
    | { responseStatus: 'error-parse'; data: string }
    | { responseStatus: 'httpError'; status: number; body: string };
export type ChatStatusDataResponseType =
    | { responseStatus: 'success'; data: ChatStatusType }
    | { responseStatus: 'error'; data: string }
    | { responseStatus: 'httpError'; status: number; body: string };
