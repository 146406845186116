import React, { useState } from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';
import { ImagePreloadState } from './ImagePreloadState';
import {
    SilkIconWrapper,
    SilkIconWrapperNew,
} from 'src/domains/sportsbook/webview/components/raceBoxSelection/RaceBoxSelection.style';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

const LazyImageWrapper = styled('img', { label: 'LazyImageWrapper' })`
    width: 100%;
`;

interface AppStateComponentType {
    className?: string;
    src?: string | null;
    placeholder: string;
    alt?: string | null;
    justSrc?: string | null;
}

export const LazyImage = observer('LazyImage', (props: AppStateComponentType) => {
    const [imagePreload] = useState(() => new ImagePreloadState());
    const { alt, className, justSrc, placeholder } = props;
    const img = justSrc ?? null;
    const src = props.src ?? null;
    const common = useCommon();
    const config = ConfigComponents.get(common).config;

    if (img === null && src === null) {
        if (config.raceSummaryRowHorseRacingNew === true) {
            return <SilkIconWrapperNew className={className} />;
        }
        return <SilkIconWrapper />;
    }

    return (
        <LazyImageWrapper
            className={className}
            alt={alt ?? ''}
            src={img === null ? imagePreload.getSrc(src, placeholder) : img}
        />
    );
});
