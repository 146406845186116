import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { TabWrapper } from 'src/domains/layouts/webview/others/luckyKing/RHSDrawer';
import { LuckyKingWalletContent } from 'src/domains/players/webview/components/LuckyKingWallet/luckyKingWalletContent/LuckyKingWalletContent';
import {
    AccountDrawerHeader,
    TransactionsList,
    TransactionHistoryTabState,
    Pagination,
} from 'src/domains/players/webview/components/Account';
import { useAppStateContext } from 'src/appState/AppState';
import { FiltersNewView } from 'src/domains/layouts/webview/components/filters/Filters';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { AccountHelperWrapper, AccountHelperLink } from 'src/domains/players/webview/components/LuckyKingLogin';
import {
    TransactionListFilterWrapper,
    TransactionsListWrapper,
} from 'src/domains/players/webview/components/LuckyKingWallet/LuckyKingWalletTab.style';
import { useCommon } from 'src/domains/common/Common';
import { TranslationsStore } from 'src/domains/layouts/state/translationsStore/TranslationsStore';

export const LuckyKingWalletTab = observer('LuckyKingWalletTab', () => {
    const common = useCommon();
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const {
        configComponents: { config },
        languagesState,
        starRouter,
        sdkCustomer,
    } = appLayoutsState;
    const { layout, accountHelperMail, filtersVersion } = config;
    const { accountState } = appPlayersState;
    const translationsStore = TranslationsStore.get(common);
    const [state] = useState(
        () => new TransactionHistoryTabState(accountState, translationsStore, languagesState, config)
    );

    if (!sdkCustomer.session.isAuthorized) {
        starRouter.redirectToHomepage();
    }

    return (
        <TabWrapper>
            <AccountDrawerHeader
                key='account-drawer-header'
                title='Wallet'
                parent='accounts'
                hasClose={true}
                hasBack={false}
            />
            <LuckyKingWalletContent />

            <TransactionListFilterWrapper>
                <FiltersScrollableWrapper>
                    <FiltersNewView
                        select={state.collection.activeFilter}
                        filters={state.filtersHome}
                        skin={filtersVersion.transactionHistory}
                    />
                </FiltersScrollableWrapper>
            </TransactionListFilterWrapper>

            <TransactionsListWrapper>
                <TransactionsList
                    layout={layout}
                    state={state}
                />
                <Pagination collection={state.collection} />
            </TransactionsListWrapper>

            <AccountHelperWrapper>
                <AccountHelperLink to={{ account: 'static', static: 'help' }}>
                    <I18n
                        langKey='account.help-and-faq'
                        defaultText="Help and FAQ's"
                    />
                </AccountHelperLink>

                <AccountHelperLink to={`mailto:${accountHelperMail}`}>
                    <I18n
                        langKey='account.contact-support'
                        defaultText='Contact Support'
                    />
                </AccountHelperLink>
            </AccountHelperWrapper>
        </TabWrapper>
    );
});
