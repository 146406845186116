import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    InfoHeader,
    InfoSubHeader,
    NoConnectionWrapper,
    WarningIconWrapper,
    WarningIconYeeeHaaaWrapper,
} from './NoConnection/NoConnection.style';
import { UniverseVersion } from 'src/domains/common/UniverseVersion';
import { UniverseType } from 'src_common/common/universe';

export const NoConnection = (): JSX.Element => (
    <NoConnectionWrapper>
        <UniverseVersion>
            {(universe: UniverseType): JSX.Element => {
                if (universe === 'yeeehaaa') {
                    return <WarningIconYeeeHaaaWrapper />;
                }
                return <WarningIconWrapper />;
            }}
        </UniverseVersion>
        <InfoHeader>
            <I18n
                langKey='layouts.page-no-connection.header'
                defaultText='There is no internet'
            />
        </InfoHeader>
        <InfoSubHeader>
            <I18n
                langKey='layouts.page-no-connection.sub-header'
                defaultText='Please check your connection and try again.'
            />
        </InfoSubHeader>
    </NoConnectionWrapper>
);
