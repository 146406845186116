import { autorun, observable, when } from 'mobx';
import { Common } from 'src/domains/common/Common';
import { TraderChatState } from 'src/domains/players/state/traderChat/TraderChatState';
import { TraderChatWebsocketData } from 'src/domains/players/state/traderChat/TraderChatWebsocketData';
import { assertNever } from 'src_common/common/assertNever';
import { AutoMap } from 'src_common/common/mobx-utils/AutoMap';
import { Value } from 'src_common/common/mobx-utils/Value';
import { timeout } from 'src_common/common/mobx-utils/timeout';

const ToStringTrait = Symbol();

const observerOptions: IntersectionObserverInit = {
    root: null,
    rootMargin: '0px',
    threshold: 0,
};

class MapObserved<K extends { [ToStringTrait](): string }, V> {
    private readonly data: Value<Map<string, V>> = new Value(new Map());

    public set(key: K, value: V): void {
        const keyString = key[ToStringTrait]();
        const map = this.data.getValue();
        map.set(keyString, value);
        this.data.setValue(map);
    }

    public get(key: K): V | null {
        const keyString = key[ToStringTrait]();
        const map = this.data.getValue();
        return map.get(keyString) ?? null;
    }

    public delete(key: K): void {
        const keyString = key[ToStringTrait]();
        const map = this.data.getValue();
        map.delete(keyString);
        this.data.setValue(map);
    }
}

const htmlElement2id: WeakMap<Element, TraderChatMessageModelId> = new WeakMap();

class MessageState {
    private element: HTMLElement | null = null;
    @observable public isVisibleFlag: boolean = false;

    public constructor(
        private readonly observer: IntersectionObserver,
        public readonly id: TraderChatMessageModelId
    ) {}

    public setElement(newElement: HTMLElement | null): void {
        if (this.element === newElement) {
            return;
        }

        if (this.element !== null) {
            this.observer.unobserve(this.element);
        }

        if (newElement !== null) {
            this.observer.observe(newElement);
        }

        this.element = newElement;
    }

    public scrollIntoView(): void {
        if (this.element === null) {
            console.error(`scrollIntoView: element is null`, { id: this.id });
        } else {
            this.element.scrollIntoView();
        }
    }
}

type FullIdType = ['typing'] | ['message', string];

export class TraderChatMessageModelId {
    protected nominal: 'nominal' = 'nominal' as const;

    private static get = AutoMap.create((id: FullIdType) => new TraderChatMessageModelId(id));

    private constructor(private readonly fullId: FullIdType) {}

    public static forTyping(): TraderChatMessageModelId {
        return TraderChatMessageModelId.get(['typing']);
    }

    public static forMessage(id: string): TraderChatMessageModelId {
        return TraderChatMessageModelId.get(['message', id]);
    }

    public get messageId(): string | null {
        if (this.fullId[0] === 'message') {
            return this.fullId[1];
        }

        return null;
    }

    public [ToStringTrait](): string {
        return JSON.stringify(this.fullId);
    }

    public toDisplay(): string {
        switch (this.fullId[0]) {
            case 'typing': {
                return 'typing';
            }
            case 'message': {
                return `messageId=${this.fullId[1]}`;
            }
            default: {
                return assertNever('', this.fullId[0]);
            }
        }
    }
}

export class TraderChatMessageModel {
    private readonly data: MapObserved<TraderChatMessageModelId, MessageState> = new MapObserved();

    private readonly observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        for (const entrie of entries) {
            const id = htmlElement2id.get(entrie.target);

            if (id !== undefined && id.messageId !== null) {
                const state = this.data.get(id);
                if (state !== null) {
                    state.isVisibleFlag = entrie.intersectionRatio > 0;

                    if (state.isVisibleFlag) {
                        const userId = this.common.session.userId;

                        if (userId !== null) {
                            const message = TraderChatWebsocketData.get(this.common, userId).messages.find(
                                (message) => message.id === id.messageId
                            );

                            if (message !== undefined) {
                                TraderChatState.get(this.common).readMessage(message);
                            }
                        }
                    }
                }
            }
        }
    }, observerOptions);

    public constructor(private readonly common: Common) {}

    public setRef = (id: TraderChatMessageModelId, ref: HTMLElement | null): void => {
        if (ref === null) {
            const element = this.data.get(id);

            if (element === null) {
                console.error(`setRef: expected element id=${id.toDisplay()}`);
                return;
            }

            element.setElement(null);
            this.data.delete(id);
        } else {
            htmlElement2id.set(ref, id);

            const state = this.data.get(id) ?? new MessageState(this.observer, id);
            state.setElement(ref);
            this.data.set(id, state);
        }
    };

    public isVisible = (id: TraderChatMessageModelId): boolean => {
        return this.data.get(id)?.isVisibleFlag ?? false;
    };

    public isRendered(id: TraderChatMessageModelId): boolean {
        return this.data.get(id) !== null;
    }

    public scrollIntoView(id: TraderChatMessageModelId): void {
        const state = this.data.get(id);

        if (state === null) {
            console.error('scrollIntoView - missing', id.toDisplay());
        } else {
            state.scrollIntoView();
        }
    }

    private scrollToMessage = async (messageId: TraderChatMessageModelId): Promise<void> => {
        await when(() => this.isRendered(messageId), {
            timeout: 10_000,
        });

        this.scrollIntoView(messageId);
    };

    private scrollToMessageWithResume = async (messageId: TraderChatMessageModelId): Promise<void> => {
        for (let i = 0; i < 10; i++) {
            await this.scrollToMessage(messageId);
            await timeout(1000);

            if (this.isVisible(messageId)) {
                break;
            }
        }
    };

    public subscribe(common: Common, userId: number): () => void {
        const websocketData = TraderChatWebsocketData.get(common, userId);

        autorun(async (dispose): Promise<void> => {
            const lastMessage = websocketData.getLastStandardMessage();
            if (lastMessage === null) {
                return;
            }

            const lastMessageId = TraderChatMessageModelId.forMessage(lastMessage.id);

            if (this.isRendered(lastMessageId)) {
                dispose.dispose();
                await this.scrollToMessageWithResume(lastMessageId);
            }
        });

        const unsubscribe = websocketData.onWhenNewMessage(async (message): Promise<void> => {
            const lastMessage = websocketData.getLastStandardMessage();

            const lastMessageVisible =
                lastMessage !== null && this.isVisible(TraderChatMessageModelId.forMessage(lastMessage.id));

            if (lastMessageVisible === false) {
                return;
            }

            if (message.type === 'typing' && message.sender.type === 'staff') {
                const typingId = TraderChatMessageModelId.forTyping();
                await this.scrollToMessageWithResume(typingId);
                return;
            }

            if (message.type === 'standard') {
                const messageId = TraderChatMessageModelId.forMessage(message.id);
                await this.scrollToMessageWithResume(messageId);
            }
        });

        return () => {
            unsubscribe();
        };
    }
}
