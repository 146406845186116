import styled from '@emotion/styled';

import { LayoutType } from 'src/domains/layouts/config/features/types';

import { withConfig } from 'src/withConfig';



interface WrapperProps {

    margin?: string;

    layout?: LayoutType;

}



export const ErrorWrapper = withConfig(theme => styled('div', { label: 'ErrorWrapper' })<WrapperProps>`

    background-color: ${theme.star.statuses.error.bgColor};

    border-radius: ${({ layout }): string => layout === 'secondary' ? '0 0 4px 4px' : '0'};

    color: ${theme.star.statuses.error.txtColor};

    display: flex;

    font-weight: ${theme.star.fontWeight.regular};

    margin: 0 8px 8px;

    padding: ${({ layout }): string => layout === 'secondary' ? '8px 8px 4px 8px' : '4px 8px'};

    text-align: left;

`);

