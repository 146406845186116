import styled from '@emotion/styled';
import { Spinner } from 'src/domains/layouts/shared/assets/icons/Spinner';
import { withConfig } from 'src/withConfig';

export const SpinnerWrapper = styled('div', { label: 'SpinnerWrapper' })`
    left: 0;
    width: 24px;
    right: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 24px;
`;

export const SpinnerDark = styled(Spinner, { label: 'SpinnerDark' })`
    width: 24px;
`;

export const SpinnerLight = withConfig(theme => styled(Spinner, { label: 'SpinnerLight' })`
    stroke: ${theme.star.signUp.bgColorTertiary};
    width: 24px;
`);
