import React, { useState } from 'react';
import { SelectionItemStyle } from './SelectionRaceCard.style';
import { observer } from 'src/utils/mobx-react';
import { SelectionRaceCardState } from './SelectionRaceCard.state';
// eslint-disable-next-line no-restricted-imports
import { useAsObservableSource } from 'mobx-react-lite';
import { useAppStateContext } from 'src/appState/AppState';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
interface PropsType {
    selectionId: SelectionId | undefined;
    sp?: boolean;
    borderTop?: boolean;
    borderRight?: boolean;
    borderBottom?: boolean;
    borderLeft?: boolean;
    isFinished?: boolean;
    isRacingPage: boolean;
}

export const SelectionRaceCard = observer('SelectionRaceCard', (propsIn: PropsType) => {
    const props = useAsObservableSource(propsIn);
    const { borderTop, borderRight, borderBottom, borderLeft, isFinished } = props;

    const { appSportsBookState } = useAppStateContext();
    const { betSlipState } = appSportsBookState;

    const common = useCommon();
    const { config } = ConfigComponents.get(common);
    const { basicData } = BasicDataModel.get(common);
    const starRouter = StarRouter.get(common);

    const [state] = useState(() => new SelectionRaceCardState(betSlipState, starRouter, props, basicData));

    if (state.selectionView === null) {
        const selectionId: string | number | undefined =
            props.sp === true ? `sp-${props.selectionId?.toOldId() ?? 'id-undefined'}` : props.selectionId?.toOldId();

        if (config.raceSummaryRowHorseRacingNew) {
            return (
                <SelectionItemStyle
                    href='#'
                    data-test-selection-id={selectionId}
                    onClick={state.chooseNew}
                    isFinished={isFinished}
                    borderBottom={borderBottom}
                    borderLeft={borderLeft}
                    borderRight={false}
                    borderTop={borderTop}
                >
                    -
                </SelectionItemStyle>
            );
        }
        return (
            <SelectionItemStyle
                href='#'
                data-test-selection-id={selectionId}
                onClick={state.chooseNew}
                isFinished={isFinished}
                borderBottom={borderBottom}
                borderLeft={borderLeft}
                borderRight={borderRight}
                borderTop={borderTop}
            >
                -
            </SelectionItemStyle>
        );
    }

    const { priceChange, suspended, price } = state.selectionView;
    const isEmpty = price === undefined;

    const selectionId: string | number | undefined =
        props.sp === true ? `sp-${props.selectionId?.toOldId() ?? 'id-undefined'}` : props.selectionId?.toOldId();

    return (
        <SelectionItemStyle
            href='#'
            data-test-selection-id={selectionId}
            onClick={state.chooseNew}
            isFinished={isFinished}
            isSelected={state.isSelected}
            isSuspended={suspended}
            isEmpty={isEmpty}
            isOddsUp={priceChange === 'up'}
            isOddsDown={priceChange === 'down'}
            borderTop={borderTop}
            borderRight={borderRight}
            borderBottom={borderBottom}
            borderLeft={borderLeft}
        >
            {state.getOddsValue}
        </SelectionItemStyle>
    );
});
