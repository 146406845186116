import React from 'react';
import { MessagesList } from 'src/domains/players/webview/components/Account/traderChat/components/MessagesList';
import { ChatInput } from 'src/domains/players/webview/components/Account/traderChat/components/ChatInput';
import { Error } from 'src/domains/players/webview/components/Account/traderChat/components/Error';
import { observer } from 'src/utils/mobx-react';
import { TraderChatWrapper } from 'src/domains/players/webview/components/Account/traderChat/TraderChat.style';
import { NotificationBar } from 'src/domains/players/webview/components/Account/traderChat/components/NotificationBar';
import { useCommon } from 'src/domains/common/Common';

export const TraderChat = observer('TraderChat', () => {
    const common = useCommon();

    const userId = common.session.userId;
    if (userId === null) {
        return null;
    }

    return (
        <TraderChatWrapper data-test='trader-chat'>
            <NotificationBar />
            <MessagesList userId={userId} />
            <ChatInput />
            <Error />
        </TraderChatWrapper>
    );
});
