import { Session } from 'src_common/sdk/session';
import { OpenapiProxyCustomerTransactionHistoryResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_transaction_history';
import {
    openapiProxyAnonymousSportsRequest,
    OpenapiProxyAnonymousSportsResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_anonymous_sports';
import { OpenapiProxyCustomerNetDepositResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_net_deposit';
import { OpenapiProxyAnonymousFindPageResponse200Type } from 'src/api_openapi/generated/openapi_proxy_anonymous_find_page';

import { computed, makeObservable } from 'mobx';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { CurrencySymbolType, CurrencyType } from 'src_common/common/amount/website-money/currency';
import { Amount } from 'src_common/common/amount/Amount';
import {
    OpenapiProxyAnonymousRequestPasswordResetParamsType,
    openapiProxyAnonymousRequestPasswordResetRequest,
    OpenapiProxyAnonymousRequestPasswordResetResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_anonymous_request_password_reset';
import {
    openapiProxyAnonymousMobileAppUpdateRequest,
    OpenapiProxyAnonymousMobileAppUpdateResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_anonymous_mobile_app_update';
import { Common } from 'src/domains/common/Common';
import {
    BasicDataModel,
    AccountBasicDataType as AccountBasicDataTypeNew,
    UserBasicDataType,
} from 'src/domains/players/state/BasicDataModel';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';

export type AccountBasicDataType = AccountBasicDataTypeNew;
export type CustomerTransactionHistoryType = OpenapiProxyCustomerTransactionHistoryResponse200Type;
export type CustomerNetDepositType = OpenapiProxyCustomerNetDepositResponse200Type;
export type PageCMSType = OpenapiProxyAnonymousFindPageResponse200Type['result'];

export class SdkCustomer {
    /**
     * @deprecated - please use Common.session
     */
    public readonly session: Session;
    /**
     * @deprecated - please use Common.models
     */
    public readonly models: ModelsState;

    public static get = AutoWeakMap.create((common: Common) => new SdkCustomer(common));

    private constructor(public readonly common: Common) {
        makeObservable(this);
        this.session = common.session;
        this.models = common.models;
    }

    /**
     * @deprecated - please use BasicDataModel.get(this.common).basicData
     */
    public get basicData(): UserBasicDataType {
        return BasicDataModel.get(this.common).basicData;
    }

    /**
     * @deprecated - please use BasicDataModel.get(this.common).basicData
     */
    @computed public get currency(): CurrencyType {
        return BasicDataModel.get(this.common).currency;
    }

    /**
     * @deprecated - please use BasicDataModel.get(this.common).money(...)
     */
    public money = (amount: Amount | null | undefined, withoutZeros?: boolean): string => {
        return BasicDataModel.get(this.common).money(amount, withoutZeros);
    };

    /**
     * @deprecated - please use BasicDataModel.get(this.common).moneySymbol
     */
    @computed public get moneySymbol(): CurrencySymbolType {
        return BasicDataModel.get(this.common).moneySymbol;
    }

    public getVersionList(): Promise<OpenapiProxyAnonymousMobileAppUpdateResponse200Type> | null {
        return this.session.call(openapiProxyAnonymousMobileAppUpdateRequest, {});
    }

    public getSportsList(): Promise<OpenapiProxyAnonymousSportsResponse200Type> {
        return this.session.call(openapiProxyAnonymousSportsRequest, {});
    }

    public requestResetPassword(
        params: OpenapiProxyAnonymousRequestPasswordResetParamsType
    ): Promise<OpenapiProxyAnonymousRequestPasswordResetResponse200Type> {
        return this.session.call(openapiProxyAnonymousRequestPasswordResetRequest, params);
    }
}
