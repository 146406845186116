import React from 'react';
import { computed, observable, makeObservable } from 'mobx';
import { MarketHeader } from 'src/domains/sportsbook/webview/components/marketHeader/MarketHeader';
import { SelectionGroup } from 'src/domains/sportsbook/webview/components/selectionsGroup/SelectionGroup';
import { GoalscorerMarketGroup } from 'src/domains/sportsbook/webview/components/goalscorerMarketGroup/GoalscorerMarketGroup';
import { EventMarketItemType } from 'src_common/common/websocket2/modelsApi/EventMarkets';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext, AppState } from 'src/appState/AppState';
import {
    MarketGroupWrapper,
    MarketSeparator,
} from 'src/domains/sportsbook/webview/components/eventMarkets/marketGroup/MarketGroup.style';
import { MarketFiltersForSportState } from 'src/domains/sportsbook/state/marketFiltersState/MarketFiltersForSportState';
import { EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';
import { sortSelectionsByCriteria } from 'src_common/utils/sport/sort';
import { OutrightMarket } from 'src/domains/sportsbook/webview/components/eventMarkets/marketGroup/outrightMarket/OutrightMarket';
// Define placeholders and their replacements
// otherwise the match will be replaced with an empty string
const PLACEHOLDERS = (match: string, getTranslation: (key: string, defaultText: string) => string): string => {
    switch (match) {
        case '${home}':
            return getTranslation('events.market-group.placeholders.home', 'Home');
        case '${away}':
            return getTranslation('events.market-group.placeholders.away', 'Away');
        case '${homesubparticipant}':
            return getTranslation('events.market-group.placeholders.home-subparticipant', 'Home Subparticipant');
        case '${awaysubparticipant}':
            return getTranslation('events.market-group.placeholders.away-subparticipant', 'Away Subparticipant');
        case '${player}':
            return getTranslation('events.market-group.placeholders.player', 'Player');
        default:
            return '';
    }
};

interface StateType {
    name: string;
    templateId: string;
    marketsNew: Array<MarketId>;
    selections: Record<number | string, SelectionModel[]>;
}

function buildStateNew(markets: Array<EventMarketItemType>, appState: AppState): StateType {
    const marketsList = markets;
    const mark = markets[0];
    const templateId = mark === undefined ? '' : mark.templateId;
    const { languagesState } = appState.appLayoutsState;

    // Replace any match placeholders with an alternative
    const name =
        mark === undefined
            ? ''
            : mark.tags['group-template'] === 'yes'
              ? mark.templateName.replace(/(\s\${line})|\${\w+(-)?\w+}/gi, (match) =>
                    PLACEHOLDERS(match, languagesState.getTranslation)
                )
              : mark.name;

    const marketsNew: Array<MarketId> = [];
    for (const market of markets) {
        marketsNew.push(market.id2);
    }

    const selections: Record<string, SelectionModel[]> = {};
    marketsList.forEach((market: EventMarketItemType) => {
        const fullMarket = appState.appSportsBookState.models.getMarket(market.id);
        if (fullMarket === null) {
            selections[market.id] = [];
            return;
        }
        const displayOrder = fullMarket.displayOrderTag;
        const order = displayOrder !== undefined && displayOrder !== '-' ? displayOrder : 'by-creation';
        const marketSelections = fullMarket.selections.filter((x) => x.display);

        selections[market.id] = sortSelectionsByCriteria(marketSelections, order);
    });

    return {
        name,
        templateId,
        marketsNew,
        selections,
    };
}

interface PropsType {
    type: string;
    eventId: EventId;
    markets: Array<EventMarketItemType>;
    order: number;
}

class MarketGroupState {
    @observable public isOpenInner: boolean | null = null;

    public constructor(
        private readonly order: number,
        private readonly marketFiltersForSportState: MarketFiltersForSportState | null
    ) {
        makeObservable(this);
    }

    @computed public get isOpen(): boolean {
        if (this.isOpenInner === null) {
            return this.isOpenOnStart;
        }

        return this.isOpenInner;
    }

    @computed public get isOpenOnStart(): boolean {
        if (this.marketFiltersForSportState !== null) {
            if (this.marketFiltersForSportState.activeFilter === 'popular') {
                return true;
            }
        }
        return this.order <= 4;
    }

    public setIsOpen = (): void => {
        if (this.isOpenInner === null) {
            this.isOpenInner = !this.isOpenOnStart;
        } else {
            this.isOpenInner = !this.isOpenInner;
        }
    };
}

export const MarketGroup = observer('MarketGroup', (props: PropsType) => {
    const appState = useAppStateContext();
    const { appSportsBookState } = appState;
    const { eventId, type, order } = props;
    const event = eventId.getEventModel();
    const marketFiltersForSportState =
        event === null ? null : appSportsBookState.getMarketFiltersBySport(event.sport, eventId);
    const [marketGroupState] = React.useState(() => new MarketGroupState(order, marketFiltersForSportState));

    const state = buildStateNew(props.markets, appState);
    const { marketsNew, selections, name, templateId } = state;
    const marketId = marketsNew[0] === undefined ? undefined : marketsNew[0];
    const market = marketId?.getModel() ?? null;
    if (market === null) {
        return null;
    }

    const marketDisplayTemplate = market.getTag('display-template');
    // Redirect to special components only if no market display template was chosen or is a goalscorer
    const specialMarketType =
        marketDisplayTemplate !== undefined && /goalscorer/.exec(marketDisplayTemplate) !== null ? 'goalscorer' : type;

    if (specialMarketType === 'goalscorer') {
        return (
            <GoalscorerMarketGroup
                eventId={eventId}
                markets={marketsNew}
                selections={selections}
                isOpen={marketGroupState.isOpen}
                onClick={marketGroupState.setIsOpen}
            />
        );
    } else if (specialMarketType === 'custom-outrights-market') {
        return (
            <OutrightMarket
                eventId={eventId}
                markets={marketsNew}
                selections={selections}
                isOpen={marketGroupState.isOpen}
                onClick={marketGroupState.setIsOpen}
            />
        );
    }

    return (
        <>
            <MarketGroupWrapper>
                <MarketHeader
                    name={name}
                    templateId={templateId}
                    isOpen={marketGroupState.isOpen}
                    onClick={marketGroupState.setIsOpen}
                    eventId={eventId}
                    marketId={marketId}
                />
                {marketGroupState.isOpen && marketId !== undefined && (
                    <SelectionGroup
                        eventId={eventId}
                        marketIdMain={marketId}
                        selections={selections}
                    />
                )}
            </MarketGroupWrapper>

            {marketGroupState.isOpen ? null : <MarketSeparator />}
        </>
    );
});
