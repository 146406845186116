import { Amount } from 'src_common/common/amount/Amount';
import { action, computed, observable, makeObservable } from 'mobx';
import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import {
    WithdrawSwiftyGlobalProviderSteps,
    WithdrawSwiftyGlobalProviderStepsType,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawSwiftyGlobalProvider/WithdrawSwiftyGlobalProvider';
import { AccountState } from 'src/domains/players/shared/Types';
import {
    validateAmountRequire,
    validateAmountPattern,
    validateMaxAmount,
    validateMinAmount,
} from 'src/domains/players/webview/components/ValidatorsNew';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import {
    GlobalValidationDepositSuccessResponse,
    swiftyGlobalValidationDeposit,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/billingInfoFiledsValidation/swiftyBillingInfoFieldsValidation';

export class WithdrawSwiftyGlobalProviderState {
    public stepsState: WithdrawSwiftyGlobalProviderSteps;
    @observable public errorMessage: Array<string> = [];

    public amountState: FormInputState<string, Amount>;

    @observable public withdrawSuccessMessage: string | null = null;
    @observable public withdrawErrorMessage: string | null = null;
    @observable public isWithdrawFormSubmitting = false;

    public constructor(
        private readonly configComponents: ConfigComponents,
        private readonly account: AccountState
    ) {
        makeObservable(this);
        this.stepsState = new WithdrawSwiftyGlobalProviderSteps();

        this.amountState = FormInputState.new('')
            .map(validateAmountRequire)
            .map(validateAmountPattern)
            .map(
                validateMinAmount(
                    new Amount(this.configComponents.config.minWithdrawAmount),
                    () => account.usersState.moneySymbol,
                    'ERROR_MIN_WITHDRAW'
                )
            )
            .map(
                validateMaxAmount(
                    () => this.playableBalance,
                    () => account.usersState.moneySymbol,
                    'ERROR_MAX_WITHDRAW'
                )
            );
    }

    @computed private get playableBalance(): Amount {
        const value = this.account.usersState.walletData.valueReady?.playableBalance ?? 0;

        return this.configComponents.precision.newFromAnything(value);
    }

    @computed public get showBalance(): string {
        return this.account.usersState.money(this.playableBalance);
    }

    public handlePaymentAdditionsChange = (amount: Amount): void => {
        this.amountState.setValue(amount.value);
        this.amountState.setAsVisited();
    };

    @action public submitWithdrawForm = async (): Promise<void> => {
        this.amountState.setAsVisited();

        this.isWithdrawFormSubmitting = true;
        this.withdrawSuccessMessage = null;
        this.withdrawErrorMessage = null;

        try {
            await this.submitAmountForm();
        } catch (e) {
            this.withdrawErrorMessage = getErrorByCode('ERROR_UNKNOWN');
        }
    };

    private submitAmountForm = async (): Promise<void> => {
        const basicData = this.validationUserDataInfo();

        if (basicData === null) {
            return;
        }

        if (this.amountState.result.value.type === 'error') {
            this.isWithdrawFormSubmitting = false;
            return;
        }

        // temporary, change when new version of wallet will be available
        // eslint-disable-next-line no-restricted-globals
        const amountToWithdraw = Number(this.amountState.result.value.data.value);

        try {
            const { iframeUrl } = await this.account.usersState.swiftyGlobaWithdrawalInitiate({
                requestBody: {
                    amount: amountToWithdraw,
                    currency: basicData.data.currency,
                    customer: basicData.data.body,
                    ip: this.account.ipUser ?? '127.0.0.1',
                    theme: 'light',
                },
            });

            this.stepsState.redirectToIframe(iframeUrl);
            this.isWithdrawFormSubmitting = false;
        } catch {
            this.isWithdrawFormSubmitting = false;
            this.stepsState.redirectToFailureView('serverIssue');
        }
    };

    @computed public get currentStep(): WithdrawSwiftyGlobalProviderStepsType {
        return this.stepsState.step;
    }

    private validationUserDataInfo = (): null | GlobalValidationDepositSuccessResponse => {
        const basicData = this.account.account?.basicDataReady ?? null;
        const billingInfo = swiftyGlobalValidationDeposit(basicData);

        if (billingInfo?.type === 'error') {
            this.errorMessage = billingInfo.fieldsRequired;
            return null;
        } else if (billingInfo === undefined) {
            this.errorMessage = ['unkown'];
            return null;
        }

        return billingInfo;
    };
}
