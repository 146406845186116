import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { ButtonSpinnerWrapper, ButtonUniversalWrapper } from 'src/domains/layouts/webview/components/common/button/Button.style';

export type ButtonVersion =
    'primary' |
    'secondary' |
    'secondary-light' |
    'secondary-dark' |
    'tertiary-dark' |
    'tertiary-light';

interface PropsType {
    children: React.ReactNode;
    className?: string;
    dataTest?: string;
    disabled?: boolean;
    isLoading?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    size: 'large' | 'medium' | 'small' | 'xs';
    type?: 'button' | 'reset' | 'submit';
    /**
        * @description layout: star
        * - primary | secondary-dark | secondary-light
        * -----------------------------
        * @description layout: luckyKing
        * - primary | secondary | tertiary-dark | tertiary-light
    */
    version?: ButtonVersion;
    id?: string;
}

export const Button = observer('Button', ({ children,
    className,
    dataTest,
    disabled = false,
    isLoading = false,
    onClick,
    size = 'xs',
    type = 'button',
    version,
}: PropsType) => {
    const { config: { layout } } = useAppStateContext();

    return (
        <ButtonUniversalWrapper
            className={className}
            data-test={dataTest}
            data-test-button-disabled={disabled}
            disabled={disabled}
            layout={layout}
            isLoading={isLoading}
            onClick={onClick}
            size={size}
            type={type}
            version={version}
        >
            { isLoading ? <ButtonSpinnerWrapper size={size} /> : children }
        </ButtonUniversalWrapper>
    );
});
