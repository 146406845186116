import { AppState } from 'src/appState/AppState';
import { CarouselEventNavigationState } from 'src/domains/layouts/webview/components/eventNavigation/CarouselEventNavigationState';
import { computed, makeObservable } from 'mobx';
import { EventsCollectionQueryModel } from 'src_common/common/websocket2/models/EventsCollectionQueryModel';
import { OptionType, sortOptions } from 'src/domains/layouts/webview/components/eventNavigation/common';

export class EventState {
    private readonly appState: AppState;
    private readonly state: CarouselEventNavigationState;

    public constructor(appState: AppState, state: CarouselEventNavigationState) {
        makeObservable(this);
        this.appState = appState;
        this.state = state;
    }

    @computed.struct private get collectionEvents(): EventsCollectionQueryModel | null {
        const page = this.state.pageType;

        if (page === null) {
            return null;
        }

        if (page.type === 'competition') {
            return this.appState.appLayoutsState.getListOfSportAndCompetition(page.sport, page.competition).getQuery();
        }

        if (page.type === 'event') {
            const event = this.state.event;

            if (event !== null) {
                return (
                    this.appState.appLayoutsState
                        .getListOfSportAndCompetition(event.sport, event.competition2)
                        .getQuery() ?? null
                );
            }
        }

        return null;
    }

    @computed public get active(): string | null {
        const event = this.state.event;

        if (event !== null) {
            return event.id.toString();
        }

        return null;
    }

    @computed public get eventsData(): Array<OptionType> {
        const collectionEvents = this.collectionEvents;

        if (collectionEvents === null) {
            return [];
        }

        const list: Array<OptionType> = [];

        for (const event of collectionEvents.events) {
            list.push({
                value: event.id.toString(),
                sportId: undefined,
                label: event.name,
            });
        }

        list.sort(sortOptions);
        return list;
    }
}
