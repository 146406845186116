import { computed, makeObservable } from 'mobx';
import { Overlay } from 'src/domains/layouts/state/overlay/Overlay';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { Session } from 'src_common/sdk/session';
import { NetworkConnectionState } from 'src/domains/layouts/state/networkConnectionState/NetworkConnectionState';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { PopupState } from 'src/domains/layouts/state/popupState/PopupState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

interface CallbacksType {
    getSpecialEventsLandingPageShouldDisplay: () => boolean;
    getSearchStateIsShow: () => boolean;
}
export class IsStaticState {
    public constructor(
        private readonly config: ConfigComponents,
        private readonly router: StarRouter,
        private readonly overlay: Overlay,
        private readonly session: Session,
        private readonly popupState: PopupState,
        private readonly networkConnectionState: NetworkConnectionState,
        private readonly callbacks: CallbacksType
    ) {
        makeObservable(this);
    }

    @computed public get isStatic(): boolean {
        const accountView = this.router.accountView;
        const innerWidth = getWindowInnerWidth() ?? 0;
        const isNewAccountDrawer = this.config.config.accountDrawerNew;

        if (this.popupState.popups?.type === 'gameModal') {
            return true;
        }

        if (accountView !== null) {
            if (this.session.isAuthorized) {
                if (accountView.account === 'login') {
                    return false;
                }
            }

            if (accountView.account === 'signup') {
                if (isNewAccountDrawer) {
                    return false;
                }

                return true;
            }

            if (accountView.account === 'finish-kyc') {
                return true;
            }

            if (innerWidth >= 1200 && this.overlay.isMenuOpen === true) {
                return false;
            }
        }

        if (this.overlay.shouldOverlayOpenWithIsStatic) {
            return true;
        }

        if (this.callbacks.getSpecialEventsLandingPageShouldDisplay()) {
            return true;
        }

        if (innerWidth >= 1200 && this.callbacks.getSearchStateIsShow()) {
            return true;
        }

        if (this.networkConnectionState.showOfflineMessage) {
            return true;
        }

        return false;
    }
}
