import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { KycWarning } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/kycWarning/KycWarning';
import { PaymentAdditions } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/PaymentsAdditions';
import {
    CardInfo,
    CashBalance,
    CashBalanceWrapper,
    DepositTabWrapper,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/DepositProcedure.style';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { InfoContainer, InfoWarningIconWrapper, PayButton } from './PaysafeTab.style';
import { PaysafeTabState } from './PaysafeTab.state';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { useCommon } from 'src/domains/common/Common';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { RemainingLimit } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/remainingLimit/RemainingLimit';
interface PropsType {
    state: PaysafeTabState;
}

export const PaysafeTab = observer('PaysafeTab', ({ state }: PropsType): JSX.Element => {
    const common = useCommon();
    const {
        config: { showKycStatus, rollingNetDepositLimit },
        appPlayersState: { usersState },
    } = useAppStateContext();
    const languagesState = LanguagesState.get(common);

    return (
        <DepositTabWrapper>
            <CashBalanceWrapper>
                <I18n
                    langKey='account.top-up.cash-balance-label'
                    defaultText='Cash balance'
                />
                <CashBalance>{state.showBalance}</CashBalance>
            </CashBalanceWrapper>

            <Input
                type='text'
                maxLength={10}
                placeholder='0.00'
                currency={usersState.moneySymbol}
                label={
                    <I18n
                        langKey='account.top-up.description-label'
                        defaultText='Deposit amount'
                    />
                }
                state={state.depositAmount}
                onChange={state.onChange}
                onBlur={state.setAmount}
                colorTheme='light'
                inputHasBackground={false}
                dataTest='deposit-amount-input'
            />

            <PaymentAdditions
                key='payment-additions'
                currency={usersState.moneySymbol}
                value={state.depositAmount.value}
                onChange={state.handleAdditionsChange}
            />

            {rollingNetDepositLimit === false || state.remainingLimitState.getRemainingLimit === undefined ? null : (
                <RemainingLimit
                    languagesState={languagesState}
                    value={state.remainingLimitState.getRemainingLimit.format(BasicDataModel.get(common).currency)}
                />
            )}

            {state.errorMessage.length > 0
                ? state.errorMessage.map((errorString) => (
                      <Messages
                          type='error'
                          marginTop='8px'
                          key={errorString}
                          message={errorString}
                      />
                  ))
                : null}

            <PayButton
                size='large'
                disabled={state.isButtonDisabled}
                onClick={state.submitDepositForm}
                type='submit'
                dataTest='deposit-pay-debit-card'
            >
                <I18n
                    langKey='account.top-up.pay-card-button'
                    defaultText='Pay using debit card'
                />
            </PayButton>

            <CardInfo data-test='deposit-card-info'>
                <I18n
                    langKey='account.top-up.top-upcard-info'
                    defaultText='Credit cards are no longer accepted'
                />
            </CardInfo>
            {showKycStatus === true ? <KycWarning isSignup={false} /> : null}
            <InfoContainer>
                <InfoWarningIconWrapper />
                New cards will be stored for future deposits and withdraw processing.
            </InfoContainer>
        </DepositTabWrapper>
    );
});
