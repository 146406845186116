import React, { useState } from 'react';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    DepositFailure,
    DepositViewType,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFailure/DepositFailure';
import { ZenetPayTabState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/zenetPayProvider/ZenetPayTab.state';
import { ZenetPayTab } from './ZenetPayTab';
import { ZenetPayQrCodeTab, ZenetPayQrCodeTabPropsType } from './ZenetPayQrCodeTab';
import { Amount } from 'src_common/common/amount/Amount';
import { useCommon } from 'src/domains/common/Common';

export type DepositStepsType =
    | {
          readonly type: 'set-method';
      }
    | {
          readonly type: 'qrCode-view';
          readonly amount: Amount;
          readonly qrCode: string;
          readonly qrCodeBase64: string;
      }
    | {
          readonly type: 'failure-view';
          readonly failureType: DepositViewType;
      };

export class ZenetPayDepositSteps {
    @observable.ref public step: DepositStepsType;

    public constructor() {
        makeObservable(this);
        this.step = {
            type: 'set-method',
        };
    }

    @action public redirectToSetMethod = (): void => {
        this.step = {
            type: 'set-method',
        };
    };

    @action public redirectToQrCodeView = ({ amount, qrCode, qrCodeBase64 }: ZenetPayQrCodeTabPropsType): void => {
        this.step = {
            type: 'qrCode-view',
            amount,
            qrCode,
            qrCodeBase64,
        };
    };

    @action public redirectToFailureView = (failureType: DepositViewType): void => {
        this.step = {
            type: 'failure-view',
            failureType,
        };
    };
}

export const ZenetPayJourney = observer('ZenetPayJourney', (): JSX.Element => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { configComponents, googleTagManager, languagesState } = appLayoutsState;
    const { accountHelperMail, minDepositAmount } = configComponents.config;
    const { accountState, usersState } = appPlayersState;
    const common = useCommon();
    const [state] = useState(
        () =>
            new ZenetPayTabState(
                accountState,
                usersState,
                common.trpcClient,
                googleTagManager,
                languagesState,
                new Amount(minDepositAmount),
                configComponents.precision
            )
    );

    const renderContent = (): JSX.Element => {
        switch (state.stepsState.step.type) {
            case 'set-method':
                return <ZenetPayTab state={state} />;
            case 'qrCode-view':
                return (
                    <ZenetPayQrCodeTab
                        amount={state.stepsState.step.amount}
                        qrCode={state.stepsState.step.qrCode}
                        qrCodeBase64={state.stepsState.step.qrCodeBase64}
                    />
                );
            case 'failure-view':
                return (
                    <DepositFailure
                        isSignup={false}
                        issueType={state.stepsState.step.failureType}
                        accountHelperMail={accountHelperMail}
                    />
                );
        }
    };

    return <>{renderContent()}</>;
});
