import * as React from 'react';
import styled from '@emotion/styled';

interface PropsType {
    className?: string
}

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

export const GolfIcon = ({ className }: PropsType): JSX.Element => (
    <SvgElement fill='#fff' className={className} width={20} height={22} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 22'>
        <path
            fill='#fill'
            fillRule='nonzero'
            d='M15.81 9.78l3.52-8.64a.25.25 0 000-.19.26.26 0 00-.14-.13L17.95.41a.25.25 0 00-.3.13L14.08 9a.42.42 0 00-.53.23l-.65 1.49A1.78 1.78 0 0110.59 12c-1.62-.1-2.86-.23-3.77-.32-.62-.06-1.06-.11-1.38-.12h-.65c-1.73-.1-3-.18-3.68.54a2.13 2.13 0 00-.43 1.76 7.61 7.61 0 001 3 3.48 3.48 0 002.48 1.51 3.5 3.5 0 002 1.49c.188.3.289.646.29 1v.26a.25.25 0 00.09.19l.39.31a.22.22 0 00.15.06.24.24 0 00.15-.06l.39-.31a.25.25 0 00.09-.19v-.26c0-.354.1-.701.29-1a3.45 3.45 0 002-1.45 4.11 4.11 0 003.51-2.38L16 10.27a.36.36 0 000-.27.33.33 0 00-.19-.22zm-8.25 7.4a.29.29 0 11.58 0 .29.29 0 01-.58 0zm.39 1.3a.28.28 0 11.29-.28.28.28 0 01-.24.28h-.05zm.57-1.75a.29.29 0 11.57.107.29.29 0 01-.57-.107zm.38 1.2a.29.29 0 110-.58.29.29 0 010 .58zm3.81-2.32a3 3 0 01-2.33 1.78 3.54 3.54 0 00.14-.93v-.25h.37a.24.24 0 00.02-.48h-.46a3.25 3.25 0 00-.64-1.35l1.07.07a.24.24 0 00.24-.23.24.24 0 00-.22-.22l-1.57-.1a3.42 3.42 0 00-4.18-.26l-2.21-.15a.24.24 0 00-.03.48l1.71.11a3.47 3.47 0 00-.78 1.27l-.9-.06a.24.24 0 00-.03.48l.79.05a3.71 3.71 0 00-.07.67c.003.287.044.573.12.85a2.21 2.21 0 01-1.34-.94.44.44 0 00-.08-.11 7 7 0 01-.82-2.57 1.35 1.35 0 01.2-1.09c.43-.44 1.58-.37 3-.29h.67c.29 0 .73.06 1.33.12.91.09 2.16.22 3.77.32a2.56 2.56 0 003.1-1.72l.5-1.13.57.24.34.15-2.28 5.29z'
        />
    </SvgElement>
);

