import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { assertNever } from 'src_common/common/assertNever';
import { useAppStateContext } from 'src/appState/AppState';
import { SignUpWelcomePage } from 'src/domains/players/webview/components/SignUp/signupTertiary/signupWelcome/SignupWelcomePage';
import { YourDetailsPage } from 'src/domains/players/webview/components/SignUp/signupTertiary/yourDetails/YourDetailsPage';
import {
    SignUpWrapper,
    SignUpWrapperOld,
    SignupOverlay,
} from 'src/domains/players/webview/components/SignUp/signupParts/Signup.style';
import { LoadingSpinner } from 'src/domains/layouts/webview/components/loaders/loadingSpinner/LoadingSpinner';
import { VerificationFailedPage } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/profileVerification/verificationFailed/VerificationFailedPage';
import { SignupType } from 'src/domains/players/webview/components/SignUp';

interface SignUpTertiaryStepsContainerPropsType {
    signUpState: SignupType;
}

export const SignUpTertiaryStepsContainer = observer(
    'SignUpTertiaryStepsContainer',
    ({ signUpState }: SignUpTertiaryStepsContainerPropsType) => {
        const { appPlayersState, appLayoutsState } = useAppStateContext();
        const { configComponents } = appLayoutsState;
        const { config } = configComponents;
        const { customKeyboard, signupPopupState } = appPlayersState;

        if (signUpState.type === 'SignupPrimary') {
            return <div>wrong signup</div>;
        }

        const { step } = signUpState.state.currentView;

        const renderContent = (): JSX.Element => {
            switch (step.type) {
                case 'create-account-cpf':
                    return (
                        <SignUpWelcomePage
                            state={signUpState.state.currentView.createAccountState.welcomeState}
                            signUpState={signUpState}
                        />
                    );
                case 'create-account-your-details':
                    return (
                        <YourDetailsPage
                            state={signUpState.state.currentView.createAccountState}
                            signUpState={signUpState}
                        />
                    );
                case 'verification-failed': //temporary
                    return <VerificationFailedPage signUpState={signUpState} />;
            }

            return assertNever('Steps in signUp', step);
        };

        const renderOverlay = (): JSX.Element | null => {
            if (signupPopupState.isAnyPopup === true) {
                return <SignupOverlay />;
            }
            return null;
        };

        if (config.accountDrawerNew) {
            return (
                <SignUpWrapper
                    ref={customKeyboard.setAsideElementRef}
                    data-test='SignUpStepsContainer'
                >
                    {signUpState.state.isLoading === false ? <>{renderContent()}</> : <LoadingSpinner />}
                    {renderOverlay()}
                </SignUpWrapper>
            );
        }

        return (
            <SignUpWrapperOld
                ref={customKeyboard.setAsideElementRef}
                shouldBeMoved={configComponents.config.noBetslip === false}
                data-test='SignUpStepsContainer'
            >
                {signUpState.state.isLoading === false ? <>{renderContent()}</> : <LoadingSpinner />}
                {renderOverlay()}
            </SignUpWrapperOld>
        );
    }
);
