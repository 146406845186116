import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface SportsLkIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const SportsLkIcon = observer(
    'SportsLkIcon',
    ({ className, onClick }: SportsLkIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                fill='#071126'
                height='24'
                onClick={onClick}
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fill='fill'
                    d='M19.07 4.93A9.94 9.94 0 0 0 12 2a9.94 9.94 0 0 0-7.07 2.93A9.93 9.93 0 0 0 2 12a9.94 9.94 0 0 0 2.93 7.07A9.94 9.94 0 0 0 12 22a9.94 9.94 0 0 0 7.07-2.93A9.94 9.94 0 0 0 22 12a9.94 9.94 0 0 0-2.93-7.07ZM9.23 3.1a9.28 9.28 0 0 1 5.54 0l.65 1.16L12 5.73 8.58 4.27l.65-1.16Zm-6.54 8.8a9.26 9.26 0 0 1 1.66-5.2l1.44-.26.29 3.71-2.5 2.77-.9-1Zm6.62 9a9.35 9.35 0 0 1-4.68-3.22l.11-1.17 3.65.71 2 3.13-1.08.56Zm.58-5.43-1.3-4.04L12 8.95l3.42 2.5-1.3 4.03H9.88Zm4.8 5.44-1.09-.56 2-3.13 3.66-.7.1 1.16a9.34 9.34 0 0 1-4.67 3.23Zm3.23-10.78.29-3.7 1.44.25a9.26 9.26 0 0 1 1.66 5.23l-.9.98-2.49-2.76Z'
                />
            </SvgElement>
        );
    }
);
