import { UniverseType } from 'src_common/common/universe';

type createGetTranslation = (getTranslation: (key: string, defaultText: string) => string) => string;
const ident = (param: createGetTranslation): createGetTranslation => param;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const MESSAGES = (universe: UniverseType | null) => ({
    'Event:suspended': ident(getTranslation => getTranslation('errors.event-suspended', 'This event is suspended')),
    'Market:suspended': ident(getTranslation => getTranslation('errors.market-suspended', 'This market is suspended')),
    'Market:sp-only': ident(getTranslation => getTranslation('errors.market-sp-only', 'This market is SP only')),
    'Selection:suspended': ident(getTranslation => getTranslation('errors.selection-suspended', 'This selection is suspended')),
    'Selection:unavailable': ident(getTranslation => getTranslation('errors.selection-unavailable', 'This selection is lo longer available')), //TODO: to check if suach a code exists
    'suspended': ident(getTranslation => getTranslation('errors.suspended', 'Something is suspended')),

    'Selection:resulted': ident(getTranslation => getTranslation('errors.selection-resulted', 'This selection is resulted')),
    'Market:tradedInPlay:started': ident(getTranslation => getTranslation('errors.market-traded-in-play-started', 'Market event can not be traded in play')),
    'Event:tradedInPlay:started': ident(getTranslation => getTranslation('errors.event-traded-in-play-started', 'Started event can not be traded in play')),
    'Selection:tradedInPlay:unacceptable': ident(getTranslation => getTranslation('errors.selection-traded-in-play', 'Event Started')),
    'Event:tradedInPlay:unacceptable': ident(getTranslation => getTranslation('errors.event-traded-in-play', 'This event is not traded in play')),
    'Market:tradedInPlay:unacceptable': ident(getTranslation => getTranslation('errors.market-traded-in-play', 'This market is not traded in play')),
    'Event:started': ident(getTranslation => getTranslation('errors.event-started', 'This event is already started so you can\'t bet on starting price')),

    'Event:not-found': ident(getTranslation => getTranslation('errors.event-not-found', 'This event doesn\'t exists')),
    'Market:not-found': ident(getTranslation => getTranslation('errors.market-not-found', 'This market doesn\'t exists')),
    'Selection:not-found': ident(getTranslation => getTranslation('errors.selection-not-found', 'This selection doesn\'t exists')),

    'Selection:increased': ident(getTranslation => getTranslation('errors.selection-increased', 'Price of this selection has increased')),
    'Selection:decreased': ident(getTranslation => getTranslation('errors.selection-decreased', 'Price of this selection has decreased')),

    'Selection:related': ident(getTranslation => getTranslation('errors.selection-related', 'There are related selections in bet')),
    'Selection:ValidationCodes.Related': ident(getTranslation => getTranslation('errors.selection-validation-codes-related', 'There are unaccepted related selections in bet')),

    'Account:suspended': ident(getTranslation => getTranslation('errors.account-suspended', 'Your account is suspended')),
    'Account:playbreak': ident(getTranslation => getTranslation('errors.account-playbreak', 'You cannot place bets as you have selected a Play Break')),
    'Account:playBreak:unacceptable': ident(getTranslation => getTranslation('errors.account-playbreak-unacceptable', 'You are currently on a Play Break')),
    'Account:complianceRestriction:unacceptable': ident(getTranslation => getTranslation('errors.account-playbreak-complianceRestriction', 'Your account is suspended')),

    'Bet:unacceptable': ident(getTranslation => getTranslation('errors.bet-unacceptable', 'Unacceptable type of bet')),
    'Bet:declined': ident(getTranslation => getTranslation('errors.bet-declined', 'Your bet has been declined. If you wish to discuss this bet further please call 08000 521 321 or contact a Trader in Trader Chat')),

    'stakePerLine:maximum': ident(getTranslation => getTranslation('errors.stake-per-line-maximum', 'You reached maximum bet')),
    // TODO: refactor this part - only temporary solution
    'stakePerLine:minimum': ident(getTranslation => {
        if (universe === 'luckyking') {
            return getTranslation('errors.stake-per-line-minimum', 'Bet need at least 0.00005 of stake');
        }

        return getTranslation('errors.stake-per-line-minimum', 'Bet need at least 1.00 of stake');
    }),

    'SettlementCode:unacceptable': ident(getTranslation => getTranslation('errors.settlement-code', 'Can\'t settle bet - something goes wrong')),
    'Bet:eachWay:unacceptable': ident(getTranslation => getTranslation('errors.each-way-unacceptable', 'This bet has no each way terms')),
    'Bet:eachWay:not-found': ident(getTranslation => getTranslation('errors.each-way-not-found', 'This bet has no each way terms')),
    'Leg:priceType:unacceptable': ident(getTranslation => getTranslation('errors.price-type-unacceptable', 'This leg price is unacceptable')),
    'Leg:priceType:missing': ident(getTranslation => getTranslation('errors.price-type-missing', 'This leg price is missing')),

    'Market:forecastsOffered:bet-type-not-allowed': ident(getTranslation => getTranslation('errors.forecasts-not-allowed', 'Forecasts are not allowed for this market')),
    'Market:tricastsOffered:bet-type-not-allowed': ident(getTranslation => getTranslation('errors.tricasts-not-allowed', 'Tricasts are not allowed for this market')),
    'Market:bet-type-not-allowed': ident(getTranslation => getTranslation('errors.bet-type-not-allowed', 'Bet type is not allowed')),
    'Market:settlement-blocked': ident(getTranslation => getTranslation('errors.settlement-blocked', 'Market has blocked settlements')),
    'Market:singles-only': ident(getTranslation => getTranslation('errors.singles-only', 'One of your selections can be placed only as Single Only')),


    'Cashout:cashout-disabled-for-account' : ident(getTranslation => getTranslation('errors.cashout.cashout-disabled-for-account','Cashout operation is disabled')),
    'Cashout:account-is-not-active' : ident(getTranslation => getTranslation('errors.cashout.account-is-not-active','Cashout is enabled only for active users')),
    'Cashout:cashout-value-changed' : ident(getTranslation => getTranslation('errors.cashout.cashout-value-changed','Cashout value has changed')),
    'Cashout:cashout-disabled-for-market' : ident(getTranslation => getTranslation('errors.cashout.cashout-disabled-for-market','Cashout operation is disabled for market')),
    'Event:event-suspended': ident(getTranslation => getTranslation('errors.cashout.event-suspended','Sorry, this event is currently suspended')),

    'missing': ident(getTranslation => getTranslation('errors.missing', 'Something is missing.')),
    'invalid': ident(getTranslation => getTranslation('errors.invalid.general', 'Some fields are invalid.')),

    'ERROR_UNKNOWN': ident(getTranslation => getTranslation('errors.unknown', 'Unknown error')),
    'ERROR_REQUIRED_FIELD': ident(getTranslation => getTranslation('errors.required-field', 'This field is required.')),
    'ERROR_INVALID_PERSONAL_ID': ident(getTranslation => getTranslation('errors.invalid-personal-id', 'Personal ID number must have exactly 13 digits and be a positive number')),
    'ERROR_CHECKED_FIELD': ident(getTranslation => getTranslation('errors.checked-field', 'You have to select this field.')),
    'ERROR_INVALID_EMAIL': ident(getTranslation => getTranslation('errors.invalid-email', 'Incorrect e-mail address.')),
    'ERROR_PHONE': ident(getTranslation => getTranslation('errors.phone', 'Phone number can contain only spaces and 7-15 numbers.')),
    'ERROR_PASSWORD': ident(getTranslation => getTranslation('errors.password', 'Password must be 8-20 characters that contain at least: 1 letter, 1 number and 1 special character: !#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~')),
    'ERROR_PASSWORD_LK': ident(getTranslation => getTranslation('errors.password:lk', 'Password must be 8 characters, at least: one digit plus one special character & one capital letter.')),
    'ERROR_PASSWORD_CAR': ident(getTranslation => getTranslation('errors.password:carousel', 'Letters (lowercase, uppercase) and a minimum of 1 alphanumeric or special character(@#$%^&+=); mix of upper and lower case letters; all alphanumeric or symbols (no letters) (@#$%^&+=), between 8 and 20.')),
    'ERROR_PASSWORD_CAR_SHORT': ident(getTranslation => getTranslation('errors.password-short', 'Your password should have two of the following options: lowercase, uppercase, numbers or special characters.')),
    'ERROR_INVALID_CITY': ident(getTranslation => getTranslation('errors.city', 'Only letters, numbers and few special: .-\'')),
    'ERROR_INVALID_ADDRESS': ident(getTranslation => getTranslation('errors.address', 'Only letters, numbers and few special: \'#$()*+,-.:;=?@[]^_`{|}~')),
    'ERROR_ALPHA_ONLY': ident(getTranslation => getTranslation('errors.alpha-only', 'Only letters are allowed.')),
    'ERROR_ALPHA_SPACES_ONLY': ident(getTranslation => getTranslation('errors.alpha-spaces-only', 'Only letters and spaces are allowed.')),
    'ERROR_ALPHA_SPACES_NUMBERS_ONLY': ident(getTranslation => getTranslation('errors.alpha-spaces-numbers-only', 'Only letters, numbers and spaces are allowed.')),
    'ERROR_NUMERIC': ident(getTranslation => getTranslation('errors.numeric', 'Only digits are allowed.')),
    'ERROR_CARD_NUMBER': ident(getTranslation => getTranslation('errors.card-number', 'Incorrect card number.')),
    'ERROR_CVV': ident(getTranslation => getTranslation('errors.cvv', 'Incorrect CVV number.')),
    'ERROR_INVALID_POSTCODE': ident(getTranslation => getTranslation('errors.invalid-post-code', 'Postcode can contain only letters, digits and hyphen (up to 10 chars)')),
    'ERROR_DECIMAL': ident(getTranslation => getTranslation('errors.decimal', 'Incorrect number.')),
    'ERROR_CONFIRM_EMAIL': ident(getTranslation => getTranslation('errors.confirm-email', 'This e-mail address is not the same.')),
    'ERROR_CONFIRM_PASSWORD': ident(getTranslation => getTranslation('errors.confirm-password', 'This password is not the same.')),
    'ERROR_MAX_LENGTH': ident(getTranslation => getTranslation('errors.max-length', 'This field can have maximum %length characters.')),
    'ERROR_INVALID_FIRST_NAME': ident(getTranslation => getTranslation('errors.first-name', 'First name starts from letter and contains letters and spaces.')),
    'ERROR_INVALID_SURNAME': ident(getTranslation => getTranslation('errors.surname', 'Surname starts from letter and contains letters, spaces and hyphens.')),
    'ERROR_INVALID_DATE': ident(getTranslation => getTranslation('errors.date', 'Please enter a valid date.')),
    'ERROR_INVALID_RANGE_DATE': ident(getTranslation => getTranslation('errors.range-date', 'This date must be after the ‘from’ date.')),
    'ERROR_MIN_WITHDRAW': ident(getTranslation => getTranslation('errors.min-withdraw', 'Minimum withdraw amount is %currencySymbol%minValue')),
    'ERROR_MAX_WITHDRAW': ident(getTranslation => getTranslation('errors.max-withdraw', 'Maximum withdraw amount is %currencySymbol%maxValue')),
    'ERROR_MAX_DEPOSIT': ident(getTranslation => getTranslation('errors.max-deposit', 'Maximum deposit amount is %currencySymbol%maxValue')),
    'ERROR_WITHDRAWABLE_BALANCE_AT_LEAST': ident(getTranslation => getTranslation('errors.withdrawable-balance-at-least', 'You have exceeded your withdrawable balance.')),

    'ERROR_PASSWORD_EQUAL_EMAIL': ident(getTranslation => getTranslation('errors.password-same-as-email', 'Password cannot be the same as email address.')),
    'ERROR_INCORRECT_FIRST_NAME': ident(getTranslation => getTranslation('errors.incorrect-first-name', 'First name can contain only letters')),
    'ERROR_INCORRECT_LAST_NAME': ident(getTranslation => getTranslation('errors.incorrect-last-name', 'Last name can contain only letters')),
    'ERROR_INCORRECT_OLD_EMAIL': ident(getTranslation => getTranslation('errors.incorrect-old-email', 'Incorrect old e-mail address')),
    'ERROR_OLD_EMAIL_EQUAL_NEW': ident(getTranslation => getTranslation('errors.old-email-equal-new', 'Your new email address needs to be different from the current one')),
    'ERROR_NEW_EMAIL_ALREADY_EXISTS': ident(getTranslation => getTranslation('errors.new-email-already-exists', 'New email address is already being used by another account')),

    'ERROR_INCORRECT_USERNAME_PASSWORD': ident(getTranslation => getTranslation('errors.incorrect-username-password', 'Incorrect e-mail or password.')),
    'ERROR_INVALID': ident(getTranslation => getTranslation('errors.invalid', 'This field is invalid.')),
    'ERROR_ALREADY_EXISTS': ident(getTranslation => getTranslation('errors.already-exists', 'This e-mail already exists.')),
    'ERROR_ACCOUNT_ALREADY_EXISTS': ident(getTranslation => getTranslation('errors.account-already-exists', 'Some of your personal details match an existing customer account. [Link {label="Click Here"}] to Login or click the Live Chat button below to chat with our team.')),
    'ERROR_TOO_YOUNG': ident(getTranslation => getTranslation('errors.too-young', 'Must be at least 18 years old.')),
    'ERROR_UNVERIFIED_CLIENT': ident(getTranslation => getTranslation('errors.unauthorized-client', 'Your account is suspended.')),

    'ERROR_REQUIRED_TERMS': ident(getTranslation => getTranslation('errors.required-terms', 'This must be checked.')),

    'INTERNAL_SERVER_ERROR_MESSAGE': ident(getTranslation => getTranslation('errors.internal-server', 'Sorry, we seem to have a problem. Please try again.')),
    'ERROR_ACCOUNT_SUSPENDED': ident(getTranslation => getTranslation('errors.account-suspended', 'Your account is suspended')),
    'ERROR_ACCOUNT_SUSPENDED_KYC_VERIFICATION': ident(getTranslation => getTranslation('errors.account-suspended-kyc-verification', 'Suspended after kyc verification')),
    'ERROR_ACCOUNT_SUSPENDED_FAILED_LOGIN_ATTEMPTS': ident(getTranslation => getTranslation('errors.account-suspended-failed-login-attempts', 'Your account has been suspended for security reasons. Please contact Customer Support at:')),
    'ERROR_ACCOUNT_SUSPENDED_CALL_CREDIT_VERIFICATION': ident(getTranslation => getTranslation('errors.account-suspended-call-credit-verification', 'Suspended after call credit verification')),
    'ERROR_ACCOUNT_BLOCKED': ident(getTranslation => getTranslation('errors.account-blocked', 'Your account is blocked')),
    'ERROR_ACCOUNT_CLOSED': ident(getTranslation => getTranslation('errors.account-closed', 'Your account is closed')),
    'ERROR_ACCOUNT_SELF_EXCLUDED': ident(getTranslation => getTranslation('errors.account-self-excluded', 'Your account is self excluded')),
    'ERROR_ACCOUNT_UNKNOWN_REASON': ident(getTranslation => getTranslation('errors.account-unknown-reason', 'Sorry, unknown status of account')),
    'ERROR_ACCOUNT_NOT_FOUND': ident(getTranslation => getTranslation('errors.account-not-found', 'Sorry, account not found')),

    'ERROR_COUNTRY_NOT_ALLOWED': ident(getTranslation => getTranslation('errors.country-not-allowed', 'The service is currently not available in your country.')),
    'ERROR_INVALID_PASSWORD': ident(getTranslation => getTranslation('errors.invalid-password', 'Provided password is invalid.')),
    'ERROR_UNAUTHORIZED_CLIENT': ident(getTranslation => getTranslation('errors.unauthorized-client', 'Your account is suspended.')),

    'ERROR_DEPOSIT_DECLINED': ident(getTranslation => getTranslation('account.top-up.errors.declined', 'Your deposit has been declined, please contact your bank for further information')),
    'ERROR_DEPOSIT_LIMIT_REACHED': ident(getTranslation => getTranslation('account.top-up.errors.limit-reached','Deposit can\'t exceed defined limit.')),
    'ERROR_MINIMUM_AMOUNT': ident(getTranslation => getTranslation('account.top-up.errors.deposit-minimum-amount','Minimum deposit amount is £1')),
});

export type MessagesKeyType = keyof ReturnType<typeof MESSAGES>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getGlobalTranslateFunction () {
    // tslint:disable-next-line:strict-type-predicates
    if (typeof window !== 'undefined') {
        try {
            const { languagesState } = $appState.appLayoutsState;
            return languagesState.getTranslation;
        } catch (e) {
            console.error(e);
        }
    }

    return (_langKey: string, defaultText: string): string => {
        return defaultText;
    };
}

/**
 * @deprecated - remove this function
 */
export const getErrorByCode = (error: MessagesKeyType): string => {
    return MESSAGES(null)[error](getGlobalTranslateFunction());
};
