import { RaceSummaryRow } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummaryNew.style';
import styled from '@emotion/styled';

export const ScrollRowWrapper = styled(RaceSummaryRow, { label: 'ScrollRowWrapper' })<{isNonRunner: boolean}>`
    display: flex;
    flex-wrap: nowrap;
    height: 72px;
    border-bottom: 0;
    opacity: ${({ isNonRunner }): string => isNonRunner === true ? '0.4' : '1'};
`;
