import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface OverlayPropsType {
    isVisible: boolean;
}

export const Overlay = withConfig(theme => styled('div', { label: 'Overlay' })<OverlayPropsType>`
    background: ${theme.star.overlay.bgColorSecondary};
    inset: 0;
    opacity: ${({ isVisible }): string => isVisible ? '1' : '0'};
    pointer-events: ${({ isVisible }): string => isVisible ? '' : 'none'};
    position: fixed;
    transition: opacity 0.2s ease;
    z-index: ${theme.star.zIndexGlobal.rhsMenu};
    @media ${theme.star.mediaQuery.desktop} {
        transition-duration: 0.3s;
    }
`);
