import styled from '@emotion/styled';

import { LayoutType } from 'src/domains/layouts/config/features/types';

import { withConfig } from 'src/withConfig';



interface CasinoGridWrapperPropsType {

    layout: LayoutType;

}



export const CasinoGridWrapper = withConfig(theme => styled('div', { label: 'CasinoGridWrapper' })<CasinoGridWrapperPropsType>`

    align-content: start;

    display: grid;

    flex: 0 0 0;

    gap: 8px;

    grid-template-columns: repeat(auto-fill, minmax(128px, auto));

    padding: 24px 16px 24px 0;

    @media ${theme.star.mediaQuery.xMobile} {

        grid-template-columns: repeat(auto-fill, minmax(170px, auto));

    }

    @media ${theme.star.mediaQuery.tablet} {

        grid-template-columns: repeat(auto-fill, minmax(228px, auto));

    }

    @media ${theme.star.mediaQuery.desktop} {

        padding-right: ${({ layout }): string => layout === 'primary' ? '24px' : '16px'};

    }

`);

