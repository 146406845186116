import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { defaultTiles } from './config';
import { SportTile } from 'src/domains/layouts/webview/components/sportTilesGrid/SportTile';
import { SportTilesGridContentElement } from './style';
import { SportTileData } from './types';
import { observable, makeObservable } from 'mobx';
import { CompetitionItemViewType } from 'src/domains/sportsbook/shared/Types';
import { AppState, useAppStateContext } from 'src/appState/AppState';
import { CasinoRouteType, LiveCasinoRouteType, SportRouteType, VirtualsRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { assertNever } from 'src_common/common/assertNever';

const filterCasino = (items: Array<SportTileData>): Array<SportTileData> => {
    return items.filter((item: SportTileData): boolean => {
        if (item.to.name === 'sport') {
            return true;
        }

        if (item.to.name === 'casino' || item.to.name === 'virtuals' || item.to.name === 'live-casino') {
            return false;
        }
        
        return assertNever('filterCasino', item.to);
    });
};

class SportTilesGridState {
    public constructor(private readonly appState: AppState) {
        makeObservable(this);
    }
    @observable public competition: CompetitionItemViewType | undefined = undefined;

    private handleRedirectionToNFL = (): void => {
        this.appState.appLayoutsState.defaultCompetitionState.setDefaultCompetitionFilter('US NFL');
        this.appState.appLayoutsState.starRouter.redirectToSportPage('americanfootball');
    }
    public onClick = (to: SportRouteType | CasinoRouteType | LiveCasinoRouteType | VirtualsRouteType, name: string): void => {
        const { sportViewTag, categoryViewTag } = this.appState.appLayoutsState.googleTagManager;

        if (to.name === 'sport' && to.id === 'americanfootball') {
            this.handleRedirectionToNFL();
        }
        if (to.name === 'sport') {
            sportViewTag(to.id, name);
        } else {
            categoryViewTag(to.name);
        }
    }
}

export const SportTilesGrid = observer('SportTilesGrid', () => {
    const appState = useAppStateContext();
    const sportListPre = defaultTiles;
    const [state] = React.useState(() => new SportTilesGridState(appState));

    const sportList = appState.appLayoutsState.featureState.allowCasinoInIOSWrapperNew
        ? sportListPre
        : filterCasino(sportListPre);

    const elements = sportList.map(sport => {
        return sport.to.name === 'sport' && sport.to.id === 'americanfootball' ?
            <SportTile
                key={sport.label}
                onClick={(): void => state.onClick(sport.to, sport.label)}
                isButton={true}
                label={sport.label}
                icon={sport.icon}
                to={sport.to}
            /> :
            <SportTile
                onClick={(): void => state.onClick(sport.to, sport.label)}
                key={sport.label}
                label={sport.label}
                icon={sport.icon}
                to={sport.to}
            />;
    });

    return (
        <SportTilesGridContentElement>
            {elements}
        </SportTilesGridContentElement>
    );
});
