import { computed, makeObservable } from 'mobx';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';

export class OverlayLuckyKing {
    public readonly router: StarRouter;

    public constructor(
        router: StarRouter,
    ) {
        makeObservable(this);
        this.router = router;
    }


    @computed public get isHamburgerMenuOpen(): boolean {
        const accountParam = this.router.accountParam;

        const innerWidth = getWindowInnerWidth() ?? 0;

        if (innerWidth < 1440) {
            if (accountParam !== undefined && accountParam === 'navigation') {
                return true;
            }
        }

        return false;
    }

    @computed public get shouldOverlayOpen(): boolean {
        return this.isHamburgerMenuOpen;
    }
}
