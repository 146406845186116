import { observable, action, computed, makeObservable } from 'mobx';
import { WelcomeStepFormType } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/signupWelcome/SignupWelcome.state';
import { ResponsibleGamblingState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/stepsStructure/responsibleGambling/ResponsibleGambling.state';
import { SignUpParamsType } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/SignUpParams';
import { CreateAccountStageType, CreateAccountStep } from './createAccount/CreateAccount.state';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { ExternalApi } from 'src/domains/layouts/state/externalApi/ExternalApi';

export type StepsForCirclesType =
    | 'create-account'
    | 'responsible-gambling'
    | 'deposit'
    | 'verification-failed'
    | 'gamstop-account-failed';

type SignUpMainStepsType =
    | {
          readonly type: 'create-account';
          readonly state: CreateAccountStep;
      }
    | {
          readonly type: 'responsible-gambling';
          readonly state: ResponsibleGamblingState;
      }
    | {
          readonly type: 'deposit';
      }
    | {
          readonly type: 'verification-failed';
      }
    | {
          readonly type: 'gamstop-account-failed';
      };

export class SignUpMainSteps {
    @observable.ref public step: SignUpMainStepsType;
    @observable public stepsWithError: Array<StepsForCirclesType> = [];

    public constructor(
        public readonly params: SignUpParamsType,
        private readonly setLoading: (isLoading: boolean) => void
    ) {
        makeObservable(this);

        if (this.params.config.config.limitsAndResponsibleGamblingOnSignup === true) {
            const callbacks = {
                redirectToVerificationFailed: this.redirectToVerificationFailed,
                redirectToGamStopAccountFailed: this.redirectToGamStopAccountFailed,
                redirectToNextStep: this.redirectToResponsibleGambling,
                setLoading: this.setLoading,
                resetPromoCodeInLocalStorage: this.resetPromoCodeInLocalStorage,
            };

            this.step = {
                type: 'create-account',
                state: new CreateAccountStep(params, callbacks),
            };
        } else {
            const callbacks = {
                redirectToVerificationFailed: this.redirectToVerificationFailed,
                redirectToGamStopAccountFailed: this.redirectToGamStopAccountFailed,
                redirectToNextStep: this.redirectToDeposit,
                setLoading: this.setLoading,
                resetPromoCodeInLocalStorage: this.resetPromoCodeInLocalStorage,
            };

            this.step = {
                type: 'create-account',
                state: new CreateAccountStep(params, callbacks),
            };
        }
    }

    @action public redirectToStepThree = (): void => {
        this.redirectToDeposit();
    };

    @action public redirectToCreateAccount = (
        stage: CreateAccountStageType,
        initialUserData?: WelcomeStepFormType
    ): void => {
        if (initialUserData === undefined) {
            if (this.step.type === 'create-account') {
                this.step.state.currentStage = stage;
            }
        } else {
            if (this.params.config.config.limitsAndResponsibleGamblingOnSignup === true) {
                const callbacks = {
                    redirectToVerificationFailed: this.redirectToVerificationFailed,
                    redirectToGamStopAccountFailed: this.redirectToGamStopAccountFailed,
                    redirectToNextStep: this.redirectToResponsibleGambling,
                    setLoading: this.setLoading,
                    resetPromoCodeInLocalStorage: this.resetPromoCodeInLocalStorage,
                };

                this.step = {
                    type: 'create-account',
                    state: new CreateAccountStep(this.params, callbacks, stage, initialUserData),
                };
            } else {
                const callbacks = {
                    redirectToVerificationFailed: this.redirectToVerificationFailed,
                    redirectToGamStopAccountFailed: this.redirectToGamStopAccountFailed,
                    redirectToNextStep: this.redirectToDeposit,
                    setLoading: this.setLoading,
                    resetPromoCodeInLocalStorage: this.resetPromoCodeInLocalStorage,
                };

                this.step = {
                    type: 'create-account',
                    state: new CreateAccountStep(this.params, callbacks, stage, initialUserData),
                };
            }
        }
    };

    @action public redirectToDeposit = (): void => {
        this.step = {
            type: 'deposit',
        };
    };

    @action public redirectToResponsibleGambling = (): void => {
        if (this.step.type === 'responsible-gambling') {
            console.warn('Ignore redirectToResponsibleGambling');
        } else {
            this.step = {
                type: 'responsible-gambling',
                state: new ResponsibleGamblingState(this.params, this.redirectToStepThree),
            };

            this.params.googleTagManager.gtmSignUpStepTwo(this.params.accountState.usersState);
            if (this.params.config.config.sendInfoAboutSignUpToMobileApp === true) {
                ExternalApi.get(this.params.common).setSignupFinish();
            }
        }
    };

    @action public addStepWithError = (step: StepsForCirclesType): void => {
        if (this.stepsWithError.includes(step) === false) {
            this.stepsWithError.push(step);
        }
    };

    @action public resetPromoCodeInLocalStorage = (): void => {
        LocalStorageState.get(this.params.common).promoCode.setValue(null);
    };

    @action public removeStepFromError = (step: StepsForCirclesType): void => {
        if (this.stepsWithError.includes(step)) {
            const indexOfStep = this.stepsWithError.indexOf(step);
            this.stepsWithError.slice(indexOfStep, 1);
        }
    };

    @action public onMoveBack = (): void => {
        if (this.step.type === 'create-account') {
            this.step.state.currentStage = 'welcome';
        }
    };

    @computed public get isStepWithBackOption(): boolean {
        if (this.step.type === 'create-account') {
            return true;
        }
        return false;
    }

    // OLD METHODS -----------------------------------------------------------------------------------

    @action public redirectToVerificationFailed = (): void => {
        this.params.googleTagManager.gtmSignUpVerificationFailed();

        this.step = {
            type: 'verification-failed',
        };
    };

    @action public redirectToGamStopAccountFailed = (): void => {
        this.step = {
            type: 'gamstop-account-failed',
        };
    };
}
