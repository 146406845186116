import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { DepositSignupPageState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/WithdrawFundsPopup.state';
import { PaymentAdditions } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/PaymentsAdditions';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { RingFencedFundsWrapper } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/RingFencedFundsWrapper';
import { PageSectionWrapper } from 'src/domains/players/webview/components/SignUp';
import {
    DepositHeader,
    PayButton,
    CardInfo,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/Deposit.style';
import { RealexTabState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/RealexTab.state';
import { KycWarning } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/kycWarning/KycWarning';
import { mapRealexErrorsMessages } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/RealexTab';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';

interface DepositPropsType {
    state: RealexTabState;
}

export const DepositSignupRealex = observer('DepositSignupRealex', ({ state }: DepositPropsType) => {
    const { appPlayersState, common } = useAppStateContext();
    const { signupPopupState, usersState } = appPlayersState;
    const configComponents = ConfigComponents.get(common);
    const languagesState = LanguagesState.get(common);

    const [depositSignupPageState] = useState(
        () => new DepositSignupPageState(state, usersState, common, signupPopupState)
    );

    return (
        <PageSectionWrapper>
            <DepositHeader data-test='deposit-header'>
                <I18n
                    langKey='signup-journey.deposit.title'
                    defaultText='Deposit'
                />
            </DepositHeader>
            <Input
                type='text'
                maxLength={10}
                placeholder='0.00'
                currency={usersState.moneySymbol}
                label={
                    <I18n
                        langKey='signup-journey.deposit-input.label'
                        defaultText='Deposit amount'
                    />
                }
                state={state.depositAmount}
                inputRef={state.setDepositInputRef}
                onChange={state.onChange}
                onBlur={state.setAmount}
                colorTheme='dark'
                dataTest='deposit-amount-input'
            />
            <PaymentAdditions
                key='payment-additions'
                currency={usersState.moneySymbol}
                value={state.depositAmount.value}
                onChange={state.handleAdditionsChange}
            />
            {state.errors.length > 0
                ? state.errors.map((error) => mapRealexErrorsMessages(error, languagesState))
                : null}
            {state.hasRingFencedFunds === false ? (
                <RingFencedFundsWrapper
                    isMoreShown={state.showMoreRingFenceFlag}
                    toggleShowMore={state.toggleShowMore}
                    usePaymentFormForUniverse='realex'
                />
            ) : null}
            <PayButton
                size='large'
                disabled={state.isButtonDisabled}
                onClick={depositSignupPageState.renderPopUpAndChangeFlag}
                dataTest='deposit-pay-debit-card'
            >
                <I18n
                    langKey='signup-journey.deposit.pay-card-button'
                    defaultText='Pay using debit card'
                />
            </PayButton>
            <CardInfo data-test='deposit-card-info'>
                <I18n
                    langKey='signup-journey.deposit.card-info'
                    defaultText='Credit cards are no longer accepted'
                />
            </CardInfo>
            {configComponents.config.showKycStatus === true ? <KycWarning isSignup={true} /> : null}
        </PageSectionWrapper>
    );
});
