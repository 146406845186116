import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    SelectionInfoWrapper,
    SelectionRowLarge,
    SelectionRowWrapper,
} from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeMainContent/AlternativeMainContent.style';
import { SelectionNameComponent } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeMainContent/selectionGroup/selectionNameComponent/SelectionNameComponent';
import { Selection } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeMainContent/selectionGroup/selection/Selection';
import { SelectionDetails } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeMainContent/selectionGroup/selectionDetails/SelectionDetails';
import { useAppStateContext } from 'src/appState/AppState';
import { EventId, MarketId, SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

interface AlternativeSelectionPropsType {
    eventId: EventId;
    marketId: MarketId;
    selectionId: SelectionId;
    displayTemplate: string | undefined;
}

export const AlternativeSelection = observer(
    'AlternativeSelection',
    ({ selectionId, eventId, displayTemplate }: AlternativeSelectionPropsType) => {
        const {
            appSportsBookState: { alternativeEventState },
        } = useAppStateContext();
        const eventItem = alternativeEventState.get(eventId).getAlternativeEventItemById(eventId);
        const alternativeSelection = eventItem.getCustomSelectionItemByMarketAndSelectionId(selectionId);
        const selectionModel = selectionId.getModel();

        if (selectionModel === null) {
            return null;
        }

        const { handleToggleShowDetails, isShouldShowDetails, isShowDetails } = alternativeSelection;

        return (
            <SelectionRowWrapper
                isSelected={isShowDetails}
                displayTemplate={displayTemplate}
            >
                <SelectionRowLarge
                    hasDetails={isShouldShowDetails}
                    displayTemplate={displayTemplate}
                >
                    <SelectionInfoWrapper onClick={handleToggleShowDetails}>
                        <SelectionNameComponent
                            alternativeSelection={alternativeSelection}
                            selectionModel={selectionModel}
                        />
                    </SelectionInfoWrapper>

                    <Selection selectionId={selectionId} />
                </SelectionRowLarge>
                {isShowDetails === true ? <SelectionDetails alternativeSelection={alternativeSelection} /> : null}
            </SelectionRowWrapper>
        );
    }
);
