import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { useAppStateContext } from 'src/appState/AppState';
import { Tab } from 'src/domains/players/webview/components/Account';

interface RhsTabsPropsType {
    tab: RightHandSideViewType | null;
}

export const RhsTabs = observer('RenderTab', (props: RhsTabsPropsType) => {
    const { accountTabsBuilderState } = useAppStateContext();

    const { tab } = props;
    const data = accountTabsBuilderState.getTabData(tab);

    if (data === null) {
        return null;
    }

    return (
        <Tab
            tabName={tab}
            showHeader={true}
            tabData={data}
            tabDataForHeader={data}
        />
    );
});
