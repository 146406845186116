import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface UserIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const UserIconNew = observer(
    'UserIconNew',
    ({ className, onClick }: UserIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M12 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM12 13a9.985 9.985 0 0 1 8 3.999V21H4v-4.001A9.985 9.985 0 0 1 12 13Z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
