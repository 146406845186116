import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { OptionType, checkOptionType } from './common';
import { SelectWrapper } from './CarouselSelection.style';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { components, OptionProps } from 'react-select';
import { MarketFiltersForSportState } from 'src/domains/sportsbook/state/marketFiltersState/MarketFiltersForSportState';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { RabState } from 'src/domains/sportsbook/betting/state/rabState/RabState';
import {
    SelectCheckIconWrapper,
    ChevronFullIconWrapper,
} from 'src/domains/layouts/webview/components/styles/baseDropdownStyle.style';

interface PropsType {
    options: Array<OptionType>;
    placeholder: string;
    active: string | null;
    type: SelectionType;
}

type SelectionType = 'event' | 'competition';

class State {
    public constructor(
        private readonly starRouter: StarRouter,
        private readonly type: SelectionType,
        private readonly models: ModelsState,
        private readonly marketFiltersForSport: MobxMapAutoNew<
            string,
            MobxMapAutoNew<EventId | null, MarketFiltersForSportState>
        >,
        private readonly rabState: RabState
    ) {}

    public onChange = (selectedOption: unknown): void => {
        if (checkOptionType(selectedOption)) {
            const id = parseInt(selectedOption.value, 10);
            const eventId = this.models.id.getEventId(id);
            const { sportId } = selectedOption;
            const sport = eventId.getEventModel()?.sport;

            if (this.type === 'event') {
                this.starRouter.redirectToEvent(eventId);
                if (sport !== undefined) {
                    this.marketFiltersForSport.get(sport).get(eventId).resetFilters();
                    const isRabAvailable = this.rabState.isAvailableForEvent(eventId);
                    if (isRabAvailable) {
                        this.rabState.closeRab();
                    }
                }
            } else {
                if (sportId === undefined) {
                    console.error('CarouselSelection onChange error');
                    return;
                }
                this.starRouter.redirectToCompetitionPage(id, sportId);
            }
        }
        if (selectedOption === undefined || selectedOption === null) {
            console.error('CarouselSelection onChange error');
            return;
        }
    };
}

export const CarouselSelection = observer('CarouselSelection', (props: PropsType) => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { languagesState, starRouter } = appLayoutsState;
    const [state] = React.useState(
        () =>
            new State(
                starRouter,
                props.type,
                appSportsBookState.models,
                appSportsBookState.marketFiltersForSport,
                appSportsBookState.rab
            )
    );

    const renderOption = (props: OptionProps): JSX.Element | null => {
        const isSelected = props.isSelected;
        return (
            <div data-test={props.label}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <components.Option {...props}>
                    {props.label} {isSelected === true ? <SelectCheckIconWrapper /> : null}
                </components.Option>
            </div>
        );
    };

    const { options, placeholder, active } = props;
    const noResultsFound = languagesState.getTranslation(
        'events.navigation.competition.no-results-found',
        'No results found.'
    );

    const activeElement = (): OptionType | null => {
        const filtered = options.find((option) => active === option.value) ?? null;
        return filtered;
    };

    return (
        <SelectWrapper
            className='custom-dropdown-select'
            classNamePrefix='react-select'
            noOptionsMessage={(): string => noResultsFound}
            onChange={state.onChange}
            options={options}
            placeholder={placeholder}
            value={activeElement()}
            isSearchable={false}
            components={{
                Option: renderOption,
                DropdownIndicator: (): JSX.Element => <ChevronFullIconWrapper position='down' />,
            }}
        />
    );
});
