import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    DepositLimitsPopupItemState,
    DepositLimitsPopupState,
    DepositLimitType,
} from 'src/domains/players/webview/components/RollingDepositLimitPopup/RollingDepositLimitPopup.state';
import { Popup } from 'src/domains/players/webview/ui/Popup';
import {
    DepositLimitPopupBtn,
    DepositLimitPopupBtnWrapper,
    DepositLimitPopupDescription,
    DepositLimitPopupHeader,
    DepositLimitPopupInput,
    DepositLimitPopupPrevious,
} from './RollingDepositLimitPopup/RollingDepositLimitPopup.style';
import { useAppStateContext } from 'src/appState/AppState';

interface DepositLimitPopupItemPropsType {
    state: DepositLimitsPopupItemState;
    label: string;
}

const DepositLimitPopupItem = observer(
    'DepositLimitPopupItem',
    (props: DepositLimitPopupItemPropsType): JSX.Element | null => {
        const {
            appPlayersState: { usersState },
        } = useAppStateContext();
        const { state, label } = props;
        const isNewLimit = state.pending !== undefined && state.limitExceededDeadline;

        return (
            <div>
                <DepositLimitPopupInput
                    type='text'
                    currency={usersState.moneySymbol}
                    maxLength={10}
                    label={
                        <I18n
                            langKey='account.rolling-net-deposit-limit-popup.new-limit.label'
                            defaultText='{label}'
                            params={{ label }}
                        />
                    }
                    state={state.returnActiveState}
                    placeholder={isNewLimit ? state.pending : state.active}
                    colorTheme='light'
                    isDisabled={true}
                    isNewLimit={isNewLimit}
                />
                {isNewLimit && (
                    <DepositLimitPopupPrevious>
                        <I18n
                            langKey='account.rolling-net-deposit-limit-popup.previous-limit.label'
                            defaultText='Previous limit  {active}'
                            params={{ active: state.active ?? '' }}
                        />
                    </DepositLimitPopupPrevious>
                )}
            </div>
        );
    }
);

export const RollingDepositLimitPopup = observer('DepositLimitPopup', (): JSX.Element | null => {
    const appState = useAppStateContext();
    const { languagesState } = appState.appLayoutsState;
    const [state] = useState(() => new DepositLimitsPopupState(appState));
    const labelDaily = languagesState.getTranslation('account.rolling-net-deposit-limit-popup.label.daily', '1 Day');
    const labelWeekly = languagesState.getTranslation('account.rolling-net-deposit-limit-popup.label.weekly', '7 Days');
    const labelMonthly = languagesState.getTranslation(
        'account.rolling-net-deposit-limit-popup.label.monthly',
        '30 Days'
    );

    if (state.isActive === false) {
        return null;
    }

    return (
        <Popup
            title={
                <DepositLimitPopupHeader>
                    <I18n
                        langKey='account.rolling-net-deposit-limit-popup.title'
                        defaultText='Net deposit limits'
                    />
                </DepositLimitPopupHeader>
            }
            closeButton={false}
            maxWidth='400px'
        >
            <div>
                <DepositLimitPopupDescription>
                    <I18n
                        langKey='account.rolling-net-deposit-limit-popup.description'
                        defaultText='You made some changes to your net deposit limits, please accept before continuing.'
                    />
                </DepositLimitPopupDescription>

                <DepositLimitPopupItem
                    label={labelDaily}
                    state={state.daily}
                />
                <DepositLimitPopupItem
                    label={labelWeekly}
                    state={state.weekly}
                />
                <DepositLimitPopupItem
                    label={labelMonthly}
                    state={state.monthly}
                />
                <DepositLimitPopupBtnWrapper>
                    <DepositLimitPopupBtn
                        onClick={state.onAccept}
                        size='medium'
                    >
                        <I18n
                            langKey='account.rolling-net-deposit-limit-popup.accept-button.label'
                            defaultText='Accept'
                        />
                    </DepositLimitPopupBtn>
                    <DepositLimitPopupBtn
                        onClick={state.onReject}
                        size='medium'
                        version='secondary-light'
                    >
                        <I18n
                            langKey='account.rolling-net-deposit-limit-popup.reject-button.label'
                            defaultText='Revert Back'
                        />
                    </DepositLimitPopupBtn>
                </DepositLimitPopupBtnWrapper>
            </div>
        </Popup>
    );
});

export { DepositLimitType };
