import styled from '@emotion/styled';
import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import { withConfig } from 'src/withConfig';

export const SignUpIssueWrapper = styled('div', { label: 'SignUpIssueWrapper' })`
    animation: fadein 0.3s;
    margin: 0 16px 40px;
    padding-top: 80px;
    text-align: center;
    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
`;

export const WarningIconBox = withConfig(theme => styled('div', { label: 'WarningIconBox' })`
    background: ${theme.star.accountDrawer.bgColor};
    border-radius: 50%;
    display: inline-flex;
    margin-bottom: 32px;
    padding: 24px;
`);

export const WarningIconWrapper = styled(WarningIcon, { label: 'WarningIconWrapper' })`
    width: 32px;
`;

export const IssueSignUpTitle = withConfig(theme => styled('h2', { label: 'IssueSignUpTitle' })`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    max-width: 220px;
    margin: 0 auto;
`);

export const IssueSignUpInfo = withConfig(theme => styled('p', { label: 'IssueSignUpInfo' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.43;
    margin: 20px auto 0;
`);
