import React from 'react';
import { EventsTable } from 'src/domains/sportsbook/webview/components/eventsTable/EventsTable';
import { useAppStateContext } from 'src/appState/AppState';
import { FilterKindType } from 'src/domains/sportsbook/webview/components/eventsFilters/EventsFilterType';
import { observer } from 'src/utils/mobx-react';
import { EventsTableHeaderStyle } from 'src/domains/sportsbook/webview/components/eventsTable/EventsMainSectionWrapper';

const filters: Array<FilterKindType> = ['sport'];

interface PropsType {
    showPriceLegend: boolean;
    isLoading: boolean;
    headerStyleLevel: 'sportLevel' | 'competitionLevel';
}

export const EventsUpcoming = observer('EventsUpcoming', (props: PropsType) => {
    const { appSportsBookState, config } = useAppStateContext();
    const isLoading = props.isLoading;
    const tableLabel = appSportsBookState.language.getTranslation('special-box.header.upcoming', 'Upcoming');
    const eventsCollection = appSportsBookState.eventsCollection.listInPlayOrFilteredUpcoming();

    return (
        <EventsTable
            headerStyle={EventsTableHeaderStyle.SOLID}
            filterVersion={config.filtersVersion.eventsUpcoming}
            icon='in-play'
            className='events-table--in-play events-table--dark'
            title={tableLabel}
            showHeaderSport={true}
            allGroupsOpen={true}
            eventsCollection={eventsCollection}
            filters={filters}
            isTogglable={true}
            showPriceLegend={props.showPriceLegend}
            hideNoEventsMessage={true}
            isLoading={isLoading}
            headerStyleLevel={props.headerStyleLevel}
            isAmericanFootball={false}
        />
    );
});
