import * as React from 'react';

import styled from '@emotion/styled';

import { observer } from 'src/utils/mobx-react';



interface PropsType {

    className?: string;

}



const SvgElement = styled('svg', { label: 'SvgElement' })`

    height: auto;

`;



export const LiveCasinoIcon = observer('LiveCasinoIcon', ({ className }: PropsType) => (

    <SvgElement

        fill='#fff'

        className={className}

        viewBox='0 0 13 13'

        width='13px'

        height='13px'

        xmlns='http://www.w3.org/2000/svg'

        fillRule='evenodd'

        clipRule='evenodd'

        strokeLinejoin='round'

        strokeMiterlimit='2'

    >

        <path d='M3.297 3.033a4.528 4.528 0 012.917-1.196V0A6.368 6.368 0 001.98 1.733l1.317 1.3zm-.227.223l-1.316-1.3A6.205 6.205 0 000 6.138h1.86a4.418 4.418 0 011.21-2.882zM1.86 6.454H0a6.205 6.205 0 001.754 4.182l1.316-1.3a4.423 4.423 0 01-1.21-2.882zm8.909-4.721A6.372 6.372 0 006.535.001v1.837a4.533 4.533 0 012.917 1.196l1.317-1.3v-.001zM9.68 3.257a4.422 4.422 0 011.209 2.882h1.86a6.21 6.21 0 00-1.753-4.182l-1.317 1.3h.001zm-7.7 7.604a6.37 6.37 0 004.234 1.731v-1.836a4.524 4.524 0 01-2.917-1.197l-1.318 1.302h.001zm4.394-6.669L4.721 5.84a1.065 1.065 0 00-.287 1.042c.1.373.395.665.773.764.376.1.777-.007 1.056-.283a.166.166 0 01.12-.046l.011.001a.155.155 0 01.105.045 1.106 1.106 0 001.537-.018 1.065 1.065 0 00-.006-1.518L6.374 4.192zM6.169 8.39c.136-.02.275-.02.412 0l-.206-.466-.206.466z' fill='fill' fillRule='nonzero' />

        <path d='M10.573 6.296c0-2.286-1.884-4.146-4.198-4.146-2.316 0-4.198 1.86-4.198 4.146 0 2.286 1.883 4.146 4.198 4.146 2.315 0 4.198-1.86 4.198-4.146zM8.256 7.573a1.427 1.427 0 01-1.557.295l.308.694a.158.158 0 01-.032.174.164.164 0 01-.175.036 1.158 1.158 0 00-.851 0 .163.163 0 01-.174-.036.158.158 0 01-.032-.174l.299-.677a1.426 1.426 0 01-1.721-.505 1.387 1.387 0 01.172-1.765l1.766-1.759a.162.162 0 01.228-.001l1.769 1.747a1.379 1.379 0 010 1.971z' fill='fill' fillRule='nonzero' />

        <path d='M6.536 10.755l-.001 1.837a6.372 6.372 0 004.234-1.732L9.452 9.559a4.523 4.523 0 01-2.917 1.196h.001zM9.68 9.336l1.316 1.3a6.21 6.21 0 001.754-4.181h-1.86a4.417 4.417 0 01-1.21 2.881z' fill='fill' fillRule='nonzero' />

    </SvgElement>

));

