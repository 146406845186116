import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface WalletTabletIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const WalletIcon = observer(
    'WalletIcon',
    ({ className, onClick }: WalletTabletIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                fill='#000911'
                height='20'
                onClick={onClick}
                viewBox='0 0 20 20'
                width='20'
                xmlns='http://www.w3.org/2000/svg'
                data-test='wallet-icon'
            >
                <path
                    fill='fill'
                    d='M16.25 5.7H3.23v-.52l11.46-.92v.92h1.56V3.6c0-1.14-.93-1.95-2.06-1.79L3.73 3.32A2.48 2.48 0 0 0 1.67 5.7v10.4c0 1.15.93 2.09 2.08 2.09h12.5c1.15 0 2.08-.94 2.08-2.09V7.78c0-1.15-.93-2.08-2.08-2.08ZM14.69 13a1.56 1.56 0 1 1 0-3.13 1.56 1.56 0 0 1 0 3.12Z'
                />
            </SvgElement>
        );
    }
);
