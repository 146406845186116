import { action, computed, observable, makeObservable } from 'mobx';
import { ActiveHomePageCarouselType } from 'src/api/config/cms/getActiveHomePageCarousel';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';
import { MobxValue } from 'src_common/common/mobx-utils/MobxValue';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { currentSlide } from './currentSlide';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { Common } from 'src/domains/common/Common';
import { Session } from 'src_common/sdk/session';

export type CarouselModeType = 'homepage' | 'casino' | 'virtuals' | 'live-casino';

const INTERVAL = 6;
const SINGLE_CARD_WIDTH = 308;
const MIN_SWIPE_DISTANCE = 50;

const getCurrentTime = (): number => new Date().getTime();

const createCurrentTime = (): MobxValue<number> => {
    return MobxValue.create({
        initValue: getCurrentTime(),
        connect: {
            connect: (self: MobxValue<number>): NodeJS.Timeout => {
                self.setValue(getCurrentTime());

                const timer = setInterval(() => {
                    self.setValue(getCurrentTime());
                }, 1000);

                return timer;
            },
            dispose: (timer: NodeJS.Timeout): void => {
                clearInterval(timer);
            },
        },
    });
};

export interface ReferencePointType {
    time: number;
    slide: number;
}

export class HomePageCarouselState {
    @observable.ref private currentTime: MobxValue<number>;
    @observable.ref private referencePoint: ReferencePointType;

    @observable private touchStart: number | null = null;
    @observable private touchEnd: number | null = null;

    @observable private refSlider: HtmlElementReactive<HTMLElement> = new HtmlElementReactive(500);
    @observable private session: Session;

    public readonly homePageCarouselResource: Resource<Array<ActiveHomePageCarouselType>>;

    public static get = AutoWeakMap.create((common: Common, carouselType: CarouselModeType) => {
        return new HomePageCarouselState(common, carouselType);
    });

    private readonly configComponents: ConfigComponents;

    private constructor(common: Common, carouselType: CarouselModeType) {
        makeObservable(this);
        this.configComponents = ConfigComponents.get(common);
        this.session = common.session;
        const models = common.models;
        const languagesState = LanguagesState.get(common);
        const trpcClient = common.trpcClient;

        this.homePageCarouselResource = new Resource(async (): Promise<Array<ActiveHomePageCarouselType>> => {
            const list = await trpcClient.client.cms.getHomePageCarouselActive.query({
                carousel_type: carouselType,
                lang: languagesState.userLang ?? 'en',
            });

            const result = [];

            for (const item of list) {
                const eventId = models.id.getEventIdOption(item.event_id ?? null);
                const oldMarketId = item.market_id ?? null;
                const marketId = oldMarketId === null ? null : eventId?.getMarketId(oldMarketId);

                result.push({
                    action_template: item.action_template,
                    bg_color: item.bg_color,
                    btn_color: item.btn_color,
                    btn_link: item.btn_link,
                    btn_title: item.btn_title,
                    carousel_type: item.carousel_type,
                    competition_id: item.competition_id,
                    date_from: item.date_from,
                    date_to: item.date_to,
                    display_order: item.display_order,
                    event_id: eventId,
                    game_id: item.game_id,
                    id: item.id,
                    img_url: item.img_url,
                    is_active: item.is_active,
                    is_desktop: item.is_desktop,
                    is_mobile: item.is_mobile,
                    market_id: marketId,
                    media_type: item.media_type,
                    selections_details: item.selections_details,
                    sport: item.sport,
                    subtitle: item.subtitle,
                    tc_text: item.tc_text,
                    title: item.title,
                    universe: item.universe,
                    is_show_for_all_users: item.is_show_for_all_users,
                    users_ids: item.users_ids,
                });
            }

            return result;
        });

        this.referencePoint = {
            time: getCurrentTime(),
            slide: 0,
        };

        this.currentTime = createCurrentTime();
    }

    @computed private get isAutomaticCarousel(): boolean {
        return this.configComponents.config.isAutomaticCarousel;
    }

    @action public setRefSlider = (refSlider: HTMLElement | null): void => {
        this.refSlider.setRef(refSlider);
    };

    @computed public get elemWidth(): null | number {
        if (this.refSlider.ref !== null) {
            return this.refSlider.ref.clientWidth;
        }
        return null;
    }

    @computed public get jumpIndicator(): number {
        if (this.elemWidth !== null) {
            return Math.floor(this.elemWidth / SINGLE_CARD_WIDTH);
        }
        return 0;
    }

    @computed public get isLoading(): boolean {
        const result = this.homePageCarouselResource.get();
        return result.type === 'loading';
    }

    private parseUsersIds(usersIds: unknown): number[] {
        if (typeof usersIds !== 'string') {
            return [];
        }
        const usersIdsList = usersIds.split(',');
        return usersIdsList.reduce<number[]>((acc, curr) => {
            const userId = parseInt(curr);
            if (!isNaN(userId)) {
                acc.push(userId);
            }
            return acc;
        }, []);
    }

    private checkPermissionUser = (usersIds: Array<number>): boolean => {
        if (usersIds.length === 0) {
            return true;
        } else {
            if (this.session.userId === null) {
                return false;
            }
            return usersIds.includes(this.session.userId);
        }
    };

    @computed private get activeHomePageCarousel(): Array<ActiveHomePageCarouselType> {
        const result = this.homePageCarouselResource.get();
        if (result.type === 'ready') {
            return result.value.filter((carouselElement): boolean => {
                const { date_from, date_to, users_ids, /*is_show_for_all_users,*/ media_type, event_id } =
                    carouselElement;
                const now = new Date().getTime();
                const dateFrom = new Date(date_from).getTime();
                const dateTo = new Date(date_to).getTime();
                const usersIds = this.parseUsersIds(users_ids);

                const viewPermissions = {
                    user: false,
                    date: false,
                    betting: false,
                };

                viewPermissions.user = this.checkPermissionUser(usersIds);
                // if (
                //     (is_show_for_all_users === true && this.session.userId !== null) ||
                //     (this.session.userId !== null && usersIds.includes(this.session.userId)) ||
                //     (is_show_for_all_users === false && usersIds.length === 0) ||
                //     is_show_for_all_users === null
                // ) {
                //     viewPermissions.user = true;
                // }

                if (dateFrom < now && now < dateTo) {
                    viewPermissions.date = true;
                }

                if (media_type === 'betting') {
                    const eventId = event_id ?? null;
                    const eventModel = eventId === null ? null : eventId.getEventModel();
                    viewPermissions.betting = eventModel !== null && eventModel.active && eventModel.display;
                }

                const { user, date, betting } = viewPermissions;
                return media_type === 'betting' ? user && date && betting : user && date;
            });
        }
        return [];
    }

    @computed public get isDesktop(): boolean {
        const innerWidth = getWindowInnerWidth();
        return innerWidth !== null && innerWidth >= 768;
    }

    @computed public get sortedLinks(): Array<ActiveHomePageCarouselType> {
        if (this.isDesktop === true) {
            return this.activeHomePageCarousel.filter((elem) => elem.is_desktop).sort(this.sortByDisplayOrder);
        } else {
            return this.activeHomePageCarousel.filter((elem) => elem.is_mobile).sort(this.sortByDisplayOrder);
        }
    }

    private sortByDisplayOrder = (a: { display_order: number }, b: { display_order: number }): number => {
        if (a.display_order > b.display_order) {
            return -1;
        }
        if (a.display_order < b.display_order) {
            return 1;
        }
        return 0;
    };

    @action public setCurrentSlide = (newSlide: number): void => {
        this.currentTime = createCurrentTime();
        this.referencePoint = {
            time: getCurrentTime(),
            slide: newSlide,
        };
    };

    @action public setNextSlide = (): void => {
        if (this.currentSlideGroupId < this.dotsList.length - 1) {
            this.setCurrentSlide(this.currentSlideGroupId + 1);
        }
    };

    @action public setPreviousSlide = (): void => {
        if (this.currentSlideGroupId > 0) {
            this.setCurrentSlide(this.currentSlideGroupId - 1);
        }
    };

    @computed public get currentTimeValue(): number {
        if (this.isAutomaticCarousel) {
            return this.currentTime.getValue();
        } else {
            return getCurrentTime();
        }
    }

    @computed public get currentSlide(): number {
        if (this.isAutomaticCarousel === false) {
            return this.referencePoint.slide * this.jumpIndicator;
        }

        const currentTime = this.currentTime.getValue();
        const slidesQuantity = this.sortedLinks.length;

        return currentSlide({
            slidesTime: INTERVAL * 1000,
            slidesQuantity: slidesQuantity - this.jumpIndicator + 1,
            currentTime,
            referencePoint: this.referencePoint,
            jumpIndicator: 1,
        });
    }

    @computed public get calcShift(): number {
        if (this.dotsList.length <= 1) {
            return 0;
        }

        const showPromos = this.currentSlide + this.jumpIndicator;

        if (showPromos >= this.sortedLinks.length) {
            const calcIndicator = this.sortedLinks.length - this.jumpIndicator;
            const correct = this.elemWidth === null ? 0 : this.elemWidth - this.jumpIndicator * SINGLE_CARD_WIDTH;
            return -1 * SINGLE_CARD_WIDTH * calcIndicator + correct + 8;
        }

        return -1 * SINGLE_CARD_WIDTH * this.currentSlide;
    }

    @computed public get dotsList(): Array<number> {
        const dots = [];

        const quantity = this.isAutomaticCarousel
            ? Math.ceil(this.sortedLinks.length) - this.jumpIndicator + 1
            : Math.ceil(this.sortedLinks.length / this.jumpIndicator);
        if (this.sortedLinks.length > 0 && this.jumpIndicator > 0) {
            for (let i = 0; i < quantity; i++) {
                dots.push(i);
            }
        }

        return dots;
    }

    @computed public get dotsIndexes(): Array<{ id: number }> {
        return this.dotsList.map((d) => {
            return { id: d };
        });
    }

    @computed public get currentSlideGroupId(): number {
        if (this.currentSlide === 0) {
            return 0;
        }
        if (this.isAutomaticCarousel) {
            return this.currentSlide;
        }
        return Math.floor(this.currentSlide / this.jumpIndicator);
    }

    public getActiveTerms(pageId: number): string {
        const item = this.sortedLinks.find((x) => x.id === pageId);

        return item?.tc_text ?? '';
    }

    public onTouchStart = (e: React.TouchEvent<HTMLDivElement>): void => {
        this.touchEnd = null;
        this.touchStart = e.targetTouches[0]?.clientX ?? null;
    };
    public onTouchMove = (e: React.TouchEvent<HTMLDivElement>): void => {
        this.touchEnd = e.targetTouches[0]?.clientX ?? null;
    };
    public onTouchEnd = (): void => {
        if (this.touchStart === null || this.touchEnd === null) return;
        const distance = this.touchStart - this.touchEnd;
        const isLeftSwipe = distance > MIN_SWIPE_DISTANCE;
        const isRightSwipe = distance < -MIN_SWIPE_DISTANCE;

        if (isLeftSwipe) {
            this.setNextSlide();
        }

        if (isRightSwipe) {
            this.setPreviousSlide();
        }
    };
}
