import styled from '@emotion/styled';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

export const FooterCopyrightWrapper = styled('div', { label: 'FooterCopyrightWrapper' })`
    display: flex;
    flex-flow: row nowrap;
`;

export const FooterCopyrightText = withConfig(theme => styled('p', { label: 'FooterCopyrightText' })`
    color: ${theme.star.footerMain.txtColorTertiary};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin: 0;
`);

export const LinkWrapper = withConfig(theme => styled(Link, { label: 'LinkWrapper' })`
    color: ${theme.star.footerMain.txtColorSecondary};
    cursor: pointer;
    text-decoration: none;
    transition: color 0.2s ease;
    &:hover {
        color: ${theme.star.footerMain.txtColorQuinary};
    }
`);

export const EmptyLinkWrapper = withConfig(theme => styled('span', { label: 'EmptyLinkWrapper' })`
    color: ${theme.star.footerMain.txtColorSecondary};
`);

export const MobileAppVersion = withConfig(theme => styled('p', { label: 'MobileAppVersion' })`
    color: ${theme.star.footerMain.txtColorTertiary};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin: 8px 0;
`);
