import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    LinkItemLinkType,
    NavigationPanel,
} from 'src/domains/layouts/webview/components/navigationPanel/NavigationPanel';
import { useCommon } from 'src/domains/common/Common';

export const NavigationSidebarSectionLuckyKing = observer(
    'NavigationSidebarSectionLuckyKing',
    (): React.ReactElement => {
        const { appLayoutsState } = useAppStateContext();
        const { navigationFavourites } = appLayoutsState;
        const common = useCommon();
        const { session } = common;

        const favouritesListView = navigationFavourites.favouritesListView.map(
            (item): LinkItemLinkType => ({
                ...item,
                iconType: session.isAuthorized ? 'StarFill' : undefined,
                iconClick: (): void => {
                    navigationFavourites.setFavourites(item.to.id, false);
                },
            })
        );

        const restListView = navigationFavourites.restListView.map(
            (item): LinkItemLinkType => ({
                ...item,
                iconType: session.isAuthorized ? 'StarOutline' : undefined,
                iconClick: (): void => {
                    navigationFavourites.setFavourites(item.to.id, true);
                },
            })
        );

        return (
            <>
                {session.isAuthorized ? (
                    <NavigationPanel
                        title={
                            <I18n
                                langKey='left-menu.sports-az.favourites-active-lk'
                                defaultText='Favourites'
                            />
                        }
                        titleDataTest='favourites'
                        type='favourites'
                        links={favouritesListView}
                        typeTag='sport'
                    />
                ) : null}

                <NavigationPanel
                    title={
                        <I18n
                            langKey='left-menu.sports-az.favourites-rest-lk'
                            defaultText='Sports'
                        />
                    }
                    titleDataTest='sports'
                    links={restListView}
                    type='sports'
                    typeTag='sport'
                />
            </>
        );
    }
);
