import { ConfigType } from 'src/withConfig';
import { SelectionPriceType } from './HomePageCarouselPromos.style';

export function getSelectionPromoBgColor(
    theme: ConfigType,
    { isPricedUp, isSelected, isSuspended }: SelectionPriceType
): string {
    if (isPricedUp) {
        if (isSuspended === true) {
            return theme.star.selection.bgColorSenary;
        }

        if (isSelected === true) {
            return theme.star.selection.bgColor;
        }

        return theme.star.selection.bgColorQuinary;
    }

    if (isSuspended === true) {
        return theme.star.selection.bgColorSenary;
    }

    if (isSelected === true) {
        return theme.star.selection.bgColorQuinary;
    }

    return theme.star.selection.bgColor;
}

export function getSelectionPromoTextColor(
    theme: ConfigType,
    { isPricedUp, isSelected, isSuspended }: SelectionPriceType
): string {
    if (isPricedUp) {
        if (isSuspended === true) {
            return theme.star.selection.txtColorSecondary;
        }

        if (isSelected === true) {
            return theme.star.selection.bgColorQuinary;
        }

        return theme.star.selection.txtColorTertiary;
    }

    if (isSuspended === true) {
        return theme.star.selection.txtColorSecondary;
    }

    if (isSelected === true) {
        return theme.star.selection.txtColorTertiary;
    }

    return theme.star.selection.txtColor;
}
