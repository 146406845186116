import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { sortSelections } from 'src/domains/layouts/utils/sortSelections';
import { HeroWidgetModelType } from 'src/api/config/cms_new/hero_widget/get_hero_widget_active';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { computed, makeObservable } from 'mobx';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { sortSelectionsByCriteria } from 'src_common/utils/sport/sort';

export interface HeroWidgetImagesType {
    mobile: string;
    tablet: string;
    desktop: string;
    bgColor: string;
}

export class HeroWidgetItemState {
    public constructor(
        private readonly router: StarRouter,
        private readonly heroWidgetItem: HeroWidgetModelType
    ) {
        makeObservable(this);
    }

    public get headerImages(): HeroWidgetImagesType {
        const { header_img_small, header_img_medium, header_img_big, header_bg_color } = this.heroWidgetItem;
        return {
            mobile: header_img_small,
            tablet: header_img_medium,
            desktop: header_img_big,
            bgColor: header_bg_color ?? '',
        };
    }

    public get promoImages(): HeroWidgetImagesType {
        const { promo_img_small, promo_img_medium, promo_img_big, promo_bg_color } = this.heroWidgetItem;
        return {
            mobile: promo_img_small,
            tablet: promo_img_medium,
            desktop: promo_img_big,
            bgColor: promo_bg_color ?? '',
        };
    }

    public get marketModel(): MarketModel | null {
        return this.heroWidgetItem.market_id.getModel();
    }

    public get eventModel(): EventModel | null {
        return this.heroWidgetItem.event_id.getEventModel();
    }

    public get filteredAndSortDefaultSelections(): Array<SelectionModel> {
        if (this.marketModel !== null) {
            return sortSelections(this.marketModel.selections)
                .filter((x) => x.resultType !== 'void' && x.display)
                .slice(0, 10);
        }
        return [];
    }

    public get filteredAndSortByCriteriaSelections(): Record<string, Array<SelectionModel>> {
        const selections: Record<string, SelectionModel[]> = {};
        if (this.marketModel !== null) {
            const displayOrder = this.marketModel.displayOrderTag;
            const order = displayOrder !== undefined && displayOrder !== '-' ? displayOrder : 'by-creation';
            const marketSelections = this.marketModel.selections.filter((x) => x.display);
            selections[this.marketModel.id] = sortSelectionsByCriteria(marketSelections, order);
        }

        return selections;
    }

    @computed public get isMarketNotEmpty(): boolean {
        for (const [_key, value] of Object.entries(this.filteredAndSortByCriteriaSelections)) {
            if (value.length > 0) {
                return true;
            }
        }
        return false;
    }

    public handleRedirectToRacePage = (): void => {
        const { event_id } = this.heroWidgetItem;
        this.router.redirectToEvent(event_id);
    };

    public handleRedirectTEventPage = (): void => {
        this.router.redirectToEvent(this.heroWidgetItem.event_id);
    };
}
