import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    LanguageSwitcherWrapper,
    ActiveLanguageWrapper,
    LanguagesList,
    DropdownChevron,
    LanguageListItem,
    CheckIconWrapper,
} from './LanguageSwitcher.style';
import { useAppStateContext } from 'src/appState/AppState';
import { LanguageSwitcherState } from './LanguageSwitcher.state';
import { LanguageFlag } from './LanguageFlag';
import { useCommon } from 'src/domains/common/Common';

interface LanguageSwitcherPropsType {
    setRef: (ref: HTMLElement | null) => void;
    isSidebar?: boolean;
    reference?: HTMLElement | null;
}

export const LanguageSwitcher = observer(
    'LanguageSwitcher',
    ({ isSidebar, reference, setRef }: LanguageSwitcherPropsType): JSX.Element | null => {
        const common = useCommon();
        const { appLayoutsState } = useAppStateContext();
        const {
            breakpointsState,
            configComponents: { config },
            languagesState,
        } = appLayoutsState;
        const [state] = React.useState(
            () =>
                new LanguageSwitcherState(
                    common,
                    config,
                    languagesState,
                    appLayoutsState.starRouter.externalApiEmmiters
                )
        );

        const showLangName = breakpointsState.desktop.isBiggerOrEq === true || isSidebar;
        const chevronPosition = state.isListExpanded ? 'up' : 'down';
        const ref = reference ?? null;

        if (state.activeLanguage === null) {
            return null;
        } else {
            return (
                <LanguageSwitcherWrapper
                    ref={setRef}
                    isSidebar={isSidebar}
                >
                    <ActiveLanguageWrapper
                        onClick={(): void => state.toggleLanguageList(ref)}
                        isSidebar={isSidebar}
                    >
                        <LanguageFlag
                            lang={state.activeLanguage}
                            langName={state.currentLanguageName ?? ''}
                            langNameSide='right'
                            showLangName={showLangName}
                            isSidebar={isSidebar}
                        />
                        <DropdownChevron
                            position={chevronPosition}
                            isSidebar={isSidebar}
                        />
                    </ActiveLanguageWrapper>
                    {state.isListExpanded ? (
                        <LanguagesList isSidebar={isSidebar}>
                            {state.alternativeLanguages.map(({ langCode, langName }) => {
                                const isActive = state.activeLanguage === langCode;
                                return (
                                    <LanguageListItem
                                        key={langCode}
                                        data-test={langCode}
                                        onClick={(): void => state.onChangeLanguage(langCode)}
                                        isActive={isActive}
                                        isSidebar={isSidebar}
                                    >
                                        <LanguageFlag
                                            lang={langCode}
                                            langName={langName}
                                            langNameSide='right'
                                            showLangName={true}
                                            isOnList={true}
                                            isActive={isActive}
                                        />
                                        {isActive && <CheckIconWrapper />}
                                    </LanguageListItem>
                                );
                            })}
                        </LanguagesList>
                    ) : null}
                </LanguageSwitcherWrapper>
            );
        }
    }
);
