import { createContext } from 'src/utils/createContext';
import { EnvVariables } from './contextStore/EnvVariables';
import { AutoWeakMap, autoWeakMapKey } from 'src_common/common/mobx-utils/AutoWeakMap';
import { TrpcClient } from 'src/appState/TrpcClient';
import { Session } from 'src_common/sdk/session';
import { UniverseModuleType } from './universes.type';
import { ConfigType } from 'src/domains/layouts/config/features/types';
import { ModernizrDetectType } from 'src/domains/layouts/state/environmentState/modernizr/ModernizrType';
import { ModelsStateSocketConfig } from 'src_common/common/websocket2/ModelsStateSocketConfig';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { ServerTimeState } from 'src_common/common/websocket2/ServerTimeState';

export class Common {
    public [autoWeakMapKey] = (): void => {};

    public readonly models: ModelsState;
    public readonly serverTime: ServerTimeState;

    public constructor(
        public readonly startingUrl: string,
        public readonly envVariables: EnvVariables,
        public readonly session: Session,
        public readonly isBrowser: boolean,
        public readonly trpcClient: TrpcClient,
        public readonly themeColors: UniverseModuleType['themeColors'],
        public readonly features: ConfigType,
        public readonly faviconUrl: string,
        public readonly modernizrDetect: ModernizrDetectType | null,
        public readonly websiteBaseUrl: string
    ) {
        AutoWeakMap.register(this);

        const modelsStateSocketConfig = new ModelsStateSocketConfig({
            apiEventListIncludes: (): Record<string, string[]> => this.apiEventListIncludes,
            oddsFormatShort: (): 'f' | 'd' | 'a' => BasicDataModel.get(this).oddsFormatShort,
        });

        this.models = new ModelsState(this.session.isBrowser(), modelsStateSocketConfig, this.session.websocketUrl);

        this.serverTime = new ServerTimeState();
    }

    public get apiEventListIncludes(): Record<string, string[]> {
        return {
            horseracing: ['internationalhorseracing', 'horseracingantepost'],
            greyhoundracing: ['internationalgreyhoundracing'],
        };
    }
}

const { AppContext, useContext } = createContext<Common>('Common');

export const ProviderCommon = AppContext.Provider;

export const useCommon = (): Common => {
    return useContext();
};
