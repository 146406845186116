import { css, SerializedStyles } from '@emotion/react';

import styled from '@emotion/styled';

import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';

import { withConfig } from 'src/withConfig';

import { NotRunningPriceViewType } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow';



interface PropsType {

    isRaceInProgress?: boolean;

}



/* Table caption and cells */

export const RaceSummaryCaption = withConfig(theme => styled('li', { label: 'RaceSummaryCaption' })`

    background: ${theme.star.raceSummary.bgColorSecondary};

    border: 1px solid ${theme.star.raceSummary.borderColor};

    color: ${theme.star.raceSummary.txtColorTertiary};

    display: flex;

    flex-flow: row nowrap;

    font-size: ${theme.star.fontSize.xSmall};

    justify-content: space-between;

    line-height: 1.4;

`);



export const setRaceCell = (theme: EmotionTheme): SerializedStyles => css`

    align-items: center;

    display: flex;

    font-size: ${theme.star.fontSize.xSmall};

    justify-content: center;

`;



export const SilkCell = withConfig(theme => styled('span', { label: 'SilkCell' })`

    ${setRaceCell(theme)};

    flex: 0 0 64px;

    img {

        width: 40px;

    }

    @media ${theme.star.mediaQuery.tablet} {

        flex: 0 0 80px;

        margin-right: -16px;

        img {

            width: 50px;

        }

    }

`);



export const RunnerCaption = withConfig(theme => styled('span', { label: 'RunnerCaption' })`

    ${setRaceCell(theme)};

    flex: 1 1 0%;

    justify-content: flex-start;

    padding-left: 16px;

`);



interface RunnerCellPropsType {

    isHideRunnerInfo?: boolean;

}



export const RunnerCell = withConfig(theme => styled(RunnerCaption, { label: 'RunnerCell' })<RunnerCellPropsType>`

    align-items: flex-start;

    ${({ isHideRunnerInfo }): string => isHideRunnerInfo === true ? 'flex: 1 1 0%': 'flex: 0 1 0%'};

    flex-flow: column nowrap;

    justify-content: center;

    padding: 0 16px 0 0;

    @media ${theme.star.mediaQuery.tablet} {

        flex: 1 1 50%;

        padding: 16px 0 16px 16px;

    }

`);



interface RaceRunnerNameAndPlacePropsType {

    isCast: boolean;

}



export const RaceRunnerNameAndPlace = withConfig(theme => styled('span', { label: 'RaceRunnerNameAndPlace' })<RaceRunnerNameAndPlacePropsType>`

    color: ${theme.star.raceSummary.txtColor};

    display: block;

    font-size: ${theme.star.fontSize.small};

    line-height: 1.3334;

    padding-bottom: 4px;

    ${({ isCast }): SerializedStyles | null => isCast ? css`

        max-width: 100px;

        overflow: hidden;

        text-overflow: ellipsis;

        white-space: nowrap;

    ` : null}

`);



export const RunnerDetails = withConfig(theme => styled('i', { label: 'RunnerDetails' })`

    color: ${theme.star.raceSummary.txtColorSecondary};

    display: block;

    font-size: ${theme.star.fontSize.xSmall};

    font-style: normal;

    line-height: 1.4;

`);



export const RunnerInfoCell = withConfig(theme => styled('span', { label: 'RunnerInfoCell' })`

    display: flex;

    @media ${theme.star.mediaQuery.tablet} {

        flex: 1 1 50%;

        flex-flow: column nowrap;

        justify-content: center;

    }

`);



export const RunnerCellsWrapper = withConfig(theme => styled('span', { label: 'RunnerCellsWrapper' })`

    display: flex;

    flex: 1 1 0%;

    flex-flow: column nowrap;

    justify-content: center;

    padding-right: 16px;

    @media ${theme.star.mediaQuery.tablet} {

        flex-flow: row nowrap;

    }

`);



export const WeightAndAgeLine = styled('span', { label: 'WeightAndAgeLine' })`

    display: flex;

`;



export const SelectionCaption = withConfig(theme => styled('span', { label: 'SelectionCaption' })<PropsType>`

    ${setRaceCell(theme)};

    border-left: 1px solid ${theme.star.raceSummary.borderColor};

    color: ${({ isRaceInProgress }): string => isRaceInProgress === true ? theme.star.raceCardColors.txtColorInProgressRace : 'inherit'};

    margin-right: ${({ isRaceInProgress }): string => isRaceInProgress === true ? '1px' : '0'};

    flex: 0 0 48px;

    padding: 1px 4px;

    text-align: center;

    @media ${theme.star.mediaQuery.tablet} {

        flex: 0 0 64px;

        margin-left: -1px;

    }

`);



export const SelectionCaptionCast = withConfig(theme => styled(SelectionCaption, { label: 'SelectionCaptionCast' })`

    @media ${theme.star.mediaQuery.tablet} {

        margin-left: 0;

    }

`);





export const PriceHistoryCaption = withConfig(theme => styled('span', { label: 'PriceHistoryCaption' })<PropsType>`

    ${setRaceCell(theme)};

    border-left: 1px solid ${theme.star.raceSummary.borderColor};

    flex: 0 0 76px;

    margin-right: -1px;

    text-align: center;

    @media ${theme.star.mediaQuery.tablet} {

        flex: 0 0 88px;

        margin-right: 0;

    }

`);



export const PriceHistoryCell = withConfig(theme => styled(PriceHistoryCaption, { label: 'PriceHistoryCell' })`

    border-right: 1px solid ${theme.star.raceSummary.borderColor};

    overflow: hidden;

    margin-right: 0;

    padding: 4px;

`);



interface RaceSummaryRowPropsType {

    isNonRunner?: boolean;

    tags?: React.ReactNode;

    isAlternativeTag?: boolean;

}



const setTags = (isAlternativeTag?: boolean, tags?: React.ReactNode): SerializedStyles | null => {

    if (tags === undefined || tags === null) {

        return null;

    }



    if (isAlternativeTag === true) {

        return css`

            margin-bottom: 18px;

        `;

    }



    return css`

        margin-bottom: 28px;

    `;

};



export const RaceSummaryRow = withConfig(theme => styled('li', { label: 'RaceSummaryRow' })<RaceSummaryRowPropsType>`

    ${({ isAlternativeTag, tags }): SerializedStyles | null => setTags(isAlternativeTag, tags)};

    background-color: ${theme.star.raceSummary.bgColor};

    color: ${theme.star.raceSummary.txtColor};

    border-bottom: 1px solid  ${theme.star.raceSummary.borderColor};

    display: flex;

    flex-flow: row nowrap;

    height: 72px;

    justify-content: space-between;

    opacity: ${({ isNonRunner }): number => isNonRunner === true ? 0.4 : 1};

    position: relative;

    &:last-of-type {

        border-bottom: 0;

    }

    @media ${theme.star.mediaQuery.tablet} {

        border-left: 1px solid  ${theme.star.raceSummary.borderColor};

        height: 64px;

    }

`);



export const UnnamedFavouriteLabel = styled('span', { label: 'UnnamedFavouriteLabel' })`

    align-items: center;

    border-right: none;

    display: flex;

    flex: 1 1 0%;

    justify-content: flex-start;

    padding-left: 16px;

`;



export const NonRunnerCell = withConfig(theme => styled('span', { label: 'NonRunnerCell' })<NotRunningPriceViewType>`

    ${setRaceCell(theme)};

    border-right: 1px solid ${theme.star.raceSummary.borderColor};

    flex: ${({ eachWayExtra }): string => eachWayExtra === true ? '0 0 64px' : '0 0 48px'};

    width: ${({ eachWayExtra }): string => eachWayExtra === true ? '64px' : '48px'};

    font-size: ${theme.star.fontSize.small};

    margin-left: -1px;

    @media ${theme.star.mediaQuery.tablet} {

        flex: 0 0 64px;

        width: 64px;

        font-size: ${theme.star.fontSize.xRegular};

    }

`);



export const NonRunnerCellMatchBet = withConfig(theme => styled(NonRunnerCell, { label: 'NonRunnerCellMatchBet' })`

    border-left: 1px solid ${theme.star.raceSummary.borderColor};

`);



export const RaceSummary = withConfig(theme => styled('div', { label: 'RaceSummary' })`

    font-size: ${theme.star.fontSize.small};

    line-height: 1.3;

    margin-top: -1px;

    &:first-of-type {

        margin-top: -1px;

    }

`);

