import * as React from 'react';
import { SerializedStyles } from '@emotion/react';
import { action, computed, makeObservable, observable } from 'mobx';
import { timeout } from 'src_common/common/mobx-utils/timeout';
import { assertNever } from 'src_common/common/assertNever';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { ModalLayer, OverlayOnClickType } from 'src/domains/layouts/webview/components/modals/Layer.state';
import { AnimationType } from 'src/domains/layouts/webview/components/modals/Layer.style';

export class ModalState {
    @observable public list: Array<ModalLayer>;

    public constructor(public readonly starRouter: StarRouter) {
        makeObservable(this);
        this.list = [];
    }

    public show(params: {
        animation: AnimationType;
        content: React.ReactElement;
        contentCss: SerializedStyles;
        layerCss: () => SerializedStyles;
        overlayOnClick: OverlayOnClickType;
    }): void {
        this.list.push(
            new ModalLayer(params.animation, params.content, params.contentCss, params.layerCss, params.overlayOnClick)
        );
    }

    @computed public get isAnyLayerVisible(): boolean {
        return this.list.some((layer) => layer.visible);
    }

    @action public closeTop = async (): Promise<void> => {
        const last = this.list
            .slice()
            .reverse()
            .find((item) => item.visible);

        if (last === undefined) {
            return;
        }

        last.visible = false;
        await timeout(3000);

        this.list = this.list.filter((item) => item !== last);
    };

    @action public closeAll = async (): Promise<void> => {
        const list = [...this.list];

        for (const layer of list) {
            layer.visible = false;
        }

        this.starRouter.closeAccount();

        await timeout(3000);

        this.list = this.list.filter((layer) => {
            return list.includes(layer) ? false : true;
        });
    };

    @action public clickOverlay = async (): Promise<void> => {
        const last = this.list
            .slice()
            .reverse()
            .find((item) => item.visible);

        if (last === undefined) {
            return;
        }

        switch (last.overlayOnClick) {
            case 'none':
                return;
            case 'closeAll':
                await this.closeAll();
                return;
            case 'closeTop':
                await this.closeTop();
                return;
            default:
                return assertNever('Overlay onClick missing: ', last.overlayOnClick);
        }
    };
}
