import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
// fix in LT-2382
// eslint-disable-next-line
import { useAsObservableSource } from 'mobx-react-lite';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { RacesSlider } from 'src/domains/sportsbook/webview/components/racesSlider/RacesSlider';
import { SportMeetingsList } from 'src/domains/sportsbook/webview/components/containers/sportMeetingsList/SportMeetingsList';
import { QuickPick } from 'src/domains/sportsbook/webview/components/quickPick/QuickPick';
import { AtLeastTabletView, FiltersWrapper, MobileView, ContentWrapper } from './NewRacingPage.style';
import { FiltersNewView } from 'src/domains/layouts/webview/components/filters/Filters';
import { Accordion } from 'src/domains/sportsbook/webview/components/racing/Accordion';
import { AccordionState } from 'src/domains/sportsbook/webview/components/racing/Accordion.state';
import { CompetitionsTrapChallenge } from 'src/domains/sportsbook/webview/components/containers/competitionsTrapChallenge/CompetitionsTrapChallenge';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import { PriceChangeLegend } from 'src/domains/layouts/webview/components/priceChangeLegend/PriceChangeLegend';
import { RaceSportType } from 'src/domains/sportsbook/webview/components/racesSlider/RacesSlider.state';
import { MeetingsSpecials } from 'src/domains/sportsbook/webview/components/meetingsAntePostAndSpecials/MeetingsSpecials';
import { MeetingsAntePost } from 'src/domains/sportsbook/webview/components/meetingsAntePostAndSpecials/MeetingsAntePost';
import { areSpecialEventsPresent, getHeader, renderButton } from './NewRacingPage.helpers';
import { RacesSliderListState } from 'src/domains/sportsbook/webview/components/racesSlider/RacesSliderList.state';
import { SportHeaderStar } from 'src/domains/layouts/webview/components/sportHeader/SportHeader';
import { SportRacesTypeParamType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { HorseRacingState } from './NewRacingPage.state';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { SeoPagesContent } from 'src/domains/layouts/webview/components/seoPages/SeoPages';

interface FilterType {
    key: SportRacesTypeParamType;
    label: string;
    onClick: () => void;
    dataTest: string;
}

const getAzListLabel = (configComponents: ConfigComponents, language: LanguagesState): string => {
    return configComponents.config.racingPageTabNames === 'betzone-type'
        ? language.getTranslation('sports.racing.meeting-zone.label', 'Meeting Zone')
        : language.getTranslation('sports.racing.az.label', 'A-Z');
};

const getAntePostLabel = (configComponents: ConfigComponents, language: LanguagesState): string => {
    return configComponents.config.racingPageTabNames === 'betzone-type'
        ? language.getTranslation('sports.racing.ante-post-zone.label', 'Ante Post Zone')
        : language.getTranslation('sports.racing.ante-post.label', 'Ante Post');
};

const getFilters = (
    configComponents: ConfigComponents,
    language: LanguagesState,
    horseRacingState: HorseRacingState,
    onChangeCallback: (id: SportRacesTypeParamType) => void
): Array<FilterType> => {
    const { getTranslation } = language;

    const azListLabel = getAzListLabel(configComponents, language);

    const quickPickLabel =
        configComponents.config.racingPageTabNames === 'betzone-type'
            ? language.getTranslation('sports.racing.fast-bet-zone.label', 'Fast Bet Zone')
            : language.getTranslation('sports.racing.quick-pick.label', 'Quick Pick');

    const buildRacecardLabel =
        configComponents.config.racingPageTabNames === 'betzone-type'
            ? language.getTranslation('sports.racing.racecard-zone.label', 'My Racecard Zone')
            : language.getTranslation('sports.racing.build-racecard.label', 'Build a Racecard');

    const trapChallengeBlock: FilterType = {
        key: 'trap-challenge',
        label: getTranslation('sports.racing.trap-challenge.label', 'Trap Challenge'),
        dataTest: getTranslation('sports.racing.trap-challenge.label', 'Trap Challenge'),
        onClick: (): void => onChangeCallback('trap-challenge'),
    };
    const antePostLabel = getAntePostLabel(configComponents, language);

    const specialsFilter: FilterType = {
        key: 'specials',
        label: getTranslation('sports.racing.specials.label', 'Specials'),
        dataTest: getTranslation('sports.racing.specials.label', 'Specials'),
        onClick: (): void => onChangeCallback('specials'),
    };

    const optionAz: FilterType = {
        key: 'list',
        label: azListLabel,
        dataTest: language.getTranslation('sports.racing.az.label', 'A-Z'),
        onClick: (): void => onChangeCallback('list'),
    };

    const optionQuickPick: FilterType = {
        key: 'quick-pick',
        label: quickPickLabel,
        dataTest: language.getTranslation('sports.racing.quick-pick.label', 'Quick Pick'),
        onClick: (): void => onChangeCallback('quick-pick'),
    };

    const optionBuildRaceCard: FilterType = {
        key: 'build-racecard',
        label: buildRacecardLabel,
        dataTest: language.getTranslation('sports.racing.build-racecard.label', 'Build a Racecard'),
        onClick: (): void => onChangeCallback('build-racecard'),
    };

    const optionAntePost: FilterType = {
        key: 'ante-post',
        label: antePostLabel,
        dataTest: language.getTranslation('sports.racing.ante-post.label', 'Ante Post'),
        onClick: (): void => onChangeCallback('ante-post'),
    };

    if (horseRacingState.showTrapChallenge === true) {
        return [optionAz, optionQuickPick, optionBuildRaceCard, optionAntePost, trapChallengeBlock];
    }

    if (horseRacingState.showSpecials === true) {
        return [optionAz, specialsFilter, optionQuickPick, optionBuildRaceCard, optionAntePost];
    }

    return [optionAz, optionQuickPick, optionBuildRaceCard, optionAntePost];
};

interface PropsType {
    sport: RaceSportType;
    filterType: SportRacesTypeParamType | null;
    eventId: EventId | null;
}

export const HorseRacing = observer('HorseRacing', (props: PropsType) => {
    const { sport, eventId } = props;

    const appState = useAppStateContext();
    const { appLayoutsState, appSportsBookState } = appState;
    const { configComponents, languagesState, starRouter } = appLayoutsState;

    const filterType = props.filterType ?? 'list';
    const state = appSportsBookState.horseRacingState.get(sport);
    const [azAccordionState] = useState(new AccordionState());
    const [antePostAccordionState] = useState(new AccordionState());
    const [specialsAccordionState] = useState(new AccordionState());
    const [trapChallengeAccordionState] = useState(new AccordionState());

    const selectedNew = state.selectedNew;
    const buildRaceCard = filterType === 'build-racecard';

    const racesSliderState = appSportsBookState.raceSlider.get(sport);
    const sliderProps = useAsObservableSource({
        events: racesSliderState.eventsCollection.eventsForView,
        sport: sport,
    });
    const [racesSliderListState] = React.useState(() => new RacesSliderListState(appState, sliderProps));
    const isLoading = racesSliderListState.shouldShowLoading;
    const options = getHeader(sport);
    const isAntePostReverse = filterType === 'ante-post' && configComponents.config.isAntePostReverse;

    const filters = getFilters(configComponents, languagesState, state, state.cleanSelected);

    return (
        <section className='sport-racing'>
            <SportHeaderStar
                options={options}
                sportId={sport}
                key={`sport-header + ${sport}`}
                isCompetitionPage={false}
                competitionId={null}
            />

            <ContentWrapper isAntePostReverse={isAntePostReverse}>
                <RacesSlider
                    key={`next-off__${sport}`}
                    sport={sport}
                    mainPage={false}
                    isRacingToggle={false}
                    racesSliderState={racesSliderState}
                    racesSliderListState={racesSliderListState}
                />

                <AtLeastTabletView
                    data-test='AtLeastTabletView'
                    isAntePostReverse={isAntePostReverse}
                >
                    <FiltersWrapper>
                        <FiltersScrollableWrapper>
                            <FiltersNewView
                                select={filterType}
                                filters={filters}
                            />
                        </FiltersScrollableWrapper>
                    </FiltersWrapper>
                    <React.Fragment key='filters'>
                        {filterType === 'list' ? (
                            <SportMeetingsList
                                key='list'
                                sport={sport}
                                selected={selectedNew}
                                isLoading={isLoading}
                                isBuilding={false}
                                shared
                            />
                        ) : null}
                        {filterType === 'quick-pick' ? (
                            <QuickPick
                                label={
                                    <I18n
                                        langKey='racing.quick-pick.label'
                                        defaultText='quick pick'
                                    />
                                }
                                key='quick-pick'
                                sport={sport}
                                isLoading={isLoading}
                                shared
                            />
                        ) : null}
                        {filterType === 'build-racecard' ? (
                            <SportMeetingsList
                                key='build-racecard'
                                sport={sport}
                                selected={selectedNew}
                                isBuilding
                                onChoose={state.toggleEvent}
                                isLoading={isLoading}
                                shared
                            />
                        ) : null}
                        {filterType === 'ante-post' ? (
                            <MeetingsAntePost
                                key='ante-post'
                                sport={sport}
                                filterType={filterType}
                                eventId={eventId}
                                isLoading={isLoading}
                                shared
                            />
                        ) : null}
                        {filterType === 'specials' ? (
                            <MeetingsSpecials
                                key='specials'
                                sport={sport}
                                filterType={filterType}
                                isLoading={isLoading}
                                eventId={eventId}
                                shared
                            />
                        ) : null}
                        {filterType === 'trap-challenge' ? (
                            <CompetitionsTrapChallenge
                                key='trap-challenge'
                                isLoading={isLoading}
                                sportEvents={state.trapChallengeEvents}
                                shared
                            />
                        ) : null}
                    </React.Fragment>
                </AtLeastTabletView>
            </ContentWrapper>

            <MobileView
                key='accordions'
                data-test='MobileView'
            >
                <Accordion
                    state={azAccordionState}
                    title={getAzListLabel(configComponents, languagesState)}
                >
                    <SportMeetingsList
                        key='list'
                        sport={sport}
                        selected={selectedNew}
                        isBuilding={false}
                        isLoading={isLoading}
                        shared
                    />
                </Accordion>
                {sport === 'horseracing' && areSpecialEventsPresent(state.collectionEvents) ? (
                    <Accordion
                        state={specialsAccordionState}
                        title={
                            <I18n
                                langKey='sports.racing.specials-mobile.label'
                                defaultText='Specials'
                            />
                        }
                    >
                        <MeetingsSpecials
                            key='specials'
                            isLoading={isLoading}
                            sport={sport}
                            filterType={filterType}
                            eventId={eventId}
                            shared
                        />
                    </Accordion>
                ) : null}
                <Accordion
                    state={antePostAccordionState}
                    title={getAntePostLabel(configComponents, languagesState)}
                >
                    <div ref={state.setRef}>
                        <MeetingsAntePost
                            key='ante-post'
                            sport={sport}
                            filterType={filterType}
                            isLoading={isLoading}
                            eventId={eventId}
                            shared
                            handleScroll={state.handleScroll}
                        />
                    </div>
                </Accordion>
                {state.showTrapChallenge ? (
                    <Accordion
                        state={trapChallengeAccordionState}
                        title={
                            <I18n
                                langKey='sports.racing.trap-challenge.label'
                                defaultText='Trap Challenge'
                            />
                        }
                    >
                        <CompetitionsTrapChallenge
                            key='trap-challenge'
                            isLoading={isLoading}
                            sportEvents={state.trapChallengeEvents}
                            shared
                        />
                    </Accordion>
                ) : null}
            </MobileView>

            {selectedNew.racesSelected() > 0 && buildRaceCard
                ? renderButton(starRouter, state.isBuilding, state.selectedNew, sport)
                : null}
            <SeoPagesContent pageSlug={sport} />
            <PriceChangeLegend isRace={true} />
        </section>
    );
});
