import React from 'react';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { TraderChatState } from 'src/domains/players/state/traderChat/TraderChatState';
import { ErrorWrapper } from 'src/domains/players/webview/components/Account/traderChat/components/Error.style';
import { observer } from 'src/utils/mobx-react';

export const Error = observer('Error', () => {
    const common = useCommon();

    const config = ConfigComponents.get(common).config;
    const { error } = TraderChatState.get(common);

    if (error === null) {
        return null;
    }

    return <ErrorWrapper layout={config.layout}>{error}</ErrorWrapper>;
});
