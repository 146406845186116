import React, { useState } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    StaticPageInfoWrapper,
    StaticPageInnerWrapper,
    StaticPageWrapper,
    FreeBetIconWrapper,
    StaticPageLoaderWrapper,
    StaticFreeBetLoaderWrapper,
    StaticAccountSummaryWrapper,
    AccountSummaryDefinitionTag,
    AccountSummaryDefinitionDescribe,
    ButtonWrapper,
} from 'src/domains/players/webview/components/Account/staticPage/StaticPage.style';
import { StaticPageHeader } from 'src/domains/players/webview/components/Account/staticPage/StaticPageHeader';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { Result } from 'src_common/common/mobx-utils/Resource';
import { PageCMSType } from 'src/domains/layouts/state/customer';
import { Amount } from 'src_common/common/amount/Amount';

interface StaticPageContentType {
    title: React.ReactElement | null | string;
    content: React.ReactElement | null | string;
}

export class StaticPageState {
    public handleExportTerms = (page: Result<PageCMSType | null> | null): void => {
        const isReadyAndValid = page !== null && page.type === 'ready' && page.value !== null;
        if (isReadyAndValid) {
            const tempElem = document.createElement('div');
            const pageContent = page.value?.content ?? '';
            const formattedContent = pageContent.replace('mailto:', '');

            tempElem.innerHTML = formattedContent;
            const allSelectors = Array.from(tempElem.querySelectorAll('a'));
            for (const index of allSelectors) {
                const text = document.createTextNode(`(${index.getAttribute('href') ?? ''})`);
                index.replaceWith(text);
            }
            const link = document.createElement('a');

            link.setAttribute('download', 'terms-and-conditions.txt');
            link.setAttribute(
                'href',
                `data:text/plain;charset=utf-8,${encodeURIComponent(tempElem.textContent ?? '')}`
            );
            link.click();

            link.remove();
            tempElem.remove();
        } else {
            console.error('Page is invalid');
        }
    };
}

const FreeBetCredits = observer('FreeBetCredits', () => {
    const { appPlayersState, appLayoutsState } = useAppStateContext();
    const { configComponents } = appLayoutsState;

    let content = <StaticFreeBetLoaderWrapper size={12} />;

    const freeBetsAmount = appPlayersState.usersState.freeBetsData.valueReady?.response?.totalAmount;
    if (freeBetsAmount === undefined) {
        content = <>{appPlayersState.usersState.money(new Amount('0'))}</>;
    } else {
        content = <>{appPlayersState.usersState.money(configComponents.precision.newFromAnything(freeBetsAmount))}</>;
    }

    return (
        <StaticAccountSummaryWrapper>
            <AccountSummaryDefinitionTag>
                <I18n
                    langKey='account.summary.free-bet-credits.label'
                    defaultText='Free Bet Credits'
                />
            </AccountSummaryDefinitionTag>

            <AccountSummaryDefinitionDescribe>
                <FreeBetIconWrapper />
                {content}
            </AccountSummaryDefinitionDescribe>
        </StaticAccountSummaryWrapper>
    );
});

const build_CUSTOM_STATIC_CONTENT: Record<string, React.ComponentType> = {
    'free-bet-credits': FreeBetCredits,
};

interface StaticPagePropsType {
    id: string;
}

export const StaticPage = observer('StaticPage', (props: StaticPagePropsType): React.ReactElement => {
    const { appPlayersState } = useAppStateContext();

    const [state] = useState(() => new StaticPageState());
    const pageId = props.id;

    const page = appPlayersState.usersState.getPage(pageId);
    const CustomContent = build_CUSTOM_STATIC_CONTENT[pageId];
    const isPageValid = page.type === 'ready' && page.value !== null;

    const renderStaticPage = (page: Result<PageCMSType | null> | null): StaticPageContentType => {
        const type = page === null ? 'error' : page.type;
        const title =
            page !== null && page.type === 'ready' && page.value !== null ? (
                page.value?.title ?? null
            ) : (
                <I18n
                    langKey='account.static-page.error.title'
                    defaultText='Error occurred'
                />
            );

        const content =
            page !== null && page.type === 'ready' && page.value !== null ? (
                <div dangerouslySetInnerHTML={{ __html: page.value?.content ?? '' }} />
            ) : (
                <StaticPageInfoWrapper>
                    <I18n
                        langKey='account.static-page.error.description'
                        defaultText="Sorry, we can't find page you are searching for."
                    />
                </StaticPageInfoWrapper>
            );

        switch (type) {
            case 'loading':
                return {
                    title: null,
                    content: <StaticPageLoaderWrapper />,
                };
            case 'ready':
                return {
                    title: title,
                    content: content,
                };
            case 'error':
                return {
                    title: (
                        <I18n
                            langKey='account.static-page.error.title'
                            defaultText='Error occurred'
                        />
                    ),
                    content: (
                        <StaticPageInfoWrapper>
                            <I18n
                                langKey='account.static-page.error.description'
                                defaultText="Sorry, we can't find page you are searching for."
                            />
                        </StaticPageInfoWrapper>
                    ),
                };
        }
    };

    const { title, content } = renderStaticPage(page);
    const isTernsAndConditions = page.type === 'ready' && page.value?.slug === 'terms-and-conditions';
    return (
        <StaticPageWrapper data-test='static-page'>
            <StaticPageHeader
                title={title}
                back={true}
                parent='summary'
            />
            <StaticPageInnerWrapper data-test='static-page-content'>
                {CustomContent === undefined ? null : <CustomContent key='custom-content' />}
                {content}
                {appPlayersState.usersState.config.canDownloadTerms && isPageValid && isTernsAndConditions ? (
                    <ButtonWrapper
                        size='large'
                        onClick={(): void => state.handleExportTerms(page)}
                        dataTest='terms-and-conditions-download-button'
                    >
                        <I18n
                            langKey='account.static-page.download-tc'
                            defaultText="Download T&C's"
                        />
                    </ButtonWrapper>
                ) : null}
            </StaticPageInnerWrapper>
        </StaticPageWrapper>
    );
});

interface StaticPageTabPropsType {
    id: string;
}
export const StaticPageTab = observer('StaticPageTab', (props: StaticPageTabPropsType): React.ReactElement => {
    const { id } = props;

    return <StaticPage id={id} />;
});
