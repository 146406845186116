import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { ResetPasswordForm } from 'src/domains/players/webview/components/Account/resetPasswordProcedure/resetPasswordForm/ResetPasswordForm';
import { McBookieResetPasswordFormTerms } from 'src/domains/players/webview/components/Account/resetPasswordProcedure/resetPasswordForm/McBookieResetPasswordFormTerms';
import { ResetPasswordRhsRouteType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';


interface PropsType {
    currentRoute: ResetPasswordRhsRouteType;
}

export const ResetPasswordProcedure = observer('ResetPasswordProcedure', (props: PropsType) =>{
    const { currentRoute } = props;
    const { appLayoutsState } = useAppStateContext();
    const { resetPasswordForm } = appLayoutsState.configComponents.config;

    if (resetPasswordForm === 'McBookieValidateAccountForm' || currentRoute.isVerify === true){
        return <McBookieResetPasswordFormTerms currentRoute={currentRoute} />;
    }

    return <ResetPasswordForm currentRoute={currentRoute} />;
});
