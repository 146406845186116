import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { NavigationChevronIconWrapper } from 'src/domains/layouts/webview/components/navigationPanel/NavigationPanel.style';
import { StarEventsLink } from '../NavigationSidebar/NavigationSidebar.style';

export const PromotedEventsLink = observer('StarEventsSection', (): React.ReactElement | null => {
    const appState = useAppStateContext();

    const {
        appLayoutsState: { starRouter, promotedEventsState, overlay },
        config: { promotedEvents },
    } = appState;

    const redirectToStarEvents = (): void => {
        starRouter.redirectToStarEvents();
        overlay.toggleMenu();
    };

    const promotedSports = promotedEventsState.promotedSportsToShow;
    const shouldShowStarEventsLink = promotedEvents.route !== '';
    const starEventsLabel = promotedEvents.label;

    if (shouldShowStarEventsLink && promotedSports.length > 0) {
        return (
            <StarEventsLink onClick={redirectToStarEvents}>
                <I18n
                    langKey='left-menu.star-events.label'
                    defaultText='{starEventsLabel}'
                    params={{ starEventsLabel }}
                />

                <NavigationChevronIconWrapper position='right' />
            </StarEventsLink>
        );
    }

    return null;
});
