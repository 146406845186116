import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

const buildSelectBorder = (theme: EmotionTheme, isFocus: boolean, isError?: boolean): string => {
    if (isFocus) {
        return `
            border: 1px solid ${theme.star.form.light.borderColor};
        `;
    } else if (isError === true) {
        return `
            border: 1px solid ${theme.star.statuses.error.bgColor};
        `;
    }
    return `
        border: 1px solid ${theme.star.form.light.bgColor};
    `;
};

interface SelectStyledPropType {
    isFocus: boolean;
    isSelected?: boolean;
    isError?: boolean;
    showIndicator?: boolean;
}

export const SelectWrapper = withConfig(theme => styled('div', { label: 'SelectWrapper' })<SelectStyledPropType>`
    position: relative;
    margin-bottom: 8px;
    background-color: ${theme.star.form.light.bgColor};
    ::before,
    ::after {
        display: ${({ showIndicator }): string => showIndicator === true ? 'block' : 'none'};
        position: absolute;
        content: '';
        width: 1px;
        height: 8px;
        top: 20px;
        right: 20px;
        background-color: ${theme.star.form.bgColor};
        pointer-events: none;
        transform: translateY(-50%) translateX(50%) rotate(45deg);
    }
    ::after {
        margin-right: 5px;
        transform: translateY(-50%) translateX(50%) rotate(-45deg);
    }
`);

export const SelectLabel = withConfig(theme => styled('label', { label: 'SelectLabel' })<SelectStyledPropType>`
    white-space: nowrap;
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    font-size: ${theme.star.fontSize.small};
    line-height: 1.17;
    color: ${theme.star.form.txtColorSecondary};
`);

export const SelectElement = withConfig(theme => styled('select', { label: 'SelectElement' })<SelectStyledPropType>`
    position: relative;
    width: 100%;
    height: 40px;
    padding: 0 25px 0 8px;
    font-size: ${theme.star.fontSize.small};
    color: ${theme.star.form.txtColor};
    line-height: 1;
    appearance: none;
    outline: none;
    background: transparent;
    ${({ isFocus, isError }): string => buildSelectBorder(theme, isFocus, isError)};
`);

export const EmptyOption = styled('option', { label: 'EmptyOption' })`
    display: none;
`;
