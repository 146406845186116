import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { getErrorByCode, MessagesKeyType } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { AccountBasicDataType } from 'src/domains/players/state/BasicDataModel';
import { Amount } from 'src_common/common/amount/Amount';
import { CurrencySymbolType, CurrencyType } from 'src_common/common/amount/website-money/currency';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { DateTime } from 'src_common/utils/time/time';

export const validateMinAmount =
    (minValue: Amount, currencySymbol: () => CurrencySymbolType, errorCode: MessagesKeyType) =>
    (amount: Amount): Result<Amount> => {
        if (amount.isLessThan(minValue)) {
            return Result.createError(
                getErrorByCode(errorCode)
                    .replace('%currencySymbol', currencySymbol())
                    .replace('%minValue', minValue.value)
            );
        }

        return Result.createOk(amount);
    };

export const validateMinAmountNew =
    (minValue: Amount, errorMessage: string) =>
    (amount: Amount): Result<Amount> => {
        if (amount.isLessThan(minValue)) {
            return Result.createError(errorMessage);
        }

        return Result.createOk(amount);
    };

export const validateMaxAmountNew =
    (maxValue: Amount | null, errorMessage: string) =>
    (amount: Amount): Result<Amount> => {
        if (maxValue === null || amount.isLessThan(maxValue)) {
            return Result.createOk(amount);
        }

        return Result.createError(errorMessage);
    };

export const validateMaxDeposit =
    (
        maxValue: () => Amount | null,
        moneySymbol: () => CurrencyType,
        languagesState: LanguagesState,
        depositRestrictionFn: () => AccountBasicDataType['depositRestriction'],
        accountHelperMail: string
    ) =>
    (amount: Amount): Result<Amount> => {
        const maxAmount = maxValue();
        const currencySymbol = moneySymbol();
        const depositRestriction = depositRestrictionFn();
        const isValidDate =
            depositRestriction?.expiryDate !== null &&
            depositRestriction?.expiryDate !== undefined &&
            DateTime.from(depositRestriction.expiryDate) !== null &&
            DateTime.current().isBefore(DateTime.from(depositRestriction.expiryDate) ?? '');

        if (maxAmount === null || amount.isLessOrEqual(maxAmount)) {
            return Result.createOk(amount);
        } else if (depositRestriction?.permanent === true || isValidDate === true) {
            return Result.createError(
                languagesState.getTranslation(
                    'account.top-up.errors.limit-deposit-restriction',
                    "You're approaching your current net deposit limit! To adjust your limit, whether to increase or decrease it, please reach out to us at {email}. We're here to help!",
                    {
                        email: accountHelperMail,
                    }
                )
            );
        } else if (maxAmount.isEqualWithZero()) {
            return Result.createError(
                languagesState.getTranslation(
                    'errors.max-deposit-when-reached',
                    'Your deposit limit has been reached. Please try again later.'
                )
            );
        } else {
            return Result.createError(
                languagesState.getTranslation(
                    'errors.max-deposit-new',
                    'You can only deposit a maximum of {maxValue}',
                    {
                        maxValue: maxAmount.format(currencySymbol),
                    }
                )
            );
        }
    };

export const validateMaxAmount =
    (maxValueFunction: () => Amount, currencySymbol: () => CurrencySymbolType, errorCode: MessagesKeyType) =>
    (amount: Amount): Result<Amount> => {
        const maxValue = maxValueFunction();

        if (amount.isGreaterThan(maxValue)) {
            return Result.createError(
                getErrorByCode(errorCode)
                    .replace('%currencySymbol', currencySymbol())
                    .replace('%maxValue', maxValue.value)
            );
        }

        return Result.createOk(amount);
    };
