import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    AccountNavLink,
    AccountNavText,
    HelpIconWrapper,
} from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/AccountNavigation.style';
import { UniverseComponent } from 'src/domains/common/universes';

export const FaqLinkNav = observer('FaqLinkNav', () => (
    <AccountNavLink
        dataTest='account-navigation-help'
        to={{ account: 'static', static: 'help' }}
        routeNameForGtm='help'
    >
        <HelpIconWrapper />

        <AccountNavText>
            <UniverseComponent>
                {(universeVersion): React.ReactElement => <universeVersion.faqLinkNav />}
            </UniverseComponent>
        </AccountNavText>
    </AccountNavLink>
));
