import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { PofPopup } from './PofPopup';
import { useCommon } from 'src/domains/common/Common';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';

export const PofPopups = observer('PofPopups', (): JSX.Element | null => {
    const {
        appPlayersState: { usersState },
    } = useAppStateContext();

    const common = useCommon();
    const localStorageState = LocalStorageState.get(common);

    const pofStatus = usersState.basicData.valueReady?.pofStatus;

    if (pofStatus === 'not-checked' || pofStatus === 'passed') {
        localStorageState.pofPopups.setValue({
            isPofFailedModalAlreadyShowed: false,
            isPofisPofRequestedWithoutCRModalAlreadyShowed: false,
            isPofRequestedModalAlreadyShowed: false,
        });
    }

    if (usersState.isPofRequestedWithoutCRModal === true) {
        return (
            <PofPopup
                subHeaderText={
                    <I18n
                        langKey='pof.popup.requested-without-cr.subheader'
                        defaultText='An enhanced verification email has been sent to your registered email address. Please read and respond promptly. You have one week to provide the requested documentation from when the email was sent. If your account activity requires it, we may need to restrict your account before the deadline.'
                    />
                }
                descriptionText={null}
                onButtonClick={usersState.togglePofRequestedWithoutCRPopup}
            />
        );
    }

    if (usersState.isPofFailedModal === true) {
        return (
            <PofPopup
                subHeaderText={
                    <I18n
                        langKey='pof.popup.failed.subheader'
                        defaultText='We requested enhanced verification on your account via e-mail.'
                    />
                }
                descriptionText={
                    <I18n
                        langKey='pof.popup.failed.description'
                        defaultText='As we have not received a reply, we have placed a temporary compliance restriction on the account.'
                    />
                }
                onButtonClick={usersState.togglePofFailedPopup}
            />
        );
    }

    if (usersState.isPofRequestedModal === true) {
        return (
            <PofPopup
                subHeaderText={
                    <I18n
                        langKey='pof.popup.requested.subheader'
                        defaultText='Your account is currently under restrictions as we need to conduct additional enhanced verification. An email has been sent to you detailing the necessary information. We apologise for any inconvenience this may cause.'
                    />
                }
                descriptionText={null}
                onButtonClick={usersState.togglePofRequestedPopup}
            />
        );
    }

    return null;
});
