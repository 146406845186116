import styled from '@emotion/styled';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { css, SerializedStyles } from '@emotion/react';
import { PhoneIcon } from 'src/domains/layouts/shared/assets/icons/PhoneIcon';
import { SocialFacebookIcon } from 'src/domains/layouts/shared/assets/icons/social/SocialFacebookIcon';
import { SocialInstagramIcon } from 'src/domains/layouts/shared/assets/icons/social/SocialInstagramIcon';
import { SocialTwitterIcon } from 'src/domains/layouts/shared/assets/icons/social/SocialTwitterIcon';
import { EmailIcon } from 'src/domains/layouts/shared/assets/icons/EmailIcon';
import { withConfig } from 'src/withConfig';

export const LinkWrapperStatic = withConfig(theme => styled(Link, { label: 'LinkWrapperStatic' })`
    align-items: center;
    background: ${theme.star.footerMain.bgColorTertiary};
    border-radius: 8px;
    color: ${theme.star.footerMain.txtColorTertiary};
    display: flex;
    flex-flow: row wrap;
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.bold};
    height: 64px;
    justify-content: center;
    line-height: 1.4;
    padding: 8px 4px;
    text-decoration: none;
    transition: color .2s ease;
    &:hover {
        color: ${theme.star.footerMain.txtColor};
    }
    @media ${theme.star.mediaQuery.xxLargeDesktop} {
        font-size: ${theme.star.fontSize.small};
        line-height: 1.3334;
    }
`);

const setIcon = (): SerializedStyles => {
    return css`
        display: block;
        fill: currentcolor;
        flex: 0 0 24px;
        margin-right: 8px;
        width: 24px;
    `;
};

export const IconTwitterWrapper = styled(SocialTwitterIcon, { label: 'IconTwitterWrapper' })`
    ${setIcon};
`;

export const IconEnvelopeWrapper = styled(EmailIcon, { label: 'IconEnvelopeWrapper' })`
    ${setIcon};
`;

export const IconPhoneWrapper = styled(PhoneIcon, { label: 'IconPhoneWrapper' })`
    ${setIcon};
`;

export const FacebookIconWrapper = styled(SocialFacebookIcon, { label: 'FacebookIconWrapper' })`
    ${setIcon};
`;

export const InstagramIconWrapper = styled(SocialInstagramIcon, { label: 'InstagramIconWrapper' })`
    ${setIcon};
`;
