import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { useAsObservableSource } from 'mobx-react-lite';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { DividendsInformationBox, getTopSelectionsSorted } from './RaceResults';
import { RaceResultsRowNew } from './RaceResultsRowNew';
import { RunnerCaption } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummaryNew.style';
import {
    ResultCaption,
    ResultSelectionCaption,
    ResultsTableMessage,
    RaceSummaryResultsCaption,
} from './RaceResultsNew.style';

interface RaceResultsPropsType {
    race: EventModel;
    sport: 'horseracing' | 'greyhoundracing';
}

export const RaceResultsNew = observer('RaceResultsNew', (propsIn: RaceResultsPropsType) => {
    const props = useAsObservableSource(propsIn);
    const { race, sport } = props;
    const { appSportsBookState } = useAppStateContext();

    const { models } = appSportsBookState;

    const topSelectionsSorted = getTopSelectionsSorted(race, models, sport);

    if (topSelectionsSorted === null) {
        return null;
    }

    return (
        <>
            <ResultsTableMessage>
                <I18n
                    langKey='events.race-results.label'
                    defaultText='Race Results'
                />
            </ResultsTableMessage>
            <RaceSummaryResultsCaption>
                <ResultCaption>
                    <I18n
                        langKey='events.race-results.result.label'
                        defaultText='Result'
                    />
                </ResultCaption>
                <RunnerCaption>
                    <I18n
                        langKey='events.race-results.runner.label'
                        defaultText='Runner'
                    />
                </RunnerCaption>
                <ResultSelectionCaption>
                    <I18n
                        langKey='events.race-results.selection.sp'
                        defaultText='SP'
                    />
                </ResultSelectionCaption>
            </RaceSummaryResultsCaption>
            {topSelectionsSorted.map((selection) => {
                return (
                    <RaceResultsRowNew
                        key={selection.id2.key}
                        selection={selection}
                        sport={sport}
                    />
                );
            })}
            <DividendsInformationBox
                models={models}
                race={race}
            />
        </>
    );
});
