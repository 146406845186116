import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { VirtualsIcon } from 'src/domains/layouts/shared/assets/icons/VirtualsIcon';
import { InPlayIcon } from 'src/domains/layouts/shared/assets/icons/InPlayIcon';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { FootballIcon } from 'src/domains/layouts/shared/assets/icons/FootballIcon';
import { StatsIcon } from 'src/domains/layouts/shared/assets/icons/StatsIcon';
import { CasinoTableGamesIcon } from 'src/domains/layouts/shared/assets/icons/CasinoTableGamesIcon';
import { NewsIcon } from 'src/domains/layouts/shared/assets/icons/NewsIcon';
import { PromotionsIcon } from 'src/domains/layouts/shared/assets/icons/PromotionsIcon';
import { CasinoLucky6Icon } from 'src/domains/layouts/shared/assets/icons/CasinoLucky6Icon';
import { CasinoIcon } from 'src/domains/layouts/shared/assets/icons/CasinoIcon';
import { CasinoLkIcon } from 'src/domains/layouts/shared/assets/icons/luckyKing/CasinoLkIcon';
import { LiveCasinoLkIcon } from 'src/domains/layouts/shared/assets/icons/luckyKing/LiveCasinoLkIcon';
import { SportsLkIcon } from 'src/domains/layouts/shared/assets/icons/luckyKing/SportsLkIcon';
import { css, SerializedStyles } from '@emotion/react';
import { LayoutType } from 'src/domains/layouts/config/features/types';
import { withConfig } from 'src/withConfig';

interface HeaderMainLinksProps {
    source?: string;
    isActive: boolean;
    layout: LayoutType;
}

interface HeaderMainLinkPropsType {
    isSpecialLink: boolean;
    layout: LayoutType;
    isLargeDesktop: boolean;
}

export const HeaderMainLinkWrapper = withConfig(theme => styled('nav', { label: 'HeaderMainLinkWrapper' }) <HeaderMainLinkPropsType>`
    background-color: ${({ isLargeDesktop }): string => isLargeDesktop ? theme.star.headerMain.bgColor : theme.star.headerMain.bgColorQuaternary};
    padding: ${({ isSpecialLink, layout }): string => isSpecialLink ? '8px 0 16px' : layout === 'secondary' ? '16px' : '0 0 16px'};
    @media ${theme.star.mediaQuery.largeDesktop} {
        align-items: center;
        display: flex;
        height: 100%;
        overflow: hidden;
        padding: 0;
    }
`);


const setLink = (layout: LayoutType, theme: EmotionTheme, isActive?: boolean): SerializedStyles => {
    if (layout === 'secondary') {
        return css`
            align-items: center;
            border-radius: 8px;
            cursor: pointer;
            display: flex;
            font-size: ${theme.star.fontSize.small};
            font-weight: ${theme.star.fontWeight.bold};
            line-height: 1.3334;
            margin-bottom: 8px;
            padding: 12px;
            text-decoration: none;
            text-transform: uppercase;
            transition: color .2s ease;
            color: ${isActive === true ? theme.star.headerMain.bgColorSecondary : theme.star.headerMain.txtColor};
            ${isActive === true ? `background: ${theme.star.headerMain.bgColorSecondary}` : ''};
            &:last-of-type {
                margin-bottom: 0;
            }
            > div {
                transition: background-color .2s ease;
            }
            svg {
                transition: fill .2s ease;
            }
            &:hover {
                color: ${theme.star.headerMain.txtColorSecondary};
            }
            @media ${theme.star.mediaQuery.largeDesktop} {
                flex-flow: row nowrap;
                font-size: ${theme.star.fontSize.regular};
                line-height: 1.5;
                margin-bottom: 0;
                padding: 8px;
                white-space: nowrap;
            }
        `;
    }

    return css`
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: ${theme.star.fontSize.regular};
        font-weight: ${theme.star.fontWeight.bold};
        line-height: 1.5;
        padding: 8px 16px;
        text-decoration: none;
        text-transform: uppercase;
        transition: color .2s ease;
        color: ${isActive === true ? theme.star.headerMain.txtColorSecondary : theme.star.headerMain.txtColorTertiary};
        svg {
            transition: fill .2s ease;
        }
        &:hover {
            color: ${theme.star.headerMain.txtColorSecondary};
        }
        @media ${theme.star.mediaQuery.largeDesktop} {
            color: ${isActive === true ? theme.star.headerMain.txtColorSecondary : theme.star.headerMain.txtColor};
            flex-flow: row nowrap;
            white-space: nowrap;
            padding: 0 8px;
        }
    `;
};

export const HeaderStyledLink = withConfig(theme => styled(Link, { label: 'HeaderStyledLink' }) <HeaderMainLinksProps>`
    ${({ layout, isActive }): SerializedStyles => setLink(layout, theme, isActive)};
`);

interface HeaderMainStaticLinksProps {
    layout: LayoutType;
}

export const HeaderStyledStaticLink = withConfig(theme => styled(Link, { label: 'HeaderStyledStaticLink' }) <HeaderMainStaticLinksProps>`
    ${({ layout }): SerializedStyles => setLink(layout, theme)};
`);

export const HeaderStyledStaticOnClick = withConfig(theme => styled('button', { label: 'HeaderStyledStaticOnClick' }) <HeaderMainStaticLinksProps>`
    ${({ layout }): SerializedStyles => setLink(layout, theme)};
    background: none;
    border: none;
    cursor: pointer;
`);

const setIconInMenu = (): SerializedStyles => {
    return css`
        fill: currentcolor;
        flex: 0 0 16px;
        margin: 0 8px 0 0;
        height: 16px;
        width: 16px;
    `;
};

const setLKIconInMenu = (theme: EmotionTheme): SerializedStyles => {
    return css`
        margin: 0 8px 0 0;
        height: 20px;
        width: 20px;
        flex: 0 0 20px;
        fill: currentcolor;
        @media ${theme.star.mediaQuery.largeDesktop} {
            margin: 0 4px 0 0;
            height: 16px;
            width: 16px;
            flex: 0 0 16px;
        }
    `;
};

interface HeaderIconsPropsType {
    source?: string;
}

export const SpecialsIconWrapper = styled('div', { label: 'SpecialsIconWrapper' }) <HeaderIconsPropsType>`
    ${(): SerializedStyles => setIconInMenu()};
    background: currentcolor;
    ${({ source }): string => source === undefined ? '' : `mask: url(${source}) no-repeat center`};
    mask-size: contain;
`;

export const CherryIconWrapper = styled(CasinoIcon, { label: 'CherryIconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;

export const FootballIconWrapper = styled(FootballIcon, { label: 'FootballIconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;
export const StatsIconWrapper = styled(StatsIcon, { label: 'StatsIconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;

export const LKFootballIconWrapper = withConfig(theme => styled(SportsLkIcon, { label: 'LKFootballIconWrapper' })`
    ${(): SerializedStyles => setLKIconInMenu(theme)};
`);

export const InPlayIconWrapper = styled(InPlayIcon, { label: 'InPlayIconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;

export const VirtualSportsIconWrapper = styled(VirtualsIcon, { label: 'VirtualSportsIconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;

export const NewsIconWrapper = styled(NewsIcon, { label: 'NewsIconWrapper' })`
    ${setIconInMenu()};
`;

export const PromotionsIconWrapper = styled(PromotionsIcon, { label: 'PromotionsIconWrapper' })`
    ${setIconInMenu()};
`;

export const LiveCasinoIconWrapper = styled(CasinoTableGamesIcon, { label: 'LiveCasinoIconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;

export const LKCasinoIconWrapper = withConfig(theme => styled(CasinoLkIcon, { label: 'LKCasinoIconWrapper' })`
    ${(): SerializedStyles => setLKIconInMenu(theme)};
`);

export const LKLiveCasinoIconWrapper = withConfig(theme => styled(LiveCasinoLkIcon, { label: 'LKLiveCasinoIconWrapper' })`
    ${(): SerializedStyles => setLKIconInMenu(theme)};
`);

export const CasinoLucky6IconWrapper = styled(CasinoLucky6Icon, { label: 'CasinoLucky6IconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;

export const BingoIconWrapper = styled(CasinoLucky6Icon, { label: 'BingoIconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;
