import * as React from 'react';
import styled from '@emotion/styled';

interface PropsType {
    className?: string
}

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

export const NFLIcon = ({ className }: PropsType): JSX.Element => (
    <SvgElement fill='#fff' className={className} width='22' height='22' viewBox='0 0 42 40'>
        <path
            fill='#fill'
            d='M1.948 38.735c.044.324.299.615.632.623 1.54.039 3.109-.115 4.57-.615 1.051-.358 2.046-.935 2.76-1.78 1.249-1.487 1.426-3.507 1.651-5.352a.384.384 0 01.342-.333c.186-.021.367-.038.549-.06a.382.382 0 01.42.308c.077.431.19.871.337 1.32 1.452 4.317 6.352 5.26 10.36 4.249 1.678-.423 3.036-1.166 4.41-2.191 1.177-.876 2.392-1.785 3.823-2.2.42-.123.81-.183 1.16-.183.45 0 .704.15.847.316.25.286.627.444 1.012.444h1.375c.41 0 .8-.188 1.05-.508 2.725-3.51 4.117-7.94 4.117-12.492C41.363 9.416 32.927.576 22.557.576c-4.592 0-8.985 1.807-12.31 4.911-2.072 1.935-3.814 4.297-4.978 6.868-1.033 2.289-2.192 5.611.485 7.234 1.833 1.11 4.017 1.187 5.712 2.584.692.568 1.107 1.277 1.436 2.097a.422.422 0 01-.307.568l-11.33 2.827a.821.821 0 00-.623.901c-.004 0 1.021 7.978 1.306 10.169zM24.676 23.56c1.38 0 2.495 1.102 2.495 2.464 0 1.362-1.115 2.464-2.495 2.464-1.38 0-2.495-1.102-2.495-2.464 0-1.362 1.116-2.464 2.495-2.464zm-12.773 5.206c.064-.56.12-1.017.16-1.375a.378.378 0 01.302-.33c.06-.012.126-.025.186-.042a.254.254 0 01.312.27c-.057.486-.109.999-.134 1.532a.386.386 0 01-.346.363c-.018 0-.035.005-.057.005a.382.382 0 01-.423-.423zm-2.47 3.263c-.064.525-.134 1.084-.211 1.687-.38 3.057-3.334 3.647-5.016 3.732a.386.386 0 01-.403-.329l-.6-4.433a.379.379 0 01.332-.427c1.164-.145 3.205-.393 5.466-.653.25-.03.463.18.433.423zm-6.369-3.032c1.63-.342 4.48-.944 6.564-1.38a.25.25 0 01.303.273l-.152 1.282a.38.38 0 01-.341.333c-2.547.29-4.908.576-6.184.734a.387.387 0 01-.432-.329l-.065-.495a.387.387 0 01.307-.418z'
        />
    </SvgElement>
);
