import React, { useState } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { EventRow } from 'src/domains/sportsbook/webview/components/eventRow/EventRow';
import { EventRowNFL } from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/EventRowNFL';
import { EventGroupHeader } from 'src/domains/sportsbook/webview/components/eventGroupHeader/EventGroupHeader';
import { EventGroupHeaderNFL } from 'src/domains/sportsbook/webview/components/eventsList/EventGroupHeaderNFL';
import { observer } from 'src/utils/mobx-react';
import { EventsListState } from 'src/domains/sportsbook/webview/components/eventsList/EventList.state';
import { EventsCollectionQueryModel } from 'src_common/common/websocket2/models/EventsCollectionQueryModel';
import { EventRowNBA } from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/EventRowNBA';
import { EventGroupHeaderNBA } from 'src/domains/sportsbook/webview/components/eventsList/EventGroupHeaderNBA';
import { EventRowMLB } from 'src/domains/sportsbook/webview/components/eventsList/eventRowMLB/EventRowMLB';
import { EventGroupHeaderMLB } from 'src/domains/sportsbook/webview/components/eventsList/EventGroupHeaderMLB';
import { EventRowNHL } from 'src/domains/sportsbook/webview/components/eventsList/eventRowNHL/EventRowNHL';
import { EventGroupHeaderNHL } from 'src/domains/sportsbook/webview/components/eventsList/EventGroupHeaderNHL';
import { EventListWrapper } from 'src/domains/sportsbook/webview/components/eventsList/EventList.style';
import { EventsGroupViewCompetition } from 'src/domains/sportsbook/state/eventsListState/eventsGroup/EventsGroupView';
import { EventsTableInfo } from 'src/domains/sportsbook/webview/components/sportEventsListNew/EventsTableMobX.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { SpecialSportsState } from 'src/domains/sportsbook/state/specialSportsState/SpecialSportsState';
import { useCommon } from 'src/domains/common/Common';
import { TranslationsStore } from 'src/domains/layouts/state/translationsStore/TranslationsStore';

const checkCompetition = (competitionName: string, competionArray: Array<string>): boolean => {
    const chunks = competitionName.toLowerCase().split(' ');
    for (const item of competionArray) {
        if (chunks.includes(item.toLowerCase())) {
            return true;
        }
    }
    return false;
};

const renderEvents = (
    filterMarketsByPopularTag: boolean,
    competitionGroup: EventsGroupViewCompetition
): React.ReactNode => {
    const out = [];
    const sport = competitionGroup.sport;

    for (const event of competitionGroup.events) {
        const competitionName = competitionGroup.name;
        const mlbOrMajorCompetition = checkCompetition(competitionName, ['MLB', 'Major']) && sport === 'baseball';
        if (mlbOrMajorCompetition === true && event.template.startsWith('two-participant')) {
            out.push(
                <EventRowMLB
                    key={`event_${event.id}`}
                    eventId={event.id2}
                    smallEvent={event}
                />
            );
        } else if (
            checkCompetition(competitionName, ['NHL']) === true &&
            event.template.startsWith('two-participant')
        ) {
            out.push(
                <EventRowNHL
                    key={`event_${event.id}`}
                    eventId={event.id2}
                    smallEvent={event}
                />
            );
        } else if (
            checkCompetition(competitionName, ['NBA']) === true &&
            event.template.startsWith('two-participant')
        ) {
            out.push(
                <EventRowNBA
                    key={`event_${event.id}`}
                    eventId={event.id2}
                    smallEvent={event}
                />
            );
        } else if (competitionName === 'US NFL' || competitionName === 'NFL Matches') {
            out.push(
                <EventRowNFL
                    key={`event_${event.id}`}
                    eventId={event.id2}
                    smallEvent={event}
                />
            );
        } else {
            out.push(
                <EventRow
                    key={`event_${event.id}`}
                    smallEvent={event}
                    possibleMarketAndSelectionInList={competitionGroup.possibleMarketAndSelectionInList}
                    filterMarketsByPopularTag={filterMarketsByPopularTag}
                />
            );
        }
    }

    return out;
};

const emptySelections: Array<string> = [];

const renderCompetitionGroupItem = (
    state: EventsListState,
    competitionGroup: EventsGroupViewCompetition,
    filterMarketsByPopularTag: boolean,
    headerStyle: 'sportLevel' | 'competitionLevel'
): React.ReactNode => {
    const out: Array<React.ReactNode> = [];

    const headerCompetition = state.calculateShowFlag(true, competitionGroup.visible);

    if (headerCompetition.showHeader) {
        const competitionName = competitionGroup.name;
        const competitionSport = competitionGroup.sport;
        const mlbOrMajorCompetition =
            checkCompetition(competitionName, ['MLB', 'Major']) && competitionSport === 'baseball';

        if (mlbOrMajorCompetition === true && competitionGroup.areEventsTwoParticipantsOnly === true) {
            out.push(
                <EventGroupHeaderMLB
                    key={`sport_competition_${competitionGroup.id}`}
                    label={competitionName}
                    onToggle={competitionGroup.toggle}
                    small={true}
                    open={headerCompetition.showBody}
                    headerStyle={headerStyle}
                />
            );
        } else if (
            checkCompetition(competitionName, ['NHL']) === true &&
            competitionGroup.areEventsTwoParticipantsOnly === true
        ) {
            out.push(
                <EventGroupHeaderNHL
                    key={`sport_competition_${competitionGroup.id}`}
                    label={competitionName}
                    onToggle={competitionGroup.toggle}
                    small={true}
                    open={headerCompetition.showBody}
                    headerStyle={headerStyle}
                />
            );
        } else if (
            checkCompetition(competitionName, ['NBA']) === true &&
            competitionGroup.areEventsTwoParticipantsOnly === true
        ) {
            out.push(
                <EventGroupHeaderNBA
                    key={`sport_competition_${competitionGroup.id}`}
                    label={competitionName}
                    onToggle={competitionGroup.toggle}
                    small={true}
                    open={headerCompetition.showBody}
                    headerStyle={headerStyle}
                />
            );
        } else if (competitionName === 'US NFL' || competitionName === 'NFL Matches') {
            out.push(
                <EventGroupHeaderNFL
                    key={`sport_competition_${competitionGroup.id}`}
                    label={competitionName}
                    onToggle={competitionGroup.toggle}
                    small={true}
                    open={headerCompetition.showBody}
                    headerStyle={headerStyle}
                />
            );
        } else {
            out.push(
                <EventGroupHeader
                    key={`sport_competition_${competitionGroup.id}`}
                    isOutrightEvent={headerCompetition.showBody === true ? competitionGroup.isOutrightEvent : false}
                    selections={
                        headerCompetition.showBody === true ? competitionGroup.lastSelectionsHeader : emptySelections
                    }
                    label={competitionName}
                    onToggle={competitionGroup.toggle}
                    small={true}
                    open={headerCompetition.showBody}
                    headerStyle={headerStyle}
                />
            );
        }
    }

    if (headerCompetition.showBody) {
        out.push(renderEvents(filterMarketsByPopularTag, competitionGroup));
    }

    return out;
};

const renderCompetitionGroup = (
    key: string,
    competitions: readonly EventsGroupViewCompetition[],
    state: EventsListState,
    filterMarketsByPopularTag: boolean,
    headerStyle: 'sportLevel' | 'competitionLevel'
): React.ReactNode => {
    const out: Array<React.ReactNode> = [];
    for (const competitionGroup of competitions) {
        out.push(renderCompetitionGroupItem(state, competitionGroup, filterMarketsByPopularTag, headerStyle));
    }

    return (
        <div
            data-test='competitions-and-events'
            key={key}
        >
            {out}
        </div>
    );
};

export interface EventListPropsType {
    events: EventsCollectionQueryModel;
    filterMarketsByPopularTag: boolean;
    showHeaderSport: boolean;
    allGroupsOpen: boolean;
    headerStyle: 'sportLevel' | 'competitionLevel';
    specialSportsState: SpecialSportsState | null;
}

export const EventsList = observer('EventsList', (props: EventListPropsType) => {
    const common = useCommon();
    const appState = useAppStateContext();
    const translationsStore = TranslationsStore.get(common);
    const allGroupsOpen = props.allGroupsOpen;
    const specialSportState = props.specialSportsState;

    const [state] = useState(() => new EventsListState(appState, specialSportState, allGroupsOpen));
    const { filterMarketsByPopularTag } = props;

    const renderContent = (): React.ReactNode => {
        const { showHeaderSport, headerStyle } = props;

        const eventsGroup = state.groupEvents.get(props.events);

        const out = [];

        if (eventsGroup.listToView.sports.length === 0) {
            out.push(
                <EventsTableInfo>
                    <I18n
                        langKey='events.table.empty'
                        defaultText="Sorry, we haven't found any events with such criteria."
                    />
                </EventsTableInfo>
            );
        }

        for (const sportGroup of eventsGroup.listToView.sports) {
            const sport = sportGroup.id;

            const headerSport = state.calculateShowFlag(showHeaderSport, sportGroup.visible);
            const sportName = translationsStore.translateSport(sport);

            const sportKey = `sport_${sport}`;

            if (headerSport.showHeader) {
                out.push(
                    <EventGroupHeader
                        key={`${sportKey}_header`}
                        isOutrightEvent={false}
                        selections={emptySelections}
                        label={sportName}
                        onToggle={sportGroup.toggle}
                        small={false}
                        open={headerSport.showBody}
                        headerStyle={headerStyle}
                    />
                );
            }

            if (headerSport.showBody) {
                out.push(
                    renderCompetitionGroup(
                        `${sportKey}_body`,
                        sportGroup.competitions,
                        state,
                        filterMarketsByPopularTag,
                        headerStyle
                    )
                );
            }
        }

        return out;
    };

    const renderSpecialSportContent = (): React.ReactNode => {
        const { headerStyle } = props;
        const eventsGroup = state.groupEvents.get(props.events);
        const competitions = eventsGroup.specialSportListToView;

        return [renderCompetitionGroup('special_body', competitions, state, filterMarketsByPopularTag, headerStyle)];
    };

    return (
        <EventListWrapper>
            {props.specialSportsState?.isSpecial === true ? renderSpecialSportContent() : renderContent()}
        </EventListWrapper>
    );
});
