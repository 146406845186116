import styled from '@emotion/styled';
import { LayoutType } from 'src/domains/layouts/config/features/types';
import { withConfig } from 'src/withConfig';

interface AccountTabContentWrapperProps {
    layout: LayoutType;
}

export const AccountTabContentWrapper = withConfig(theme => styled('div', { label: 'AccountTabContentWrapper' })<AccountTabContentWrapperProps>`
    background-color: ${({ layout }): string => layout === 'primary' ? theme.star.accountDrawer.bgColor : theme.star.accountDrawer.bgColorSecondary};
    font-size: ${theme.star.fontSize.small};
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: contain;
`);
