import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface InfoIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const InfoIcon = observer(
    'InfoIcon',
    ({ className, onClick }: InfoIconPropsType): JSX.Element => (
        <SvgElement
            className={className}
            onClick={onClick}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='#020D16'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 2C6.478 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.522 2 12 2m.934 3.61c.975 0 1.261.566 1.261 1.213 0 .807-.646 1.554-1.749 1.554-.923 0-1.362-.464-1.335-1.231 0-.647.54-1.536 1.823-1.536M9.749 15.808c-.466 1.776.02 2.18.687 2.18.666 0 2.383-.605 3.96-2.18l-.378-.507c-.644.508-1.298.75-1.498.75-.155 0-.222-.203-.066-.788l.874-3.312c.333-1.213.222-2-.444-2-.797 0-2.66.793-4.28 2.146l.333.545c.51-.344 1.376-.691 1.575-.691.154 0 .132.202 0 .706z'
                fill='fill'
            />
        </SvgElement>
    )
);
