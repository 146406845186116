import { observable, makeObservable } from 'mobx';
import { ConfigType } from 'src/domains/layouts/config/features/types';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { CustomKeyboardState } from 'src/domains/players/state/CustomKeyboardState';
import { SignUpSecondaryCreateAccountState } from 'src/domains/players/webview/components/SignUp/signupSecondary/signUpSecondaryState/SignUpSecondaryCreateAccount.state';
import { SignUpState } from 'src/domains/players/webview/components/SignUp';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { TrpcClient } from 'src/appState/TrpcClient';

type SignUpSecondaryStepType =
    | { type: 'create-account'; state: SignUpSecondaryCreateAccountState }
    | { type: 'deposit'; state: undefined };

export class SignUpSecondaryState {
    @observable public step: SignUpSecondaryStepType;

    public constructor(
        public readonly signUpState: SignUpState,
        public readonly customKeyboard: CustomKeyboardState,
        public readonly language: LanguagesState,
        private readonly config: ConfigType,
        private readonly router: StarRouter,
        private readonly trpc: TrpcClient
    ) {
        makeObservable(this);
        this.step = {
            type: 'create-account',
            state: new SignUpSecondaryCreateAccountState(
                signUpState,
                this.customKeyboard,
                this.language,
                this.config,
                this.router,
                this.trpc
            ),
        };
    }
}
