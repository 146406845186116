// eslint-disable-next-line no-restricted-imports
import { useAsObservableSource } from 'mobx-react-lite';
import React from 'react';
import { AccountDrawerHeader } from 'src/domains/players/webview/components/Account/accountDrawerHeader/AccountDrawerHeader';
import { observer } from 'src/utils/mobx-react';
import { useCommon } from 'src/domains/common/Common';

interface PropsType {
    title: React.ReactElement | string | null | undefined;
    back: boolean;
    parent?: string;
}

export const StaticPageHeader = observer('StaticPageHeader', (propsIn: PropsType) => {
    const props = useAsObservableSource(propsIn);
    const { back, parent, title } = props;
    const common = useCommon();
    const { session } = common;
    const { isAuthorized } = session;

    if (title === null) {
        return null;
    }

    return (
        <AccountDrawerHeader
            key='account-drawer-header'
            title={title}
            hasClose={!isAuthorized}
            parent={parent}
            hasBack={back}
        />
    );
});
