import styled from '@emotion/styled';
import { LayoutType } from 'src/domains/layouts/config/features/types';
import { withConfig } from 'src/withConfig';

interface HeaderWrapperPropsType {
    layout: LayoutType;
}

export const HeaderWrapper = withConfig(theme => styled('div', { label: 'HeaderWrapper' })<HeaderWrapperPropsType>`
    color: ${theme.star.signUp.txtColor};
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    margin: ${({ layout }): string => layout === 'secondary' ? '48px 0 64px 0' : '48px 0 29px 0'};
    text-align: center;
`);

