import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

interface PropsType {
    className?: string
}

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

export const DartsIcon = observer('DartsIcon', ({ className }: PropsType) => (
    <SvgElement fill='#fff' className={className} width='22' height='22' viewBox='0 0 22 22'>
        <path
            fill='#fill'
            fillRule='evenodd'
            d='M3.554 4.686a9.523 9.523 0 0111.482-1.535L13.5 4.69a7.453 7.453 0 00-8.477 1.469 7.49 7.49 0 000 10.575 7.448 7.448 0 0010.55 0 7.49 7.49 0 001.464-8.498l1.532-1.544c2.09 3.65 1.578 8.393-1.532 11.51a9.513 9.513 0 01-13.483 0c-3.725-3.73-3.725-9.781 0-13.516zM6.3 7.44a5.64 5.64 0 015.815-1.36l-1.608 1.612a3.727 3.727 0 00-2.863 1.095 3.77 3.77 0 000 5.321 3.747 3.747 0 005.308 0 3.76 3.76 0 001.092-2.874l1.608-1.612a5.677 5.677 0 01-1.357 5.833 5.647 5.647 0 01-7.995 0 5.68 5.68 0 010-8.015zM18.883.477l.277 2.086 2.08.277-2.75 2.757-.88-.118-5.433 5.447a1.954 1.954 0 01-1.879 2.476 1.954 1.954 0 01-1.95-1.955 1.954 1.954 0 012.469-1.885l5.433-5.446-.117-.882 2.75-2.757z'
        />
    </SvgElement>
));
