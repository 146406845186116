import React from 'react';
import { LineChartSeries } from './LineChartSeries';
import { observer } from 'src/utils/mobx-react';
import { LineChartSvg } from 'src/domains/sportsbook/webview/components/priceHistory/lineChart/LineChart.style';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';

interface DataType {
    x: number;
    y: number;
    label: string;
}

interface DataSeriesType {
    values: Array<DataType>;
}

interface PropsType {
    circle: number;
    margin: number;
    width: number;
    height: number;
    data: Array<DataSeriesType>;
    d3: typeof import('d3');
    className?: string;
}

export const LineChart = observer(
    'LineChart',
    ({ d3, circle, margin, width, height, data: baseData, className = '' }: PropsType) => {
        const showLabels = true;
        const max = 4;

        const minFunction = (data: Array<DataSeriesType>, property: 'x' | 'y'): number | undefined => {
            return d3.min(data, (c) => d3.min(c.values, (v) => v[property]));
        };

        const maxFunction = (data: Array<DataSeriesType>, property: 'x' | 'y'): number | undefined => {
            return d3.max(data, (c) => d3.max(c.values, (v) => v[property]));
        };

        const data = baseData.map((series) => ({ values: series.values.slice(0, max) }));
        const firstData = data[0];
        const paths = firstData === undefined ? 0 : firstData.values.length - 1;
        const maxPaths = max - 1;

        let horizontalMargin = margin;
        let from = 0;
        let to = width;

        if (paths < maxPaths) {
            horizontalMargin = (0.55 * (width - 2 * margin)) / 2;
            from = horizontalMargin;
            to = width - horizontalMargin;
        }

        //@ts-expect-error
        const xScale = d3.scale
            .linear()
            .range([from, to])
            .domain([minFunction(data, 'x'), maxFunction(data, 'x')]);

        //@ts-expect-error
        const yScale = d3.scale
            .linear()
            .range([height - margin, margin])
            .domain([minFunction(data, 'y'), maxFunction(data, 'y')]);

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const isLabel = showLabels === true ? 'showLabels' : '';

        const innerWidth = getWindowInnerWidth();

        const isTablet = innerWidth !== null && innerWidth >= 768;
        const setViewBox = isTablet ? `-25 0 ${width + 50} ${height + 12}` : `0 0 ${width} ${height + 12}`;

        return (
            <LineChartSvg
                className={`line-chart ${isLabel} ${className}`}
                width={width}
                preserveAspectRatio='xMidYMid meet'
                height={height}
                viewBox={setViewBox}
                data-test='line-chart'
            >
                {data.map((series, key) => {
                    return (
                        <LineChartSeries
                            key={key}
                            data={series.values}
                            xScale={xScale}
                            yScale={yScale}
                            circle={circle}
                            showLabels={showLabels}
                            d3={d3}
                        />
                    );
                })}
            </LineChartSvg>
        );
    }
);
