import React from 'react';
import { MobileFooter } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/mobileFooter/MobileFooter';
import { ContactUs } from 'src/domains/layouts/webview/others/luckyKing/Footer/ContactUs';
import { observer } from 'src/utils/mobx-react';
import { MainFooterWrapper, FooterSeparator, FooterSectionGrid } from './Footer/Footer.style';
import { FooterLegalInfo } from './Footer/FooterLegalInfo';
import { FooterLinks } from './Footer/FooterLinks';
import { PaymentAndGambling } from './Footer/PaymentAndGambling';
import { ValidationScriptIconWrapper } from 'src/domains/layouts/webview/others/luckyKing/Footer/Footer.style';
export { ValidationScriptIconWrapper };

export const MainFooter = observer('MainFooter', () => (
    <>
        <MainFooterWrapper>
            <FooterLinks />
            <FooterSeparator />

            <FooterSectionGrid>
                <ContactUs />
                <PaymentAndGambling />
            </FooterSectionGrid>

            <FooterLegalInfo />
        </MainFooterWrapper>

        <MobileFooter />
    </>
));
