import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { BannersType, heroImageStyles } from 'src/domains/layouts/webview/components/heroEvents/HomepageHeroWidget.style';
import { SerializedStyles } from '@emotion/react';

export const Wrapper = withConfig(theme => styled('div', { label: 'Wrapper' })<BannersType>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.star.heroWidget.bgColorSecondary};
    padding: 12px 8px;
    @media ${theme.star.mediaQuery.tablet}{
        padding: 18px 16px;
    }
    ${({ banners }): SerializedStyles => heroImageStyles(banners, theme) };
`);
