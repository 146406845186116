import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { RaceSummaryCaptionHeader, RaceSummaryCaptionHeaderCast } from './HeadersHorseRacingNew.style';
import {
    RunnerCaption,
    SelectionCaption,
    PriceHistoryCaption,
    SelectionCaptionCast,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummaryNew.style';

interface HeadersPropsType {
    type?: string;
    race: EventModel;
    market: MarketModel;
    isSpecials?: boolean;
    isRaceInProgress: boolean;
    isPriceOnly?: boolean;
}

export const HeadersHorseRacingNew = observer('HeadersHorseRacingNew', (props: HeadersPropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const { breakpointsState } = appLayoutsState;
    const { type, race, market, isSpecials, isRaceInProgress } = props;

    if (type === 'cast') {
        return (
            <RaceSummaryCaptionHeaderCast>
                <RunnerCaption data-test='race-summary-header-runner'>
                    <I18n
                        langKey='events.race-summary.runner.label'
                        defaultText='Runner'
                    />
                </RunnerCaption>

                <SelectionCaptionCast
                    isRaceInProgress={isRaceInProgress}
                    data-test='race-summary-header-1st'
                >
                    <I18n
                        langKey='events.race-summary.1st.label'
                        defaultText='1st'
                    />
                </SelectionCaptionCast>

                <SelectionCaptionCast
                    isRaceInProgress={isRaceInProgress}
                    data-test='race-summary-header-2nd'
                >
                    <I18n
                        langKey='events.race-summary.2nd.label'
                        defaultText='2nd'
                    />
                </SelectionCaptionCast>

                {market.tricastsOffered ? (
                    <SelectionCaptionCast
                        isRaceInProgress={isRaceInProgress}
                        data-test='race-summary-header-3rd'
                    >
                        <I18n
                            langKey='events.race-summary.3rd.label'
                            defaultText='3rd'
                        />
                    </SelectionCaptionCast>
                ) : (
                    ''
                )}

                <SelectionCaptionCast
                    isRaceInProgress={isRaceInProgress}
                    data-test='race-summary-header-anyorder'
                >
                    <I18n
                        langKey='events.race-summary.any-order.label'
                        defaultText='Any Order'
                    />
                </SelectionCaptionCast>
            </RaceSummaryCaptionHeaderCast>
        );
    } else if (market.newTemplateId.includes('place-betting')) {
        return (
            <RaceSummaryCaptionHeader>
                <RunnerCaption>
                    <I18n
                        langKey='events.race-summary.runner.label'
                        defaultText='Runner'
                    />
                </RunnerCaption>
            </RaceSummaryCaptionHeader>
        );
    } else if (race.antePost || isSpecials === true) {
        return (
            <RaceSummaryCaptionHeader>
                <RunnerCaption>
                    <I18n
                        langKey='events.race-summary.runner.label'
                        defaultText='Runner'
                    />
                </RunnerCaption>

                <SelectionCaption isRaceInProgress={isRaceInProgress}>
                    <I18n
                        langKey='events.race-summary.price.label'
                        defaultText='Price'
                    />
                </SelectionCaption>
            </RaceSummaryCaptionHeader>
        );
    } else {
        return (
            <RaceSummaryCaptionHeader>
                <RunnerCaption>
                    <I18n
                        langKey='events.race-summary.runner.label'
                        defaultText='Runner'
                    />
                </RunnerCaption>
                <PriceHistoryCaption
                    isRaceInProgress={isRaceInProgress}
                    className='race-summary__selection__price-history'
                >
                    <I18n
                        langKey='events.race-summary.price-history.label:new-racecard'
                        defaultText='History'
                    />
                </PriceHistoryCaption>

                <SelectionCaption isRaceInProgress={isRaceInProgress}>
                    <I18n
                        langKey='events.race-summary.price.label'
                        defaultText='Price'
                    />
                </SelectionCaption>

                {breakpointsState.tablet.isBiggerOrEq === false || props.isPriceOnly === true ? (
                    ''
                ) : (
                    <SelectionCaption isRaceInProgress={isRaceInProgress}>SP</SelectionCaption>
                )}
            </RaceSummaryCaptionHeader>
        );
    }
});
