import { EnvVariables, EnvZod } from 'src/domains/common/contextStore/EnvVariables';

export const getEnvVariables = async (): Promise<EnvVariables | null> => {
    // tslint:disable-next-line
    if (typeof window === 'undefined') {
        return null;
    }

    try {
        const dataEnv = document.documentElement.getAttribute('data-env');

        if (dataEnv === null) {
            console.error('getEnvVariables(1): Missing attribute html:data-env');
        } else {
            const dataEnvParsed = JSON.parse(dataEnv);

            const safeData = EnvZod.safeParse(dataEnvParsed);

            if (safeData.success) {
                return new EnvVariables(safeData.data);
            } else {
                console.error('getEnvVariables(2): Data does not match validator');
            }
        }
    } catch (error) {
        console.error('getEnvVariables(3): Problem processing attribute html:data-env');
    }

    const response = await fetch('/_hot_config.json');
    const jsonData = await response.json();

    const jsonDataSafe = EnvZod.safeParse(jsonData);
    if (jsonDataSafe.success) {
        return new EnvVariables(jsonDataSafe.data);
    }

    return null;
};
