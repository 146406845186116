import styled from '@emotion/styled';
import { HelpIcon } from 'src/domains/layouts/shared/assets/icons/HelpIcon';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const LuckyKingWalletWrapper = withConfig(theme => styled('div', { label: 'LuckyKingWalletWrapper' })`
    background-color: ${theme.star.luckyKingWallet.bgColorTertiary};
    padding: 19px 16px 6px 16px;
`);

export const ContentWrapper = styled('div', { label: 'ContentWrapper' })`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const ContentGridWrapper = withConfig(theme => styled('div', { label: 'ContentGridWrapper' })`
    display: grid;
    grid-template-columns: 1fr min-content;
    color: ${theme.star.luckyKingWallet.bgColor};
    font-size: ${theme.star.fontSize.small};
    white-space: nowrap;
    row-gap: 6px;
`);

export const AmountWrapper = withConfig(theme => styled('div', { label: 'AmountWrapper' })`
    font-weight: ${theme.star.fontWeight.bold};
    color: ${theme.star.luckyKingWallet.bgColor};
    font-size: ${theme.star.fontSize.small};
`);

export const DepositButton = styled(Button, { label: 'DepositButton' })`
    margin: 10px 5px 10px 0;
    width: 50%;
    height: 40px;
    border-radius: 8px;
`;

export const WithdrawButton = styled(Button, { label: 'WithdrawButton' })`
    margin: 10px 0 10px 5px;
    width: 50%;
    height: 40px;
    border-radius: 8px;
`;

export const HelpIconWrapper = withConfig(theme => styled(HelpIcon, { label: 'HelpIconWrapper' })`
    color: ${theme.star.luckyKingWallet.bgColor};
    fill: ${theme.star.luckyKingWallet.bgColor};
    height: 14px;
    margin-bottom: -2px;
    margin-left: 5px;
    width: 14px;
`);
