import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { LazyImage } from 'src/domains/sportsbook/webview/components/lazyImage/LazyImage';
import EMPTY_SILK_old from 'src/domains/layouts/shared/assets/silk/empty-hr-silk-old.webp';
import EMPTY_SILK from 'src/domains/sportsbook/webview/components/raceSummary/assets/empty-hr-silk.svg';
import { PriceHistory } from 'src/domains/sportsbook/webview/components/priceHistory/PriceHistory';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { UnnamedFavouriteLabel } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow.style';
import {
    PlaceCell,
    PriceHistoryCell,
    RunnerCell,
    SilkCell,
    RaceSummaryCaption,
    WeightCell,
    RaceSummaryRow,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import {
    SpInProgress,
    WinInProgress,
} from 'src/domains/sportsbook/webview/components/selectionRaceCard/SelectionRaceCard.style';

interface PropsType {
    className?: string;
    antePost: boolean;
    isSpecials?: boolean;
    isUnnamedFavourite: boolean;
    tags?: React.ReactNode;
    place?: React.ReactNode;
    runner?: React.ReactNode;
    sp?: React.ReactNode;
    winner?: React.ReactNode;
    selectionId: SelectionId;
    nrPricesToShow?: number;
    isNonRunner?: boolean;
    isRaceInProgress?: boolean;
    isRaceFinished?: boolean;
    dataTest?: string;
}

export const RaceSummaryRowHorseRacing = observer('RaceSummaryRowHorseRacing', (props: PropsType) => {
    const common = useCommon();
    const config = ConfigComponents.get(common).config;

    const {
        antePost,
        isSpecials,
        className,
        isUnnamedFavourite,
        tags,
        place,
        runner,
        sp,
        winner,
        selectionId,
        nrPricesToShow,
        isNonRunner,
        isRaceInProgress,
        dataTest,
    } = props;

    const selectionModel = selectionId.getModel();
    if (selectionModel === null) {
        return null;
    }

    const marketModel = selectionModel.getMarket();
    if (marketModel === null) {
        return null;
    }

    const silkUrl = selectionModel.metaDataSilkUrl;

    const metaDataWeight = selectionModel.metaDataWeight ?? 'n/a';

    const isOnlyPrice = marketModel.templateId === 'win-only';

    if (isUnnamedFavourite) {
        return (
            <RaceSummaryRow
                className={className}
                isNonRunner={isNonRunner}
                tags={tags}
                isAlternativeTag={config.isAlternativeRacingBoost}
                data-test={dataTest}
            >
                <UnnamedFavouriteLabel>{runner}</UnnamedFavouriteLabel>
                {isRaceInProgress === true ? <SpInProgress /> : sp}
                {tags}
            </RaceSummaryRow>
        );
    } else if (marketModel.templateId.includes('place-betting')) {
        return (
            <RaceSummaryCaption className={className}>
                <PlaceCell>{place}</PlaceCell>
                <SilkCell>
                    <LazyImage
                        src={silkUrl}
                        placeholder={config.shouldDisplaySilkWithoutQuestionMark ? EMPTY_SILK : EMPTY_SILK_old}
                        alt={selectionModel.metaDataSilk}
                    />
                </SilkCell>
                <RunnerCell>{runner}</RunnerCell>

                <WeightCell>{metaDataWeight === '' ? 'n/a' : metaDataWeight}</WeightCell>
                {isRaceInProgress === true ? <WinInProgress /> : winner}
                {tags}
            </RaceSummaryCaption>
        );
    } else if (antePost) {
        const isHideRunnerInfo = selectionModel.name === '';
        return (
            <RaceSummaryRow
                className={className}
                isNonRunner={isNonRunner}
                tags={tags}
                isAlternativeTag={config.isAlternativeRacingBoost}
                data-test={dataTest}
            >
                <RunnerCell isHideRunnerInfo={isHideRunnerInfo}>{runner}</RunnerCell>
                {isRaceInProgress === true ? <WinInProgress /> : winner}
                {tags}
            </RaceSummaryRow>
        );
    } else if (isSpecials === true) {
        return (
            <RaceSummaryRow
                className={className}
                isNonRunner={isNonRunner}
                tags={tags}
                isAlternativeTag={config.isAlternativeRacingBoost}
                data-test={dataTest}
            >
                <RunnerCell>{runner}</RunnerCell>
                {isRaceInProgress === true ? <WinInProgress /> : winner}
                {tags}
            </RaceSummaryRow>
        );
    } else {
        return (
            <RaceSummaryRow
                className={className}
                isNonRunner={isNonRunner}
                tags={tags}
                isAlternativeTag={config.isAlternativeRacingBoost}
                data-test={dataTest}
            >
                <PlaceCell>{place}</PlaceCell>
                <SilkCell>
                    <LazyImage
                        src={silkUrl}
                        placeholder={config.shouldDisplaySilkWithoutQuestionMark ? EMPTY_SILK : EMPTY_SILK_old}
                        alt={selectionModel.metaDataSilk}
                    />
                </SilkCell>
                <RunnerCell>{runner}</RunnerCell>
                {isOnlyPrice === true ? (
                    isRaceInProgress === true ? (
                        <WinInProgress />
                    ) : (
                        winner
                    )
                ) : (
                    <>
                        <WeightCell>{metaDataWeight === '' ? 'n/a' : metaDataWeight}</WeightCell>

                        <PriceHistoryCell
                            className='race-summary__selection__price-history'
                            isRaceInProgress={isRaceInProgress}
                        >
                            <PriceHistory
                                nrPricesToShow={nrPricesToShow}
                                selectionId={selectionId}
                            />
                        </PriceHistoryCell>
                        {isRaceInProgress === true ? <WinInProgress /> : winner}
                        {isRaceInProgress === true ? <SpInProgress /> : sp}
                        {tags}
                    </>
                )}
            </RaceSummaryRow>
        );
    }
});
