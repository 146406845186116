import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { VerificationFailedPageState } from './VerificationFailedPage';
import {
    VerifyInfoGamStop,
    HomeButtonWrapper,
} from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/profileVerification/ProfileVerification.style';
import {
    VerificationFailedWrapper,
    EmailLink,
    WarningTriangleGamStop,
    WarningTriangleIconWrapper,
} from './VerificationFailed.style';
import { SignupType } from 'src/domains/players/webview/components/SignUp';
import { useCommon } from 'src/domains/common/Common';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

interface PropsType {
    signUpState: SignupType;
}

export const GamStopAccountFailedPage = observer('GamStopAccountFailedPage', ({ signUpState }: PropsType) => {
    const common = useCommon();
    const router = StarRouter.get(common);
    const config = ConfigComponents.get(common).config;

    const { appPlayersState } = useAppStateContext();
    const { modalState } = appPlayersState;

    const [state] = useState(() => new VerificationFailedPageState(signUpState, router, modalState));

    return (
        <VerificationFailedWrapper>
            <WarningTriangleGamStop>
                <WarningTriangleIconWrapper />
            </WarningTriangleGamStop>
            <VerifyInfoGamStop>
                <I18n
                    langKey='signup-journey.gamstop-account-failed.info'
                    defaultText='Your account is currently suspended Please contact customer services on'
                />
                <EmailLink href={`mailto: ${config.signUpFooterMail}`}> {config.signUpFooterMail}</EmailLink>
            </VerifyInfoGamStop>
            <HomeButtonWrapper
                size='large'
                onClick={state.onHomeClick}
                dataTest='home-button'
            >
                <I18n
                    langKey='signup-journey.gamstop-account-failed.home-btn'
                    defaultText='Home'
                />
            </HomeButtonWrapper>
        </VerificationFailedWrapper>
    );
});
