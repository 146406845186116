import React from 'react';
import { ContactPreferencesProcedure } from 'src/domains/players/webview/components/Account/preferencesTab/contactPreferences/ContactPreferencesProcedure';
import { MarketingQuestionsForm } from 'src/domains/players/webview/components/Account/preferencesTab/MarketingQuestionsForm';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Header, MarketingSectionWrapper, SectionWrapper, Wrapper } from './PreferencesTab.style';
import { SettingsFromWrapper } from 'src/domains/players/webview/components/Account/preferencesTab/SettingsForm';
import { QuestionAnswers } from './types';
import { useAppStateContext } from 'src/appState/AppState';
import { SettingsFromWrapperLK } from './SettingsFormLK';

export const PreferencesTab = observer('PreferencesTab', () => {
    const { appPlayersState, config: { layout } } = useAppStateContext();
    const { marketingQuestionsState } = appPlayersState;

    return (
        <Wrapper>
            <SectionWrapper>
                <Header data-test='settings-header'>
                    <I18n langKey='account.preferences-tab.settings-title' defaultText='Settings' />
                </Header>
                {layout === 'secondary' ? <SettingsFromWrapperLK /> : <SettingsFromWrapper />}
            </SectionWrapper>

            <SectionWrapper>
                <Header data-test='contact-header'>
                    <I18n langKey='account.preferences-tab.contact-title' defaultText='Contact' />
                </Header>
                <ContactPreferencesProcedure key='contact-preferences' />
            </SectionWrapper>

            {marketingQuestionsState.showMarketingQuestions && (
                <MarketingSectionWrapper>
                    <Header data-test='marketing-header'>
                        <I18n langKey='account.preferences-tab.marketing-title' defaultText='Marketing' />
                    </Header>
                    <MarketingQuestionsForm marketingQuestionsState={marketingQuestionsState} />
                </MarketingSectionWrapper>
            )}
        </Wrapper>
    );
});

export {
    QuestionAnswers
};
