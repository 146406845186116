import styled from '@emotion/styled';
import { LayoutType } from 'src/domains/layouts/config/features/types';
import { WalletIcon } from 'src/domains/layouts/shared/assets/icons/luckyKing/WalletIcon';
import { AccountNavigationLink } from 'src/domains/layouts/webview/components/accountNavigationLink/AccountNavigationLink';
import { setLinkWrapper, styleNavIcon } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/AccountNavigation.style';
import { withConfig } from 'src/withConfig';

export const Balance = styled('span', { label: 'Balance' })`
    display: block;
`;

interface BalanceWrapperProps {
    layout: LayoutType;
}

export const BalanceWrapper = withConfig(theme => styled(AccountNavigationLink, { label: 'BalanceWrapper' })<BalanceWrapperProps>`
    ${setLinkWrapper(theme)};
    flex-flow: ${({ layout }): string => layout === 'primary' ? 'column nowrap' : 'row nowrap'};
`);

export const BalanceWrapperTitle = withConfig(theme => styled('div', { label: 'BalanceWrapperTitle' })`
    display: block;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
`);

export const WalletIconWrapper = styled(WalletIcon, { label: 'WalletIconWrapper' })`
    ${styleNavIcon()};
    margin-right: 4px;
`;
