import React, { RefObject } from 'react';
import { EventMarkets } from 'src/domains/sportsbook/webview/components/eventMarkets/EventMarkets';
import { YouDecideHref } from 'src/domains/sportsbook/webview/components/matchCard/youDecideHeader/YouDecideHeaderHref';
import { SelectionRab } from 'src/domains/sportsbook/webview/components/selectionRab/SelectionRab';
import { SelectionRabCorrectScore } from 'src/domains/sportsbook/webview/components/selectionRab/selectionRabCorrectScore/SelectionRabCorrectScore';
import { YouDecide } from 'src/domains/sportsbook/webview/components/youDecide/YouDecide';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';
import { MarketRabView } from 'src/domains/sportsbook/betting/state/rabState/Types';
import { YouDecideHeader } from 'src/domains/sportsbook/webview/components/matchCard/youDecideHeader/YouDecideHeader';
import { SelectionRabCombined } from 'src/domains/sportsbook/webview/components/selectionRab/selectionRabCombined/SelectionRabCombined';
import { EventsTableMarketsLoader } from 'src/domains/sportsbook/webview/components/loaders/EventsTableMarketsLoader';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { MarketFiltersForSportState } from 'src/domains/sportsbook/state/marketFiltersState/MarketFiltersForSportState';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { BetslipRabId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import { RabState } from 'src/domains/sportsbook/betting/state/rabState/RabState';
import {
    RabHeader,
    MatchCardContent,
    MatchCardContentWrapper,
    MatchCardContentMarkets,
    MatchCardContentMarketsRAB,
    MatchCardInfoWrapper,
} from './MatchCard.style';

export const contentMarkets = (
    marketFiltersForSport: MarketFiltersForSportState,
    event: EventModel
): React.ReactNode => {
    const { marketsForView } = marketFiltersForSport;

    return (
        <MatchCardContent key='match-card-content'>
            {marketsForView.length > 0 ? (
                <EventMarkets
                    eventId={event.id2}
                    markets={marketsForView}
                />
            ) : event.allMarketsLoading ? null : (
                <MatchCardInfoWrapper>Missing markets</MatchCardInfoWrapper>
            )}
            {event.allMarketsLoading ? <EventsTableMarketsLoader /> : null}
        </MatchCardContent>
    );
};

export const renderViewNormal = (
    marketsWrapperRef: RefObject<HTMLDivElement>,
    contentMarkets: React.ReactNode,
    getMarketsWrapperWidth: number | undefined,
    isYouDecideOpen: boolean,
    rabAvailable: boolean
): JSX.Element => {
    return (
        <MatchCardContentWrapper ref={marketsWrapperRef}>
            <MatchCardContentMarkets
                widthContent={getMarketsWrapperWidth}
                isYouDecideOpen={isYouDecideOpen}
                rabAvailable={rabAvailable}
            >
                {contentMarkets}
            </MatchCardContentMarkets>
        </MatchCardContentWrapper>
    );
};

export const renderViewRab = (
    marketsWrapperRef: RefObject<HTMLDivElement>,
    contentMarkets: React.ReactNode,
    getMarketsWrapperWidth: number | undefined,
    isYouDecideOpen: boolean,
    rabAvailable: boolean,
    renderContentMarketsRAB: Array<JSX.Element>
): JSX.Element => {
    return (
        <MatchCardContentWrapper ref={marketsWrapperRef}>
            <MatchCardContentMarkets
                widthContent={getMarketsWrapperWidth}
                isYouDecideOpen={isYouDecideOpen}
                rabAvailable={rabAvailable}
            >
                {contentMarkets}
            </MatchCardContentMarkets>
            {rabAvailable ? (
                <MatchCardContentMarketsRAB
                    widthContent={getMarketsWrapperWidth}
                    isYouDecideOpen={isYouDecideOpen}
                    rabAvailable={rabAvailable}
                >
                    {renderContentMarketsRAB}
                </MatchCardContentMarketsRAB>
            ) : null}
        </MatchCardContentWrapper>
    );
};

export const mapItem = (item: MarketRabView, index: number, rabId: BetslipRabId): JSX.Element | null => {
    if (item.viewDetails.selectionDisplayType === 'CorrectScore') {
        return (
            <SelectionRabCorrectScore
                rabId={rabId}
                rabMarket={item}
                key={item.name}
                order={index}
            />
        );
    }

    if (item.templateType === 'FirstNextGoalScorer') {
        return (
            <SelectionRabCombined
                templateType={item.templateType}
                rabId={rabId}
                marketName='Player to score'
                order={index}
                firstMarketName='First/next goalscorer'
                secondMarketName='Anytime scorer'
            />
        );
    }

    if (item.templateType === 'FirstNextCardedPlayer') {
        return (
            <SelectionRabCombined
                templateType={item.templateType}
                rabId={rabId}
                marketName='Player to be carded'
                order={index}
                firstMarketName='First/next carded player'
                secondMarketName='Anytime carded player'
            />
        );
    }

    if (item.templateType === 'AnytimeScorer' || item.templateType === 'AnytimeCardedPlayer') {
        return null;
    }

    return (
        <SelectionRab
            rabId={rabId}
            rabMarket={item}
            key={item.name}
            order={index}
        />
    );
};

export const renderContentMarketsRAB = (rabId: BetslipRabId): Array<JSX.Element> => {
    const marketsForView = rabId.getModel().marketsForView;

    const result: Array<JSX.Element> = [];

    for (const [index, item] of marketsForView.entries()) {
        const itemElement = mapItem(item, index, rabId);

        if (itemElement !== null) {
            result.push(itemElement);
        }
    }

    return result;
};

export const renderRABBottomBetslip = (
    reactiveWrapper: HtmlElementReactive<HTMLElement>,
    isFooter: boolean,
    rabId: BetslipRabId
): JSX.Element | null => {
    return (
        <YouDecide
            setRef={reactiveWrapper}
            isFooterVisible={isFooter}
            rabId={rabId}
        />
    );
};

export const renderRABHeader = (rabState: RabState, eventId: EventId, isYouDecideOpen: boolean): JSX.Element => {
    const onYouDecideClick = (): void => {
        rabState.toggleOpenForEvent(eventId);
    };
    return (
        <>
            <YouDecideHeader />
            <YouDecideHref
                onClick={onYouDecideClick}
                isYouDecideOpen={isYouDecideOpen}
            />
        </>
    );
};

export const renderRAB = (rabState: RabState, eventId: EventId): JSX.Element => {
    const { isBetBuilderOpen: isYouDecideOpen } = rabState;
    return (
        <RabHeader isYouDecideOpen={isYouDecideOpen}>{renderRABHeader(rabState, eventId, isYouDecideOpen)}</RabHeader>
    );
};
