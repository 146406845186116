import { computed, makeObservable } from 'mobx';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import {
    CompetitionType,
    EventsCollectionQueryModel,
} from 'src_common/common/websocket2/models/EventsCollectionQueryModel';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';

export type CompetitionItemViewType = {
    id: number;
    name: string;
};

/**
 * @deprecated
 * This class should be remove in near future
 */
export class EventsCollectionList {
    public readonly getQuery: () => EventsCollectionQueryModel | null;
    public constructor(getQuery: () => EventsCollectionQueryModel | null) {
        makeObservable(this);
        this.getQuery = getQuery;
    }

    @computed public get isEmpty(): boolean | null {
        return this.getQuery()?.isEmpty ?? null;
    }

    @computed public get length(): number {
        return this.ids.length;
    }

    /**
     * @deprecated - please use ids2
     */
    @computed.struct public get ids(): Array<number> {
        const result = this.getQuery();

        if (result === null) {
            return [];
        }
        return result.ids;
    }

    @computed.struct public get ids2(): Array<EventId> {
        const result = this.getQuery();

        if (result === null) {
            return [];
        }
        return result.ids2;
    }

    public get total(): number {
        return this.ids.length;
    }

    @computed public get isLoading(): boolean {
        const list = this.getQuery();

        return list === null;
    }

    @computed.struct public get eventItems(): Array<EventListGroupEventItemType> | null {
        return this.getQuery()?.events ?? null;
    }

    @computed.struct public get competitionIds(): Array<number> | null {
        return this.getQuery()?.competitionIds ?? null;
    }

    @computed.struct public get competitionIdsByDisplayOrderAndDay(): Array<CompetitionType> | null {
        return this.getQuery()?.competitionIdsByDisplayOrderAndDay ?? null;
    }

    @computed public get competitionForView(): Array<CompetitionItemViewType> | null {
        return this.getQuery()?.competitionForView ?? null;
    }

    @computed public get competitionForViewSortByName(): Array<CompetitionItemViewType> | null {
        return this.getQuery()?.competitionForViewSortByName ?? null;
    }

    public filterBySportAndEvent(
        filter: (sport: string, event: EventListGroupEventItemType) => boolean
    ): EventsCollectionList {
        return new EventsCollectionList(() => this.getQuery()?.filterBySportAndEvent(filter) ?? null);
    }

    public filterByCompetition(filter: (competitionId: number) => boolean): EventsCollectionList {
        return new EventsCollectionList(() => this.getQuery()?.filterByCompetition(filter) ?? null);
    }

    public limit(limit: number): EventsCollectionList {
        return new EventsCollectionList(() => this.getQuery()?.limit(limit) ?? null);
    }
}
