import React from 'react';
import { groupBy, range } from 'lodash';
import { Selection } from 'src/domains/sportsbook/webview/components/Selection';
import { MarketHeader } from 'src/domains/sportsbook/webview/components/marketHeader/MarketHeader';
import { ExpandButton } from 'src/domains/sportsbook/webview/components/expandButton/ExpandButton';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { GoalScorerMarket } from 'src/domains/sportsbook/webview/components/goalscorerMarketGroup/GoalScorerMarket';
import { observer } from 'src/utils/mobx-react';
import { sortSelectionsByCriteria } from 'src_common/utils/sport/sort';
import { FiltersNewView } from 'src/domains/layouts/webview/components/filters/Filters';
import { FiltersSkin } from 'src/domains/layouts/webview/components/filters/FiltersSkin';
import { EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';
import { GoalscorerMarketGroupState } from './GoalscorerMarketGroup.state';
import {
    Caption,
    CaptionGroup,
    MarketGroupCaption,
    GoalscorerName,
    Goalscorers,
    GoalscorersElement,
    MarketGroupFilters,
    SelectionsGroup,
    SelectionsGroupWrap,
    SelectionsInRow,
} from 'src/domains/sportsbook/webview/components/goalscorerMarketGroup/GoalscorerMarketGroup.style';
import {
    MarketGroupWrapper,
    MarketSeparator,
} from 'src/domains/sportsbook/webview/components/eventMarkets/marketGroup/MarketGroup.style';

interface PropsType {
    eventId: EventId;
    markets: Array<MarketId>;
    selections: Record<string, Array<SelectionModel>>;
    isOpen: boolean;
    onClick(e: React.MouseEvent): void;
}

type OptionItemType = {
    name: string | undefined;
    selections: Record<string, SelectionModel>;
    otherName?: string;
};

export const MARKET_NAMES: Record<string, JSX.Element> = {
    'first-goalscorer': (
        <I18n
            langKey='events.goalscorer-market.first'
            defaultText='First'
        />
    ),
    'anytime-goalscorer': (
        <I18n
            langKey='events.goalscorer-market.anytime'
            defaultText='Anytime'
        />
    ),
    'hattrick-goalscorer': (
        <I18n
            langKey='events.goalscorer-market.hattrick'
            defaultText='Hat-trick'
        />
    ),
};

const first = (obj: Record<string, SelectionModel>): SelectionModel | undefined => {
    const key = Object.keys(obj)[0];
    if (typeof key === 'string') {
        return obj[key];
    }
};

function getOptions(selections: Record<string, SelectionModel[]>): Record<string, Array<OptionItemType>> {
    const groupedSelections: Record<string, Record<string, SelectionModel>> = {};
    let marketSelections: SelectionModel[];
    const marketsNew = [];

    for (const marketId of Object.keys(selections)) {
        marketSelections = selections[marketId] ?? [];

        for (const selection of marketSelections) {
            const name = selection.name;
            const groupedSelection = groupedSelections[name];
            if (groupedSelection === undefined) {
                groupedSelections[name] = {};
            }
            // @ts-expect-error check is done above
            groupedSelections[name][marketId] = selection;
            marketsNew.push(marketId);
        }
    }

    const optionsGrouped = Object.keys(groupedSelections).map((selectionName) => {
        const sel = groupedSelections[selectionName];

        return {
            name: selectionName,
            selections: sel ?? {},
        };
    });

    const options = groupBy(optionsGrouped, (x) => {
        const selectionIdentifier = first(x.selections)?.selectionIdentifiers;
        return selectionIdentifier === '-' ? first(x.selections)?.templateId : selectionIdentifier;
    });

    for (const id of ['H', 'A', 'NG']) {
        const option = options[id];
        if (option === undefined) {
            options[id] = [];
        }
    }

    return options;
}

function getTemplateIds(markets: Array<MarketId>): Array<string | undefined> {
    return markets.map((id) => id.getModel()?.newTemplateId);
}

function selectionsForMarket(
    markets: Array<MarketId>,
    selectionDictionary: Record<string, SelectionModel>
): Array<SelectionModel> {
    const marketsOrder = Object.keys(MARKET_NAMES).map((marketTemplateId) =>
        markets.find((marketId) => {
            return marketId.getModel()?.templateId === marketTemplateId;
        })
    );

    return marketsOrder.reduce((result: Array<SelectionModel>, marketModel) => {
        if (marketModel !== undefined) {
            const selection = selectionDictionary[marketModel.getModel()?.id ?? ''];
            if (selection !== undefined) {
                result.push(selection);
            }
        }
        return result;
    }, []);
}

const getSortedSelections = (selections: Record<string, Array<SelectionModel>>): Record<string, SelectionModel[]> => {
    const selectionsSorted: Record<string, Array<SelectionModel>> = {};

    for (const [key, selectionModels] of Object.entries(selections)) {
        const sorted = sortSelectionsByCriteria(selectionModels, 'by-creation');
        selectionsSorted[key] = sorted;
    }
    return selectionsSorted;
};

const setGoalscorersOptions = (
    options: Record<string, OptionItemType[]>,
    openOption: string
): Array<OptionItemType> => {
    const optionH = options['H'] ?? null;
    const optionA = options['A'] ?? null;

    const no = Math.max(optionH === null ? 0 : optionH.length, optionA === null ? 0 : optionA.length);
    const other = openOption === 'H' ? 'A' : 'H';

    return range(0, no).map((idx) => {
        return {
            //@ts-expect-error
            name: options[openOption][idx]?.name ?? undefined,
            //@ts-expect-error
            selections: options[openOption][idx]?.selections ?? {},
            //@ts-expect-error
            otherName: options[other][idx]?.name ?? undefined,
        };
    });
};

const getGoalscorers = (options: Record<string, OptionItemType[]>, openOption: string): Array<OptionItemType> => {
    // TODO: remove 'NG' identifier - it is for old-trading only, while 'no-scorer' is for new-trading
    const noScorer = options['no-scorer'] ?? [];
    const noGoalscorer = options['NG'] ?? [];

    let goalscoresNG: Array<OptionItemType> = [];

    if (noScorer.length > 0) {
        goalscoresNG = noScorer;
    } else {
        goalscoresNG = noGoalscorer;
    }

    const goalscorersOptions = setGoalscorersOptions(options, openOption);
    return goalscorersOptions.concat(goalscoresNG);
};

export const GoalscorerMarketGroup = observer('GoalscorerMarketGroup', (props: PropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const { breakpointsState } = appLayoutsState;

    const { isOpen, onClick, selections, markets } = props;

    const [state] = React.useState(() => new GoalscorerMarketGroupState());
    const { open, isExpanded, toggleExpand, handleOpenHome, handleOpenAway } = state;

    const event = props.eventId.getEventModel();

    if (event === null) {
        return null;
    }

    const participants = event.participants;

    const market = event.markets[0] ?? null;
    const marketId = market === null ? undefined : market.id2;

    if (marketId === undefined) {
        return null;
    }

    const sortedSelections = getSortedSelections(selections);

    const homeName: string | null = Object.values(participants).find((x) => x.role === 'home')?.name ?? null;
    const awayName: string | null = Object.values(participants).find((x) => x.role === 'away')?.name ?? null;

    const options = getOptions(sortedSelections);
    const goalscorers = getGoalscorers(options, open);

    // Show more/less button only if there
    // are more then 6 participants
    const expandButton =
        goalscorers.length > 6 ? (
            <ExpandButton
                isExpanded={isExpanded}
                toggleExpand={toggleExpand}
            />
        ) : null;

    const isLargeDesktop = breakpointsState.largeDesktop.isBiggerOrEq;

    const filters = [
        {
            key: 'H',
            label:
                homeName === null ? (
                    <I18n
                        langKey='events.selections-group.team.home'
                        defaultText='Home'
                    />
                ) : (
                    homeName
                ),
            onClick: handleOpenHome,
        },
        {
            key: 'A',
            label:
                awayName === null ? (
                    <I18n
                        langKey='events.selections-group.team.away'
                        defaultText='Away'
                    />
                ) : (
                    awayName
                ),
            onClick: handleOpenAway,
        },
    ];

    return (
        <>
            <MarketGroupWrapper className='market-group--goalscorer'>
                <MarketHeader
                    name='First Goalscorer'
                    templateId='first-goalscorer'
                    isOpen={isOpen}
                    onClick={(e: React.MouseEvent): void => onClick(e)}
                    eventId={event.id2}
                    marketId={marketId}
                />
                {isOpen ? (
                    <SelectionsGroupWrap>
                        {isLargeDesktop === true ? (
                            <GoalScorerMarket selections={selections} />
                        ) : (
                            <>
                                <MarketGroupFilters className='market-group__teams'>
                                    <FiltersNewView
                                        select={open}
                                        skin={FiltersSkin.SECONDARY}
                                        filters={filters}
                                    />
                                </MarketGroupFilters>

                                <MarketGroupCaption>
                                    <Caption isFirst={true}>
                                        <I18n
                                            langKey='events.goalscorer-market.player'
                                            defaultText='Player'
                                        />
                                    </Caption>
                                    <CaptionGroup>
                                        {Object.keys(MARKET_NAMES)
                                            .filter((id) => getTemplateIds(props.markets).includes(id))
                                            .map((templateId: string) => {
                                                const marketName = MARKET_NAMES[templateId];
                                                return <Caption key={templateId}>{marketName}</Caption>;
                                            })}
                                    </CaptionGroup>
                                </MarketGroupCaption>

                                <SelectionsGroup>
                                    <Goalscorers className='market-group__goalscorers'>
                                        {goalscorers
                                            .slice(0, isExpanded ? goalscorers.length : 9)
                                            .map(({ name, selections, otherName }, idx) => {
                                                return name === undefined ? null : (
                                                    <GoalscorersElement
                                                        key={`${idx}${name}${otherName ?? ''}`}
                                                        className={`market-group__goalscorers--${String(selections.templateId)}`}
                                                    >
                                                        {name === 'No goalscorer' ? (
                                                            <GoalscorerName
                                                                templateId={String(selections.templateId)}
                                                                className='market-group__goalscorers__name'
                                                            >
                                                                <I18n
                                                                    langKey='selection.football.match.no-goalscorer'
                                                                    defaultText='No goalscorer'
                                                                />
                                                            </GoalscorerName>
                                                        ) : (
                                                            <GoalscorerName
                                                                templateId={String(selections.templateId)}
                                                                className='market-group__goalscorers__name'
                                                            >
                                                                {name}
                                                            </GoalscorerName>
                                                        )}
                                                        <SelectionsInRow className='market-group__goalscorers__selections'>
                                                            {selectionsForMarket(markets, selections).map(
                                                                (selection) => {
                                                                    const selectionId = selection.id2;
                                                                    return (
                                                                        <Selection
                                                                            key={selectionId.key}
                                                                            selectionId={selection.id2}
                                                                            borderBottom={true}
                                                                            borderRight={true}
                                                                            borderLeft={true}
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                        </SelectionsInRow>
                                                    </GoalscorersElement>
                                                );
                                            })}
                                    </Goalscorers>
                                </SelectionsGroup>
                            </>
                        )}
                        {isLargeDesktop === true ? null : expandButton}
                    </SelectionsGroupWrap>
                ) : null}
            </MarketGroupWrapper>

            {isOpen ? null : <MarketSeparator />}
        </>
    );
});
