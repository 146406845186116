import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const RaceSummaryCaptionHeader = withConfig(theme => styled('li', { label: 'RaceSummaryCaptionHeader' })`
    background: ${theme.star.raceSummary.bgColorSecondary};
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
    border-top: 1px solid ${theme.star.raceSummary.borderColor};
    color: ${theme.star.raceSummary.txtColorTertiary};
    display: flex;
    flex-flow: row nowrap;
    font-size: ${theme.star.fontSize.xSmall};
    height: 24px;
    justify-content: space-between;
    line-height: 1.4;
    @media ${theme.star.mediaQuery.tablet} {
        border-left: 1px solid  ${theme.star.raceSummary.borderColor};
    }
`);

export const RaceSummaryCaptionHeaderCast = withConfig(theme => styled(RaceSummaryCaptionHeader, { label: 'RaceSummaryCaptionHeaderCast' })`
    height: 30px;
    @media ${theme.star.mediaQuery.tablet} {
        height: 24px;
    }
`);
