import * as React from 'react';
import { AppCasinoState, CasinoGameModelExt, GameDetailsCommonModel } from 'src/domains/casino/state/AppCasino.state';
import { observer } from 'src/utils/mobx-react';
import { TableInfoBottom } from './RealTimeLobby.style';
import { GameplayDetails } from './gameplay-details/GameplayDetails';
import { LastResults } from './last-results/LastResults';
import { OccupiedSeats } from './occupied-seats/OccupiedSeats';

interface PropsType {
    appCasinoState: AppCasinoState;
    gameModel: CasinoGameModelExt;
    showRealTimeLobby?: boolean;
}

const getRealTimeLobby = (
    gameModel: CasinoGameModelExt,
    appCasinoState: AppCasinoState
): GameDetailsCommonModel | null => {
    if (gameModel.provider === 'Pragmatic') {
        return appCasinoState.liveCasinoSocket.getModel(gameModel.launchGameId)?.values ?? null;
    }

    return appCasinoState.realTimeLobbySocket.getModel(gameModel.id)?.values ?? null;
};

export const RealTimeLobby = observer('RealTimeLobby', (props: PropsType) => {
    const { appCasinoState, gameModel } = props;
    const { features } = appCasinoState;
    const isLargeVersion = appCasinoState.breakpointsState.desktop.isBiggerOrEq === true;

    if (props.showRealTimeLobby === false) {
        return null;
    }

    const realTimeLobby = getRealTimeLobby(gameModel, appCasinoState);

    if (realTimeLobby === null) {
        return null;
    }

    const shouldRenderMaxBet = features.config.gameThumbnailSettings[props.gameModel.collection].minBetOnly === false;

    return (
        <>
            <OccupiedSeats
                largeVersion={isLargeVersion}
                currentSeats={realTimeLobby.currentSeats ?? null}
                maxSeats={realTimeLobby.maxSeats ?? null}
            />
            <TableInfoBottom>
                <LastResults
                    largeVersion={isLargeVersion}
                    rouletteNumbers={realTimeLobby.rouletteNumbers}
                />
                <GameplayDetails
                    largeVersion={isLargeVersion}
                    hasBetlimits={realTimeLobby.hasBetLimits}
                    minBet={realTimeLobby.minBet}
                    maxBet={shouldRenderMaxBet ? realTimeLobby.maxBet : undefined}
                    tableName={realTimeLobby.tableName ?? gameModel.name}
                    dealerName={realTimeLobby.dealerName}
                />
            </TableInfoBottom>
        </>
    );
});
