import * as React from 'react';
import { BetslipYouDecideItem } from './BetslipYouDecideItem';
import { useState } from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { TrashIconWrapper } from 'src/domains/sportsbook/betting/ui/betSlip/betslipSelection/BetslipSelection.style';
import { BetslipRabId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import {
    BetslipYouDecideWrapper,
    BetslipYouDecideLabel,
    BetslipYouDecideLabelEvent,
    CloseIcon,
    ChevronIconWrapper,
} from './BetslipYouDecide.style';

class BetslipYouDecideState {
    @observable public isVisible = true;

    @action public toggleVisibility = (): void => {
        this.isVisible = !this.isVisible;
    };

    public constructor() {
        makeObservable(this);
    }
}

interface PropsType {
    rabSelectionId: BetslipRabId;
}

export const BetslipYouDecide = observer('BetslipYouDecide', (props: PropsType) => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { betSlipState, rab } = appSportsBookState;
    const { config } = appLayoutsState.configComponents;

    const { rabSelectionId } = props;

    const { clearError } = betSlipState.betSlipSummaryState;
    const [state] = useState(() => new BetslipYouDecideState());

    const handleDeleteRab = (): void => {
        rab.removeFromBetslip(rabSelectionId);
        clearError();
    };

    const activeBets = rab.activeBets;

    if (activeBets.length === 0) {
        return null;
    }
    const model = rabSelectionId.getModel();

    const eventModel = model.eventId.getEventModel();
    const eventName = eventModel === null ? '' : eventModel.name;

    return (
        <BetslipYouDecideWrapper
            className='BetslipYouDecide'
            data-test='betslip-rab-section'
        >
            <BetslipYouDecideLabel onClick={state.toggleVisibility}>
                <ChevronIconWrapper position={state.isVisible ? 'down' : 'right'} />

                {config.rabHeaderTitle}
            </BetslipYouDecideLabel>
            <BetslipYouDecideLabelEvent data-test='event-name'>{eventName}</BetslipYouDecideLabelEvent>

            <CloseIcon
                /* eslint-disable-next-line */
                onClick={handleDeleteRab}
            >
                <TrashIconWrapper />
            </CloseIcon>

            {state.isVisible ? (
                <BetslipYouDecideItem
                    className='x-BetslipYouDecideItem'
                    key={model.eventId.key}
                    rabSelectionId={model.id}
                />
            ) : null}
        </BetslipYouDecideWrapper>
    );
});
