import { observable, action, makeObservable } from 'mobx';
import { CreateAccountState } from 'src/domains/players/webview/components/SignUp/signupTertiary/signupState/createAccount/CreateAccount.state';
import { SignUpParamsType } from 'src/domains/players/webview/components/SignUp/signupTertiary/signupState/SignUpParams';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';

type SignUpMainStepsType =
    | {
          readonly type: 'create-account-cpf';
      }
    | {
          readonly type: 'create-account-your-details';
      }
    | {
          readonly type: 'verification-failed';
      };

export class SignUpMainSteps {
    @observable.ref public step: SignUpMainStepsType;
    public createAccountState: CreateAccountState;

    private readonly localStorageState: LocalStorageState;

    public constructor(
        public readonly params: SignUpParamsType,
        private readonly setLoading: (isLoading: boolean) => void,
        private readonly toggleAside: () => Promise<void>
    ) {
        makeObservable(this);
        const callbacks = {
            redirectToVerificationFailed: this.redirectToVerificationFailed,
            redirectToNextStep: this.redirectToYourDetails,
            setLoading: this.setLoading,
            toggleAside: this.toggleAside,
            resetPromoCodeInLocalStorage: this.resetPromoCodeInLocalStorage,
        };
        this.createAccountState = new CreateAccountState(params, callbacks);
        this.localStorageState = LocalStorageState.get(params.common);

        this.step = {
            type: 'create-account-cpf',
        };
    }

    @action public resetPromoCodeInLocalStorage = (): void => {
        this.localStorageState.promoCode.setValue(null);
    };

    @action public redirectToYourDetails = (): void => {
        this.step = {
            type: 'create-account-your-details',
        };
    };
    @action public redirectToVerificationFailed = (): void => {
        this.params.googleTagManager.gtmSignUpVerificationFailed();

        this.step = {
            type: 'verification-failed',
        };
    };
}
