import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    WithdrawTicketWrapper,
    SuccessMessageWrapper,
    LogoCoinsPaidWrapper,
    LogoCoinsPaidFooter,
    WithdrawTicketStrap,
    WithdrawTicketStrapWrapper,
    HorizontalLine,
    WithdrawTicketHeaderWrapper,
    WithdrawTicketBodyWrapper,
    WithdrawTicketCloseBtn,
    WithdrawTicketInfoIcon,
    WithdrawTicketDetailsWrapper,
    WithdrawTicketTitle,
    WithdrawTicketUnderTitle,
    WithdrawTicketCaption,
    WithdrawTicketWalletWrapper,
    WithdrawTicketBtnWrapper,
    WithdrawForm,
    BalanceRowWrapper,
    WithdrawInput,
    Swiper,
} from 'src/domains/players/webview/components/LuckyKingWallet/withdrawTicketPopup/WithdrawTicketPopup.style';
import { LogoCoinsPaid } from 'src/domains/common/paymentMethods/logos/LogoCoinspaid';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { WithdrawFormState } from 'src/domains/players/webview/components/LuckyKingWallet/withdrawTicketPopup/WithdrawTicketPopupState';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { PaymentAdditions } from 'src/domains/players/webview/components/WithdrawAndDeposit';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

const preventDefault = (event: React.FormEvent<HTMLFormElement>): void => event.preventDefault();

interface PropsType {
    closePopup: () => void;
}

export const WithdrawTicket = observer('WithdrawTicket', ({ closePopup }: PropsType) => {
    const { appLayoutsState, appPlayersState, config } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    const { usersState } = appPlayersState;
    const [state] = useState(() => new WithdrawFormState(usersState, languagesState, config));

    return (
        <WithdrawTicketWrapper>
            <WithdrawTicketHeaderWrapper>
                <WithdrawTicketCloseBtn onClick={closePopup} />
                <WithdrawTicketTitle data-test='withdraw-ticket-title'>
                    <I18n langKey='lucky-king.deposit-ticket.header.label-one-withdraw' defaultText='Withdraw' />
                </WithdrawTicketTitle>
                <WithdrawTicketUnderTitle data-test='withdraw-ticket-under-title'>
                    <I18n
                        langKey='lucky-king.deposit-ticket.header.label-two-withdraw'
                        defaultText='Minimum withdraw 0.0003 BTC'
                    />
                </WithdrawTicketUnderTitle>
            </WithdrawTicketHeaderWrapper>

            <WithdrawTicketBodyWrapper>
                {state.withdrawErrorMessage === null ? null : (
                    <Messages type='error' message={state.withdrawErrorMessage} />
                )}

                {state.withdrawSuccessMessage === null ? null : (
                    <SuccessMessageWrapper marginTop='8px' type='success' message={state.withdrawSuccessMessage} />
                )}

                <WithdrawForm onSubmit={preventDefault}>
                    <WithdrawTicketWalletWrapper>
                        <BalanceRowWrapper>
                            <WithdrawInput
                                type='text'
                                maxLength={100}
                                label={
                                    <I18n
                                        langKey='lucky-king.withdraw-ticket.header.description-label'
                                        defaultText='Amount to withdraw'
                                    />
                                }
                                state={state.amountState}
                                onChange={state.handleAmountInputChange}
                                onBlur={state.setAmount}
                                colorTheme='light'
                                inputHasBackground={false}
                                dataTest='withdraw-ticket-amount-input'
                            />
                        </BalanceRowWrapper>
                        <Swiper>
                            <PaymentAdditions
                                key='payment-additions'
                                value={state.amountState.value}
                                currency={usersState.moneySymbol}
                                options={['0.0003', '0.0004', '0.0005', '0.001']}
                                onChange={state.handlePaymentAdditionsChange}
                            />
                        </Swiper>
                    </WithdrawTicketWalletWrapper>

                    <WithdrawTicketWalletWrapper>
                        <Input
                            name='walletAddress'
                            label={
                                <I18n
                                    langKey='lucky-king.withdraw-ticket.wallet-address.label'
                                    defaultText='Wallet address'
                                />
                            }
                            state={state.externalWalletAddressState}
                            colorTheme='light'
                            dataTest='withdraw-ticket-wallet-address-input'
                        />
                    </WithdrawTicketWalletWrapper>

                    <WithdrawTicketBtnWrapper
                        type='submit'
                        size='large'
                        onClick={state.submitWithdrawForm}
                        disabled={state.disableWithdrawButton}
                        dataTest='withdraw-ticket-submit-button'
                    >
                        <I18n langKey='lucky-king.withdraw-ticket.header.label-submit' defaultText='Submit' />
                    </WithdrawTicketBtnWrapper>
                </WithdrawForm>

                <WithdrawTicketDetailsWrapper>
                    {' '}
                    {/* temporarily hidden */}
                    <WithdrawTicketInfoIcon />
                    <WithdrawTicketCaption>
                        <I18n langKey='lucky-king.withdraw-ticket.header.label-four' defaultText='' />
                    </WithdrawTicketCaption>
                </WithdrawTicketDetailsWrapper>
            </WithdrawTicketBodyWrapper>

            <HorizontalLine />

            <WithdrawTicketStrapWrapper>
                <WithdrawTicketStrap position='left' />
                <WithdrawTicketStrap position='right' />
            </WithdrawTicketStrapWrapper>

            <LogoCoinsPaidFooter>
                <LogoCoinsPaidWrapper>
                    <I18n langKey='lucky-king.withdraw-ticket.header.label-coins-paid' defaultText='Trusted by' />
                    <LogoCoinsPaid />
                </LogoCoinsPaidWrapper>
            </LogoCoinsPaidFooter>
        </WithdrawTicketWrapper>
    );
});
