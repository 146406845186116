import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    WalletIconWrapper,
    QuickHeaderButton,
    LinkName,
} from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/QuickHeader.style';
import { observer } from 'src/utils/mobx-react';
import { Amount } from 'src_common/common/amount/Amount';

export const WalletButton = observer('WalletButton', () => {
    const { appLayoutsState } = useAppStateContext();
    const { starRouter, sdkCustomer } = appLayoutsState;

    const playableBalanceNow = appLayoutsState.walletResource().valueReady?.playableBalance;
    const playableBalance = playableBalanceNow === undefined ? new Amount('0') : new Amount(playableBalanceNow);
    const balance = sdkCustomer.money(playableBalance);

    return (
        <QuickHeaderButton
            data-test='account-navigation-wallet-link'
            isActive={false}
            onClick={starRouter.redirectToWallet}
        >
            <WalletIconWrapper />
            <LinkName>{balance}</LinkName>
        </QuickHeaderButton>
    );
});
