import { observer } from 'src/utils/mobx-react';
import React from 'react';
import { RaceSummerRowMarket } from 'src/domains/sportsbook/webview/components/raceSummary/templates/rowMarkets/RaceSummerRowMarket';
import { ExpandButton } from 'src/domains/sportsbook/webview/components/expandButton/ExpandButton';
import { RowMarketsState } from 'src/domains/sportsbook/webview/components/raceSummary/templates/rowMarkets/RaceSummaryRowMarkets.state';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    PlaceCaption,
    RunnerCaption,
    SelectionCaption,
    SilkCaption,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { RaceCaption } from 'src/domains/sportsbook/webview/components/raceSummary/templates/rowMarkets/RaceSummerRowMarkets.style';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

export type RaceSummaryRowMarketsConfigType = {
    displayShowMoreButton: boolean;
    toggleMarkets: boolean;
    withTableHeader: boolean;
};

type RaceSummaryRowMarketsPropsType = {
    eventId: EventId;
    state: RowMarketsState;
    config: RaceSummaryRowMarketsConfigType;
};

export const RaceSummaryRowMarkets = observer('RaceSummaryRowMarkets', (props: RaceSummaryRowMarketsPropsType) => {
    const { state, eventId } = props;

    const currentEvent = eventId.getEventModel();

    if (currentEvent === null) {
        return null;
    }
    return (
        <>
            <RaceCaption>
                <PlaceCaption>#</PlaceCaption>
                <SilkCaption>
                    <I18n
                        langKey='events.race-summary.silk.label'
                        defaultText='Silk'
                    />
                </SilkCaption>
                <RunnerCaption>
                    <I18n
                        langKey='events.race-summary.runner.label'
                        defaultText='Runner'
                    />
                </RunnerCaption>
                <SelectionCaption>
                    <I18n
                        langKey='events.race-summary.price.label'
                        defaultText='Price'
                    />
                </SelectionCaption>
            </RaceCaption>

            {state.markets.map((market) => (
                <RaceSummerRowMarket
                    key={market.id2.key}
                    marketId={market.id2}
                    toggleMarkets={props.config.toggleMarkets}
                />
            ))}
            {props.config.displayShowMoreButton === true && state.shouldShowMoreLessButton ? (
                <ExpandButton
                    isExpanded={state.isShownMore}
                    toggleExpand={state.showMoreToggle}
                />
            ) : null}
        </>
    );
});
