import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { WithdrawIssue } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawIssue/WithdrawIssue';
import { WithdrawZenetPayProcedure } from './WithdrawZenetPayProcedure';
import { WithdrawZenetPayFormState } from './WithdrawZenetPayFormState';
import { WithdrawSuccessShort } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawSuccess/WithdrawSuccessShort';
import { Amount } from 'src_common/common/amount/Amount';
import { useCommon } from 'src/domains/common/Common';

export const WithdrawZenetPayJourney = observer('WithdrawZenetPayJourney', (): JSX.Element => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const {
        googleTagManager,
        languagesState,
        configComponents: {
            config: { minWithdrawAmount },
        },
    } = appLayoutsState;
    const { usersState } = appPlayersState;
    const common = useCommon();

    const [state] = React.useState(
        () =>
            new WithdrawZenetPayFormState(
                usersState,
                languagesState,
                common.trpcClient,
                googleTagManager,
                new Amount(minWithdrawAmount)
            )
    );

    switch (state.currentStep.type) {
        case 'set-card':
            return <WithdrawZenetPayProcedure state={state} />;
        case 'failure-view':
            return <WithdrawIssue failureType={state.currentStep.failureType} />;
        case 'withdraw-pending':
            return <WithdrawSuccessShort />;
    }
});
