import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface CrownIconPropsType {
    className?: string;
    onClick?: () => void;
}

// delete - use LuckyKingLogoMark instead

export const CrownIcon = observer(
    'CrownIcon',
    ({ className, onClick }: CrownIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                fill='none'
                height='16'
                onClick={onClick}
                viewBox='0 0 19 16'
                width='19'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M2.123 13.984h13.965V16H2.123v-2.016Zm10.472-7.408L9.1 1.312 5.616 6.576 0 0l2.123 11.832h13.965L18.211 0l-5.616 6.576Z'
                    fill='url(#LKCrownIconIdA)'
                />
                <defs>
                    <linearGradient id='LKCrownIconIdA' x1='18.289' y1='-.016' x2='.988' y2='17.011' gradientUnits='userSpaceOnUse'>
                        <stop stopColor='#FDD500'/>
                        <stop offset='.505' stopColor='#FFEB7E'/>
                        <stop offset='.995' stopColor='#FDD500'/>
                    </linearGradient></defs>
            </SvgElement>
        );
    }
);
