import React from 'react';
import { observer } from 'src/utils/mobx-react';

import { ValidationScriptIconWrapper, FooterLegalInfoDescription, FooterLegalInfoWrapper } from './Footer.style';
import { FooterCopyrightDefault } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/copyright/FooterCopyright';

export const FooterLegalInfo = observer('FooterPaymentAndGamblingInfo', () => {
    React.useEffect(() => {
        setTimeout(() => {
            const widgetScript = document.createElement('script');
            widgetScript.type = 'text/javascript';

            widgetScript.src = 'https://f454dcae-8140-4076-800c-c35e48bc7e81.snippet.antillephone.com/apg-seal.js';

            document.body.appendChild(widgetScript);
        }, 0);
    }, []);

    return (
        <FooterLegalInfoWrapper>
            <ValidationScriptIconWrapper>
                <div
                    data-apg-image-size='256' 
                    data-apg-image-type='basic-light-large'
                    data-apg-seal-id='f454dcae-8140-4076-800c-c35e48bc7e81' 
                    id='apg-f454dcae-8140-4076-800c-c35e48bc7e81' 
                />
            </ValidationScriptIconWrapper>

            <FooterLegalInfoDescription data-test='FooterCopyright'>
                <FooterCopyrightDefault />
            </FooterLegalInfoDescription>
        </FooterLegalInfoWrapper>
    );
});
