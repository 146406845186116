import { action, computed, observable, makeObservable } from 'mobx';
import { UserBasicDataType } from 'src/domains/players/state/UsersState';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { Common } from 'src/domains/common/Common';

export class DepositLimitUpdatePopupState {
    @observable public isLoading: boolean = false;

    public constructor(private readonly common: Common) {
        makeObservable(this);
    }

    @computed public get userBasicData(): UserBasicDataType['valueReady'] | null {
        const userData = BasicDataModel.get(this.common).basicData.valueReady;
        if (userData === null) return null;

        return userData;
    }

    @computed public get showDepositLimitPopup(): boolean {
        return this.userBasicData?.displayNetDepositLimitsPopUp === true;
    }

    @action public onSubmitRequest = async (): Promise<void> => {
        try {
            this.isLoading = true;

            const response = await this.common.trpcClient.client.accounts.changeDisplayNetDepositLimitsPopUp.mutate({
                displayNetDepositLimitsPopUp: false,
            });
            if (response.responseStatus === 'success') BasicDataModel.get(this.common).basicData.refresh();
        } catch (e) {
            console.error('submit error:', e);
        } finally {
            this.isLoading = false;
        }
    };
}
