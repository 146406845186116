import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { SingleBetItemState } from 'src/domains/sportsbook/betting/betSlipState/betsState/SingleBetItemState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { BetsFilter } from 'src/domains/sportsbook/betting/state/BetsState';
import {
    CashOutSuccessMessage,
    CashOutThumbsUpIconWrapper,
    CashOutBtnWrapper,
} from 'src/domains/sportsbook/betting/bettingTab/cashout/selection/selectionNew/CashOutButtons.style';
import { useAppStateContext } from 'src/appState/AppState';

interface PropsType {
    betItem: SingleBetItemState;
}

export const CashOutButtons = observer('CashOutButtons', ({ betItem }: PropsType) => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const {
        betsState: { selectedFilter },
    } = appSportsBookState;
    const { sdkCustomer, configComponents } = appLayoutsState;
    const { hasCashoutEnabled } = configComponents.config;

    const { cashOut, cashOutItem, isLoadingCashOut, isShowSuccessCashOut } = betItem;
    if (selectedFilter === BetsFilter.CASH_OUT) {
        if (isShowSuccessCashOut) {
            return (
                <CashOutSuccessMessage
                    className='bet-selection__message'
                    data-test='cash-out-success-message'
                >
                    <CashOutThumbsUpIconWrapper position='up' />
                    <I18n
                        langKey='bets.selection.cash-out-successful.label'
                        defaultText='Cash Out Successful!'
                    />
                </CashOutSuccessMessage>
            );
        }

        if (cashOutItem === null) {
            return null;
        }

        const isCashoutSuspended = !hasCashoutEnabled || !cashOutItem.enabled;

        if (isCashoutSuspended) {
            return (
                <CashOutBtnWrapper
                    disabled={true}
                    size='large'
                    data-test='cash-out-button'
                >
                    <I18n
                        langKey='bets.selection.cash-out.suspended'
                        defaultText='Cash Out Suspended'
                    />
                </CashOutBtnWrapper>
            );
        } else {
            const { onConfirmCashOut, onRetrieveCashOut, isConfirm } = betItem;
            const amount = sdkCustomer.money(configComponents.precision.newFromAnything(cashOutItem.value));
            const isCashoutExist = !cashOut;

            if (isCashoutExist) {
                const isDisabled = isLoadingCashOut || cashOutItem.value === 0;
                const dataTest = isConfirm ? 'confirm-cash-out-button' : 'cash-out-button';
                return (
                    <CashOutBtnWrapper
                        dataTest={dataTest}
                        isLoading={isLoadingCashOut}
                        onClick={isConfirm ? onRetrieveCashOut : onConfirmCashOut}
                        disabled={isDisabled}
                        size='large'
                    >
                        {isConfirm ? (
                            <I18n
                                langKey='bets.selection.cash-out.confirm'
                                defaultText='Confirm {amount} cash out'
                                params={{ amount }}
                            />
                        ) : (
                            <I18n
                                langKey='bets.selection.cash-out.button'
                                defaultText='Cash Out {amount}'
                                params={{ amount }}
                            />
                        )}
                    </CashOutBtnWrapper>
                );
            }
            return null;
        }
    }
    return null;
});
