import { action, observable, makeObservable } from 'mobx';

export class GoalscorerMarketGroupState {
    @observable public isExpanded: boolean = false;
    @observable public open: string = 'H';

    public constructor() {
        makeObservable(this);
    }

    @action public toggleExpand = (): void => {
        this.isExpanded = !this.isExpanded;
    };

    @action public handleOpenHome = (): void => {
        this.open = 'H';
    };

    @action public handleOpenAway = (): void => {
        this.open = 'A';
    };
}
