import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const MainWrapper = styled('main', { label: 'MainWrapper' })`
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
`;

export const GridWrapper = withConfig(theme => styled('div', { label: 'GridWrapper' })`
    align-items: center;
    background: ${theme.star.sportTiles.bgColor};
    display: flex;
    min-height: calc(100vh - 96px);
    @media ${theme.star.mediaQuery.tablet} {
        display: none;
    }
`);

export const DesktopWrapper = withConfig(theme => styled('div', { label: 'DesktopWrapper' })`
    display: none;
    @media ${theme.star.mediaQuery.tablet} {
        display: block;
    }
`);
