import styled from '@emotion/styled';
import { McBookieLogo } from 'src/domains/layouts/shared/assets/icons/logo/mcbookie.logo';
import { NeBetLogo } from 'src/domains/layouts/shared/assets/icons/logo/nebet.logo';
import { StarSportsLogo } from 'src/domains/layouts/shared/assets/icons/logo/star.logo';
import { withConfig } from 'src/withConfig';

export const MainContainer = withConfig(theme => styled('article', { label: 'MainContainer' })`
    align-items: center;
    background-color: ${theme.star.browserUnsupported.bgColor};
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    justify-content: center;
    min-height: 100%;
    padding: 0 20px;
    width: 100%;
`);

export const AboutBrowserInfo = withConfig(theme => styled('h2', { label: 'AboutBrowserInfo' })`
    color: ${theme.star.browserUnsupported.txtColor};
    font-size: 14px;
    font-weight: 700;
    line-height: 1.3334;
    margin: 26px 0 16px;
    max-width: 770px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: 18px;
    }
`);

export const AdditionalInfo = withConfig(theme => styled('p', { label: 'AdditionalInfo' })`
    color: ${theme.star.browserUnsupported.txtColor};
    font-size: 14px;
    font-weight: 400;
    line-height: 1.14;
    margin: 0;
    text-align: center;
    width: 100%;
`);

export const StarsportsLogoIconWrapper = styled(StarSportsLogo, { label: 'StarsportsLogoIconWrapper' })`
    max-height: 35px;
    max-width: 240px;
    width: 100%;
`;

export const McBookieLogoWrapper = styled(McBookieLogo, { label: 'McBookieLogoWrapper' })`
    max-height: 64px;
    max-width: 200px;
    width: 100%;
`;

export const NebetLogoWrapper = styled(NeBetLogo, { label: 'NebetLogoWrapper' })`
    max-height: 58px;
    max-width: 200px;
    width: 100%;
`;

export const DownloadBrowserLink = withConfig(theme => styled('a', { label: 'DownloadBrowserLink' })`
    color: ${theme.star.browserUnsupported.txtColor};
    cursor: pointer;
    text-decoration: none;
    transition: opacity .2s ease;
    &:hover {
        opacity: .85;
    }
`);
