import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

interface PropsType {
    className?: string;
    onClick?: () => void;
}

const SvgElement = styled('svg', { label: 'SvgElement' })<PropsType>`
    height: auto;
`;

export const LuckyKingLogo = observer('LuckyKingLogo', ({ className, onClick }: PropsType): JSX.Element => (
    <SvgElement
        className={className}
        clip-rule='evenodd'
        fill-rule='evenodd'
        height='36px'
        onClick={onClick}
        stroke-linejoin='round'
        stroke-miterlimit='2'
        viewBox='0 0 232 36'
        width='232px'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path d='M47.5028 30.2243V1.06916H43.0145L40.2849 16.4112V34.8982H55.9116V30.2243H47.5028Z' fill='white'/>
        <path d='M56.8458 24.2125V1.06916H63.9905V25.1682C63.9905 27.7571 64.3019 30.3807 67.1598 30.3807C70.0177 30.3807 70.3291 27.7918 70.3291 25.1682V1.06916H77.5105V24.2125C77.5105 31.3015 75.2571 35.3499 67.1598 35.3499C59.0625 35.3499 56.8458 31.3015 56.8458 24.2125Z' fill='white'/>
        <path d='M80.5514 24.0736V11.9111C80.5514 4.96112 82.9695 0.599993 90.9752 0.599993C98.6328 0.599993 100.978 4.52673 100.978 10.3647V13.2142H93.9246V10.0695C93.9246 7.6891 93.9247 5.53442 91.0302 5.53442C88.1357 5.53442 87.8976 7.77599 87.8976 10.2954V25.6548C87.8976 28.539 88.4655 30.3807 91.0302 30.3807C93.595 30.3807 93.9246 28.122 93.9246 25.6548V22.3535H100.978V25.1508C100.978 31.0409 98.7794 35.2977 90.9752 35.2977C83.171 35.2977 80.5514 30.8498 80.5514 24.0736Z' fill='white'/>
        <path d='M103.762 1.06916H111.09V15.3513L117.557 1.06916H124.61L117.905 16.2722L125.123 34.8981H117.795L112.299 19.782L111.108 21.6237V34.8981H103.781L103.762 1.06916Z' fill='white'/>
        <path d='M132.763 21.4847L125.801 1.06916H132.634L136.463 12.971L139.98 1.06916H146.63L139.761 21.4847V34.8981H132.763V21.4847Z' fill='white'/>
        <path d='M153.079 1.06916H160.407V15.3513L166.874 1.06916H173.927L167.222 16.2722L174.44 34.8981H167.112L161.616 19.782L160.425 21.6237V34.8981H153.097L153.079 1.06916Z' fill='white'/>
        <path d='M176.876 1.06916H184.204V34.8981H176.876V1.06916Z' fill='white'/>
        <path d='M188.143 1.06916H193.217L201.131 18.6526V1.06916H207.177V34.8981H202.286L194.353 16.1853V34.8981H188.143V1.06916Z' fill='white'/>
        <path d='M211.262 23.7087V12.3802C211.262 5.15223 213.68 0.652113 221.704 0.652113C229.362 0.652113 231.872 4.49189 231.872 10.5558V12.5017H224.819V10.1214C224.819 7.49776 224.599 5.58654 221.796 5.58654C218.993 5.58654 218.663 7.96701 218.663 10.469V25.4984C218.663 28.2958 219.231 30.4328 221.998 30.4328C224.764 30.4328 225.442 28.2088 225.442 25.2203V21.6236H221.961V17.4538H232V34.8287H227.255L226.834 31.9096C226.374 33.005 225.557 33.9325 224.503 34.5575C223.448 35.1824 222.212 35.4721 220.972 35.3846C213.644 35.3846 211.262 30.7629 211.262 23.7087Z' fill='white'/>
        <path d='M4.61758 31.0152H34.992V35.4H4.61758V31.0152ZM27.3938 14.9027L19.7956 3.45362L12.2158 14.9027L0 0.599993L4.61758 26.3347H34.992L39.6098 0.599993L27.3938 14.9027Z' fill='url(#paint0_linear_414_966)'/>
        <defs>
            <linearGradient id='paint0_linear_414_966' x1='39.7782' y1='0.565063' x2='2.14887' y2='37.5987' gradientUnits='userSpaceOnUse'>
                <stop stopColor='#FDD500'/>
                <stop offset='0.505208' stopColor='#FFEB7E'/>
                <stop offset='0.994904' stopColor='#FDD500'/>
            </linearGradient>
        </defs>
    </SvgElement>
));
