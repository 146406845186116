import { Resource } from 'src_common/common/mobx-utils/Resource';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { Common } from 'src/domains/common/Common';

type SportModelType = {
    id: string;
    name: string;
};

export class AllSports {
    private readonly allSportsResource: Resource<Array<SportModelType>>;

    public static get = AutoWeakMap.create((common: Common) => new AllSports(common));

    private constructor(common: Common) {
        const sdkCustomer = SdkCustomer.get(common);

        this.allSportsResource = new Resource(async (): Promise<Array<SportModelType>> => sdkCustomer.getSportsList());
    }

    /**
     * @deprecated - remove this function
     */
    public getAllSports(): Array<string> | null {
        const allSports = this.allSportsResource.get();

        if (allSports.type === 'ready') {
            return allSports.value.map((item) => item.id);
        }

        return null;
    }

    /**
     * @deprecated - remove this function
     */
    public getSport(sportId: string): string | null {
        const allSports = this.allSportsResource.get();

        if (allSports.type === 'ready') {
            for (const item of allSports.value) {
                if (item.id === sportId) {
                    return item.name;
                }
            }
        }

        return null;
    }

    public get allSports(): Array<SportModelType> | null {
        const allSports = this.allSportsResource.get();

        if (allSports.type === 'ready') {
            return allSports.value;
        }

        return null;
    }
}
