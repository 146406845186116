import styled from '@emotion/styled';
import { LayoutType } from 'src/domains/layouts/config/features/types';
import { CheckCircleOutlineIcon } from 'src/domains/layouts/shared/assets/icons/CheckCircleOutlineIcon';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const Wrapper = withConfig(theme => styled('div', { label: 'Wrapper' })`
    padding: 40px 16px;
    text-align: center;
    font-size: ${theme.star.fontSize.xRegular};
`);

export const CheckIcon = withConfig(theme => styled(CheckCircleOutlineIcon, { label: 'CheckIcon' })`
    width: 48px;
    fill: ${theme.star.statuses.success.bgColor};
    margin-bottom: 8px;
`);

export const Title = withConfig(theme => styled('h2', { label: 'Title' })`
    font-size: ${theme.star.fontSize.medium};
    color: ${theme.star.luckyKingLogin.txtColorSecondary};
    line-height: 1.3334;
    margin: 0 0 16px 0;
`);

export const PrimaryDescription = withConfig(theme => styled('p', { label: 'PrimaryDescription' })`
    color: ${theme.star.luckyKingLogin.txtColorSecondary};
    line-height: 1.3334;
    margin: 0 0 16px 0;
`);

interface AccountHelperContainerType {
    layout: LayoutType;
}

export const AccountHelperContainer = withConfig(theme => styled('div', { label: 'AccountHelperContainer' })<AccountHelperContainerType>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${({ layout }): string => layout === 'secondary' ? 'height: 100%' : ''};
    background-color: ${theme.star.accountDrawer.bgColor};
`);

interface LoginBtnWrapperType {
    isShaded?: boolean | undefined;
}

/* Please use proper config colours or button version */
export const LoginBtnWrapper = withConfig(theme => styled(Button, { label: 'LoginBtnWrapper' })<LoginBtnWrapperType>`
    ${({ isShaded }): string => isShaded === true ? `color: ${theme.star.luckyKingLogin.txtColorSecondary}` : ''};

    ${({ isShaded }): string => isShaded === true ? `background-color: ${theme.star.signUp.bgColorSecondary}` : ''};
    display: block;
    margin: 0 0 16px 0;
    width: 100%;
`);
