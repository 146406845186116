import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    RaceSummaryRowMarkets,
    RaceSummaryRowMarketsConfigType,
} from 'src/domains/sportsbook/webview/components/raceSummary/templates/rowMarkets/RaceSummaryRowMarkets';
import {
    RowMarketsState,
    RowMarketsStateStrategy,
    SHORT_LIST_COUNT,
} from 'src/domains/sportsbook/webview/components/raceSummary/templates/rowMarkets/RaceSummaryRowMarkets.state';
import { computed, observable, makeObservable } from 'mobx';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { useCommon } from 'src/domains/common/Common';
import { RaceSummaryRowMarketsNew } from './templates/rowMarkets/RaceSummaryRowMarketsNew';

export class RaceSummaryBettingWithoutState implements RowMarketsStateStrategy {
    @observable public isShownMore: boolean = true;

    public constructor(public readonly eventId: EventId) {
        makeObservable(this);
    }

    @computed public get markets(): Array<MarketModel> {
        const currentEvent = this.eventId.getEventModel();
        if (currentEvent === null) {
            return [];
        }

        const out = currentEvent.marketWithout.sort((mA, mB) => (mA.displayOrder < mB.displayOrder ? -1 : 1));
        if (out.length < SHORT_LIST_COUNT) {
            return out;
        }

        return this.isShownMore ? out : out.slice(0, SHORT_LIST_COUNT);
    }
}

const viewConfig: RaceSummaryRowMarketsConfigType = {
    displayShowMoreButton: false,
    toggleMarkets: false,
    withTableHeader: true,
};

export interface RaceSummaryBettingWithoutPropsType {
    eventId: EventId;
}

export const RaceSummaryBettingWithout = observer(
    'RaceSummaryBettingWithout',
    (props: RaceSummaryBettingWithoutPropsType) => {
        const [state] = useState(() => new RowMarketsState(new RaceSummaryBettingWithoutState(props.eventId)));
        const common = useCommon();
        const { config } = ConfigComponents.get(common);

        if (config.raceSummaryRowHorseRacingNew) {
            return (
                <RaceSummaryRowMarketsNew
                    state={state}
                    eventId={props.eventId}
                    config={viewConfig}
                />
            );
        }
        return (
            <RaceSummaryRowMarkets
                state={state}
                eventId={props.eventId}
                config={viewConfig}
            />
        );
    }
);
