import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { PriceInputWrapper, Option, SwitchIconWrapper } from './PriceInput.style';
import { useAppStateContext } from 'src/appState/AppState';
import { BetslipSingleId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';

interface PriceInputType {
    selectionId: BetslipSingleId;
}

export const PriceInput = observer('PriceInput', ({ selectionId }: PriceInputType): JSX.Element | null => {
    const appState = useAppStateContext();
    const legModel = selectionId.getModel();
    const { isReferred } = appState.appSportsBookState.betSlipState.referralState;

    if (legModel.priceOptions !== undefined) {
        const label = legModel.selectedPriceType;
        return (
            <PriceInputWrapper
                priceDirection={isReferred ? null : legModel.selectionChangedDirection}
                onClick={legModel.handleSwitchPriceType}
            >
                <Option>{label}</Option>
                {!legModel.switchDisabled && <SwitchIconWrapper />}
            </PriceInputWrapper>
        );
    }
    return null;
});
