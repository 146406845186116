import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    BetslipFooterContainer,
    BalanceValue,
    BalanceWrapper,
    ReturnsLabel,
    BalanceLabel,
    TotalReturnsWrapper,
    TotalReturnsValue,
} from './BetslipFooter.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { Amount } from 'src_common/common/amount/Amount';
import { useCommon } from 'src/domains/common/Common';
import { SdkCustomer } from 'src/domains/layouts/state/customer';

export const BetslipFooter = observer('BetslipFooter', () => {
    const common = useCommon();
    const {
        appSportsBookState: { betSlipState },
    } = useAppStateContext();
    const { session } = common;
    const { betSlipSummaryState } = betSlipState;
    const { potentialReturn, totalStake, balanceAfter, isLegsChangedWarning, isAnyNA } = betSlipSummaryState;
    const zeroAmount = new Amount('0');
    const sdkCustomer = SdkCustomer.get(common);

    const { isAuthorized } = session;
    const notApplicable = 'n/a';
    const totalReturns = potentialReturn.isGreaterThan(zeroAmount)
        ? sdkCustomer.money(potentialReturn)
        : sdkCustomer.money(zeroAmount);
    const totalReturnsValue = isLegsChangedWarning ? notApplicable : totalReturns;
    const balanceAfterValue = sdkCustomer.money(balanceAfter);

    const totalReturnsValueSignsNumber = Array.from(totalReturnsValue).length;
    const balanceAfterValueSignsNumber = Array.from(balanceAfterValue).length;
    const isNoValue = totalReturnsValue === notApplicable || totalStake.isEqualWith(zeroAmount);
    const isValueTooLong =
        !isNoValue && (totalReturnsValueSignsNumber > 6 || balanceAfterValueSignsNumber > 6) ? true : false;

    return (
        <BetslipFooterContainer>
            <TotalReturnsWrapper
                isValueTooLong={isValueTooLong}
                data-test='total-returns'
            >
                <ReturnsLabel>
                    <I18n
                        langKey='betslip.total-returns-info'
                        defaultText='Total returns:'
                    />
                </ReturnsLabel>
                <TotalReturnsValue>{isAnyNA ? notApplicable : totalReturnsValue}</TotalReturnsValue>
            </TotalReturnsWrapper>
            {isAuthorized &&
            totalStake.isGreaterThan(zeroAmount) &&
            !isLegsChangedWarning &&
            (balanceAfter.isGreaterThan(zeroAmount) || balanceAfter.isEqualWith(zeroAmount)) ? (
                <BalanceWrapper
                    isValueTooLong={isValueTooLong}
                    data-test='balance-after'
                >
                    <BalanceLabel>
                        <I18n
                            langKey='betslip.balance-value-info'
                            defaultText='Balance after:'
                        />
                    </BalanceLabel>
                    <BalanceValue>{balanceAfterValue}</BalanceValue>
                </BalanceWrapper>
            ) : null}
        </BetslipFooterContainer>
    );
});
