import styled from '@emotion/styled';
import { LogoBitcoin } from 'src/domains/common/paymentMethods/logos/LogoBitcoin';
import { Logo18Plus } from 'src/domains/layouts/shared/assets/logotypes/responsible-gambling/Logo18Plus';
import { withConfig } from 'src/withConfig';

export const FooterGridItem = withConfig(theme => styled('div', { label: 'FooterGridItem' })`
    align-items: center;
    background: ${theme.star.footerMain.bgColorTertiary};
    border-radius: 8px;
    display: flex;
    height: 64px;
    justify-content: center;
    padding: 8px 4px;
`);

export const BitcoinIconWrapper = withConfig(theme => styled(LogoBitcoin, { label: 'BitcoinIconWrapper' })`
    width: 77px;
    @media ${theme.star.mediaQuery.xxLargeDesktop} {
        width: 115px;
    }
`);

export const Logo18PlusWrapper = styled(Logo18Plus, { label: 'Logo18PlusWrapper' })`
    width: 32px;
`;
