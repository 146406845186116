import { AppState } from 'src/appState/AppState';
import { computed, makeObservable } from 'mobx';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import {
    CompetitionRouteType,
    EventRouteType,
    SportRouteType,
} from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { assertNever } from 'src_common/common/assertNever';
import { EventState } from 'src/domains/layouts/webview/components/eventNavigation/CarouselEvent.state';
import { CompetitionState } from 'src/domains/layouts/webview/components/eventNavigation/CarouselCompetition.state';
import { CompetitionId, EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { Common } from 'src/domains/common/Common';

type PageType =
    | {
          type: 'sport';
          sport: string;
      }
    | {
          type: 'competition';
          sport: string;
          competition: CompetitionId;
      }
    | {
          type: 'event';
          eventId: EventId;
      };

class MobxMapSerialize<K, V> {
    private data: Map<string, V>;

    public constructor(
        private readonly serializeKey: (key: K) => string,
        private readonly fnBuild: (key: K) => V
    ) {
        this.data = new Map();
    }

    public get(key: K): V {
        const keySerialized = this.serializeKey(key);

        const value = this.data.get(keySerialized);
        if (typeof value !== 'undefined') {
            return value;
        }

        const newValue = this.fnBuild(key);
        this.data.set(keySerialized, newValue);
        return newValue;
    }
}

export class CarouselEventNavigationState {
    public readonly competitionState: CompetitionState;
    public readonly eventState: EventState;

    public constructor(
        private readonly appState: AppState,
        private readonly currentView: SportRouteType | CompetitionRouteType | EventRouteType,
        private readonly common: Common
    ) {
        this.competitionState = new CompetitionState(appState, this);
        this.eventState = new EventState(appState, this);
        makeObservable(this);
    }

    @computed public get pageType(): PageType | null {
        const { currentView } = this;

        if (currentView.name === 'sport') {
            return {
                type: 'sport',
                sport: currentView.id,
            };
        }

        if (currentView.name === 'competition') {
            return {
                type: 'competition',
                sport: currentView.slug,
                competition: this.common.models.id.getCompetitionId(currentView.id),
            };
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (currentView.name === 'event') {
            return {
                type: 'event',
                eventId: this.common.models.id.getEventId(currentView.id),
            };
        }

        return null;
    }

    public urlToEvent(eventId: number, slug: string): string {
        return this.appState.appLayoutsState.starRouter.buildUrlTo({ name: 'event', id: eventId, slug });
    }

    public urlToCompetition(competition: number, sport: string): string {
        return this.appState.appLayoutsState.starRouter.buildUrlTo({
            name: 'competition',
            id: competition,
            slug: sport,
        });
    }

    public urlToSport(sport: string): string {
        return this.appState.appLayoutsState.starRouter.buildUrlTo({ name: 'sport', nameType: 'regular', id: sport });
    }

    @computed public get event(): EventModel | null {
        const page = this.pageType;
        if (page?.type === 'event') {
            return page.eventId.getEventModel();
        }
        return null;
    }
}

export class CarouselEventAutoMap {
    private readonly data: MobxMapSerialize<
        SportRouteType | CompetitionRouteType | EventRouteType,
        CarouselEventNavigationState
    >;

    public constructor(appState: AppState, common: Common) {
        this.data = new MobxMapSerialize(
            (key: SportRouteType | CompetitionRouteType | EventRouteType): string => {
                if (key.name === 'sport') {
                    return `sport-${key.id}`;
                }
                if (key.name === 'competition') {
                    return `competition-${key.id}`;
                }
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (key.name === 'event') {
                    return `event-${key.id}`;
                }
                return assertNever('', key);
            },
            (key: SportRouteType | CompetitionRouteType | EventRouteType): CarouselEventNavigationState => {
                return new CarouselEventNavigationState(appState, key, common);
            }
        );
    }

    public get(key: SportRouteType | CompetitionRouteType | EventRouteType): CarouselEventNavigationState {
        return this.data.get(key);
    }
}
