import { scrollToNavigationBar } from 'src/domains/casino/utils/utils';
import { BreakpointsState } from 'src/domains/layouts/state/breakpointsState/BreakpointsState';
import { assertNever } from 'src_common/common/assertNever';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { CollectionType } from './store/game-store/typesExt';
import { GamePlayTagType, RedirectCasinoCallbackType } from 'src/domains/casino/utils/callbackTypes';
import { CurrencyType } from 'src_common/common/amount/website-money/currency';
import { CasinoGameModelExt } from './store/game-store/CasinoGameModelExt';
import { ConfigState } from './ConfigState';
import { Common } from 'src/domains/common/Common';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';

export class RedirectState {
    public constructor(
        private readonly starRouter: StarRouter,
        private readonly breakpointsState: BreakpointsState,
        private readonly config: ConfigState,
        private readonly callbacks: RedirectCasinoCallbackType,
        private readonly common: Common
    ) {}

    public getIsUserAutorized = (): boolean => {
        return this.common.session.isAuthorized;
    };

    public get currentView(): string | undefined {
        return this.starRouter.currentView?.name;
    }

    public goToLoginForm = (): boolean => {
        if (this.getIsUserAutorized() === false) {
            // TODO: setTimeout to remove after starRouter inner state change to mobx
            setTimeout(() => {
                this.starRouter.redirectToLogin();
            }, 0);
            return true;
        }

        return false;
    };

    public redirectToLogin = (): void => {
        this.starRouter.redirectToLogin();
    };

    public onClickLogin = (): void => {
        this.starRouter.redirectToLogin();
    };

    public redirectToCasinoPage = (): void => {
        this.starRouter.redirectToCasinoPage();
        window.scrollTo(0, 0);
    };

    public redirectToLiveCasinoPage = (): void => {
        this.starRouter.redirectToLiveCasinoPage();
        window.scrollTo(0, 0);
    };

    public redirectToVirtualsPage = (): void => {
        this.starRouter.redirectToVirtualsPage();
        window.scrollTo(0, 0);
    };

    /**
     * @deprecated - Please use RedirectState.redirectToCollectionTab
     */
    public redirectToCasinoTab = (id: string | number, navigationOffset?: number): void => {
        this.starRouter.redirectToCasinoTabPage(id.toString());
        scrollToNavigationBar(this.breakpointsState, this.config, navigationOffset);
    };

    /**
     * @deprecated - Please use RedirectState.redirectToCollectionTab
     */
    public redirectToLiveCasinoTab = (id: string | number, navigationOffset?: number): void => {
        this.starRouter.redirectToLiveCasinoTabPage(id.toString());
        scrollToNavigationBar(this.breakpointsState, this.config, navigationOffset);
    };

    public redirectToCollectionTab = (
        collectionType: CollectionType,
        tabId: string,
        navigationOffset?: number
    ): void => {
        if (collectionType === 'casino') {
            this.starRouter.redirectToCasinoTabPage(tabId);
            scrollToNavigationBar(this.breakpointsState, this.config, navigationOffset);
            return;
        }

        if (collectionType === 'live-casino') {
            this.starRouter.redirectToLiveCasinoTabPage(tabId);
            scrollToNavigationBar(this.breakpointsState, this.config, navigationOffset);
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (collectionType === 'virtuals') {
            return; // No tabs in Virtuals
        }

        return assertNever('redirectToCollectionTab', collectionType);
    };

    public redirectToGameCollection = (gameModel: CasinoGameModelExt): void => {
        if (gameModel.collection === 'live-casino') {
            this.redirectToLiveCasinoPage();
        } else if (gameModel.collection === 'virtuals') {
            this.redirectToVirtualsPage();
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (gameModel.collection === 'casino') {
            this.redirectToCasinoPage();
        } else {
            assertNever('Redirect to collection', gameModel.collection);
        }
    };

    public onHandleUrlRedirection = (redirectUrl: string): void => {
        this.starRouter.handleUrlRedirection(redirectUrl);
        window.scrollTo(0, 0);
    };

    public redirectToHomepage = (): void => {
        this.starRouter.redirectToHomepage();
    };

    public redirectToTransactionHistory = (): void => {
        this.starRouter.redirectToTransactionHistory();
    };

    public redirectToCasinoGameInfo = (): void => {
        this.starRouter.redirectToCasinoGameInfo();
    };

    public redirectWithoutAccountParam = (): void => {
        return this.starRouter.closeAccount();
    };

    public redirectToCasinoBannerTerms = (): void => {
        return this.starRouter.redirectToCasinoBannerTerms();
    };

    public getHomePage = (collection: CollectionType): string => {
        const homeAddress = this.starRouter.buildUrlTo({ name: collection }).substr(1);
        return homeAddress;
    };

    // ------------- Callbacks Gateway -------------

    public redirectToDepositPage = (): void => {
        if (this.config.commonConfig.usesCrypto === true) {
            return this.starRouter.redirectToAddCredit();
        }
        this.callbacks.onRoutingAccountChange('top-up');
        this.starRouter.redirectToTopUp(null);
    };

    public onGoogleTagManagerGamePlayTag = (data: GamePlayTagType): void => {
        this.callbacks.onGoogleTagManagerGamePlayTag(data);
    };

    public resetRealityCheckTime = (): void => {
        LocalStorageState.get(this.common).realityCheckTime.setValue(new Date().getTime());
    };

    public getCurrency = (): CurrencyType => {
        return this.callbacks.getCurrency();
    };
}
