import { z } from 'zod';

export const ContactPreferenceEnum = {
    EMAIL: 'email',
    SMS: 'sms',
    PHONE: 'phone',
    WHATSAPP: 'whatsApp',
} as const;

export const InputSchema = z.object({
    username: z.string(), //Temporary
    surname: z.string(), //Temporary
    firstName: z.string(), //Temporary
    email: z.string(),
    password: z.string(),
    birthDate: z.string(),
    marketing: z.boolean(),
    contactPreferences: z.array(z.nativeEnum(ContactPreferenceEnum)), //Array<ContactPreference>;
});

export type CreateAccountInputType = z.infer<typeof InputSchema>;
