import { computed, makeObservable, observable, action } from 'mobx';
import { TrpcClient } from 'src/appState/TrpcClient';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { getRollingLimits, putRollingLimits, getDepositLimits } from 'src_server/trpc/types/responsibleGambling';
import { RollingNetDepositLimitItemState } from 'src/domains/players/webview/components/Account/limitsTab/rollingNetDepositLimit/RollingNetDepositLimitItem';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { UsersState } from 'src/domains/players/state/UsersState';
import { Amount } from 'src_common/common/amount/Amount';

export type InfoMessageType = 'success' | 'error' | 'empty' | 'belowOne';

interface ConvertedLimitsType {
    [key: string]: Amount | undefined;
}
interface ChekIsDepositLimitAlreadySetType {
    newValueWhenDiffer: Record<number, Amount | undefined>;
}

export class RollingNetDepositLimitState {
    public readonly daily: RollingNetDepositLimitItemState;
    public readonly weekly: RollingNetDepositLimitItemState;
    public readonly monthly: RollingNetDepositLimitItemState;

    public readonly getDepositLimitsData: Resource<getDepositLimits.ResponseType>;

    @observable public infoMessage: InfoMessageType | null = null;
    @observable public isDisableButton = false;

    public constructor(
        private readonly trpcClient: TrpcClient,
        private readonly configComponents: ConfigComponents,
        private readonly usersState: UsersState
    ) {
        makeObservable(this);

        this.getDepositLimitsData = new Resource(async (): Promise<getDepositLimits.ResponseType> => {
            const response = await this.trpcClient.client.responsibleGamblingRouter.getDepositLimits.query();
            return response;
        });

        this.daily = new RollingNetDepositLimitItemState(
            this.configComponents,
            this.usersState,
            this.clearInfoMessage,
            () => this.getRollingLimits().get(1),
            () => this.getDepositLimits?.rolling1Day.active?.used ?? 0
        );

        this.weekly = new RollingNetDepositLimitItemState(
            this.configComponents,
            this.usersState,
            this.clearInfoMessage,
            () => this.getRollingLimits().get(7),
            () => this.getDepositLimits?.rolling7Days.active?.used ?? 0
        );

        this.monthly = new RollingNetDepositLimitItemState(
            this.configComponents,
            this.usersState,
            this.clearInfoMessage,
            () => this.getRollingLimits().get(30),
            () => this.getDepositLimits?.rolling30Days.active?.used ?? 0
        );
    }
    private clearInfoMessage = (): void => {
        this.infoMessage = null;
    };

    private clearInputs = (): void => {
        this.daily.resetValue();
        this.weekly.resetValue();
        this.monthly.resetValue();
    };

    private getRollingLimits = (): Map<number, getRollingLimits.LimitsObjectSchemaType> => {
        const data = this.usersState.rollingNetDepositLimitData.valueReady;
        if (data === null) {
            return new Map();
        }
        return data;
    };

    @computed private get getDepositLimits(): getDepositLimits.SuccessResponseType | undefined {
        const response = this.getDepositLimitsData.get();

        if (response.type === 'ready' && response.value.responseStatus === 'success') {
            return response.value.data;
        }
        return undefined;
    }

    @computed public get rollingNetDepositLimitStatus(): 'error' | 'loading' | 'ready' {
        const depositsModel = this.usersState.rollingNetDepositLimitData.get();

        return depositsModel.type;
    }

    @computed public get isErrorInput(): boolean {
        return this.daily.isError || this.weekly.isError || this.monthly.isError;
    }

    private getActualLimits = (): ConvertedLimitsType => {
        const getValue = (period: RollingNetDepositLimitItemState): Amount | undefined => {
            const shortenValue = period.limitAmount.result.value;
            return shortenValue.type === 'ok' ? shortenValue.data : undefined;
        };

        return {
            dailyValue: getValue(this.daily),
            weeklyValue: getValue(this.weekly),
            monthlyValue: getValue(this.monthly),
        };
    };

    private getPreviousLimits = (): ConvertedLimitsType => {
        const getPreviousLimit = (period: RollingNetDepositLimitItemState): Amount | undefined => {
            const limit = period.getLimits()?.activeLimitValue;
            return limit === undefined ? undefined : this.configComponents.precision.newFromOld(limit);
        };

        return {
            previousDaily: getPreviousLimit(this.daily),
            previousWeekly: getPreviousLimit(this.weekly),
            previousMonthly: getPreviousLimit(this.monthly),
        };
    };

    private checkIsDepositLimitAlreadySet = (): ChekIsDepositLimitAlreadySetType => {
        const { dailyValue, weeklyValue, monthlyValue } = this.getActualLimits();
        const { previousDaily, previousWeekly, previousMonthly } = this.getPreviousLimits();

        const hasValueChanged = (newValue: Amount | undefined, oldValue: Amount | undefined): boolean =>
            newValue !== undefined && oldValue?.value !== newValue.value;

        return {
            newValueWhenDiffer: {
                1: hasValueChanged(dailyValue, previousDaily) ? dailyValue : undefined,
                7: hasValueChanged(weeklyValue, previousWeekly) ? weeklyValue : undefined,
                30: hasValueChanged(monthlyValue, previousMonthly) ? monthlyValue : undefined,
            },
        };
    };

    private checkIsLessThanOne(limits: ConvertedLimitsType): boolean {
        const limit = new Amount('1');
        const values = [limits.dailyValue, limits.weeklyValue, limits.monthlyValue];

        return values.some((value) => value !== undefined && value.isLessThan(limit));
    }

    private async updateRollingLimits(
        preparedPayload: putRollingLimits.InputType['payload']['list']
    ): Promise<putRollingLimits.ResponseType> {
        const response = await this.trpcClient.client.responsibleGamblingRouter.putRollingLimits.mutate({
            payload: {
                list: preparedPayload,
            },
        });
        return response;
    }

    private setTriggersWarningEmail = (limitValueMax: number | null, pendingLimitValue: number): boolean => {
        return pendingLimitValue === limitValueMax ? true : false;
    };

    private async updateLimits(): Promise<void> {
        const daysArray = [1, 7, 30];
        const preparedPayload: putRollingLimits.LimitObjectType[] = [];

        daysArray.forEach((days) => {
            const newValue = this.checkIsDepositLimitAlreadySet().newValueWhenDiffer[days];
            if (newValue !== undefined) {
                const previousLimitData = this.getRollingLimits().get(days);
                const pendingLimitValue = parseInt(newValue.multiply(100).value, 10);
                const triggersWarningEmail = this.setTriggersWarningEmail(
                    previousLimitData?.limitValueMax ?? null,
                    pendingLimitValue
                );

                preparedPayload.push({
                    windowLengthInDays: days,
                    pendingLimitValue,
                    limitValueMax: previousLimitData?.limitValueMax ?? null,
                    triggersWarningEmail,
                    hardLimit: previousLimitData?.hardLimit ?? false,
                });
            }
        });

        const response = await this.updateRollingLimits(preparedPayload);

        this.infoMessage = response.responseStatus === 'success' ? 'success' : 'error';

        if (response.responseStatus === 'success') {
            this.usersState.rollingNetDepositLimitData.refresh();
            await this.getDepositLimitsData.refresh();
        }
    }

    @action public onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        if (this.isDisableButton === true) {
            console.warn('action is executed');
            return;
        }

        e.preventDefault();

        const { dailyValue, weeklyValue, monthlyValue } = this.getActualLimits();

        if (dailyValue === undefined && weeklyValue === undefined && monthlyValue === undefined) {
            this.clearInputs();
            this.infoMessage = 'empty';
            return;
        }

        if (this.checkIsLessThanOne({ dailyValue, weeklyValue, monthlyValue })) {
            this.clearInputs();
            this.infoMessage = 'belowOne';
            return;
        }

        try {
            this.isDisableButton = true;
            await this.updateLimits();

            this.isDisableButton = false;
        } catch (error) {
            console.log(error);
            this.infoMessage = 'error';
            this.isDisableButton = false;
        }
    };
}
