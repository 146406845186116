import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const TransactionsListWrapper = withConfig(theme => styled('div', { label: 'TransactionsListWrapper' })`
    background: ${theme.star.luckyKingWallet.bgColorSecondary};
    flex: 1 1 0%;
`);

export const TransactionListFilterWrapper = withConfig(theme => styled('div', { label: 'TransactionListFilterWrapper' })`
    background: ${theme.star.luckyKingWallet.bgColorSecondary};
    flex: 0 0 56px;
    overflow-x: hidden;
    padding: 16px 0 8px 16px;
`);
