import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { Selection } from 'src/domains/sportsbook/betting/bettingTab/cashout/selection/selectionNew/SelectionNew';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    FiltersState,
    FiltersStateStrategy,
    FilterType,
} from 'src/domains/layouts/webview/components/filters/Filters.state';
import { BetsState, BetsFilter } from 'src/domains/sportsbook/betting/state/BetsState';
import { BetsFiltersWrapper, EmptyInformation, PlaceBetInfo, MyBetsSection } from './MyBets.style';
import { useAppStateContext } from 'src/appState/AppState';
import { useCommon } from 'src/domains/common/Common';
import { SdkCustomer } from 'src/domains/layouts/state/customer';

class MyBetsStrategy implements FiltersStateStrategy {
    public constructor(private readonly betsState: BetsState) {}

    public get filters(): FilterType[] {
        return this.betsState.filters;
    }

    public getActiveFilterId(): string | null {
        return this.betsState.selectedFilter;
    }

    public setActiveFilterId(id: string | number | null): void {
        if (typeof id === 'string') {
            this.betsState.changeSelectedFilter(id);
        } else {
            console.error('Selected filter can not be `null` or `number`');
        }
    }
}

export const MyBets = observer('NewMyBets', () => {
    const {
        appSportsBookState: { betsState },
        config: { casinoMiniGames: isMiniCasino, filtersVersion },
    } = useAppStateContext();
    const { betsListForView, selectedFilter } = betsState;
    const common = useCommon();
    const sdkCustomer = SdkCustomer.get(common);
    const cashoutEnabled = sdkCustomer.basicData.valueReady?.cashoutEnabled ?? false;
    const strategy = new MyBetsStrategy(betsState);
    const [filtersState] = useState(() => new FiltersState(strategy));

    let contentView;

    if (selectedFilter === BetsFilter.CASH_OUT && cashoutEnabled === false) {
        contentView = null;
    } else if (betsListForView.length === 0) {
        contentView = (
            <PlaceBetInfo key='info'>
                <EmptyInformation>
                    <I18n
                        langKey='bets.empty'
                        defaultText='Place a bet and they will appear here!'
                    />
                </EmptyInformation>
            </PlaceBetInfo>
        );
    } else {
        contentView = (
            <MyBetsSection
                key='bets'
                isMiniCasino={isMiniCasino}
            >
                {betsListForView.map((betId: number) => (
                    <Selection
                        key={betId}
                        betId={betId}
                    />
                ))}
            </MyBetsSection>
        );
    }

    return (
        <>
            <BetsFiltersWrapper
                state={filtersState}
                skin={filtersVersion.betSlipTab}
                isMultipleLine={true}
            />
            {contentView}
        </>
    );
});
