import styled from '@emotion/styled';
import { action, computed, observable, makeObservable } from 'mobx';
import React, { useState } from 'react';
import { AppState } from 'src/appState/AppState';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { ConfigKeysType, configTypesDesc } from 'src/domains/layouts/config/features/types';
import { observer } from 'src/utils/mobx-react';
import * as t from 'io-ts';
import { useCommon } from 'src/domains/common/Common';

const ConfigItem = styled('li', { label: 'ConfigItem' })`
    margin-bottom: 10px;
    padding: 5px;
    border-bottom: 1px solid gray;
`;

const ItemDesc = styled('h5', { label: 'ItemDesc' })`
    margin: 10px 0;
`;

const Label = styled('span', { label: 'Label' })<{ localStorage: boolean }>`
    color: ${({ localStorage }): string => (localStorage ? '#ffa600' : 'green')};
    margin-right: 20px;
`;

class FeaturesFlagItemState {
    @observable public value: boolean;

    public constructor(
        public readonly configComponents: ConfigComponents,
        public readonly flag: ConfigKeysType,
        public readonly localStorageState: LocalStorageState
    ) {
        makeObservable(this);
        this.value = this.isChecked;
    }

    @computed public get isChecked(): boolean {
        const isChecked = this.configComponents.config[this.flag];
        if (typeof isChecked === 'boolean') {
            return isChecked;
        }
        return false;
    }

    @computed public get flagIsBoolean(): boolean {
        if (typeof this.configComponents.config[this.flag] === 'boolean') {
            return true;
        }

        return false;
    }

    @computed public get flagDescription(): string {
        return configTypesDesc[this.flag];
    }

    @action public toggleFlag = (): void => {
        this.value = !this.value;
        this.saveInLocalStorage({ key: this.flag, value: this.value });
    };

    @action public saveInLocalStorage = ({ key, value }: { key: string; value: boolean | string }): void => {
        const configFromLocalStorage = this.localStorageState.debugPanelConfig.getValue();
        const newConfig = {
            ...configFromLocalStorage,
            [key]: value,
        };
        //@ts-expect-error
        this.localStorageState.debugPanelConfig.setValue(newConfig);
    };

    @computed public get isFromLocalStorage(): boolean {
        const configFromLocalStorage = { ...this.localStorageState.debugPanelConfig.getValue() };

        if (configFromLocalStorage[this.flag] !== undefined) {
            return true;
        }
        return false;
    }

    @action public removeFromLocalStorage = (): void => {
        const configFromLocalStorage = this.localStorageState.debugPanelConfig.getValue();
        if (configFromLocalStorage !== null && configFromLocalStorage[this.flag] !== null) {
            delete configFromLocalStorage[this.flag];
            this.localStorageState.debugPanelConfig.setValue({ ...configFromLocalStorage });
            this.value = this.isChecked;
        }
    };
}

interface FeaturesFlagItemPropsType {
    flag: ConfigKeysType;
    value: unknown;
    configComponents: ConfigComponents;
}

const FeaturesFlagItem = observer('FeaturesFlag', ({ flag, value, configComponents }: FeaturesFlagItemPropsType) => {
    const common = useCommon();
    const localStorageState = LocalStorageState.get(common);
    const [state] = useState(() => new FeaturesFlagItemState(configComponents, flag, localStorageState));

    if (typeof value === 'object') {
        return null;
    }

    const renderInput = (): JSX.Element | null => {
        if (t.boolean.is(value)) {
            return (
                <input
                    type='checkbox'
                    checked={state.isChecked}
                    onChange={state.toggleFlag}
                />
            );
        }
        if (t.string.is(value)) {
            return (
                <input
                    type='text'
                    disabled={true}
                    value={value}
                />
            );
        }

        return null;
    };

    return (
        <ConfigItem key={flag}>
            {flag}
            {renderInput()}
            <ItemDesc>{state.flagDescription}</ItemDesc>
            <div>
                <span>
                    This config is from:{' '}
                    <Label localStorage={state.isFromLocalStorage}>
                        {state.isFromLocalStorage ? 'Local Storage' : 'Default config'}
                    </Label>
                </span>
                <button onClick={state.removeFromLocalStorage}>Reset to Default</button>
            </div>
        </ConfigItem>
    );
});

interface PropsType {
    appState: AppState;
}

export const FeaturesFlagTab = observer('FeaturesFlagTab', ({ appState }: PropsType) => {
    const { appLayoutsState } = appState;
    const { configComponents } = appLayoutsState;

    return (
        <ul>
            {Object.entries(configComponents.config).map(([key, value]) => (
                <FeaturesFlagItem
                    key={key}
                    // @ts-expect-error
                    flag={key}
                    value={value}
                    configComponents={configComponents}
                />
            ))}
        </ul>
    );
});
