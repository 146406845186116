import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { Transaction } from 'src/domains/players/webview/components/Account/transactionsList/Transaction';
import { NetDeposit } from 'src/domains/players/webview/components/Account/transactionsList/NetDeposit';
import { TransactionHistoryTabState } from 'src/domains/players/webview/components/Account/transactionHistoryTab/TransactionHistoryTab.state';
import { CaptionWrapper, HeaderElem, TransactionListLoaderWrapper, BetsListWrapper, HeaderElemNetDeposit, TransactionListMessage, TransactionListEmptyMessageContainer } from './TransactionsList.style';
import { useAppStateContext } from 'src/appState/AppState';
import { Amount } from 'src_common/common/amount/Amount';
import { LayoutType } from 'src/domains/layouts/config/features/types';
import { StatusType } from 'src/domains/players/webview/components/Account/transactionHistoryTab/types';

const headerFieldsData = {
    default: [
        //eslint-disable-next-line react/jsx-key
        <HeaderElem><I18n langKey='account.transactions-list.header.transaction' defaultText='Transaction' /></HeaderElem>,
        //eslint-disable-next-line react/jsx-key
        <HeaderElem><I18n langKey='account.transactions-list.header.amount' defaultText='Amount' /></HeaderElem>,
        //eslint-disable-next-line react/jsx-key
        <HeaderElem><I18n langKey='account.transactions-list.header.balance_at_time' defaultText='Balance at time' /></HeaderElem>
    ],
    everythingFilter: [
        //eslint-disable-next-line react/jsx-key
        <HeaderElem><I18n langKey='account.transactions-list.header.transaction' defaultText='Transaction' /></HeaderElem>,
        //eslint-disable-next-line react/jsx-key
        <HeaderElem><I18n langKey='account.transactions-list.header.amount' defaultText='Amount' /></HeaderElem>,
        //eslint-disable-next-line react/jsx-key
        <HeaderElem><I18n langKey='account.transactions-list.header.balance' defaultText='Balance' /></HeaderElem>
    ],
    netDeposit: [
        //eslint-disable-next-line react/jsx-key
        <HeaderElemNetDeposit><I18n langKey='account.transactions-list.header.from' defaultText='From' /></HeaderElemNetDeposit>,
        //eslint-disable-next-line react/jsx-key
        <HeaderElemNetDeposit><I18n langKey='account.transactions-list.header.to' defaultText='To' /></HeaderElemNetDeposit>,
        //eslint-disable-next-line react/jsx-key
        <HeaderElemNetDeposit><I18n langKey='account.transactions-list.header.net_deposit' defaultText='Net Deposit' /></HeaderElemNetDeposit>
    ],
};

const displayHeaderFields = (filter: string | null): Array<JSX.Element> => {
    let data;
    if (filter === 'net-deposit') {
        data = headerFieldsData.netDeposit;
    } else if (filter === 'all') {
        data = headerFieldsData.everythingFilter;
    } else {
        data = headerFieldsData.default;
    }
    return data.map((field: JSX.Element, id: number) => <React.Fragment key={id}>{field}</React.Fragment>);
};

const getMessage = (responseStatus: StatusType, haveData: boolean,isDateFilter: boolean): React.ReactNode | null => {
    if (responseStatus === 'error') {
        return (
            <TransactionListMessage>
                <I18n langKey='account.transactions-list.error' defaultText="Sorry, because of temporary issues we can't load transactions. Try again." />
            </TransactionListMessage>
        );
    } else if (responseStatus === 'loading') {
        return (
            <TransactionListMessage>
                <TransactionListLoaderWrapper
                    size={14}
                >
                    <I18n langKey='account.transactions-list.loading' defaultText='Loading transactions...' />
                </TransactionListLoaderWrapper>
            </TransactionListMessage>
        );
    } else if (!haveData) {
        return (
            <TransactionListEmptyMessageContainer>
                <TransactionListMessage data-test='transaction-list-message'>
                    {isDateFilter ?<I18n langKey='account.transactions-list.empty2' defaultText='No transactions for the time period specified.' /> : <I18n langKey='account.transactions-list.empty1' defaultText="You don't have any transactions yet." /> }
                </TransactionListMessage>
            </TransactionListEmptyMessageContainer>
        );
    }
    return null;
};

const Header = observer('Header', (props: TransactionsListPropsTypes) => {
    const { activeFilter } = props.state.collection;

    return (
        <CaptionWrapper>
            {displayHeaderFields(activeFilter)}
        </CaptionWrapper>
    );
});

const TransactionsListNetDeposit = observer('TransactionsListNetDeposit', (props: TransactionsListPropsTypes) => {
    const { state, layout } = props;

    return (
        <BetsListWrapper>
            <Header layout={layout} state={state} />
            <NetDeposit key='NetDeposit' state={state} />
        </BetsListWrapper>
    );
});

const TransactionsListAll = observer('TransactionsListAll', (props: TransactionsListPropsTypes) => {
    const { state, layout } = props;

    return (
        <BetsListWrapper
            onTouchStart={state.handleTouchStart}
            onTouchMove={state.handleTouchMove}
            onTouchEnd={state.handleTouchEnd}
        >
            <Header layout={layout} state={state} />
            {state.collection.transactionsForView.map(transaction => <Transaction key={transaction.id} transaction={transaction} />)}
        </BetsListWrapper>
    );
});

interface TransactionsListPropsTypes {
    state: TransactionHistoryTabState;
    isDateFilter?: boolean;
    layout?: LayoutType;
}

export const TransactionsList = observer('TransactionsList', (props: TransactionsListPropsTypes) => {
    const { state,isDateFilter } = props;
    const { collection } = state;
    const activeFilter = collection.activeFilter;
    const isNetDeposit = activeFilter === 'net-deposit';
    const { config: { layout } } = useAppStateContext();

    if (isNetDeposit) {
        const noNetDeposit = collection.netDeposit?.aggregations.totalValue.isEqualWith(new Amount('0')) === false;
        const message = getMessage(collection.responseStatusNetDeposit, noNetDeposit, isDateFilter ?? false);

        if (message !== null) {
            return (
                <div>
                    {message}
                </div>
            );
        }

        return <TransactionsListNetDeposit layout={layout} state={state} />;
    }

    const message = getMessage(collection.responseStatusTransactions, collection.transactions.data.length > 0, isDateFilter ?? false);
    if (message !== null) {
        return (
            <div>
                {message}
            </div>
        );
    }

    return <TransactionsListAll layout={layout} state={state} />;
});
