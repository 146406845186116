import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { FiltersWrapper } from './AlternativeMarketFilters.style';
import { AlternativeMarketFiltersState } from './AlternativeMarketFilters.state';
// TODO: refactor this
// eslint-disable-next-line
import { useAsObservableSource } from 'mobx-react-lite';
import { Filters } from 'src/domains/layouts/webview/components/filters/Filters';
import { FiltersState } from 'src/domains/layouts/webview/components/filters/Filters.state';
import { AlternativeEventDetailsItemState } from 'src/domains/sportsbook/state/alternativeEventState/AlternativeEventDetailsItemState';
import { useAppStateContext } from 'src/appState/AppState';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

interface AlternativeMarketFilterItemPropsType {
    marketFiltersForView: Array<{ id: string; key: string; label: string }>;
    activeMarketFilter: string;
    setActiveFilter: (filter: string) => void;
    eventItem: AlternativeEventDetailsItemState;
}

const AlternativeMarketFiltersRow = observer(
    'AlternativeMarketFiltersRow',
    (propsInner: AlternativeMarketFilterItemPropsType) => {
        const props = useAsObservableSource(propsInner);
        const [state] = React.useState(() => new FiltersState(new AlternativeMarketFiltersState(props)));

        return (
            <Filters
                state={state}
                isMultipleLine={true}
            />
        );
    }
);

interface PropsType {
    eventId: EventId | null;
}

export const AlternativeMarketFilters = observer('AlternativeMarketFilters', (props: PropsType) => {
    const {
        appSportsBookState: { alternativeEventState },
    } = useAppStateContext();
    const { getAlternativeEventItemById, eventId } = alternativeEventState.get(props.eventId);

    if (eventId === null) {
        return null;
    }

    const eventItem = getAlternativeEventItemById(eventId);
    const { marketFiltersForView, setActiveFilter, activeMarketFilter } = eventItem;

    if (marketFiltersForView.length > 0) {
        return (
            <FiltersWrapper>
                <AlternativeMarketFiltersRow
                    marketFiltersForView={marketFiltersForView}
                    activeMarketFilter={activeMarketFilter}
                    setActiveFilter={setActiveFilter}
                    eventItem={eventItem}
                />
            </FiltersWrapper>
        );
    }

    return null;
});
