import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    LinkItemLinkType,
    NavigationPanel,
} from 'src/domains/layouts/webview/components/navigationPanel/NavigationPanel';
import { NavigationSidebarSectionLuckyKing } from './NavigationSidebarLuckyKing';

const NavigationSidebarSectionAZFavourites = observer(
    'NavigationSidebarSectionAZFavourites',
    (): React.ReactElement => {
        const { appLayoutsState } = useAppStateContext();
        const { navigationFavourites } = appLayoutsState;

        const favouritesListView = navigationFavourites.favouritesListView.map(
            (item): LinkItemLinkType => ({
                ...item,
                iconType: 'StarFill',
                iconClick: (): void => {
                    navigationFavourites.setFavourites(item.to.id, false);
                },
            })
        );

        const restListView = navigationFavourites.restListView.map(
            (item): LinkItemLinkType => ({
                ...item,
                iconType: 'StarOutline',
                iconClick: (): void => {
                    navigationFavourites.setFavourites(item.to.id, true);
                },
            })
        );

        return (
            <>
                <NavigationPanel
                    title={
                        <I18n
                            langKey='left-menu.sports-az.favourites-active'
                            defaultText='Your Favourites'
                        />
                    }
                    titleDataTest='your-favourites'
                    links={favouritesListView}
                    typeTag='sport'
                />
                <NavigationPanel
                    title={
                        <I18n
                            langKey='left-menu.sports-az.favourites-rest'
                            defaultText='A-Z Betting'
                        />
                    }
                    titleDataTest='a-z-betting'
                    links={restListView}
                    typeTag='sport'
                />
            </>
        );
    }
);

const NavigationSidebarSectionAZDefault = observer('NavigationSidebarSectionAZDefault', (): React.ReactElement => {
    const { appLayoutsState } = useAppStateContext();
    const { sportsList } = appLayoutsState;
    const { sportsLinks } = sportsList;

    return (
        <NavigationPanel
            title={
                <I18n
                    langKey='left-menu.sports-az-betting.title'
                    defaultText='A-Z Betting'
                />
            }
            titleDataTest='a-z-betting'
            links={sportsLinks}
            typeTag='sport'
        />
    );
});

export const NavigationSidebarSectionAZ = observer('NavigationSidebarSectionAZ', (): React.ReactElement => {
    const { appLayoutsState } = useAppStateContext();
    const { configComponents, session } = appLayoutsState;

    if (configComponents.config.layout === 'secondary') {
        return <NavigationSidebarSectionLuckyKing />;
    }

    if (configComponents.config.navigationFavourites && session.isAuthorized) {
        return <NavigationSidebarSectionAZFavourites />;
    }

    return <NavigationSidebarSectionAZDefault />;
});
