import { StarRouter } from './router/StarRouter';
import * as React from 'react';
import { makeAutoObservable } from 'mobx';
import { css } from '@emotion/react';
import { RhsTabs } from 'src/domains/players/webview/components/Account/rhsMenu/RhsTabs';
import { ModalState } from 'src/domains/layouts/webview/components/modals/Modal.state';
import { modalRhsMenu } from 'src/domains/layouts/webview/components/modals/Layer.style';
import { EmotionTheme } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { BannersBoxState } from 'src/domains/layouts/state/bannersBoxState/BannersBoxState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

export class AccountDrawerNew {
    public constructor(
        private bannersBoxState: BannersBoxState,
        private config: ConfigComponents,
        private modalState: ModalState,
        private starRouter: StarRouter,
        private theme: EmotionTheme
    ) {
        makeAutoObservable(this);
        this.initOnChangeAccountView();
    }

    private initOnChangeAccountView(): void {
        this.starRouter.onChangeAccountView(async (_prevView, nextView) => {
            if (nextView === null) {
                return;
            }

            const account = nextView.account;

            if (account === null) {
                return;
            }

            this.modalState.show({
                layerCss: () => modalRhsMenu(this.bannersBoxState, this.config, this.theme),
                contentCss: css``,
                animation: 'right',
                content: <RhsTabs tab={nextView} />,
                overlayOnClick: 'closeAll',
            });
        });
    }
}
