import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    SignupPopupElement,
    SignupPopupHeader,
    PopupParagraph,
    GotItButtonWrapper,
    CancelButton,
    SignupPopupWrapper,
} from './SignupPopup.style';
import { PopupAgreeOrCancel, SignupPopupState } from 'src/domains/players/state/SignupPopupState';
import { useAppStateContext } from 'src/appState/AppState';
import { Overlay } from 'src/domains/layouts/state/overlay/Overlay';

class AgreeOrCancelPopUpState {
    public constructor(
        public readonly signupPopupState: SignupPopupState,
        public readonly popup: PopupAgreeOrCancel,
        public readonly overlay: Overlay
    ) {}

    public handleCancelButtonClick = (): void => {
        this.signupPopupState.hide();
        if (this.popup.cancelAction !== undefined) {
            this.popup.cancelAction();
        }
    };

    public agreeAction = (): void => {
        this.popup.agreeAction();
        this.handleCancelButtonClick();
    };
}

interface AgreeOrCancelPropsType {
    popup: PopupAgreeOrCancel;
}

export const AgreeOrCancelPopup = observer('AgreeOrCancelPopup', (props: AgreeOrCancelPropsType): JSX.Element => {
    const {
        appLayoutsState: { overlay },
        appPlayersState: { signupPopupState },
    } = useAppStateContext();
    const { popup } = props;
    const [state] = useState(() => new AgreeOrCancelPopUpState(signupPopupState, popup, overlay));

    return (
        <SignupPopupWrapper>
            <SignupPopupElement>
                <SignupPopupHeader data-test='popup-header'>{popup.title}</SignupPopupHeader>
                <PopupParagraph data-test='popup-paragraph'>{popup.content}</PopupParagraph>
                <GotItButtonWrapper
                    type='submit'
                    size='medium'
                    onClick={state.agreeAction}
                    dataTest='agree-continue-button'
                >
                    <I18n
                        langKey='signup-journey.agree-cancel-popup.agree-btn'
                        defaultText='Agree &amp; Continue'
                    />
                </GotItButtonWrapper>
                <CancelButton
                    onClick={state.handleCancelButtonClick}
                    data-test='cancel-button'
                >
                    <I18n
                        langKey='signup-journey.agree-cancel-popup.cancel-btn'
                        defaultText='Cancel'
                    />
                </CancelButton>
            </SignupPopupElement>
        </SignupPopupWrapper>
    );
});
