import React from 'react';
import { AccountTabContentWrapper } from './AccountTab.style';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';

interface AccountTabType {
    children: React.ReactNode;
}

export const AccountTab = observer('AccountTab', (props: AccountTabType) => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { accountState } = appPlayersState;
    const { configComponents: { config } } = appLayoutsState;
    const { children } = props;

    return (
        <AccountTabContentWrapper ref={accountState.setRef} layout={config.layout}>
            {children}
        </AccountTabContentWrapper>
    );
});

