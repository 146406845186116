import styled from '@emotion/styled';
import { LayoutType } from 'src/domains/layouts/config/features/types';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';

interface ButtonElementType {
    layout: LayoutType;
};

const styleAutoFillInput = (layout: LayoutType): string | undefined => {

    if (layout === 'secondary'){
        return `
            border-radius: 4px;
        `;
    }
};

export const ButtonsContainer = styled('div', { label: 'ButtonsContainer' })`
    display: flex;
    margin-top: 8px;
`;

export const ButtonWrapper = styled(Button, { label: 'ButtonWrapper' })<ButtonElementType>`
    flex: 0 1 25%;
    &:not(:last-of-type) {
        margin-right: 4px !important;
    }
    ${({ layout }): string | undefined => styleAutoFillInput(layout)}
`;
