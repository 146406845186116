import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    LuckyKingWalletWrapper,
    WithdrawButton,
    DepositButton,
    ContentWrapper,
    ContentGridWrapper,
    AmountWrapper,
    HelpIconWrapper,
} from 'src/domains/players/webview/components/LuckyKingWallet/luckyKingWalletContent/LuckyKingWalletContent.style';

export const LuckyKingWalletContent = observer('LuckyKingWalletContent', () => {
    const {
        appPlayersState: { luckyKingWalletState },
    } = useAppStateContext();

    return (
        <LuckyKingWalletWrapper>
            <ContentGridWrapper>
                <I18n langKey='lucky-king.wallet.cash-balance' defaultText='Cash balance' />
                <AmountWrapper>{luckyKingWalletState.showBalance}</AmountWrapper>
                <I18n langKey='lucky-king.wallet.withdrawable-balance' defaultText='Withdrawable balance' />
                <AmountWrapper>{luckyKingWalletState.withdrawableBalance}</AmountWrapper>
                <div>
                    <I18n langKey='lucky-king.wallet.free-bet-credits' defaultText='Free bet credits' />
                    <HelpIconWrapper />
                </div>
                <AmountWrapper>{luckyKingWalletState.freeBetsAmount}</AmountWrapper>
            </ContentGridWrapper>
            <ContentWrapper>
                <DepositButton size='medium' onClick={luckyKingWalletState.onDepositClicked}>
                    <I18n langKey='lucky-king.wallet.copy-label' defaultText='Deposit' />
                </DepositButton>
                <WithdrawButton size='medium' version='secondary' onClick={luckyKingWalletState.onWithdrawClicked} dataTest='lucky-king-wallet-withdraw-button'>
                    <I18n langKey='lucky-king.wallet.copy-label-withdraw' defaultText='Withdraw' />
                </WithdrawButton>
            </ContentWrapper>
        </LuckyKingWalletWrapper>
    );
});
