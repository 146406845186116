import * as React from 'react';
import { computed, makeObservable } from 'mobx';
import { EnvironmentState } from 'src/domains/layouts/state/environmentState/EnvironmentState';

import { getUniverseFont } from './font-meta';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { renderTitle } from './renderTitle';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { Common } from 'src/domains/common/Common';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';

interface SingleOGTagPropertyType {
    property: string;
    content: string;
}

interface SingleOGTagNameType {
    name: string;
    content: string;
}

export type MetaOpenGraphTagsType = SingleOGTagPropertyType | SingleOGTagNameType;

export class HeaderMeta {
    private readonly router: StarRouter;
    public readonly env: EnvironmentState;
    private readonly configComponents: ConfigComponents;

    public static get = AutoWeakMap.create((common: Common) => new HeaderMeta(common));

    private constructor(private readonly common: Common) {
        makeObservable(this);

        this.router = StarRouter.get(common);
        this.env = EnvironmentState.get(common);
        this.configComponents = ConfigComponents.get(common);
    }

    @computed.struct private get metaScale(): React.ReactElement {
        const content = this.configComponents.config.headerMetaScale;
        return (
            <meta
                name='viewport'
                content={content}
            />
        );
    }

    @computed.struct private get metaFacebookDomainVerification(): React.ReactElement | null {
        const content = this.configComponents.config.headerMetaFacebookDomainVerification;

        if (content !== null) {
            return (
                <meta
                    name='facebook-domain-verification'
                    content={content}
                />
            );
        }

        return null;
    }

    @computed.struct private get favicon(): React.ReactElement {
        const faviconSrc = this.common.faviconUrl;

        return (
            <link
                rel='shortcut icon'
                href={faviconSrc}
                type='image/png'
            />
        );
    }

    private getContent(content: string): string {
        const websiteBaseUrl = this.env.websiteBaseUrl;

        if (content.charAt(0) === '/') {
            return `${websiteBaseUrl}${content}`;
        } else {
            return content;
        }
    }

    @computed.struct private get metaOpenGraph(): Array<React.ReactElement> | null {
        const metaOpenGraphTags = this.configComponents.config.metaOpenGraph;

        if (metaOpenGraphTags !== null) {
            return metaOpenGraphTags.map((singleTag: MetaOpenGraphTagsType) => {
                if ('name' in singleTag) {
                    return (
                        <meta
                            content={this.getContent(singleTag.content)}
                            key={singleTag.name}
                            property={singleTag.name}
                        />
                    );
                } else {
                    return (
                        <meta
                            content={this.getContent(singleTag.content)}
                            key={singleTag.property}
                            property={singleTag.property}
                        />
                    );
                }
            });
        }
        return null;
    }

    @computed.struct private get siteVerification(): React.ReactElement | null {
        const data = this.configComponents.config.siteVerificationMetaMap;

        if (data !== null) {
            return (
                <meta
                    name={data.name}
                    content={data.content}
                />
            );
        }

        return null;
    }

    @computed.struct private get styleMainFont(): React.ReactElement {
        return (
            <style
                type='text/css'
                dangerouslySetInnerHTML={{
                    __html: getUniverseFont(),
                }}
            />
        );
    }

    @computed.struct public get metaTitle(): Array<React.ReactElement> {
        return renderTitle(this.router, this.configComponents);
    }

    @computed.struct public get metaList(): Array<React.ReactElement> {
        const out: Array<React.ReactElement> = [];
        out.push(this.metaScale);
        out.push(this.favicon);

        if (this.metaOpenGraph !== null) {
            out.push(...this.metaOpenGraph);
        }

        const siteVerification = this.siteVerification;

        if (siteVerification !== null) {
            out.push(siteVerification);
        }

        out.push(this.styleMainFont);

        const metaFacebookVerification = this.metaFacebookDomainVerification;

        if (metaFacebookVerification !== null) {
            out.push(metaFacebookVerification);
        }

        return out;
    }

    public get metaToStaticJsx(): React.ReactElement {
        const newList = [];

        newList.push('\n');
        newList.push('\n');

        for (const item of this.metaList) {
            newList.push('\n');
            newList.push(item);
        }

        newList.push('\n');
        newList.push('\n');

        return React.createElement(React.Fragment, {}, ...newList);
    }
}
