import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { LayoutSignUp } from 'src/domains/players/webview/components/SignUp/signupTertiary/SignupLayout';

import { SignUpWelcomeState } from 'src/domains/players/webview/components/SignUp/signupTertiary/signupWelcome/SignupWelcome.state';
import { LoginBtnWrapper } from 'src/domains/players/webview/components/SignUp/signupTertiary/SignUp.style';
import { PageSectionWrapper } from 'src/domains/players/webview/components/SignUp/signupParts/Signup.style';
import { AlreadyHaveAccount } from 'src/domains/players/webview/components/SignUp/signupParts/alreadyHaveAccount/AlreadyHaveAccount';
import { SignupType } from 'src/domains/players/webview/components/SignUp';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface SignUpWelcomePagePropsType {
    state: SignUpWelcomeState;
    signUpState: SignupType;
}

export const SignUpWelcomePage = observer('SignUpWelcomePage', ({ state, signUpState }: SignUpWelcomePagePropsType) => {
    if (signUpState.type === 'SignupPrimary') {
        return <div>wrong signup</div>;
    }

    return (
        <LayoutSignUp
            footer={true}
            signUpState={signUpState.state}
        >
            <PageSectionWrapper>
                <Input
                    label={
                        <I18n
                            langKey='signup.tertiary.cpf'
                            defaultText='CPF Number'
                        />
                    }
                    state={state.cpfState}
                    showSuccessIcon={state.isWelcomeStepFormModelOk}
                    errorMarginBottom='0'
                    dataTest='cpf-input'
                />
                {state.backendCreateAccountError === null ? null : (
                    <Messages
                        type='error'
                        marginBottom='16px'
                        message={state.backendCreateAccountError}
                    />
                )}
                <LoginBtnWrapper
                    disabled={!state.stepIsValid}
                    type='submit'
                    size='large'
                    onClick={state.onMoveToYourDetails}
                    dataTest='create-account-button'
                >
                    <I18n
                        langKey='signup.tertiary.welcome.create-account'
                        defaultText='Next'
                    />
                </LoginBtnWrapper>

                <AlreadyHaveAccount />
            </PageSectionWrapper>
        </LayoutSignUp>
    );
});
