import { makeObservable, observable } from 'mobx';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { Response200Type } from 'src/api_openapi/generated/openapi_website_cms_getSeoPageContent';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { Common } from 'src/domains/common/Common';

interface SeoPagesContentType {
    id: number;
    universe: string;
    page_slug: string;
    title?: null | undefined | string;
    short_description?: null | undefined | string;
    full_description?: null | undefined | string;
    meta_title?: null | undefined | string;
    meta_description?: null | undefined | string;
    is_active: boolean;
}

export class SeoPagesContentState {
    @observable public isShowMore: boolean = false;
    private seoPageResource: Resource<Response200Type>;

    public constructor(
        private readonly common: Common,
        private readonly languagesState: LanguagesState,
        private readonly slug: string
    ) {
        makeObservable(this);
        this.seoPageResource = new Resource(async (): Promise<Response200Type> => {
            const response = await this.common.trpcClient.client.cms.getSeoPage.query({
                page_slug: this.slug,
                lang: this.languagesState.userLang ?? 'en',
            });
            return response;
        });
    }

    public static get = AutoWeakMap.create((common: Common, slug: string) =>
        SeoPagesContentState.fromCommon(common, slug)
    );

    public static fromCommon(common: Common, slug: string): SeoPagesContentState {
        const languagesState = LanguagesState.get(common);

        return new SeoPagesContentState(common, languagesState, slug);
    }

    public toggleShowMore = (): void => {
        this.isShowMore = !this.isShowMore;
    };

    public get data(): SeoPagesContentType | undefined {
        const data = this.seoPageResource.get();

        if (data.type === 'ready') {
            return data.value[0];
        }

        return;
    }
}
