import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { ShowMoreWrapper, MarketGroupSection, GoToChevronIcon } from './HomepageHeroWidget.style';
import { SelectionGroup } from 'src/domains/sportsbook/shared/Components';
import { HeroWidgetModelType } from 'src/api/config/cms_new/hero_widget/get_hero_widget_active';
import { HeroWidgetItemState } from './HeroWidgetItem.state';
import { HeroWidgetBanner } from './HeroWidgetBanner';
import { useAppStateContext } from 'src/appState/AppState';
import { HeroWidgetMainBanner } from './HeroWidgetMainBanner/HeroWidgetMainBanner';

interface HomepageHeroWidgetDefaultPropsType {
    heroWidgetItem: HeroWidgetModelType;
}

export const HomepageHeroWidgetDefault = observer(
    'HomepageHeroWidgetDefault',
    ({ heroWidgetItem }: HomepageHeroWidgetDefaultPropsType): JSX.Element | null => {
        const { appLayoutsState } = useAppStateContext();
        const { starRouter } = appLayoutsState;
        const [heroWidgetItemState] = useState(() => new HeroWidgetItemState(starRouter, heroWidgetItem));
        const {
            headerImages,
            promoImages,
            marketModel,
            eventModel,
            filteredAndSortByCriteriaSelections,
            isMarketNotEmpty,
            handleRedirectTEventPage,
        } = heroWidgetItemState;
        const { is_active_promo, title, subtitle, id, tc_text, tc_title } = heroWidgetItem;

        if (marketModel !== null && eventModel !== null) {
            return (
                <React.Fragment key={marketModel.id}>
                    <>
                        <HeroWidgetMainBanner
                            images={headerImages}
                            title={title}
                            subtitle={subtitle}
                        />
                        {isMarketNotEmpty ? (
                            <MarketGroupSection>
                                <SelectionGroup
                                    isHomepage={true}
                                    eventId={marketModel.eventId2}
                                    marketIdMain={marketModel.id2}
                                    selections={filteredAndSortByCriteriaSelections}
                                />
                            </MarketGroupSection>
                        ) : null}
                    </>
                    <ShowMoreWrapper
                        onClick={handleRedirectTEventPage}
                        data-test='show-more'
                    >
                        <I18n
                            langKey='events.goto.event'
                            defaultText='Go to event'
                        />
                        <GoToChevronIcon position='right' />
                    </ShowMoreWrapper>

                    {is_active_promo ? (
                        <HeroWidgetBanner
                            withMargin={false}
                            banners={promoImages}
                            slug={`${id}-hero-promo`}
                            content={tc_text ?? ''}
                            title={tc_title ?? ''}
                        />
                    ) : null}
                </React.Fragment>
            );
        }

        return null;
    }
);
