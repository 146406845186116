import { MethodType, GenerateUrlApiType, ResponseType, ParamsFetchType } from 'src_common/browser/apiUtils';
import { GenerateUrlApiParamsType } from 'src_common/server/webDriver/sdkApiWebUtils';
import {
    openapi_website_cms_getActiveHomePageCarouselV2,
    decodeResponse200,
    Response200Type,
} from 'src/api_openapi/generated/openapi_website_cms_getActiveHomePageCarouselV2';
import { CarouselModeType } from 'src/domains/layouts/state/cmsState/homePageCarouselState/HomePageCarouselState';

interface ParamsType {
    carousel_type: CarouselModeType;
    lang: string;
}

export const getActiveHomePageCarouselV2 = {
    browser: {
        params: (params: ParamsType): ParamsFetchType<ParamsType> => {
            return {
                type: MethodType.GET,
                url: `/api-web/cms/active-home-page-carousel-v2/${params.lang}/${params.carousel_type}`,
            };
        },
        decode: (status: number, data: ResponseType): Response200Type => {
            if (status === 200 && data.type === 'json') {
                return decodeResponse200(data.json);
            }

            throw new Error(`unhandled response ${status} - ${data.type}`);
        },
    },
    express: {
        method: MethodType.GET,
        urlBrowser: '/api-web/cms/active-home-page-carousel-v2/:lang/:carousel_type',
    },
    generateUrlApi: async (params: GenerateUrlApiParamsType<ParamsType>): Promise<GenerateUrlApiType> => {
        const resp = await openapi_website_cms_getActiveHomePageCarouselV2(
            params.API_URL,
            params.API_TIMEOUT,
            params.jwtToken,
            {
                universe: params.API_UNIVERSE,
                carousel_type: params.req.params.carousel_type,
                translation: params.req.params.lang,
            }
        );

        return {
            passToBackend: false,
            status: resp.status,
            responseBody: resp.body,
        };
    },
};
