import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import {
    Author,
    MessageAuthor,
    MessageItem,
} from 'src/domains/players/webview/components/Account/traderChat/components/Message.style';
import { DateTime } from 'src_common/utils/time/time';
import { ChatMessageType } from 'src_server/trpc/types/chat';
import {
    TraderChatMessageModel,
    TraderChatMessageModelId,
} from 'src/domains/players/webview/components/Account/traderChat/TraderChatMessageModel';

interface MessagePropsType {
    traderChatMessageModel: TraderChatMessageModel;
    message: ChatMessageType;
}

export const Message = observer<MessagePropsType>('Message', ({ traderChatMessageModel, message }) => {
    if (message.type !== 'standard') {
        return null;
    }

    const messageSenderType = message.sender.type;
    const customerMessage = message.sender.type === 'customer';
    const staffMessage = message.sender.type === 'staff';
    const existTime = message.sentAt;
    const time = DateTime.from(message.sentAt)?.format('DD/MM/YYYY HH:mm');
    const author = customerMessage ? (
        <I18n
            langKey='account.trader-chat.sender.you'
            defaultText='You'
        />
    ) : staffMessage ? (
        <I18n
            langKey='account.trader-chat.staff.you'
            defaultText='Support'
        />
    ) : (
        message.sender.name
    );
    const content = message.content?.text;
    const id = TraderChatMessageModelId.forMessage(message.id);

    return (
        <MessageItem
            ref={(element): void => traderChatMessageModel.setRef(id, element)}
            senderType={messageSenderType}
            data-test='message-item'
        >
            {content}
            <MessageAuthor senderType={messageSenderType}>
                <Author senderType={messageSenderType}>{author}</Author>
                {existTime === '' ? null : time}
            </MessageAuthor>
        </MessageItem>
    );
});
