import React from 'react';
import { CheckIcon, PrimaryDescription, Title, Wrapper, AccountHelperContainer, LinkButton } from 'src/domains/players/webview/components/Account/forgotPasswordTab/resetPasswordSent/ResetPasswordSent.style';
import { AccountHelperInfo } from 'src/domains/players/webview/components/Account/component/accountHelperInfo/AccountHelperInfo';
import { AlignBottomWrapper } from 'src/domains/players/webview/components/Account/forgotPasswordTab/ForgotPasswordTab.style';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';


export const ResetPasswordSuccessfullyChange = observer('ResetPasswordSuccessfullyChange', () => {
    const { appLayoutsState: { starRouter } } = useAppStateContext();

    return (
        <AccountHelperContainer>
            <Wrapper>
                <CheckIcon />
                <Title data-test='reset-password-successfully-change-title'>
                    <I18n langKey='account.reset-password-successfully-change.title' defaultText='Your Password has been Reset Successful' />
                </Title>
                <PrimaryDescription data-test='reset-password-successfully-change-description'>
                    <I18n langKey='account.reset-password-successfully-change-desc' defaultText='You can now log in using your new password' />
                </PrimaryDescription>
                <LinkButton onClick={starRouter.redirectToLogin}
                    size='large'
                    dataTest='back-to-login-button'>
                    <I18n langKey='account.reset-password-successfully-change.login-link' defaultText='Back to Login' />
                </LinkButton>
                <LinkButton onClick={starRouter.closeAccount}
                    version='secondary-light'
                    size='large'
                    dataTest='return-home-button'
                >
                    <I18n langKey='account.reset-password-successfully-change.resend-link' defaultText='Return Home' />
                </LinkButton>
            </Wrapper>
            <AlignBottomWrapper>
                <AccountHelperInfo />
            </AlignBottomWrapper>
        </AccountHelperContainer>
    );
});
