import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { SliderState } from './Slider.state';
import { SliderHeader } from './slider-header/SliderHeader';
import { ButtonChevronIcon } from 'src/domains/layouts/shared/ScrollButtons.style';
import { ScrollLeft, SliderWrapper, Swiper, ScrollRight } from './Slider.style';
import { convertTitleToDataTest } from 'src/domains/casino/utils/utils';
interface SliderPropsType {
    appCasinoState: AppCasinoState,
    children: JSX.Element,
    title?: string | JSX.Element,
    withoutViewAll?: boolean,
    onViewAllClick?: () => void,
}

export const Slider = observer('Slider', (props: SliderPropsType) => {
    const { appCasinoState, children, title, withoutViewAll, onViewAllClick } = props;
    const { breakpointsState, features } = appCasinoState;
    const [state] = React.useState(() => new SliderState());
    const { scrollLeftArrow, scrollRightArrow, goToLeft, goToRight, setRef } = state;
    const isDesktop = breakpointsState.tablet.isBiggerOrEq;

    const goLeftAvailable = scrollLeftArrow === true && isDesktop === true;
    const goRightAvailable = scrollRightArrow === true && isDesktop === true;

    const dataTest = convertTitleToDataTest(title ?? 'default');

    return (
        <section data-test={`slider-${dataTest}`}>
            {title !== undefined && (
                <SliderHeader
                    appCasinoState={appCasinoState}
                    title={title}
                    onViewAllClick={onViewAllClick}
                    withoutViewAll={withoutViewAll}
                />
            )}
            <SliderWrapper key='slider-wrapper'>
                {goLeftAvailable &&
                    <ScrollLeft layout={features.config.layout} isVisible={goLeftAvailable} onClick={goToLeft}>
                        <ButtonChevronIcon position='left' />
                    </ScrollLeft>
                }
                <Swiper key='slider-swiper' ref={setRef}>
                    {children}
                </Swiper>
                {goRightAvailable &&
                    <ScrollRight layout={features.config.layout} isVisible={goRightAvailable} onClick={goToRight}>
                        <ButtonChevronIcon position='right' />
                    </ScrollRight>
                }
            </SliderWrapper>
        </section>
    );
});
