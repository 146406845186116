import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { RealexJourney } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/RealexJourney';
import { SwiftyGlobalJourney } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/swiftyGlobalTempIframe/SwiftyGlobalJourney';
import { InfoPage } from 'src/domains/players/webview/components/WithdrawAndDeposit/components/temporaryInfo/InfoPage';
import { ZenetPayJourney } from './zenetPayProvider/ZenetPayJourney';
import { PraxisJourney } from './praxisProvider/PraxisJourney';
import { PaysafeJourney } from './paysafeProvider/PaysafeJourney';
import { OperatorTurnOffDeposits } from './topUpProcedureParts/depositFailure/OperatorTurnOffDeposits';

export const DepositProcedureProvider = observer('DepositProcedureProvider', (): JSX.Element => {
    const {
        config: {
            usePaymentFormForUniverse,
            temporaryDepositPage,
            accountHelperMail,
            depositsTurnOffForLeavingOperator,
        },
    } = useAppStateContext();

    if (depositsTurnOffForLeavingOperator.enabled) {
        return (
            <OperatorTurnOffDeposits
                operatorName={depositsTurnOffForLeavingOperator.operatorName}
                isSignup={false}
                date={depositsTurnOffForLeavingOperator.date}
            />
        );
    }

    if (temporaryDepositPage) {
        return (
            <InfoPage
                accountHelperMail={accountHelperMail}
                infoPageType='deposit'
            />
        );
    }

    switch (usePaymentFormForUniverse.type) {
        case 'zenetPay':
            return <ZenetPayJourney />;
        case 'realex':
            return <RealexJourney isSignup={false} />;
        case 'swiftyGlobalProvider':
            return <SwiftyGlobalJourney isSignup={false} />;
        case 'praxis':
            return <PraxisJourney isSignup={false} />;
        case 'paysafe':
            return (
                <PaysafeJourney
                    isSignup={false}
                    paysafePaymentProvider={usePaymentFormForUniverse}
                />
            );
    }
});
