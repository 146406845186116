import { computed, makeObservable } from 'mobx';
import { OverlayLuckyKing } from 'src/domains/layouts/state/overlay/OverlayLuckyKing';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { Session } from 'src_common/sdk/session';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { PopupState } from 'src/domains/layouts/state/popupState/PopupState';

interface CallbacksType {
    getSearchStateIsShow: () => boolean;
}

export class IsStaticLuckyKingState {
    public constructor(
        private readonly router: StarRouter,
        private readonly overlayLuckyKing: OverlayLuckyKing,
        private readonly session: Session,
        private readonly popupState: PopupState,
        private readonly callbacks: CallbacksType
    ) {
        makeObservable(this);
    }

    @computed public get isStatic(): boolean {
        const accountView = this.router.accountView;
        const innerWidth = getWindowInnerWidth() ?? 0;

        if (this.popupState.popups?.type === 'gameModal') {
            return true;
        }

        if (accountView !== null) {
            if (this.session.isAuthorized) {
                if (accountView.account === 'login') {
                    return false;
                }
            }

            if (accountView.account === 'signup') {
                return true;
            }

            if (accountView.account === 'finish-kyc') {
                return true;
            }

            if (accountView.account === 'game-info') {
                return false;
            }

            if (accountView.account === 'top-up') {
                return false;
            }

            if (accountView.account === 'static-resource-headline-promo-terms') {
                return false;
            }

            if (accountView.account === 'terms-and-conditions-casino-banner') {
                return false;
            }

            // TO DO ADD MENU OPEN STATE IF STILL LUCKYKING IS NEEDED
            // if (innerWidth >= 1440 && this.router.accountView?.account === 'navigation') {
            //     return false;
            // }
        }

        if (this.overlayLuckyKing.shouldOverlayOpen) {
            return true;
        }

        if (innerWidth >= 1200 && this.callbacks.getSearchStateIsShow()) {
            return true;
        }

        return false;
    }
}
