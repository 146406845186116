import React, { useState } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import {
    RowWrapper,
    SelectionList,
    SelectionTemplateRowCotainer,
} from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeMainContent/AlternativeMainContent.style';
import { ExpandButton } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeMainContent/selectionGroup/expandButton/ExpandButton';
// TODO: refactor this
// eslint-disable-next-line
import { useAsObservableSource } from 'mobx-react-lite';
import classNames from 'classnames';
import { SelectionTemplateRowState } from './SelectionTemplateRow.state';
import { AlternativeSelection } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeMainContent/selectionGroup/alternativeSelection/AlternativeSelection';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';

interface SelectionRowTemplateType {
    selection: SelectionModel;
    eventId: EventId;
    marketId: MarketId;
    isExpanded: boolean;
    itemsIn: Array<SelectionModel>;
    biggestGroup: number;
    toggleExpand: (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export const SelectionRowTemplate = observer('SelectionRowTemplate', (props: SelectionRowTemplateType) => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { alternativeEventState } = appSportsBookState;
    const { breakpointsState } = appLayoutsState;

    const componentProps = useAsObservableSource(props);
    const { eventId, marketId, isExpanded, biggestGroup, toggleExpand } = componentProps;
    const [state] = useState(() => new SelectionTemplateRowState(componentProps, breakpointsState));

    const { eventModel, order, displayTemplate, columnsCount, limitRowToDisplay, divider, selectionsToRender } = state;

    const selectionsDividedByColumns = alternativeEventState.get(eventId).divideByColumns(divider, selectionsToRender);

    if (eventModel !== null) {
        return (
            <SelectionTemplateRowCotainer>
                <SelectionList
                    displayTemplate={displayTemplate}
                    className={classNames(`test-${order}`)}
                >
                    {selectionsDividedByColumns.map(
                        (selectionDividedByColumn, id) =>
                            selectionDividedByColumn.length > 0 && (
                                <RowWrapper key={id}>
                                    {selectionDividedByColumn.map((selection: SelectionModel) => {
                                        return (
                                            <AlternativeSelection
                                                key={selection.id}
                                                eventId={eventId}
                                                marketId={marketId}
                                                selectionId={selection.id2}
                                                displayTemplate={displayTemplate}
                                            />
                                        );
                                    })}
                                </RowWrapper>
                            )
                    )}
                </SelectionList>
                {biggestGroup / columnsCount > limitRowToDisplay ? (
                    <ExpandButton
                        isExpanded={isExpanded}
                        toggleExpand={toggleExpand}
                    />
                ) : null}
            </SelectionTemplateRowCotainer>
        );
    }

    return null;
});
