import { Button } from 'src/domains/layouts/webview/components/common/button/Button';

import styled from '@emotion/styled';



export const WizardWrapper = styled('div', { label: 'WizardWrapper' })`

    padding: 0 16px;

`;



export const WizardForm = styled('form', { label: 'WizardForm' })``;



export const WizardFormWrapper = styled('fieldset', { label: 'WizardFormWrapper' })`

    border: 0;

    margin: 0;

    padding: 0;

`;



export const AgreeButtonWrapper = styled(Button, { label: 'AgreeButtonWrapper' })`

    margin: 16px 0 16px 0;

    width: 100%;

`;

