import { action, computed, observable, makeObservable } from 'mobx';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';
import { StreamingModel } from './StreamingModel';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { Common } from 'src/domains/common/Common';

export interface StreamViewDetailsType {
    isFloating: boolean;
    boxHeight: number;
}

export class StreamingState {
    public iframeRef: HtmlElementReactive<HTMLElement>;
    public streamWrapperRef: HtmlElementReactive<HTMLElement>;

    private readonly data: MobxMapAutoNew<number, StreamingModel>;

    @observable public openEventId: number | null = null;
    @observable private isStreamFloatingInner: boolean = false;

    public static get = AutoWeakMap.create((common: Common) => new StreamingState(common));

    public constructor(common: Common) {
        makeObservable(this);

        this.iframeRef = new HtmlElementReactive(300);
        this.streamWrapperRef = new HtmlElementReactive(300);

        this.data = new MobxMapAutoNew(
            (eventId: number): StreamingModel => new StreamingModel(eventId, common.models, common.trpcClient)
        );
    }

    @computed public get streamViewDetails(): StreamViewDetailsType {
        return {
            isFloating: this.isStreamFloating,
            boxHeight: this.streamBoxHeight,
        };
    }

    public getModel(eventId: number): StreamingModel {
        return this.data.get(eventId);
    }

    @computed public get isStreamFloating(): boolean {
        return this.isStreamFloatingInner;
    }

    @action public closeStream = (): void => {
        this.isStreamFloatingInner = false;
        this.openEventId = null;
    };

    @action public switchFloatingStream = (): void => {
        this.isStreamFloatingInner = !this.isStreamFloatingInner;
    };

    @action public openStream = (eventId: number): void => {
        this.openEventId = eventId;
    };

    @action public setIframeRef = (ref: HTMLElement | null): void => {
        this.iframeRef.setRef(ref);
    };

    @action public setStreamingWrapperRef = (ref: HTMLElement | null): void => {
        this.streamWrapperRef.setRef(ref);
    };

    @computed public get streamBoxHeight(): number {
        const element: HTMLElement | null = this.iframeRef.ref;
        return element?.clientHeight ?? 0;
    }

    @computed public get boxWidth(): number {
        const element: HTMLElement | null = this.iframeRef.ref;
        return element?.clientWidth ?? 0;
    }

    @computed public get boxWidthWithRmgRestrictions(): number {
        const boxWidth = this.boxWidth;
        return boxWidth > 600 ? 600 : boxWidth;
    }

    @computed public get boxHeightHeightRmgRestrictions(): number {
        const MaxWidth = 600;
        const MaxHeight = 448;

        const newHeight = Math.floor((MaxHeight * this.boxWidthWithRmgRestrictions) / MaxWidth);
        return newHeight;
    }

    @computed public get providerName(): string | undefined {
        if (this.openEventId === null) {
            return undefined;
        }

        return this.getModel(this.openEventId).providerName;
    }

    @computed public get isStreamOpen(): boolean {
        return this.openEventId !== null;
    }

    @computed public get openEventModel(): StreamingModel | null {
        if (this.openEventId === null) {
            return null;
        }

        return this.getModel(this.openEventId);
    }

    public hasStream(eventId: number): boolean {
        return this.getModel(eventId).stream.length > 0;
    }

    @computed public get isMobile(): boolean {
        const innerWidth = getWindowInnerWidth();

        return innerWidth !== null && innerWidth < 1024;
    }
}
