import { action, computed, makeObservable } from 'mobx';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { Value } from 'src_common/common/mobx-utils/Value';
import { DataTimeDuration } from 'src_common/utils/time/time';
import { LayoutsCallbacksType } from 'src/domains/layouts/state/AppLayouts.state';
import { Common } from 'src/domains/common/Common';

const getCurrentTime = (): number => {
    return new Date().getTime();
};

const currentTime = new Value(getCurrentTime(), (setValue) => {
    const timer = setInterval((): void => {
        setValue(getCurrentTime());
    }, 1000);

    return (): void => {
        clearInterval(timer);
    };
});

export class RealityCheckPopupState {
    public constructor(
        private readonly config: ConfigComponents,
        private readonly starRouter: StarRouter,
        private readonly localStorageState: LocalStorageState,
        private readonly callbacks: LayoutsCallbacksType,
        private readonly common: Common
    ) {
        makeObservable(this);
    }

    @computed private get userLoggedTime(): number | null {
        const localStorageRealityCheckTime = this.localStorageState.realityCheckTime.getValue();

        if (localStorageRealityCheckTime !== null) {
            return localStorageRealityCheckTime;
        }

        if (this.common.session.loggedTime !== null) {
            return this.common.session.loggedTime;
        }

        return null;
    }

    @computed private get activeRealityCheckFrequencyInSeconds(): number | null {
        const realityCheckTimeSetByUser = this.callbacks.getRealityCheckFrequency()?.active?.duration ?? null;

        if (realityCheckTimeSetByUser === null) {
            console.info('The user has not chosen the reality check frequency');
            return null;
        }

        return Math.floor(realityCheckTimeSetByUser * 60);
    }

    @computed public get activeRealityCheckFrequencyForView(): number | null {
        if (this.activeRealityCheckFrequencyInSeconds === null) {
            return null;
        }

        const minutes = this.activeRealityCheckFrequencyInSeconds / 60;

        return minutes;
    }

    @computed public get pendingRealityCheckFrequencyForView(): number | null {
        const pendingRealityCheckTime = this.callbacks.getRealityCheckFrequency()?.pending?.duration;

        const minutes = pendingRealityCheckTime ?? null;

        return minutes;
    }

    @computed private get routesForRealityCheck(): boolean {
        if (this.callbacks.realityCheckPopupForCasino() === false) {
            return false;
        }
        return true;
    }

    @computed private get realityCheckTimer(): DataTimeDuration | null {
        if (this.activeRealityCheckFrequencyInSeconds === null || this.userLoggedTime === null) {
            return null;
        }

        const diffInMilliseconds = Math.floor(currentTime.getValue() - this.userLoggedTime);

        return DataTimeDuration.from(diffInMilliseconds);
    }

    @computed private get isTimerPopupActive(): boolean {
        if (this.realityCheckTimer === null || this.activeRealityCheckFrequencyInSeconds === null) {
            return false;
        }

        const diffTime = this.realityCheckTimer.asSeconds() - this.activeRealityCheckFrequencyInSeconds;

        return diffTime > 0;
    }

    @computed public get realityCheckTimerHours(): string {
        if (this.realityCheckTimer === null) {
            return '00';
        }

        const hours = this.realityCheckTimer.hours().toString();
        const hoursForView = hours.padStart(2, '0');

        return hoursForView;
    }

    @computed public get realityCheckTimerMinutes(): string {
        if (this.realityCheckTimer === null) {
            return '00';
        }

        const minutes = this.realityCheckTimer.minutes().toString();
        const minutesForView = minutes.padStart(2, '0');

        return minutesForView;
    }

    @computed public get realityCheckTimerSeconds(): string {
        if (this.realityCheckTimer === null) {
            return '00';
        }

        const seconds = this.realityCheckTimer.seconds().toString();
        const secondsForView = seconds.padStart(2, '0');

        return secondsForView;
    }

    @computed public get isPopupAvaiable(): boolean {
        const isUserAuthorized = this.common.session.isAuthorized;
        const isPopupVisibleOnSpecificRoute = this.routesForRealityCheck;
        const isRealityCheckConfigOn = this.config.config.isRealityCheckAvailable;

        return isUserAuthorized && isPopupVisibleOnSpecificRoute && isRealityCheckConfigOn;
    }

    @computed public get isPopupShouldAppear(): boolean {
        const isTimerPopupActive = this.isTimerPopupActive;

        return this.isPopupAvaiable && isTimerPopupActive;
    }

    @computed public get isPendingTimeActivable(): boolean {
        return this.callbacks.getRealityCheckFrequency()?.pending?.activable ?? false;
    }

    @computed public get isPopupRealityCheckNewValueConfirmationShouldAppear(): boolean {
        return this.isPopupAvaiable && this.isPendingTimeActivable;
    }

    private resetContinueSection = (): void => {
        this.localStorageState.realityCheckTime.setValue(currentTime.getValue());
    };

    @action public handleContinue = (): void => {
        this.resetContinueSection();
    };

    @action public handleRedirectToGamblingLimits = (): void => {
        this.starRouter.redirectToLimits();
        this.resetContinueSection();
    };

    @action public handleAcceptPendingRealityCheckValue = async (): Promise<void> => {
        const duration = this.pendingRealityCheckFrequencyForView;

        if (duration === null) {
            return;
        }

        await this.callbacks.handleSetRealityCheckFrequency({ status: 'active', requestBody: { duration } });
    };

    @action public handleRevertPendingRealityCheckValue = async (): Promise<void> => {
        const duration = this.activeRealityCheckFrequencyForView;

        if (duration === null) {
            return;
        }

        await this.callbacks.handleSetRealityCheckFrequency({ status: 'pending', requestBody: { duration } });
    };
}
