import { Resource } from 'src_common/common/mobx-utils/Resource';
import { computed, makeObservable } from 'mobx';
import { Response200Type } from 'src/api_openapi/generated/openapi_website_cms_getSportDetailsActive';
import { Common } from 'src/domains/common/Common';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';

export type SportModelType = Response200Type extends Array<infer Model> ? Model : never;

export class SportsConfigState {
    private readonly sportsConfigActiveResource: Resource<Array<SportModelType>>;

    public static get = AutoWeakMap.create((common: Common) => new SportsConfigState(common));
    private constructor(common: Common) {
        makeObservable(this);
        this.sportsConfigActiveResource = new Resource(async (): Promise<Array<SportModelType>> => {
            const response = await common.trpcClient.client.cms.getSportsActive.query();
            return response.body;
        });
    }

    @computed public get sportsConfigActiveResourceData(): Array<SportModelType> | 'loading' {
        const result = this.sportsConfigActiveResource.get();
        if (result.type === 'loading') {
            return 'loading';
        }

        if (result.type === 'ready') {
            return result.value;
        }

        return [];
    }
}
