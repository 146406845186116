import { computed, makeObservable } from 'mobx';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { Response200Type } from 'src/api_openapi/generated/openapi_website_cms_getContactUsElements';
import { apiCommon } from 'src/api/ApiCommon';

export class ContactUsStateLuckyKing {
    private readonly footerContactUsResource: Resource<Response200Type>;

    public constructor() {
        makeObservable(this);
        this.footerContactUsResource = new Resource(async (): Promise<Response200Type> => {
            return await apiCommon.getFooterContactUs.run({});
        });
    }

    public get data(): Response200Type | null {
        const data = this.footerContactUsResource.get();

        if (data.type === 'ready') {
            return data.value;
        }

        return null;
    }

    @computed public get isDataReady(): boolean {
        return this.data !== null;
    }
}
