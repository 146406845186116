import { action, computed, observable, makeObservable } from 'mobx';
import { throttle } from 'lodash';
import { WebsocketV1 } from 'src/domains/layouts/state/websocketV1/WebsocketV1';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { DateTime } from 'src_common/utils/time/time';
import { ChatMessageType, ChatStatusType, ChatStatusDataResponseType } from 'src_server/trpc/types/chat';
import { assertNever } from 'src_common/common/assertNever';
import { Common } from 'src/domains/common/Common';
import { TraderChatTyping } from './TraderChatTyping';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { TraderChatWebsocketData } from './TraderChatWebsocketData';

export class TraderChatState {
    private readonly chatNightModeResource: Resource<ChatStatusDataResponseType | null>;

    @observable public fakeTextareaRef: HTMLElement | null = null;
    @observable private inputMessage: string = '';

    private sendTyping: () => void;
    private sendPeriodicEventId: NodeJS.Timeout | null = null;

    @observable public error: string | null = null;

    public static get = AutoWeakMap.create((common: Common) => new TraderChatState(common));
    private constructor(private readonly common: Common) {
        makeObservable(this);

        this.chatNightModeResource = new Resource(async (): Promise<ChatStatusDataResponseType> => {
            return await this.common.trpcClient.client.chatRouter.getChatStatus.query();
        });

        this.sendTyping = throttle(
            () => {
                this.sendStartTyping();
            },
            600,
            { trailing: false }
        );
    }

    @computed.struct public get messages(): ChatMessageType[] {
        const userId = this.common.session.userId;
        if (userId === null) {
            return [];
        }

        return TraderChatWebsocketData.get(this.common, userId).messages;
    }

    @computed public get isTraderTyping(): boolean {
        const userId = this.common.session.userId;
        if (userId === null) {
            return false;
        }
        return TraderChatTyping.get(this.common).isTraderTyping;
    }

    @computed public get assignee(): boolean {
        const userId = this.common.session.userId;
        if (userId === null) {
            return false;
        }

        return TraderChatWebsocketData.get(this.common, userId).statusThread === 'assigned';
    }

    @computed public get chatNightMode(): ChatStatusType | null {
        const chatNightModeResource = this.chatNightModeResource.get();
        if (chatNightModeResource.type === 'ready' && chatNightModeResource.value?.responseStatus === 'success') {
            return chatNightModeResource.value.data;
        }
        return null;
    }

    @computed public get isNightModeEnabled(): boolean {
        if (this.chatNightMode !== null) {
            const { templates } = this.chatNightMode;
            const { scheduleStart, scheduleEnd } = templates;
            const today = DateTime.current().utc().format();

            const scheduleStartTime = scheduleStart ?? null;
            const scheduleEndTime = scheduleEnd ?? null;

            if (scheduleStartTime === null || scheduleStartTime === '') {
                return false;
            }

            if (scheduleEndTime === null || scheduleEndTime === '') {
                return false;
            }

            if (DateTime.from(today)?.isBetween(scheduleStartTime, scheduleEndTime) === true) {
                return true;
            }
        }
        return false;
    }

    @computed public get nightModeMessage(): string | null {
        if (this.chatNightMode !== null) {
            const message = this.chatNightMode.templates.messageTemplates[0];
            if (message !== undefined) {
                return message;
            }
        }

        return null;
    }

    @computed public get isTraderChatOn(): boolean {
        const basicData = BasicDataModel.get(this.common).basicDataReady;
        return basicData?.chatEnabled ?? false;
    }

    @computed public get isTraderChatForView(): boolean {
        const config = ConfigComponents.get(this.common);
        return this.isTraderChatOn && config.config.traderChat.isOn;
    }

    public get lastReadMessage(): string {
        const localStorageState = LocalStorageState.get(this.common);
        const lastReadMessage = localStorageState.lastReadMessage.getValue();
        if (lastReadMessage !== null) {
            return lastReadMessage.sentAt;
        }
        return new Date().toISOString();
    }

    @computed public get unreadMessagesCount(): number {
        return this.messages
            .filter((m) => m.type === 'standard')
            .filter((m) => m.sender.type !== 'customer')
            .filter((m) => m.sentAt > this.lastReadMessage).length;
    }

    @action public handleSubmitMessage = async (event?: React.FormEvent<HTMLFormElement>): Promise<void> => {
        if (event !== undefined) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (this.isNightModeEnabled === true) {
            return;
        }

        if (this.message.trim().length === 0) {
            return;
        }

        if (this.common.session.userId === null) {
            return;
        }

        const payload: Omit<ChatMessageType, 'id' | 'chatId'> = {
            type: 'standard',
            tags: ['conversation'],
            sentAt: new Date().toISOString(),
            sender: {
                id: this.common.session.userId,
                type: 'customer',
                name: BasicDataModel.get(this.common).basicDataReady?.name ?? '',
            },
            content: {
                text: this.message,
            },
        };

        const result = await this.common.trpcClient.client.chatRouter.sendChatMessage.mutate(payload);

        switch (result.responseStatus) {
            case 'success': {
                this.stopTyping();
                this.clearMessage();
                break;
            }
            case 'error': {
                const error = result.data.errors[0];
                if (error !== undefined) {
                    this.error = error.debugDetails;
                }
                break;
            }
            case 'error-parse': {
                console.error('Trader chat -> handleSubmitMessage -> error-parse', result);
                break;
            }
            case 'httpError': {
                console.error('Trader chat -> handleSubmitMessage -> httpError', result);
                this.error = null;
                break;
            }
            default:
                assertNever('', result);
        }
    };

    @computed public get message(): string | '' {
        return this.inputMessage;
    }

    public set message(value) {
        this.inputMessage = value;
    }

    @computed public get isWaitingForTrader(): boolean {
        const userId = this.common.session.userId;
        if (userId === null) {
            return false;
        }

        return TraderChatWebsocketData.get(this.common, userId).statusThread === 'unassigned';
    }

    @action public changeMessage = (e: React.ChangeEvent<HTMLDivElement>): void => {
        this.message = e.currentTarget.textContent ?? '';
        const isTyping = this.message.trim() !== '';

        if (isTyping) {
            this.startTyping();
        } else {
            this.stopTyping();
        }
    };

    @action public sendMessageOnEnter = (e: React.KeyboardEvent<HTMLDivElement>): void => {
        if (e.keyCode === 13) {
            e.preventDefault();
            this.handleSubmitMessage().catch((e) => console.error(e));
        }

        if (e.currentTarget.textContent !== null) {
            this.message = e.currentTarget.textContent;
        }
    };

    @action public clearMessage = (): void => {
        this.message = '';
        if (this.fakeTextareaRef !== null) {
            this.fakeTextareaRef.textContent = '';
        }
    };

    @action private startTyping = (): void => {
        this.sendTyping();
    };

    @action public stopTyping = (): void => {
        if (this.sendPeriodicEventId !== null) {
            clearInterval(this.sendPeriodicEventId);
        }
        this.sendPeriodicEventId = null;
    };

    @action public setRef = (ref: HTMLElement | null): void => {
        this.fakeTextareaRef = ref;
    };

    private sendStartTyping = (): void => {
        if (this.common.session.userId === null) {
            return;
        }

        const userId = this.common.session.userId;
        const accountName = BasicDataModel.get(this.common).basicDataReady?.name;

        this.sendPeriodicEvent(() => {
            WebsocketV1.get(this.common).emit('typing', {
                channel: `${userId}:Chat`,
                name: accountName,
            });
        });
    };

    @action private sendPeriodicEvent = (event: () => void): void => {
        if (this.sendPeriodicEventId === null) {
            event();
            this.sendPeriodicEventId = setInterval(event, 3000);
        }
    };

    public readMessage = (message: ChatMessageType): void => {
        if (message.sentAt > this.lastReadMessage) {
            const localStorageState = LocalStorageState.get(this.common);
            localStorageState.lastReadMessage.setValue(message);
        }
    };
}
