/* stylelint-disable */
import { css, SerializedStyles } from '@emotion/react';
import { EmotionTheme } from 'src/domains/layouts/state/environmentState/EnvironmentState';

export const cookieBotWidget = (theme: EmotionTheme): SerializedStyles => css`
    /* MAIN WRAPPER */
    #CybotCookiebotDialog[data-template=popup] {
        transform: scale(0.9) !important;
        top: 0 !important;
        inset: 0;
        margin: auto;
        height: min-content;
    }

    #CookiebotWidget {
        display: none !important;
    }

    /* NAVIGATION */
    .CybotCookiebotDialogNavItem {
        position: relative !important;
    }

    #CybotCookiebotDialogNav {
        border-bottom: unset !important;
        position: relative !important;
    }

    #CybotCookiebotDialogNav::before {
        content: '';
        position: absolute;
        bottom: 1px;
        width: 100%;
        left: 0;
        height: 1px;
        background-color: #D6D6D6;
    }

    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
        color: ${theme.star.cookieBot.txtColoTertiary} !important;
        border-bottom: unset !important;
    }
    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive::before { 
        content: '' !important;
        position: absolute !important;
        bottom: 0;
        width: 100%;
        left: 0;
        height: 4px;
        background-color: ${theme.star.cookieBot.txtColoTertiary} !important;
    }

    #CybotCookiebotDialogBodyButtonsWrapper {
        flex-direction: column !important;
        @media(min-width: 1024px){
            flex-direction: row !important;
        }
    }

    #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyButton:not(:last-of-type), #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton, #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:not(:first-of-type) {
        margin-bottom: 8px !important;
        width: 100% !important;
        text-align: center !important;
        @media(min-width: 1024px){
            margin-bottom: 0 !important;
            width: 33% !important;
        }
    }

    #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyButton:not(:last-of-type), #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:not(:first-of-type) {
        flex-direction: column !important;
        @media(min-width: 1024px){
            flex-direction: row !important;
        }
    }

    #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a, #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink, #CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a, #CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a, #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a, #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink, #CybotCookiebotDialogDetailBodyContentTextAbout a, #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover, #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab:hover, #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
        color: ${theme.star.cookieBot.txtColoTertiary} !important;
    }

    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
        color: ${theme.star.cookieBot.txtColoTertiary} !important;
    }

    #CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount {
        background-color: ${theme.star.cookieBot.bgColorSecondary} !important;
    }

    /* SWITCH */
    #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: ${theme.star.cookieBot.bgColorSenary} !important;
    }
    #CybotCookiebotDialogBodyLevelButtonsSelectPane label .CybotCookiebotDialogBodyLevelButtonDescription:hover {
        color: ${theme.star.cookieBot.bgColor} !important;
    }
    #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelWrapper #CybotCookiebotDialogBodyLevelButtonsRow #CybotCookiebotDialogBodyLevelButtonsSelectPane form input[type="checkbox"][disabled]:checked + .CybotCookiebotDialogBodyLevelButtonSlider, #CybotCookiebotDialog form input[type="checkbox"][disabled]:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: ${theme.star.cookieBot.bgColorTertiary} !important;
        opacity: 1;
    }

    /* BUTTONS */
    #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
        background-color: ${theme.star.cookieBot.bgColor} !important;
        border-color: ${theme.star.cookieBot.bgColor} !important;
    }
    
    #CybotCookiebotDialog form input[type=checkbox]:not(:checked)+.CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: ${theme.star.cookieBot.bgColorTertiary} !important;
    }

    #CybotCookiebotDialog input:checked+.CybotCookiebotDialogBodyLevelButtonSlider:before {
        box-shadow: 0px 0px 4px 0px ${theme.star.cookieBot.bgColorQuaternary};
    }

    #CybotCookiebotDialogBodyLevelButtonCustomize {
        order: 1;
    }

    #CybotCookiebotDialogBodyButtonDecline {
        order: 2;
    }

    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
        order: 3;
    }

    /* BORDERS */
    #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonWrapper:not(:last-of-type), #CybotCookiebotDialogBody .CybotCookiebotScrollContainer, #CybotCookiebotDialogNav {
        border-color: ${theme.star.cookieBot.bgColorQuinary} !important;
    }

    /* HIDE ELEMENTS */
    #CybotCookiebotDialog #CybotCookiebotDialogPoweredByText, #CybotCookiebotDialogHeader, .CookiebotWidget-main-logo { 
        display: none !important;
    }

    /* COOKIE BOT SETTINGS */
    /* WRAPPER */
    #CookiebotWidget strong {
        color: ${theme.star.cookieBot.txtColor} !important;
    }

    /* NAVIGATION */
    #CookiebotWidget-header *{
        color: ${theme.star.cookieBot.txtColor} !important;
    }

    #CookiebotWidget .CookiebotWidget-header .CookiebotWidget-close svg {
        fill: ${theme.star.cookieBot.txtColor} !important; 
    }

    #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
        fill: ${theme.star.cookieBot.txtColor} !important;
    }

    #CookiebotWidget .CookiebotWidget-consent-details button {
        color: ${theme.star.cookieBot.txtColor} !important;
    }

    #CookiebotWidget .CookiebotWidget-consent-details button svg {
        fill: ${theme.star.cookieBot.txtColor} !important;
    }

    /* DETAILS */
    #CookiebotWidget .CookiebotWidget-consent-details .CookiebotWidget-consent-details-box {
        background-color: ${theme.star.cookieBot.bgColorTertiary} !important;
    }
    
    #CookiebotWidget .CookiebotWidget-consent-details strong {
        color: ${theme.star.cookieBot.txtColor} !important;
    }

    #CookiebotWidget .CookiebotWidget-consent-details div {
        color: ${theme.star.cookieBot.txtColor} !important;
    }


    /* BUTTONS */
    #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
        background-color: ${theme.star.cookieBot.bgColor} !important;
        border-color: ${theme.star.cookieBot.bgColor} !important;
        color: ${theme.star.cookieBot.txtColorSecondary} !important;
    }
    
    #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-withdraw {
        border: unset;
        background-color: ${theme.star.cookieBot.bgColorSecondary} !important;
        color: ${theme.star.cookieBot.txtColor} !important;
    }

    #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
        color: ${theme.star.cookieBot.txtColorSecondary} !important;
    }

    #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyButton:not(:last-of-type) {
        background-color: ${theme.star.cookieBot.bgColorSecondary} !important;
        border-color: transparent !important;
        color: ${theme.star.cookieBot.txtColor} !important;
    }

    #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize .CybotCookiebotDialogArrow {
        display: none !important;
    }
`;
