import { action, computed, observable, makeObservable } from 'mobx';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { EventsCollectionState } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionState';
import { EventsCollectionList } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionList';
import { CompetitionId } from 'src_common/common/websocket2/id/WebsocketId';
import { Response200Type } from 'src/api_openapi/generated/openapi_website_cms_getActiveSpecialSports';
import { SpecialSportsListState } from './SpecialSportListState';
import { z } from 'zod';
import { Common } from 'src/domains/common/Common';

export type ActiveSpecialSportsType = Response200Type extends Array<infer Model> ? Model : never;

export type SpecialSportsActiveSportsType = Array<ActiveSpecialSportsType> | 'loading';

export interface SpecialSportCompetitionType {
    name: string;
    id: number;
    eventsArray: Array<EventListGroupEventItemType> | null;
    sport: string | undefined;
}

export interface SpecialSportSportType {
    id: string;
    name: string;
}

const SpecialSportsZOD = z.object({
    competitionIds: z.array(z.number()),
});

const SpecialSportsFilterIO = z.object({
    slug: z.string(),
    label: z.string(),
    displayOrder: z.number(),
    competitionIds: z.array(z.number()),
});

const SpecialSportsDetailsZOD = z.object({
    filters: z.array(SpecialSportsFilterIO),
});

export type SpecialSportsDetailsType = z.TypeOf<typeof SpecialSportsDetailsZOD>;

export const sortByEventStart = (
    a: { events: Array<EventListGroupEventItemType> },
    b: { events: Array<EventListGroupEventItemType> }
): number => {
    const eventA = a.events[0] ?? null;
    const eventB = b.events[0] ?? null;
    if (eventA === null || eventB === null) {
        return 0;
    }
    if (eventA.startTime > eventB.startTime) {
        return 1;
    }
    if (eventA.startTime < eventB.startTime) {
        return -1;
    }
    return 0;
};

export class SpecialSportsState {
    private readonly models: ModelsState;
    private readonly eventsCollection: EventsCollectionState;
    public readonly sportId: string | null;

    private readonly specialSportsListState: SpecialSportsListState;
    public constructor(common: Common, sportId: string | null) {
        makeObservable(this);

        this.models = common.models;
        this.eventsCollection = EventsCollectionState.get(common);
        this.sportId = sportId;
        this.specialSportsListState = SpecialSportsListState.get(common);
    }

    @observable public filter_coupon: string = 'all';

    @action public changeFilter_coupon = (value: string): void => {
        this.filter_coupon = value;
    };

    @computed public get currentSport(): ActiveSpecialSportsType | null {
        return this.specialSportsListState.allSpecialSports.find((s) => s.slug === this.sportId) ?? null;
    }

    @computed public get getSpecialSportBySlug(): null | ActiveSpecialSportsType {
        if (this.specialSportsListState.allSpecialSports.length === 0) {
            return null;
        }

        return this.specialSportsListState.allSpecialSports.find((s) => s.slug === this.sportId) ?? null;
    }

    @computed public get competitionIds(): Array<CompetitionId> {
        const detailsParsed = SpecialSportsZOD.safeParse(this.currentSport?.details);
        const competitionIdsRaw = detailsParsed.success ? detailsParsed.data.competitionIds : [];
        return competitionIdsRaw.map((competitionId) => this.models.id.getCompetitionId(competitionId));
    }

    @computed public get competitions(): Array<SpecialSportCompetitionType | undefined> {
        const activeFilter = this.filter_coupon;
        const allCompetitions = this.competitionIds.map((id: CompetitionId) => {
            const compettition = id.getModel();
            if (compettition !== null) {
                const eventQuery = this.models.getEventQuery({
                    competition: compettition.id,
                    'market.display': true,
                    state: 'open',
                });
                if (eventQuery !== null) {
                    return {
                        name: compettition.name,
                        id: compettition.id,
                        eventsArray: eventQuery.events,
                        sport: eventQuery.sportsIds[0],
                    };
                }
            }
        });

        return activeFilter === 'all'
            ? allCompetitions
            : allCompetitions.filter(
                  (event: SpecialSportCompetitionType | undefined) =>
                      event !== undefined && event.sport === activeFilter
              );
    }

    @computed public get availableSports(): Array<SpecialSportSportType> {
        const sports: Array<SpecialSportSportType> = [{ id: 'all', name: 'All' }];
        this.competitionIds.map((id: CompetitionId) => {
            const compettition = id.getModel();
            if (compettition !== null) {
                const eventQuery = this.models.getEventQuery({
                    competition: compettition.id2.toOldId(),
                    'market.display': true,
                    state: 'open',
                });
                if (eventQuery !== null) {
                    const sportObject = eventQuery.value.sports[0];
                    if (sportObject !== undefined && sports.find((s) => s.id === sportObject.id) === undefined) {
                        sports.push({
                            id: sportObject.id,
                            name: sportObject.name,
                        });
                    }
                }
            }
        });
        return sports;
    }

    @computed public get existedSpecialSports(): Array<ActiveSpecialSportsType> {
        return this.specialSportsListState.allSpecialSports.filter((elem) => elem.sport_type === 'existing-sport');
    }

    @computed public get specialExpandForSport(): ActiveSpecialSportsType | null {
        return (
            this.existedSpecialSports.find((elem) => elem.slug === this.sportId && this.sportId !== 'football') ?? null
        );
    }

    @computed public get isSpecial(): boolean {
        return this.specialExpandForSport !== null;
    }

    @computed public get specialExpandForSportDetails(): SpecialSportsDetailsType | null {
        if (this.specialExpandForSport === null) {
            return null;
        }
        const specialSportsDetailsRaw = SpecialSportsDetailsZOD.parse(this.specialExpandForSport.details);
        return specialSportsDetailsRaw;
    }

    @computed public get filteredCompetitions(): Array<number> {
        if (this.specialExpandForSportDetails === null) {
            return [];
        }
        const customFilter =
            this.specialExpandForSportDetails.filters.find((elem) => elem.slug === this.filter_coupon) ?? null;

        if (customFilter === null) {
            return [];
        }

        return customFilter.competitionIds;
    }

    @computed public get specialExpandForSportCompetitionsIds(): Array<number> {
        const temp: Array<number> = [];
        if (this.specialExpandForSportDetails !== null) {
            for (const filter of this.specialExpandForSportDetails.filters) {
                temp.push(...filter.competitionIds);
            }
        }

        return Array.from(new Set(temp));
    }

    @computed public get extendedCollection(): EventsCollectionList {
        const collectionForSport =
            this.sportId === null ? [] : this.eventsCollection.listOfSport(this.sportId).competitionIds ?? [];

        const competitions = [...this.specialExpandForSportCompetitionsIds, ...collectionForSport];
        return this.eventsCollection.getByCompetitions(competitions);
    }

    @computed public get customFiltersForExistingEvent(): Array<SpecialSportSportType> {
        const filters: Array<SpecialSportSportType> = [{ id: 'all', name: 'All' }];

        if (this.specialExpandForSportDetails !== null) {
            const customFilters = this.specialExpandForSportDetails.filters.map((elem) => {
                return {
                    id: elem.slug,
                    name: elem.label,
                };
            });

            filters.push(...customFilters);
        }
        return filters;
    }
}
