import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const Container = styled('div', { label: 'Container' })`
    display: flex;
    flex-direction: column;
    padding: 16px 9px 9px;
`;

export const Header = styled('div', { label: 'Header' })`
    text-align: center;
    margin-bottom: 16px;
`;

export const ReportsContainer = styled('div', { label: 'ReportsContainer' })`
    margin-bottom: 24px;
`;

export const ReportItem = withConfig(theme => styled('div', { label: 'ReportItem' })`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.star.monthlyActivityModals.borderColor};
    padding: 8px 0;
`);

export const Heading = withConfig(theme => styled('h4', { label: 'Heading' })`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    margin: 8px 0;
`);

export const Text = withConfig(theme => styled('p', { label: 'Text' })<{isBold?: boolean}>`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${({ isBold }): string => isBold === true ? theme.star.fontWeight.bold : theme.star.fontWeight.regular};
    line-height: 1.3333;
`);

