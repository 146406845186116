import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
// eslint-disable-next-line no-restricted-imports
import { useAsObservableSource } from 'mobx-react-lite';
import { RaceSummaryRowEachWayExtra } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/eachWayExtraSummary/RaceSummaryRowEachWayExtra';
import { RaceSummaryTwoColumnsList } from 'src/domains/sportsbook/webview/components/raceSummary/templates/scrollMarkets/RaceSummaryWithScroll';
import { useAppStateContext } from 'src/appState/AppState';
import {
    EachWayExtraSummaryPropsType,
    EachWayExtraSummaryState,
    getSelectionByName,
} from './EachWayExtraSummary.state';
import {
    getPlace,
    getRunner,
    NotRunningPriceView,
} from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow';
import { ScrollRowWrapper } from 'src/domains/sportsbook/webview/components/raceSummary/templates/scrollMarkets/RaceSummaryWithScroll.style';
import {
    EachWayExtraSelectionCast,
    EachWayExtraRaceSummaryCaption,
    EachWayExtraRunnerCaption,
    EachWayExtraSelectionCaption,
    EachWayExtraPlaceCaption,
    EachWayExtraSilkCaption,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/eachWayExtraSummary/EachWayExtraSummary.style';

function selectionsBuilder(
    selectionName: string,
    eachWayMarkets: MarketModel[],
    isNonRunnerRow: boolean,
    oddsType: 'f' | 'd'
): Array<JSX.Element | null> | null {
    return eachWayMarkets.map((market) => {
        const selectionInCurrentRow = getSelectionByName(selectionName, market);
        if (isNonRunnerRow === true) {
            return (
                <NotRunningPriceView
                    key={selectionInCurrentRow?.id2.toOldId()}
                    eachWayExtra={true}
                />
            );
        }

        if (selectionInCurrentRow === null) {
            return null;
        }

        return (
            <EachWayExtraSelectionCast
                key={selectionInCurrentRow.id2.key}
                selectionId={selectionInCurrentRow.id2}
                title={oddsType === 'd' ? selectionInCurrentRow.price?.d : selectionInCurrentRow.price?.f}
                borderLeft={true}
                index={selectionInCurrentRow.id}
            />
        );
    });
}

export const EachWayExtraSummary = observer(
    'EachWayExtraSummary',
    (propsIn: EachWayExtraSummaryPropsType): JSX.Element | null => {
        const props = useAsObservableSource(propsIn);
        const { appSportsBookState } = useAppStateContext();

        const [state] = useState(() => new EachWayExtraSummaryState(props));

        const selectionsByPrice = state.selectionsByPrice;
        if (selectionsByPrice === undefined) {
            return null;
        }

        const firstSelection = selectionsByPrice[0];
        if (firstSelection === undefined) {
            return null;
        }

        const marketModel = firstSelection.getMarket();
        if (marketModel === null) {
            return null;
        }
        const oddsTypeShort = appSportsBookState.getOddsFormat();
        // const oddsType = appState.accountState.account?.basicDataReady?.oddsFormat !== undefined ? appState.accountState.account?.basicDataReady?.oddsFormat : 'fractal';

        const StaticList = (): JSX.Element => {
            return (
                <>
                    <EachWayExtraRaceSummaryCaption>
                        <EachWayExtraPlaceCaption>#</EachWayExtraPlaceCaption>
                        <EachWayExtraSilkCaption>
                            <I18n
                                langKey='events.race-summary.silk.label'
                                defaultText='Silk'
                            />
                        </EachWayExtraSilkCaption>
                        <EachWayExtraRunnerCaption>
                            <I18n
                                langKey='events.race-summary.runner.label'
                                defaultText='Runner'
                            />
                        </EachWayExtraRunnerCaption>
                    </EachWayExtraRaceSummaryCaption>

                    {selectionsByPrice.map((selection) => {
                        return (
                            <RaceSummaryRowEachWayExtra
                                key={selection.id2.key}
                                isNonRunner={state.isNonRunnerInRow(selection.name)}
                                selectionId={selection.id2}
                                runner={getRunner(selection, false, true, true)}
                                place={getPlace(selection)}
                            />
                        );
                    })}
                </>
            );
        };

        const ScrollList = (): JSX.Element => {
            return (
                <>
                    <EachWayExtraRaceSummaryCaption>
                        {state.marketsPlaceAndReduction.map((market) => (
                            <EachWayExtraSelectionCaption key={market.marketId.toOldId()}>
                                {market.places}
                                <br />
                                {market.reduction}
                            </EachWayExtraSelectionCaption>
                        ))}
                    </EachWayExtraRaceSummaryCaption>
                    {selectionsByPrice.map((selection) => (
                        <ScrollRowWrapper
                            key={`price-row-${selection.id2.key}`}
                            isNonRunner={state.isNonRunnerInRow(selection.name)}
                        >
                            {selectionsBuilder(
                                selection.name,
                                state.sortedMarketsByTermPlaces,
                                state.isNonRunnerInRow(selection.name),
                                oddsTypeShort
                            )}
                        </ScrollRowWrapper>
                    ))}
                </>
            );
        };

        return (
            <RaceSummaryTwoColumnsList
                staticListElements={StaticList()}
                scrollListElements={ScrollList()}
                columnCount={state.marketsPlaceAndReduction.length}
            />
        );
    }
);
