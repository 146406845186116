import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { LazyImage } from 'src/domains/sportsbook/webview/components/lazyImage/LazyImage';
import EMPTY_SILK_old from 'src/domains/layouts/shared/assets/silk/empty-hr-silk-old.webp';
import EMPTY_SILK from 'src/domains/sportsbook/webview/components/raceSummary/assets/empty-hr-silk.svg';
import {
    RunnerCell,
    SilkCell,
    RaceSummaryRow,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummaryNew.style';

interface PropsType {
    className?: string;
    place?: React.ReactNode;
    runner?: React.ReactNode;
    oneCast?: React.ReactNode;
    twoCast?: React.ReactNode;
    threeCast?: React.ReactNode;
    anyCast?: React.ReactNode;
    selectionId: SelectionId;
    isUnnamedFavourite: boolean;
    isNonRunner?: boolean;
    dataTest?: string;
}

export const RaceSummaryCastRowHorseRacingNew = observer('RaceSummaryCastRowHorseRacing', (props: PropsType) => {
    const common = useCommon();
    const config = ConfigComponents.get(common).config;

    const {
        className,
        isUnnamedFavourite,
        runner,
        oneCast,
        twoCast,
        threeCast,
        anyCast,
        selectionId,
        isNonRunner,
        dataTest,
    } = props;

    const selectionModel = selectionId.getModel();
    if (selectionModel === null) {
        return null;
    }

    const marketModel = selectionModel.getMarket();
    if (marketModel === null) {
        return null;
    }

    const silkUrl = selectionModel.metaDataSilkUrl;

    if (isUnnamedFavourite) {
        return null;
    } else {
        return (
            <RaceSummaryRow
                className={className}
                isNonRunner={isNonRunner}
                data-test={dataTest}
            >
                <SilkCell className='cast'>
                    <LazyImage
                        src={silkUrl}
                        placeholder={config.shouldDisplaySilkWithoutQuestionMark ? EMPTY_SILK : EMPTY_SILK_old}
                        alt={selectionModel.metaDataSilk}
                    />
                </SilkCell>
                <RunnerCell isHideRunnerInfo={true}>{runner}</RunnerCell>

                {oneCast}
                {twoCast}
                {marketModel.tricastsOffered && threeCast}
                {anyCast}
            </RaceSummaryRow>
        );
    }
});
