import React, { ChangeEvent } from 'react';
import { CheckBoxLabel, InputCheckBox, CheckMarkWrapper, IconWrapper } from './CheckBox.style';

interface CheckboxPropsType {
    isChecked: boolean;
    isDisabled: boolean;
    handleOnChange: ((e: ChangeEvent<HTMLInputElement>) => void) | (() => void);
    label: React.ReactNode;
    isReversed?: boolean;
}

export const Checkbox = ({
    isChecked,
    handleOnChange,
    isDisabled,
    label,
    isReversed,
}: CheckboxPropsType): React.ReactElement => {
    return (
        <CheckBoxLabel
            isDisabled={isDisabled}
            isReversed={isReversed}
        >
            {label}
            <>
                <InputCheckBox
                    type='checkbox'
                    disabled={isDisabled}
                    onChange={handleOnChange}
                    checked={isChecked}
                />

                <IconWrapper
                    isChecked={isChecked}
                    isDisabled={isDisabled}
                    isReversed={isReversed}
                >
                    <CheckMarkWrapper isChecked={isChecked} />
                </IconWrapper>
            </>
        </CheckBoxLabel>
    );
};
