import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { BannerSection, SliderWrapper, BannersWrapper } from './CasinoBanners.style';
import { CasinoBannersState } from './CasinoBanners.state';
import { BannerArrow } from 'src/domains/casino/webview/CasinoGames/home-games/casino-banners/BannerArrow';
import { SingleBanner } from './SingleBanner';
import { Dots } from 'src/domains/layouts/webview/components/common/dots/Dots';
import { CollectionType } from 'src/domains/casino/types/types';


export type DirectionType = 'left' | 'right';

interface CasinoBannersPropsType {
    appCasinoState: AppCasinoState;
    collection: CollectionType;
}

export const CasinoBanners = observer('CasinoBanners', (props: CasinoBannersPropsType) => {
    const { appCasinoState, collection } = props;
    const { breakpointsState, features, bannerStore } = appCasinoState;
    
    const [casinoBannersState] = useState(new CasinoBannersState(bannerStore, collection, breakpointsState));
    const { slideWithDot, activeBannerIndex, slideInDirection, bannersIndexes, showLeftArrow, showRightArrow, banners } = casinoBannersState;

    if (banners.length === 0) {
        return null;
    }

    return (
        <BannerSection layout={features.config.layout}>
            <SliderWrapper>
                {showLeftArrow === true && <BannerArrow direction='left' slideInDirection={slideInDirection} /> }
                <BannersWrapper>
                    {banners.map((banner, index) => (
                        <SingleBanner
                            appCasinoState={appCasinoState}
                            key={banner.id}
                            banner={banner}
                            index={index}
                            casinoBannersState={casinoBannersState}
                        />
                    ))}
                </BannersWrapper>
                {showRightArrow === true && <BannerArrow direction='right' slideInDirection={slideInDirection} /> }
            </SliderWrapper>
            {banners.length > 1 && 
                <Dots banners={bannersIndexes} currentSlide={activeBannerIndex} smallDotIndex={3} slideWithDot={slideWithDot} position='static' marginTop={10} isHomepage={false}/>
            }
        </BannerSection>
    );
});
