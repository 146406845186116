import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { action, computed, observable, makeObservable } from 'mobx';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import React from 'react';
import { EventsCollectionList, EventsCollectionState } from 'src/domains/sportsbook/shared/Types';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { DateTime } from 'src_common/utils/time/time';
import { Overlay } from 'src/domains/layouts/state/overlay/Overlay';
import { Common } from 'src/domains/common/Common';

enum KeysCode {
    ENTER = 13,
    LEFT = 37,
    UP = 38,
    RIGHT = 39,
    DOWN = 40,
}

// 13 - enter
// 38 - up arrow
// 39 - down arrow

export class SearchState {
    @observable public query: string = '';
    @observable private selectedEventId: number | null = null;
    @observable public isShow: boolean = false;

    public constructor(
        private readonly common: Common,
        private readonly config: ConfigComponents,
        private readonly starRouter: StarRouter,
        private readonly overlay: Overlay
    ) {
        makeObservable(this);
    }

    @action public setQuery = (query: string): void => {
        this.query = query;
        this.selectedEventId = null;
    };

    private get searchCollection(): EventsCollectionList {
        const eventsCollection = EventsCollectionState.get(this.common);
        const query = this.query;

        return eventsCollection.listOfSearch(query);
    }

    @computed public get collectionSearchQuery(): Array<EventModel> {
        const out: Array<EventModel> = [];
        const eventsList = this.searchCollection;

        for (const id of eventsList.ids2) {
            const event = id.getEventModel();

            if (event !== null) {
                out.push(event);
            }
        }

        return out;
    }

    @computed private get eventsData(): EventModel[] {
        const query = this.query;

        if (query.length > 2) {
            return this.collectionSearchQuery.filter((e) => e.name.toLowerCase().includes(query.toLowerCase()));
        }

        return [];
    }

    @computed public get events(): EventModel[] {
        return this.eventsData
            .sort((a, b) => a.name.localeCompare(b.name))
            .sort((a, b) => {
                const aDate = DateTime.from(a.timeSettingsStartTime)?.unixMs();
                const bDate = DateTime.from(b.timeSettingsStartTime)?.unixMs();
                if (aDate === undefined || bDate === undefined) {
                    return 0;
                }
                return aDate - bDate;
            })
            .filter((e) => this.config.config.hiddenSportsList.includes(e.sport) === false);
    }

    @action public setSelectedEventId = (selectedEventId: number | null): void => {
        this.selectedEventId = selectedEventId;
    };

    public redirectToEvent(event: EventModel): void {
        this.starRouter.redirectToEvent(event.id2);

        if (this.common.isBrowser) {
            window.scrollTo(0, 0);
        }
        this.handleCloseAndRedirect();
    }

    @computed public get selectedEventIdForView(): number | null {
        const selectedEventId = this.selectedEventId;

        if (selectedEventId !== null) {
            return selectedEventId;
        }

        const event = this.events[0];

        if (event !== undefined) {
            return event.id;
        }

        return null;
    }

    public setActive = (event: EventModel | null): void => {
        this.setSelectedEventId(event === null ? null : event.id);
    };

    @action public clearQuery = (): void => {
        this.setQuery('');
    };

    public onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            this.setQuery(target.value);
        }
    };

    @action public handleOpen = (): void => {
        this.isShow = true;
    };

    @action public handleCloseAndRedirect = (): void => {
        this.clearQuery();
        this.isShow = false;
        this.overlay.toggleMenu();
    };

    // 38 - up arrow
    // 39 - down arrow
    // 13 - enter
    public handleMove = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        const selectedEventIdForView = this.selectedEventIdForView;
        if (selectedEventIdForView === null) {
            return;
        }

        if (e.keyCode === KeysCode.ENTER) {
            const currentEvent = this.events.find((item) => item.id === selectedEventIdForView);
            if (currentEvent === undefined) {
                return;
            }
            this.redirectToEvent(currentEvent);
            return;
        }

        const currentIndex = this.events.findIndex((item) => item.id === selectedEventIdForView);
        if (currentIndex < 0) {
            return;
        }

        const newIndex = currentIndex + this.getMoveOffset(e.keyCode);
        const newSelectEvent = this.events[newIndex];

        if (newSelectEvent !== undefined) {
            this.selectedEventId = newSelectEvent.id;
        }
    };

    private getMoveOffset = (keyCode: number): -1 | 0 | 1 => {
        if (keyCode === KeysCode.UP || keyCode === KeysCode.LEFT) {
            return -1;
        }

        if (keyCode === KeysCode.DOWN || keyCode === KeysCode.RIGHT) {
            return 1;
        }

        return 0;
    };
}
