import { AccountModel } from 'src/domains/players/state/accountState/AccountModel';
import { computed, observable, makeObservable } from 'mobx';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { UsersState } from 'src/domains/players/state/UsersState';
import { Common } from 'src/domains/common/Common';

export type OddsTypeValuesType = 'fractional' | 'decimal';
type BalanceInHeaderValuesType = 'show' | 'hide';

export class SettingsFormState {
    @observable public savingOddsType: boolean = false;
    private localStorageState: LocalStorageState;

    public constructor(
        private readonly usersState: UsersState,
        private readonly accountModel: AccountModel,
        private readonly common: Common
    ) {
        makeObservable(this);
        this.localStorageState = LocalStorageState.get(this.common);
    }

    @computed public get oddsType(): OddsTypeValuesType | null {
        const isFraction = this.oddsTypeFractional;
        const isDecimal = this.oddsTypeDecimal;

        if (isFraction) {
            return 'fractional';
        }

        if (isDecimal) {
            return 'decimal';
        }

        return null;
    }

    @computed private get oddsTypeFractional(): boolean {
        if (this.oddsFormat === 'fractional' && this.savingOddsType === false) {
            return true;
        }

        if (this.oddsFormat === 'decimal' && this.savingOddsType === true) {
            return true;
        }

        return false;
    }

    @computed private get oddsTypeDecimal(): boolean {
        if (this.oddsFormat === 'decimal' && this.savingOddsType === false) {
            return true;
        }

        if (this.oddsFormat === 'fractional' && this.savingOddsType === true) {
            return true;
        }

        return false;
    }

    @computed private get oddsFormat(): OddsTypeValuesType | null {
        const oddsFormatShort = this.usersState.oddsFormatShort;

        if (oddsFormatShort === 'd') {
            return 'decimal';
        }

        return 'fractional';
    }

    @computed public get balanceInHeader(): BalanceInHeaderValuesType {
        const showBalanceInHeader = this.localStorageState.balanceInHeader.getValue();
        return showBalanceInHeader === true ? 'show' : 'hide';
    }

    public updateOddsTypeIfChanged = async (): Promise<void> => {
        if (this.savingOddsType) {
            return;
        }

        const oddsType = this.oddsType;

        if (oddsType === null) {
            return;
        }

        this.savingOddsType = true;

        const newOddsType: OddsTypeValuesType = oddsType === 'fractional' ? 'decimal' : 'fractional';

        try {
            await this.accountModel.onChangeOddsType(newOddsType);
            await this.usersState.basicData.refreshAndWait();
        } catch (error) {
            console.error(error);
        }

        this.savingOddsType = false;
    };

    public updateBalanceInHeaderIfChanged = (newValue: string): void => {
        const newBooleanValue = newValue === 'show' ? true : false;
        const oldValue = this.localStorageState.balanceInHeader.getValue();

        if (newBooleanValue !== oldValue) {
            this.localStorageState.balanceInHeader.setValue(newBooleanValue);
        }
    };
}
