import React from 'react';
import { MainWrapper } from './Homepage.style';
import { observer } from 'src/utils/mobx-react';
import { CasinoGamesOnHomepage, VirtualGamesOnHomepage } from 'src/domains/casino/shared/Components';
import {
    EventsInPlayOrUpcoming,
    PromotedEvents,
    HomepageContentTabs,
    EventsStartingSoon,
} from 'src/domains/sportsbook/shared/Components';
import { LayoutSkeleton } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { PriceChangeLegend } from 'src/domains/layouts/webview/components/priceChangeLegend/PriceChangeLegend';
import { QuickLinkTrayContainer } from 'src/domains/layouts/webview/components/quickLinkTray/QuickLinkTray';
import { CarouselPromo } from 'src/domains/layouts/webview/components/homePageCarouselPromos/carouselPromo/CarouselPromo';
import { useAppStateContext } from 'src/appState/AppState';
import { HeroWidget } from 'src/domains/layouts/webview/components/heroEvents/HeroWidget';
import { HomeRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { SeoPagesContent } from 'src/domains/layouts/webview/components/seoPages/SeoPages';

interface RenderHomePageLayoutPropsType {
    headerStyleLevel: 'sportLevel' | 'competitionLevel';
}

interface RenderAdditionalHomePageLayoutPropsType {
    hasGamesOnHomepage: boolean;
}

const RenderAdditionalHomePageLayout = observer(
    'RenderAdditionalHomePageLayout',
    ({ hasGamesOnHomepage }: RenderAdditionalHomePageLayoutPropsType) => {
        const appState = useAppStateContext();
        const {
            appCasinoState,
            appLayoutsState: { promotedEventsState },
        } = appState;

        return (
            <>
                {hasGamesOnHomepage ? <CasinoGamesOnHomepage appCasinoState={appCasinoState} /> : null}
                <PromotedEvents promotedEventsState={promotedEventsState} />
                {hasGamesOnHomepage ? <VirtualGamesOnHomepage appCasinoState={appCasinoState} /> : null}
            </>
        );
    }
);

const RenderHomePageLayout = observer('RenderHomePageLayout', ({ headerStyleLevel }: RenderHomePageLayoutPropsType) => {
    const common = useCommon();
    const config = ConfigComponents.get(common).config;
    const { hasGamesOnHomepage, hiddenSportsList, startingSoonEvents } = config;
    const isRacingHiddenForOperator =
        hiddenSportsList.includes('horseracing') && hiddenSportsList.includes('greyhoundracing');

    return (
        <>
            <CarouselPromo modeType='homepage' />
            <QuickLinkTrayContainer />
            <HeroWidget />
            {isRacingHiddenForOperator ? null : <HomepageContentTabs />}
            <EventsInPlayOrUpcoming
                headerStyleLevel={headerStyleLevel}
                showPriceLegend={false}
            />
            {startingSoonEvents ? (
                <EventsStartingSoon
                    showPriceLegend={false}
                    headerStyleLevel={headerStyleLevel}
                />
            ) : null}
            <RenderAdditionalHomePageLayout hasGamesOnHomepage={hasGamesOnHomepage} />
            <PriceChangeLegend isRace={false} />
            <SeoPagesContent pageSlug='homepage' />
        </>
    );
});

interface HomePageLayoutPropsType {
    currentView: HomeRouteType;
    headerStyleLevel: 'sportLevel' | 'competitionLevel';
    isSearchActive: boolean;
    accountView: RightHandSideViewType | null;
}

export const HomePageLayout = observer(
    'HomePageLayout',
    ({ currentView, headerStyleLevel, isSearchActive, accountView }: HomePageLayoutPropsType) => {
        return (
            <MainWrapper className='homepage'>
                <LayoutSkeleton
                    currentView={currentView}
                    accountView={accountView}
                    isSearchActive={isSearchActive}
                    navigationType='global'
                >
                    <RenderHomePageLayout headerStyleLevel={headerStyleLevel} />
                </LayoutSkeleton>
            </MainWrapper>
        );
    }
);
