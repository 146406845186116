import { SelectionViewModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionViewModel';
import { BetSlipFilter, BetSlipState } from 'src/domains/sportsbook/betting/state/BetSlipState';
import { computed, makeObservable } from 'mobx';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';
import { UserBasicDataType } from 'src/domains/players/state/BasicDataModel';

interface PropsType {
    selectionId: SelectionId | undefined;
    sp?: boolean;
    isRacingPage: boolean;
}

export class SelectionRaceCardState {
    public constructor(
        private readonly betSlipState: BetSlipState,
        public readonly router: StarRouter,
        private readonly props: PropsType,
        private readonly basicData: UserBasicDataType
    ) {
        makeObservable(this);
    }

    @computed private get selectionId(): SelectionId | null {
        return this.props.selectionId ?? null;
    }

    @computed private get isAlwaysSP(): boolean | null {
        return this.props.sp ?? null;
    }

    @computed public get selectionView(): SelectionViewModel | null {
        if (this.selectionId !== null) {
            const selectionModel = this.selectionId.getModel();

            if (selectionModel !== null) {
                if (selectionModel.templateId === 'unnamed-favourite' && selectionModel.isSP) {
                    return selectionModel.forView(true);
                }
                return selectionModel.forView(this.isAlwaysSP ?? false);
            }
        }

        return null;
    }

    @computed public get isSelected(): boolean {
        const { isReferred } = this.betSlipState.referralState;

        if (isReferred) return false;

        if (this.selectionId !== null) {
            const isSelected = this.betSlipState.isSelected(this.selectionId);

            if (isSelected === false) {
                return false;
            }

            const legItem = this.betSlipState.getLegItemByIdSelection(this.selectionId, 0);

            if (this.selectionView !== null) {
                if (legItem.isSPSport) {
                    return true;
                }

                const { priceType } = legItem;

                if (this.isAlwaysSP === true && priceType !== 'sp') {
                    return false;
                }

                if (
                    this.isAlwaysSP === false &&
                    priceType === 'sp' &&
                    this.selectionView.displayPrice !== 'SP' &&
                    this.isRacingPage
                ) {
                    return false;
                }

                return true;
            }
        }

        return false;
    }

    @computed public get getOddsValue(): React.ReactNode {
        if (this.selectionView !== null) {
            if (this.isSPSport) {
                return 'SP';
            }
            const { displayPrice } = this.selectionView;

            if (this.selectionId !== null) {
                const isSelected = this.betSlipState.isSelected(this.selectionId);
                const selection = isSelected ? this.betSlipState.ids.getSingleId(this.selectionId, 0).getModel() : null;
                if (selection !== null) {
                    if (this.isRacingPage) {
                        return displayPrice;
                    } else {
                        return selection.isSelectedSP ? 'SP' : displayPrice;
                    }
                }
            }

            return displayPrice;
        }

        return '-';
    }

    @computed public get isSPSport(): boolean {
        const basicData = this.basicData.get();

        if (basicData.type === 'ready') {
            const eventModel = this.props.selectionId?.getEventId().getEventModel();
            const selectionSport = eventModel?.sportOriginal ?? '';
            return basicData.value?.spOnlySports?.includes(selectionSport) ?? false;
        }

        return false;
    }

    @computed public get isRacingPage(): boolean {
        return this.props.isRacingPage;
    }

    public chooseNew = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        const { selectedTab, changeBetslipTab, referralState, betSlipSummaryState, legsState } = this.betSlipState;
        const { isReferred } = referralState;
        const { clearError } = betSlipSummaryState;
        e.preventDefault();

        if (this.selectionView === null) {
            return;
        }

        const { displayPrice } = this.selectionView;
        const sp = displayPrice === 'SP';

        if (this.selectionId !== null && isReferred === false) {
            const selection = this.betSlipState.ids.getSingleId(this.selectionId, 0).getModel();

            if (selection.isSelectedSP && this.isAlwaysSP === null) {
                selection.removeLeg();
            } else if (this.isAlwaysSP === true || sp || this.isSPSport) {
                legsState.betslipData.onAddSPBet(this.selectionId);
            } else {
                legsState.betslipData.onAddSimpleBet(this.selectionId);
            }

            if (selectedTab !== BetSlipFilter.BETSLIP) {
                changeBetslipTab(BetSlipFilter.BETSLIP);
            }
            clearError();
        }
    };
}
