import React from 'react';
import { groupBy, sortBy } from 'lodash';
import { MarketGroup } from 'src/domains/sportsbook/webview/components/eventMarkets/marketGroup/MarketGroups';
import { observer } from 'src/utils/mobx-react';
import { EventMarketItemType } from 'src_common/common/websocket2/modelsApi/EventMarkets';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

const GROUPS: Record<string, string> = {
    'first-goalscorer': 'goalscorer',
    'anytime-goalscorer': 'goalscorer',
    'hattrick-goalscorer': 'goalscorer',
};

const UNGROUPED: Array<string> = [
    'race-to',
    'custom-outrights-market',
    'custom-match-market',
    'custom-market',
    'custom-two-participant-market',
];

const groupMarkets = (markets: Array<EventMarketItemType>): Record<string, Array<EventMarketItemType>> => {
    return groupBy(markets, (market) => {
        let marketDisplayTemplate = market.tags['display-template']; // eventTagModel.getMarketTag(market.id, 'display-template');
        const marketGrouping = market.tags['group-template']; // eventTagModel.getMarketTag(market.id, 'group-template');
        // let marketDisplayTemplate = market.displayTemplate;
        // const marketGrouping = market.groupTemplate;
        const templateId = market.templateId;

        marketDisplayTemplate =
            marketDisplayTemplate !== undefined && marketDisplayTemplate !== '-' ? `${marketDisplayTemplate}-` : '';
        const groupTemplate = GROUPS[templateId] ?? null;

        if (groupTemplate !== null || marketGrouping === 'yes') {
            if (groupTemplate !== null) {
                return groupTemplate;
            }
            return marketDisplayTemplate + templateId;
        } else if (UNGROUPED.includes(templateId)) {
            return `${marketDisplayTemplate + templateId}-ungrouped-${market.id}`;
        } else {
            return `${marketDisplayTemplate}${market.id}`;
        }
    });
};

const getSortedMarkets = (markets: Record<string, Array<EventMarketItemType>>): Array<string> => {
    return sortBy(
        Object.keys(markets),
        (id) => {
            let market = markets[id] ?? [];
            if (id === 'goalscorer') {
                const sortedSubMarkets = markets[id]?.sort((a: EventMarketItemType, b: EventMarketItemType) => {
                    return a.displayOrder - b.displayOrder;
                });
                market = sortedSubMarkets ?? [];
            }

            return market[0]?.displayOrder ?? 0;
        },
        (id) => {
            const market = markets[id] ?? [];
            return market[0]?.name;
        }
    );
};

interface PropsType {
    eventId: EventId;
    markets: Array<EventMarketItemType>;
}

export const EventMarkets = observer('EventMarkets', (props: PropsType) => {
    const markets = groupMarkets(props.markets);

    return (
        <div>
            {getSortedMarkets(markets).map((type, index) => {
                const marketsByType = markets[type];
                const marketType = type.includes('-ungrouped-') ? type.split('-ungrouped-')[0] : type;

                if (marketsByType === undefined || marketType === undefined) {
                    return null;
                }

                return (
                    <MarketGroup
                        key={index.toString()}
                        order={index}
                        type={marketType}
                        markets={marketsByType}
                        eventId={props.eventId}
                    />
                );
            })}
        </div>
    );
});
