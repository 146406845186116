import { action, computed, observable, makeObservable } from 'mobx';

export class NebetDefaultCompetitionState {
    @observable private competitionDefault: string | null = null;

    @action setDefaultCompetitionFilter = (competitionName: string | null): void => {
        this.competitionDefault = competitionName;
    }

    constructor() {
        makeObservable(this);
    }

    @computed get getCompetitionDefault(): string | null {
        return this.competitionDefault;
    }
}
