import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { SignupCloseIconWrapper } from 'src/domains/players/webview/components/SignUp/signupParts/Signup.style';
import { useAppStateContext } from 'src/appState/AppState';
import { AccountHelperInfo } from 'src/domains/players/webview/components/Account';
import { Header } from 'src/domains/players/webview/components/SignUp/signupParts/header/Header';
import { SignupTertiaryState } from 'src/domains/players/webview/components/SignUp/signupTertiary/signupState/SignUp.state';

interface LayoutSignUpPropsType {
    footer?: boolean;
    closeIcon?: boolean;
    children: React.ReactElement;
    signUpState: SignupTertiaryState;
}

export const LayoutSignUp = observer('LayoutSignUp', (props: LayoutSignUpPropsType) => {
    const { footer, closeIcon, children, signUpState } = props;
    const { appLayoutsState } = useAppStateContext();
    const {
        configComponents: {
            config: { layout },
        },
    } = appLayoutsState;

    return (
        <>
            <Header />
            {closeIcon !== undefined && closeIcon === false ? null : (
                <SignupCloseIconWrapper
                    layout={layout}
                    onClick={signUpState.toggleAside}
                    isWelcomePage={true}
                />
            )}
            {children}
            {footer === undefined ? null : <AccountHelperInfo version='secondary' />}
        </>
    );
});
