import { Resource } from 'src_common/common/mobx-utils/Resource';
import { observable, computed, action, makeObservable } from 'mobx';
import {
    AccountBasicDataType,
    CustomerTransactionHistoryType,
    CustomerNetDepositType,
} from 'src/domains/layouts/state/customer';
import { TransactionParamsType } from 'src/domains/players/webview/components/Account';
import { UsersState } from 'src/domains/players/state/UsersState';
import {
    OpenapiProxyCustomerDepositParamsType,
    OpenapiProxyCustomerDepositResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_deposit';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { WithdrawalsListState } from 'src/domains/players/state/WithdrawalsListState';
import { TrpcClient } from 'src/appState/TrpcClient';
import { OddsTypeValuesType } from 'src/domains/players/webview/components/Account/preferencesTab/SettingsForm.state';
import { ContactPreferencesType } from 'src/domains/layouts/config/features/types';
import { AutoMap } from 'src_common/common/mobx-utils/AutoMap';
import { signIn } from 'src_server/trpc/types';
import { Amount } from 'src_common/common/amount/Amount';
import { AmountPrecision } from 'src_common/common/amount/AmountPrecision';
import { accountResponse, setPlayBreak, setSelfExclusion } from 'src_server/trpc/types/accountModel';

type StartDateType = string | undefined;
type EndDateType = string | undefined;

const createUserTransactionHistory = (
    usersState: UsersState
): AutoMap<
    [number, number, StartDateType, EndDateType, Exclude<TransactionParamsType, 'all'> | null],
    Resource<CustomerTransactionHistoryType>
> =>
    new AutoMap(
        ([perPage, page, startDate, endDate, transactionType]) =>
            new Resource(async () => {
                const types = transactionType === null ? null : transactionType.split(';');

                return await usersState.postTransactionHistory({
                    requestBody: {
                        perPage,
                        page,
                        startDate,
                        endDate,
                        types,
                    },
                });
            })
    );

const createUserNetDeposit = (
    usersState: UsersState
): AutoMap<[StartDateType, EndDateType], Resource<CustomerNetDepositType>> =>
    new AutoMap(
        ([startDate, endDate]) =>
            new Resource(async () => {
                return await usersState.postNetDeposit({
                    requestBody: {
                        page: 1,
                        perPage: 250,
                        from: startDate,
                        to: endDate,
                    },
                });
            })
    );

interface ChangePasswordParamsType {
    oldPassword: string;
    newPassword: string;
}

export interface CountryDropdownOptionsType {
    label: string; //is prefix  -> '44'
    value: string; //is prefix+id -> '44,GB'
    id: string; //is country id -> 'GB'
}
export interface ChangePhoneParamsType {
    prefixFullData: CountryDropdownOptionsType;
    number: string;
}

export class AccountModel {
    @observable.ref public userTransactionHistory: AutoMap<
        [number, number, StartDateType, EndDateType, Exclude<TransactionParamsType, 'all'> | null],
        Resource<CustomerTransactionHistoryType>
    >;
    @observable.ref public userNetDeposit: AutoMap<[StartDateType, EndDateType], Resource<CustomerNetDepositType>>;

    public constructor(
        private readonly usersState: UsersState,
        private readonly userId: number,
        private readonly starRouter: StarRouter,
        private readonly withdrawalsListState: WithdrawalsListState,
        private readonly trpcClient: TrpcClient,
        private readonly amountPrecision: AmountPrecision
    ) {
        makeObservable(this);

        this.userTransactionHistory = createUserTransactionHistory(usersState);
        this.userNetDeposit = createUserNetDeposit(usersState);
    }

    @computed public get basicDataReady(): Omit<AccountBasicDataType, 'oddsFormat'> | null {
        return this.usersState.basicData.valueReady;
    }

    public refreshAll = async (): Promise<void> => {
        this.usersState.basicData.refresh();
        this.usersState.walletData.refresh();
        this.usersState.freeBetsData.refresh();
        this.userTransactionHistory = createUserTransactionHistory(this.usersState);
        this.userNetDeposit = createUserNetDeposit(this.usersState);

        if (this.starRouter.accountView?.account === 'withdraw') {
            await this.withdrawalsListState.refresh();
        }
    };

    public resetAll = (): void => {
        this.usersState.basicData.clear();
        this.usersState.walletData.clear();
        this.usersState.freeBetsData.clear();
    };

    @computed public get userID(): string | null {
        return this.userId.toString();
    }

    @action public onChangeOddsType = async (oddsFormat: OddsTypeValuesType): Promise<void> => {
        try {
            await this.trpcClient.client.accounts.changeOddsFormat.mutate({ oddsFormat });
        } catch (error) {
            console.error('changeOddsFormat - Incorrect response code', error);
        }
    };

    @action public onChangeContactPreferences = async (
        contactPreferences: Array<ContactPreferencesType>
    ): Promise<void> => {
        try {
            const response = await this.trpcClient.client.accounts.changeAccountContactPreferences.mutate({
                contactPreferences,
            });
            if (response.responseStatus === 'success') {
                this.usersState.basicData.refresh();
            }
        } catch (error) {
            console.error('Contact prefereces', error);
        }
    };

    @action public onChangePlayBreak = async (days: number): Promise<setPlayBreak.TPlayBrakeResponseType> => {
        const response = await this.trpcClient.client.accounts.setPlayBreak.mutate({ days });
        if (response.responseStatus === 'success') {
            this.usersState.basicData.refresh();
        }

        return response;
    };

    @action public onChangeSelfExclusion = async (
        months: number
    ): Promise<setSelfExclusion.TSelfExclusionResponseType> => {
        const response = await this.trpcClient.client.accounts.setSelfExclusion.mutate({ months });

        if (response.responseStatus === 'success') {
            this.usersState.basicData.refresh();
        }

        return response;
    };

    @action public onChangePassword = async ({
        oldPassword,
        newPassword,
    }: ChangePasswordParamsType): Promise<accountResponse.ResponseType | signIn.TSignInType> => {
        const basicData = this.usersState.basicData.valueReady;

        if (basicData === null) {
            throw new Error('No customer data');
        }

        if (basicData.email === undefined || basicData.email === null) {
            throw new Error('Wrong customer email');
        }

        const loginCustomerResponse = await this.trpcClient.client.signIn.signIn.mutate({
            username: basicData.email,
            password: oldPassword,
            grant_type: 'password',
        });

        if (loginCustomerResponse.responseStatus === 'error') {
            return loginCustomerResponse;
        }

        const response = await this.trpcClient.client.accounts.changePassword.mutate({ password: newPassword });

        return response;
    };

    @action public onUpdateTopUpLimits = async (
        daily: Amount | null,
        weekly: Amount | null,
        monthly: Amount | null
    ): Promise<void> => {
        if (daily !== null) {
            await this.trpcClient.client.accounts.changeTopUpLimits.mutate({
                period: 'daily',
                body: {
                    active: {
                        amount: this.amountPrecision.valueOldFormat(daily),
                    },
                },
            });
        }

        if (weekly !== null) {
            await this.trpcClient.client.accounts.changeTopUpLimits.mutate({
                period: 'weekly',
                body: {
                    active: {
                        amount: this.amountPrecision.valueOldFormat(weekly),
                    },
                },
            });
        }

        if (monthly !== null) {
            await this.trpcClient.client.accounts.changeTopUpLimits.mutate({
                period: 'monthly',
                body: {
                    active: {
                        amount: this.amountPrecision.valueOldFormat(monthly),
                    },
                },
            });
        }

        await this.usersState.depositLimitsData.refreshAndWait();
    };

    @action public onChangePhoneNumber = async ({ prefixFullData, number }: ChangePhoneParamsType): Promise<void> => {
        const response = await this.trpcClient.client.accounts.changePhoneNumber.mutate({
            mobilePhone: { prefix: prefixFullData.label, number, country: prefixFullData.id },
        });

        if (response.responseStatus === 'success') {
            this.usersState.basicData.refresh();
            return;
        }

        throw new Error(`onChange phone number - Incorrect response code ${response.data.code ?? ''}`);
    };

    @action public onSendTopUpAmount = async (
        data: OpenapiProxyCustomerDepositParamsType
    ): Promise<OpenapiProxyCustomerDepositResponse200Type> => {
        const response = await this.usersState.depositInitiate(data);
        this.usersState.basicData.refresh();
        this.usersState.walletData.refresh();

        return response;
    };

    @action public onChangeRingFencedFlag = async (): Promise<boolean | null> => {
        const response = await this.trpcClient.client.accounts.changeRingFencedFunds.mutate({ ringFencedFunds: true });

        if (response.responseStatus === 'success') {
            this.usersState.basicData.refresh();
            return true;
        }

        return false;
    };
}
