import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { FootballIcon } from 'src/domains/layouts/shared/assets/icons/nebet/FootballIcon';
import { GolfIcon } from 'src/domains/layouts/shared/assets/icons/nebet/GolfIcon';
import { CricketIcon } from 'src/domains/layouts/shared/assets/icons/nebet/CricketIcon';
import { TennisIcon } from 'src/domains/layouts/shared/assets/icons/nebet/TennisIcon';
import { HorseRacingIcon } from 'src/domains/layouts/shared/assets/icons/nebet/HorseRacingIcon';
import { GreyhoundRacingIcon } from 'src/domains/layouts/shared/assets/icons/nebet/GreyhoundRacingIcon';
import { RugbyIcon } from 'src/domains/layouts/shared/assets/icons/nebet/RugbyIcon';
import { NFLIcon } from 'src/domains/layouts/shared/assets/icons/nebet/NFLIcon';
import { VirtualsIcon } from 'src/domains/layouts/shared/assets/icons/VirtualsIcon';
import { DartsIcon } from 'src/domains/layouts/shared/assets/icons/nebet/DartsIcon';
import { LiveCasinoIcon } from 'src/domains/layouts/shared/assets/icons/nebet/LiveCasinoNebetIcon';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { CasinoIcon } from 'src/domains/layouts/shared/assets/icons/CasinoIcon';
import { withConfig } from 'src/withConfig';

function buildSvgDataUrlCss(svgCode: string): string {
    return `url('data:image/svg+xml;utf8,${encodeURIComponent(svgCode)}')`;
}

function buildNeBetTriangleEmpty(): string {
    return buildSvgDataUrlCss(
        `<svg fill="#5bbf21" xmlns="http://www.w3.org/2000/svg" width="200" height="185" viewBox="0 0 200 185">
            <path fill="fill" fillRule="nonzero" d="M154.15 184.53c18.5.06 32.94-6.75 40.28-19.37 7.35-12.64 6.1-28.56-3.19-44.78L137.2 26.06C128 10 114.88.9 100.28.86 85.68.8 72.5 9.81 63.2 25.81L8.42 119.97c-9.3 16-10.62 31.9-3.36 44.58 7.27 12.69 21.68 19.57 40.37 19.63l108.72.35.01-4.23-.01 4.23zm.02-8.46l-108.7-.35c-15.9-.05-27.45-5.57-33.07-15.38-5.62-9.8-4.56-22.54 3.33-36.11l54.78-94.16C78.4 16.5 88.96 9.27 100.25 9.31c11.29.04 21.8 7.33 29.6 20.95l54.05 94.32c7.9 13.8 8.9 26.55 3.22 36.33-5.68 9.76-17.25 15.21-32.95 15.16l-.01 4.23.01-4.23z"/>
         </svg>`
    );
}

function buildNeBetTriangleFilled(): string {
    return buildSvgDataUrlCss(
        `<svg fill="#5BBF21" xmlns="http://www.w3.org/2000/svg" width="200" height="185" viewBox="0 0 200 185">
            <path fill="#fill" fillRule="nonzero" d="M154.147 184.534c18.504.058 32.94-6.75 40.284-19.372 7.354-12.645 6.108-28.563-3.186-44.784l-54.05-94.322C127.996 10 114.88.902 100.28.854 85.675.808 72.499 9.821 63.197 25.815L8.422 119.974c-9.305 15.997-10.626 31.904-3.365 44.571 7.272 12.691 21.682 19.572 40.377 19.633l108.713.356.014-4.23-.014 4.23z"/>
        </svg>`
    );
}

export const SportTilesGridContentElement = styled('div', { label: 'SportTilesGridContentElement' })`
    align-content: center;
    align-self: center;
    display: flex;
    flex-flow: row wrap;
    flex: 1 1 0%;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    max-width: 620px !important;
    padding: 0 8px;
`;

export const SportTileElement = styled('div', { label: 'SportTileElement' })`
    height: 0;
    max-width: 200px;
    padding: 0 0 33.33333%;
    position: relative;
    width: 33.3333%;
`;

export const SportTileLinkElement = withConfig(theme => styled(Link, { label: 'SportTileLinkElement' })`
    align-items: center;
    background-image: ${buildNeBetTriangleEmpty()};
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 20%;
    bottom: 1.25vw;
    color: ${theme.star.sportTiles.txtColor};
    display: flex;
    flex-direction: column;
    font-size: 3.15vw;
    font-weight: 500;
    justify-content: center;
    left: 1.25vw;
    position: absolute;
    right: 1.25vw;
    text-align: center;
    text-decoration: none;
    top: 1.25vw;
    transition: color .2s ease;
    z-index: ${theme.star.zIndexGlobal.base};
    @media screen and (min-width: 640px) {
        font-size: ${theme.star.fontSize.xMedium};
    }
    &::before {
        background-image: ${buildNeBetTriangleFilled()};
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 0;
        content: '';
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity .2s ease;
        visibility: hidden;
        z-index: ${theme.star.zIndexGlobal.below};
    }
    &:hover {
        color: ${theme.star.sportTiles.txtColorSecondary};
        &::before {
            opacity: 1;
            visibility: visible;
        }
        svg {
            fill: currentcolor;
        }
    }
`);

export const IconLabel = styled('span', { label: 'IconLabel' })`
    display: block;
    line-height: 1;
    margin-top: 2.5vw;
`;

const svgIconStyled = (theme: EmotionTheme): string => {
    return `
        fill: ${theme.star.sportTiles.bgColorSecondary};
        margin: 10px 0 0;
        max-width: 43px;
        overflow: initial;
        width: 6.875vw;
        transition: fill .2s ease;
    `;
};

export const FootballIconWrapper = withConfig(theme => styled(FootballIcon, { label: 'FootballIconWrapper' })`
    ${svgIconStyled(theme)};
    width: 6.575vw;
    overflow: hidden;
`);

export const GolfIconWrapper = withConfig(theme => styled(GolfIcon, { label: 'GolfIconWrapper' })`
    ${svgIconStyled(theme)};
    width: 5.7vw;
`);

export const CricketIconWrapper = withConfig(theme => styled(CricketIcon, { label: 'CricketIconWrapper' })`
    ${svgIconStyled(theme)};
    width: 6.25wv;
`);

export const TennisIconWrapper = withConfig(theme => styled(TennisIcon, { label: 'TennisIconWrapper' })`
    ${svgIconStyled(theme)};
    width: 5.2vw;
`);

export const HorseRacingIconWrapper = withConfig(theme => styled(HorseRacingIcon, { label: 'HorseRacingIconWrapper' })`
    ${svgIconStyled(theme)};
    top: -5px;
    width: 9.375vw;
    @media screen and (min-width: 500px) {
        margin-top: 12px;
        max-width: 60px;
    }
`);

export const GreyhoundRacingIconWrapper = withConfig(theme => styled(GreyhoundRacingIcon, { label: 'GreyhoundRacingIconWrapper' })`
    ${svgIconStyled(theme)};
    width: 9.3vw;
    margin-top: 18px;
    @media screen and (min-width: 500px) {
        margin-top: 22px;
        max-width: 60px;
    }
`);

export const RugbyIconWrapper = withConfig(theme => styled(RugbyIcon, { label: 'RugbyIconWrapper' })`
    ${svgIconStyled(theme)};
    width: 6.0vw;
`);

export const NFLIconWrapper = withConfig(theme => styled(NFLIcon, { label: 'NFLIconWrapper' })`
    ${svgIconStyled(theme)};
    width: 6.25vw;
`);

//************************ TEMPORARY STYLES ***********************
//temporary new icons:

export const DartsIconWrapper = withConfig(theme => styled(DartsIcon, { label: 'DartsIconWrapper' })`
    ${svgIconStyled(theme)};
    width: 6.25vw;
`);

export const CasinoCherryIconWrapper = withConfig(theme => styled(CasinoIcon, { label: 'CasinoCherryIconWrapper' })`
    ${svgIconStyled(theme)};
    fill: #ff3b3b;
    width: 6.25vw;
    overflow: auto;
`);

export const VirtualSportsIconWrapper = withConfig(theme => styled(VirtualsIcon, { label: 'VirtualSportsIconWrapper' })`
    ${svgIconStyled(theme)};
    width: 5.95vw;
`);

export const LiveCasinoIconWrapper = withConfig(theme => styled(LiveCasinoIcon, { label: 'LiveCasinoIconWrapper' })`
    ${svgIconStyled(theme)};
    width: 6.25vw;
`);
