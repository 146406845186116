import React, { useState } from 'react';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import {
    HeaderCloseIcon,
    TitleWrapper,
    HeaderWrapper,
    ArrowBackIcon,
} from 'src/domains/players/webview/components/Account/accountDrawerHeader/AccountDrawerHeader.style';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { UniverseComponent } from 'src/domains/common/universes';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { AppPlayersState } from 'src/domains/players/state/AppPlayers.state';

export const TraderChatTitleHeader = observer('TraderChatTitleHeader', () => (
    <UniverseComponent>
        {(universeVersion): React.ReactElement => <universeVersion.traderChatHeaderLabel />}
    </UniverseComponent>
));

interface PropType {
    title: React.ReactNode | null | string;
    hasClose: boolean;
    hasBack: boolean;
    parent?: string;
    icon?: () => JSX.Element;
    isSmallerVersion?: boolean;
    isAuthorized?: boolean;
}

class AccountDrawerHeaderState {
    public constructor(
        private readonly parent: string | null = null,
        private readonly router: StarRouter,
        private readonly config: ConfigComponents,
        private readonly appPlayersState: AppPlayersState
    ) {}

    public redirectToParent = async (): Promise<void> => {
        if (this.config.config.accountDrawerNew) {
            this.router.closeAccount();
            await this.appPlayersState.modalState.closeTop();
            return;
        }

        if (this.parent !== null) {
            if (this.parent === 'summary') {
                this.router.redirectToAccountSummary();
            }
            if (this.parent === 'login') {
                this.router.redirectToLogin();
            }
            if (this.parent === 'reset-password') {
                const { token, email, isVerify } = this.router.resetPasswordParams;
                this.router.redirectToResetPassword(token ?? '', email ?? '', isVerify);
            }
        }
    };
}

export const AccountDrawerHeader = observer('AccountDrawerHeader', (props: PropType): React.ReactElement => {
    const { title, hasClose, hasBack, parent, isSmallerVersion, icon } = props;
    const { appPlayersState, appLayoutsState } = useAppStateContext();
    const { starRouter, configComponents } = appLayoutsState;

    const [state] = useState(
        () => new AccountDrawerHeaderState(parent ?? null, starRouter, configComponents, appPlayersState)
    );

    let backView = null;
    let iconView = null;
    let titleView = null;
    let closeView = null;

    const closeAccountSidebar = async (): Promise<void> => {
        starRouter.closeAccount();
        await appPlayersState.modalState.closeAll();
    };

    if (hasBack === true && parent !== undefined) {
        backView = (
            <ArrowBackIcon
                position='left'
                onClick={state.redirectToParent}
            />
        );
    }

    if (hasBack === true && parent === 'home') {
        backView = (
            <ArrowBackIcon
                position='left'
                onClick={closeAccountSidebar}
            />
        );
    }

    if (icon !== undefined) {
        iconView = <>{icon()}</>;
    }

    if (hasClose === true) {
        closeView = <HeaderCloseIcon onClick={closeAccountSidebar} />;
    }

    if (title !== null) {
        // TODO: data-test='title-header' same as in signupParts/termsAndConditions/TermsPage.tsx - should be changed to be more specific
        titleView = (
            <TitleWrapper
                key='title'
                hasIcon={iconView !== null || backView !== null}
                data-test='title-header'
                isSmallerVersion={isSmallerVersion}
            >
                {title}
            </TitleWrapper>
        );
    }

    return (
        <HeaderWrapper>
            {backView}
            {iconView}
            {titleView}
            {closeView}
        </HeaderWrapper>
    );
});
