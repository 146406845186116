import React, { useState } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { StakeInputMobx } from 'src/domains/sportsbook/betting/ui/betSlip/stakeInputMobx/StakeInputMobx';
import { observer } from 'src/utils/mobx-react';
import { Checkbox } from 'src/domains/sportsbook/betting/ui/checkbox/CheckBox';
import { PriceInput } from 'src/domains/sportsbook/betting/ui/betSlip/priceInput/PriceInput';
import { EventScore } from 'src/domains/sportsbook/betting/ui/betSlip/EventScore';
import { useAppStateContext } from 'src/appState/AppState';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { EventsCollectionState } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionState';
import { ErrorMessageDeprecated, InfoMessageDeprecated } from 'src/domains/layouts/webview/components/Messages';
import { LifeSpanButton } from 'src/domains/sportsbook/betting/ui/betSlip/betslipSelection/SelectionLifeSpanButton';
import { BetSlipSelectionState } from 'src/domains/sportsbook/betting/ui/betSlip/betslipSelection/BetslipSelection.state';
import { Amount } from 'src_common/common/amount/Amount';
import { DateTime } from 'src_common/utils/time/time';
import { BetslipSingleId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import {
    ReturnsWrapper,
    ReturnValue,
} from 'src/domains/sportsbook/betting/ui/betSlip/betslipCombination/BetslipCombination.style';
import {
    EachWayBox,
    SelectionOptions,
    BetSlipSelectionWrapper,
    EventTitle,
    MarketInfoWrapper,
    BetSlipError,
    ReturnWrapper,
    EachWayBoxLabel,
    InfoName,
    TrashIconWrapper,
    TitleWrapper,
    RelatedWarningIconWrapper,
    EventName,
    NameSeparator,
    BogIndicatorSelectionList,
} from './BetslipSelection.style';

interface BetslipSelectionPropsType {
    id: BetslipSingleId;
    isHidden?: boolean;
}

export const BetslipSelection = observer('BetslipSelection', ({ id, isHidden }: BetslipSelectionPropsType) => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { languagesState, lifeSpanState, sdkCustomer } = appLayoutsState;
    const { betSlipState, eventsCollection } = appSportsBookState;
    const { lifeSpanSocketState } = lifeSpanState;
    const [localState] = useState(() => new BetSlipSelectionState(id, betSlipState));
    const { isShowQuickBet } = betSlipState.basicBetSlipState;
    const { isReferred } = betSlipState.referralState;
    const { betTooHighValue } = betSlipState.betSlipSummaryState;

    const selectionWithBoost = lifeSpanSocketState.selectionsWithAvailableBoosts(id.toOldIdForLifespan);

    const legModel = id.getModel();

    const removeSelection = (): void => {
        localState.handleRemove(lifeSpanState, selectionWithBoost);
    };

    const renderCloseIcon = (): JSX.Element | null => {
        if (!isReferred) {
            return <TrashIconWrapper onClick={removeSelection} />;
        }
        return null;
    };

    const getEventDetails = (eventModel: EventModel, eventsCollection: EventsCollectionState): string => {
        const { timeSettingsStartTime } = eventModel;

        const competitionModel = eventsCollection.listOfSportAndCompetition('greyhoundracing', eventModel.competition);
        const competitionForView =
            competitionModel.competitionForView === null ? null : competitionModel.competitionForView[0];

        return `${competitionForView?.name ?? ''} ${DateTime.from(timeSettingsStartTime)?.format('DD MMM YYYY') ?? ''}`;
    };

    const renderPotentialReturn = (): JSX.Element | null => {
        const { priceType, returnsWithBoost, stakePerLine } = legModel;
        const isSP = priceType === 'sp';
        const returnWithBoost = returnsWithBoost === null ? new Amount('0') : returnsWithBoost;
        const showReturnValue =
            stakePerLine !== null && stakePerLine.isEqualWith(new Amount('0'))
                ? sdkCustomer.money(new Amount('0'))
                : sdkCustomer.money(returnWithBoost);
        const isStake = stakePerLine !== null && stakePerLine.isGreaterThanZero();

        return (
            <ReturnsWrapper>
                <ReturnWrapper data-test='returns'>
                    <I18n
                        langKey='betslip.returns.label'
                        defaultText='Returns:'
                    />
                    <ReturnValue>{isSP ? 'n/a' : showReturnValue}</ReturnValue>
                </ReturnWrapper>
                {betTooHighValue !== null && isStake ? (
                    <ReturnWrapper>
                        <I18n
                            langKey='betslip.max-bet.label'
                            defaultText='Max. bet:'
                        />

                        <ReturnValue>{sdkCustomer.money(betTooHighValue)}</ReturnValue>
                    </ReturnWrapper>
                ) : null}
            </ReturnsWrapper>
        );
    };

    const {
        handleChangeEachWay,
        selectionId,
        isSelectedSP,
        related,
        eachWay,
        errors,
        selectionChanged,
        returnsWithBoost,
        selectedPriceType,
        stakePerLine,
    } = legModel;
    const marketModel = legModel.getMarketModel();
    const eventModel = legModel.getEventModel();
    const selectionModel = legModel.getSelectionModel();
    const isEachWayExtra =
        typeof selectionModel?.templateMarketId === 'string' &&
        selectionModel.templateMarketId.includes('each-way-extra');
    const bogValue = sdkCustomer.basicData.valueReady?.bpEnabled ?? false;
    const displayBOG = marketModel !== null && marketModel.bp && bogValue && !isSelectedSP;

    if (marketModel !== null && eventModel !== null && selectionModel !== null) {
        const selectionWithBoost = lifeSpanSocketState.selectionsWithAvailableBoosts(selectionId.toOldIdForLifespan);
        const isStarted = eventModel.timeSettingsStarted;

        const eventName =
            marketModel.name === 'Trap Challenge' ? getEventDetails(eventModel, eventsCollection) : eventModel.name;

        return (
            <BetSlipSelectionWrapper
                ref={isShowQuickBet ? null : betSlipState.betslipData.setRef(id)}
                manyBets={betSlipState.legsIds2.length > 1}
                isHidden={isHidden}
            >
                <TitleWrapper>
                    <EventTitle>
                        {displayBOG ? (
                            <BogIndicatorSelectionList size='small'>
                                <I18n
                                    langKey='events.bog'
                                    defaultText='BOG'
                                />
                            </BogIndicatorSelectionList>
                        ) : null}
                        {related === true ? <RelatedWarningIconWrapper /> : null}

                        <EventName data-test='selection-name'>
                            {languagesState.getSelectionTranslationName(selectionModel)}
                        </EventName>

                        {eventModel.timeMatchStartedAndFinished ? <EventScore eventId={eventModel.id2} /> : null}
                    </EventTitle>
                    {!isShowQuickBet && renderCloseIcon()}
                </TitleWrapper>

                <MarketInfoWrapper>
                    <InfoName data-test='singles-market-name'>
                        {languagesState.getMarketTranslationName(marketModel)}
                    </InfoName>
                    <NameSeparator>|</NameSeparator>
                    <InfoName data-test='singles-event-name'>{eventName}</InfoName>
                </MarketInfoWrapper>

                <SelectionOptions>
                    <StakeInputMobx selectionId={id} />
                    <PriceInput selectionId={id} />
                    {marketModel.eachWay !== undefined &&
                    marketModel.eachWay.offered &&
                    selectionModel.name.toLowerCase() !== 'unnamed favourite' &&
                    eachWay !== null ? (
                        <EachWayBox data-test='ew-checkbox'>
                            <Checkbox
                                label={
                                    <EachWayBoxLabel>
                                        <I18n
                                            langKey='betslip.selection.each-way.label'
                                            defaultText='E/W'
                                        />
                                    </EachWayBoxLabel>
                                }
                                isChecked={eachWay}
                                isDisabled={isReferred || isEachWayExtra}
                                handleOnChange={handleChangeEachWay}
                            />
                        </EachWayBox>
                    ) : null}
                </SelectionOptions>

                {renderPotentialReturn()}

                {errors.length > 0 ? (
                    <BetSlipError>
                        {errors.map((error): JSX.Element | null => {
                            if (!selectionChanged && ['increased', 'decreased'].includes(error.code)) {
                                return null;
                            }
                            if (error.code === 'unavailable') {
                                // TODO: check if code 'unavailable' exists - if not, find how to show 'Selection is no longer avaiable' error info
                                return (
                                    <ErrorMessageDeprecated
                                        message={error}
                                        key={`${error.code}-${error.resource}`}
                                    />
                                );
                            }
                            return (
                                <InfoMessageDeprecated
                                    message={error}
                                    hasIcon={false}
                                    key={`${error.code}-${error.resource}`}
                                />
                            );
                        })}
                    </BetSlipError>
                ) : null}

                {selectionWithBoost === null ? null : (
                    <LifeSpanButton
                        isStarted={isStarted}
                        lifeSpanState={lifeSpanState}
                        selectionWithBoost={selectionWithBoost}
                        selectionId={selectionId.id}
                        legModelReturns={returnsWithBoost}
                        selectedPriceType={selectedPriceType}
                        stakePerLine={stakePerLine}
                    />
                )}
            </BetSlipSelectionWrapper>
        );
    }
    return null;
});
