import * as React from 'react';
import styled from '@emotion/styled';

interface PropsType {
    className?: string
}

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

export const FootballIcon = ({ className }: PropsType): JSX.Element => (
    <SvgElement fill='#fff' className={className} width={44} height={44} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44 44'>
        <path
            fill='#fill'
            d='M22 .38C10.06.38.38 10.06.38 22c0 11.94 9.68 21.62 21.62 21.62 11.94 0 21.62-9.68 21.62-21.62C43.609 10.064 33.936.391 22 .38zm10.5 26.38a.22.22 0 000 .26l-5.84-1.5-2.66-8 4.56-5.76 6.74.94 3.08 9.2-5.88 4.86zm-4.62-15.28l.14.14-4.34 5.46-8.6.1-2.74-4.56L15.6 5.9h11.34l.94 5.58zm-16 1.42l2.72 4.48L12 25.6v.1l-4.52 1.54-4.98-3.6L6.14 12.1l5.74.8zM7.56 27.72l4.9-1.72 6.76 4.82.62 6.1-4.3 3.46-9.8-6.86 1.82-5.8zm12.78 9.44l-.66-6.36 6.58-4.8 6.4 1.66 2.46 6.12-9.4 6.98-5.38-3.6zM42 22.68l-2.44-1.54a.28.28 0 00-.28 0l-.46.4-2.96-8.8.94.12a.24.24 0 00.2-.14l1.18-2.42A19.78 19.78 0 0142 22v.68zm-15.36-20a.28.28 0 00-.12.26l.44 2.46H15.78a.28.28 0 000-.28l-1.5-1.44A19.64 19.64 0 0122 2a20 20 0 014.56.6l.08.08zM4.82 12h.84L2.12 23.28V22a19.84 19.84 0 012.7-10zm1.9 22.86l8.2 5.84a19.76 19.76 0 01-8.2-5.92v.08zm20 6.58l-.6-.4 9.16-6.8.38.96a.24.24 0 00.22.16h.98a19.8 19.8 0 01-10.12 6l-.02.08z'
        />
    </SvgElement>
);
