import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { EventsCollectionList } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionList';
import {
    EventsFilterGroupFlagState,
    EventsFilterGroupComputeds,
} from 'src/domains/sportsbook/webview/components/eventsFilters/EventsFilterGroupComputeds';
import { FilterKindType } from 'src/domains/sportsbook/webview/components/eventsFilters/EventsFilterType';
import { MainSectionHR } from 'src/domains/layouts/webview/components/sectionHeader/SectionHeader.style';
import { EventsTableLoader } from 'src/domains/sportsbook/webview/components/eventsTable/InPlayLoader';
import { EventsTableSportLoader } from 'src/domains/sportsbook/webview/components/eventsTable/EventsTableSportLoader';
import {
    EventsMainSectionWrapper,
    EventsTableHeaderStyle,
} from 'src/domains/sportsbook/webview/components/eventsTable/EventsMainSectionWrapper';
import { EventsTableMarketsLoader } from 'src/domains/sportsbook/webview/components/loaders/EventsTableMarketsLoader';
import { EventsTableInfo } from 'src/domains/sportsbook/webview/components/sportEventsListNew/EventsTableMobX.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { FiltersSkinType } from 'src/domains/layouts/webview/components/filters/FiltersSkin';
import { useCommon } from 'src/domains/common/Common';

interface PropsType {
    allGroupsOpen: boolean;
    className?: string;
    dataTest?: string;
    eventsCollection: EventsCollectionList;
    filters: Array<FilterKindType>;
    headerStyle?: EventsTableHeaderStyle;
    icon: 'in-play' | 'star-fill' | null;
    isLoading: boolean;
    isNotOutright?: boolean;
    isSportPage?: boolean;
    isTogglable: boolean;
    showHeaderSport: boolean;
    showPriceLegend: boolean;
    hideNoEventsMessage?: boolean;
    title: React.ReactNode;
    headerStyleLevel: 'sportLevel' | 'competitionLevel';
    filterVersion: FiltersSkinType;
    isCompetitionPage?: boolean;
    isAmericanFootball: boolean;
    sportId?: string;
}

export const EventsTable = observer('EventsTable', (props: PropsType) => {
    const common = useCommon();
    const { appSportsBookState } = useAppStateContext();
    const specialSportState =
        props.sportId === undefined ? null : appSportsBookState.specialSportsMap.get(props.sportId);

    const [flag] = React.useState(() => new EventsFilterGroupFlagState());

    const eventsFilter = new EventsFilterGroupComputeds(
        common,
        () => props.eventsCollection.getQuery() ?? null,
        specialSportState,
        flag
    );
    const {
        allGroupsOpen,
        dataTest,
        eventsCollection,
        filters,
        headerStyle,
        icon,
        isLoading,
        isSportPage,
        isTogglable,
        showHeaderSport,
        showPriceLegend,
        title,
        hideNoEventsMessage,
        headerStyleLevel,
        filterVersion,
        isCompetitionPage,
        isAmericanFootball,
    } = props;

    if (isLoading === true) {
        if (isSportPage === true) {
            return <EventsTableSportLoader />;
        }
        return <EventsTableLoader />;
    }

    const collection = eventsFilter.filter;

    if (collection === null) {
        return <EventsTableMarketsLoader />;
    }

    const dataTestInfo = dataTest === undefined ? '' : dataTest;

    if (eventsCollection.length > 0) {
        return (
            <>
                <EventsMainSectionWrapper
                    allGroupsOpen={allGroupsOpen}
                    collection={collection}
                    dataTest={dataTestInfo}
                    eventsFilter={eventsFilter}
                    filters={filters}
                    headerStyle={headerStyle}
                    icon={icon}
                    isTogglable={isTogglable}
                    showHeaderSport={showHeaderSport}
                    showPriceLegend={showPriceLegend}
                    title={title}
                    headerStyleLevel={headerStyleLevel}
                    filterVersion={filterVersion}
                    isCompetitionPage={isCompetitionPage}
                    isAmericanFootball={isAmericanFootball}
                    specialSportState={specialSportState}
                />

                {eventsFilter.flag.isOpen ? null : <MainSectionHR />}
            </>
        );
    }
    if (hideNoEventsMessage === true) {
        return null;
    }
    return (
        <EventsTableInfo>
            <I18n
                langKey='events.table.empty'
                defaultText="Sorry, we haven't found any events with such criteria."
            />
        </EventsTableInfo>
    );
});
