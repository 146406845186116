import * as React from 'react';
import { ReactElement } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { PopularLoader, RecentlyViewedLoader, SportsLoader, HelpAndSupportLoader, RowLoader, LoaderContentWrapper } from './SidebarLoaderComponent.style';

export const SidebarLoaderComponent = observer('SidebarLoaderComponent', (): JSX.Element | null => {
    const { config:{ navigationPopular, navigationRecentlyViewed, navigationHelpSupport, layout } } = useAppStateContext();
    const numberArray = [53, 88, 95, 109, 55, 80, 107, 71, 51, 128, 97, 108, 89, 67, 102, 125, 89, 118, 96, 118, 64, 124, 84, 131, 111, 95, 66, 118, 112, 112, 73, 118, 127, 74, 111, 134, 93, 104, 48, 103, 80, 55, 71, 51, 128, 107, 97];

    const generatePlacehoder = (start: number ,end: number): ReactElement => {
        const length = end - start;
        const iterationArray = Array.from({ length }, (_, i) => 0 + i);
        return (
            <div>
                {iterationArray.map((element: number, iteration: number) => {
                    return <RowLoader width={numberArray[element] ?? 0} key={iteration}/>;
                })}
            </div>
        );
    };

    return (
        <LoaderContentWrapper layout={layout}>
            {navigationPopular === null ? null : <>
                <PopularLoader />
                {generatePlacehoder(0, 3)} </> }
            {navigationRecentlyViewed === true ? <>
                <RecentlyViewedLoader />
                {generatePlacehoder(4, 11)} </> : null }
            <SportsLoader />
            {generatePlacehoder(12, 42)}
            {navigationHelpSupport === true ? <>
                <HelpAndSupportLoader /> 
                {generatePlacehoder(43, 46)}</> : null}
        </LoaderContentWrapper>
    );
});
