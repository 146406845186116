import React from 'react';
import { useCommon } from 'src/domains/common/Common';
import {
    TraderChatMessageModel,
    TraderChatMessageModelId,
} from 'src/domains/players/webview/components/Account/traderChat/TraderChatMessageModel';
import { TraderChatState } from 'src/domains/players/state/traderChat/TraderChatState';
import { MessageItem } from 'src/domains/players/webview/components/Account/traderChat/components/Message.style';
import { DotsAnimation } from 'src/domains/players/webview/components/Account/traderChat/components/TypingMessage.style';
import { observer } from 'src/utils/mobx-react';

interface PropsType {
    traderChatMessageModel: TraderChatMessageModel;
}

export const TypingMessage = observer('TypingMessage', (props: PropsType) => {
    const common = useCommon();
    const { traderChatMessageModel } = props;
    const { isTraderTyping } = TraderChatState.get(common);

    if (isTraderTyping) {
        const id = TraderChatMessageModelId.forTyping();
        return (
            <MessageItem
                senderType='staff'
                ref={(element): void => traderChatMessageModel.setRef(id, element)}
            >
                <DotsAnimation />
            </MessageItem>
        );
    }

    return null;
});
