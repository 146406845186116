import * as React from 'react';
import styled from '@emotion/styled';

interface PropsType {
    className?: string
}

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

export const GreyhoundRacingIcon = ({ className }: PropsType): JSX.Element => (
    <SvgElement fill='#fff' className={className} width={32} height={17} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 17'>
        <path
            fill='#fill'
            d='M10.333 7.93a1.93 1.93 0 00.537-.19c.35.213.664.473.886.803.268.402.513.944.723 1.452-.397-.024-.921-.106-1.644-.295 0 0-1.434-.319-.711-1.263.163-.212.221-.377.21-.507m5.34 3.823c.43-.224.909-.555 1.387-.92-.175.46-.361.896-.525 1.25a7.302 7.302 0 01-.863-.33m1.423-2.513a7.039 7.039 0 01-1.69 1.616s-.315.107-.945.225a.617.617 0 00-.058-.036c-.012-.023-.024-.035-.035-.059a.486.486 0 00.14-.047c.174-.059.314-1.027-.42-1.062-.093-.012-.175 0-.268 0-.385-1.428-.117-3.304-.63-4.52l-.047-.094v-.071s.933.118 1.05 1.38c.081.85 1.19 2.36 2.903 2.668m6.867-7.093c-.07.024-.14.036-.21.06-1.014.236-2.751.495-4.477 0-2.67-.78-6.937-3.871-11.018-1.027a4.743 4.743 0 00-.991.92C6.73 2.714 3.932 5.688.038 5.711c0 0-.163.2.187.248.314.047 3.24.53 6.32-2.03-.13 1.782 1.2 3.529 2.692 3.942a49.095 49.095 0 01-.92 1.522c-.21.295 2.6 1.534 4.255 1.629.105 0 .198.011.292.011l.081.26c-.186.023-.396.035-.606.06 0 0-.606.152-.56.636.059.472.292.673.7.496.326-.154.746-.366 1.562-.425.443.142.921.295 1.178.39.198.058.571.212.98.353-.07.13-.117.224-.14.272-.175.342-.047.66-.047.66-.012.402.07 1.346 0 1.83-.082.472.093.601.163.649.07.035.944.378 1.05.378.104.01.21-.072.21-.072.104-.035.186-.2.104-.365-.082-.154-.455-.272-.583-.378-.14-.118-.151-.283-.151-.283-.047-.413.093-1.664.28-1.947a1.12 1.12 0 00.198-.46c.303.047.548.035.664-.083.408-.39.443-.743.443-.743a.422.422 0 00-.035-.402c-.14-.047-.326-.023-.501.024a23.535 23.535 0 012.355-3.399c2.029-1.215 3.592-4.97 9.922-3.622.525.082.607-.354.607-.354.058-.024.227-.338-.047-.449-.198-.059-1.294-.543-1.69-.684-.397-.142-.479-.33-.479-.33.455-.45 2.11-.272 2.565-.272.443 0 .572-.307.572-.307.28-.118.291-.39.338-.59.046-.189-.455-.236-.455-.236-.187-.094-.478-.13-1.131-.189-.665-.07-.805-.153-1.586-.554C28.067.507 26.983.46 26.4.743c0 0-1.457.449-2.752.555-1.002.082-.326.696.444.802a.41.41 0 01-.129.047'
        />
    </SvgElement>
);
