import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';



export const MainFooterWrapper = withConfig(theme => styled('footer', { label: 'MainFooterWrapper' })`

    color: ${theme.star.footerMain.txtColor};

    padding: 16px 16px 72px;

    @media ${theme.star.mediaQuery.tablet} {

        padding: 24px 24px 32px;

    }

`);



export const FooterSeparator = withConfig(theme => styled('hr', { label: 'FooterSeparator' })`

    background: ${theme.star.footerMain.borderColor};

    border: none;

    display: block;

    height: 1px;

    margin: 32px 0;

`);



export const FooterSectionGrid = withConfig(theme => styled('div', { label: 'FooterSectionGrid' })`

    display: grid;

    gap: 16px;

    grid-template-columns: 1fr 1fr;

    @media ${theme.star.mediaQuery.tablet} {

        grid-template-columns: repeat(4, 1fr);

    }

`);



export const FooterLegalInfoWrapper = styled('div', { label: 'FooterLegalInfoWrapper' })`

    display: flex;

    align-items: flex-start;

    margin-top: 32px;

`;



export const FooterLegalInfoDescription = withConfig(theme => styled('p', { label: 'FooterLegalInfoDescription' })`

    color: ${theme.star.footerMain.txtColorTertiary};

    font-size: ${theme.star.fontSize.small};

    line-height: 1.3334;

    margin: 0;

`);



export const ValidationScriptIconWrapper = withConfig(theme => styled('div', { label: 'ValidationScriptIconWrapper' })`

    background: ${theme.star.footerMain.bgColorSecondary};

    border-radius: 4px;

    flex: 0 0 42px;

    height: 42px;

    margin-right: 16px;

    overflow: hidden;

    padding: 5px;

    width: 42px;

`);



export const ValidationScriptImgWrapper = styled('img', { label: 'ValidationScriptImgWrapper' })`

    height: 42px;

    margin-right: 15px;

`;

