import React, { useState } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { AccountHelperInfo } from 'src/domains/players/webview/components/Account/component/accountHelperInfo/AccountHelperInfo';
import {
    AlignBottomWrapper,
    ForgotPasswordBtnWrapper,
    ForgotPasswordDescription,
    ForgotPasswordForm,
    ForgotPasswordWrapper,
} from './ForgotPasswordTab.style';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import {
    ForgotPasswordErrorMessageType,
    ForgotPasswordTabState,
} from 'src/domains/players/webview/components/Account/forgotPasswordTab/ForgotPasswordTab.state';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { ResetPasswordSent } from 'src/domains/players/webview/components/Account/forgotPasswordTab/resetPasswordSent/ResetPasswordSent';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

const renderMessage = (
    errorMessage: ForgotPasswordErrorMessageType,
    errorStatus: string | null,
    accountHelperMail: string
): JSX.Element | null => {
    if (errorMessage === null) {
        return null;
    }

    if (
        (errorMessage === 'suspended' || errorMessage === 'call-credit' || errorStatus === 'closed') &&
        errorStatus !== null
    ) {
        return (
            <Messages
                type='error'
                marginBottom='16px'
                message={
                    <I18n
                        langKey='account.reset-my-password.error-message'
                        defaultText='Your account has been {status} for security reasons. Please contact Customer Support at {accountHelperMail}'
                        params={{ status: errorStatus, accountHelperMail }}
                    />
                }
            />
        );
    }

    return (
        <Messages
            type='error'
            marginBottom='16px'
            message={errorMessage}
        />
    );
};

export const ForgotPasswordTab = observer('ForgotPasswordTab', () => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { configComponents, languagesState } = appLayoutsState;
    const { usersState } = appPlayersState;
    const { config } = configComponents;
    const { forgotPasswordInputType: inputType, accountHelperMail } = config;
    const [state] = useState(() => new ForgotPasswordTabState(accountHelperMail, usersState));

    if (state.showSuccessMessage === true) {
        return <ResetPasswordSent goBackToForgotPassword={state.goBackToForgotPassword} />;
    }

    return (
        <ForgotPasswordWrapper>
            <ForgotPasswordForm>
                <ForgotPasswordDescription
                    layout={config.layout}
                    data-test='forgot-password-description'
                >
                    <I18n
                        langKey='account.reset-my-password.form-description'
                        defaultText='Enter you email address and we will send you a link to reset your password. Reset link will only be sent if a valid account with the submitted email address was found'
                    />
                </ForgotPasswordDescription>

                <Input
                    name='email'
                    placeholder={languagesState.getTranslation('account.reset-my-password.email-placeholder', 'Email')}
                    colorTheme={config.layout === 'secondary' ? 'dark' : 'light'}
                    type={inputType}
                    label={languagesState.getTranslation('account.reset-my-password.email-label', 'Email')}
                    state={state.emailState}
                    inputHasBackground={false}
                    dataTest='forgot-password-email-input'
                />

                {renderMessage(state.errorMessage, state.errorStatus, accountHelperMail)}

                <ForgotPasswordBtnWrapper
                    onClick={state.resetPassword}
                    isLoading={state.inProgress}
                    disabled={!state.isEmailValid}
                    size='large'
                    layout={config.layout}
                    dataTest='recover-password-button'
                >
                    <I18n
                        langKey='account.reset-my-password'
                        defaultText='Recover Password'
                    />
                </ForgotPasswordBtnWrapper>
            </ForgotPasswordForm>
            <AlignBottomWrapper>
                <AccountHelperInfo />
            </AlignBottomWrapper>
        </ForgotPasswordWrapper>
    );
});
