import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface CasinoLkIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const CasinoLkIcon = observer(
    'CasinoLkIcon',
    ({ className, onClick }: CasinoLkIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                fill='#071126'
                height='24'
                onClick={onClick}
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fill='fill'
                    d='m8.51 11.88-.08-.01a.41.41 0 0 1-.32-.49c.75-3.8.05-6.23-.59-7.52l-.65-.37a.41.41 0 0 1-.22-.36V2.4a.41.41 0 0 1 .7-.3c.05.05.37.37.73 1.02 1.3-.05 6.47.18 9.6 7.05a.41.41 0 1 1-.75.35c-2.72-5.99-6.92-6.59-8.45-6.6.63 1.5 1.16 3.98.44 7.61a.41.41 0 0 1-.4.33Zm5.19 3.37c-.22-1.03-.71-2.4-1.82-3-.72-.38-1.62-.42-2.68-.12a9.2 9.2 0 0 1 1.26.69.28.28 0 1 1-.3.45c-.03 0-1.94-1.28-3.79-1.28-1.83 0-3.37 2.5-3.37 4.07 0 4 1.72 5.94 5.26 5.94 1.81 0 3.22-.61 4.2-1.82a6.52 6.52 0 0 0 1.24-4.93Zm-7.6-.75a.55.55 0 0 1-.42.39c-.09.02-.69.1-.84.05a.54.54 0 0 1-.36-.54c0-.4.32-.69.66-.75.43-.07.64.05.76.17a.7.7 0 0 1 .21.42c.02.07 0 .17-.01.26Z'
                />
                <path
                    fill='fill'
                    d='M21.34 11.95a2.65 2.65 0 0 0-1.53-1.33c-.83-.24-1.2 0-1.56.24-.21.13-.43.27-.7.27-.25 0-.48-.06-.73-.14a5.75 5.75 0 0 0-1.9-.28c-1.14 0-1.85 1.17-2.07 1.6a5.46 5.46 0 0 1 1.4 2.82c.12.6.14 1.58-.1 2.63.44.2 1.61.71 2.8.81 1.81.16 3.65.07 4.52-2.73a5.08 5.08 0 0 0-.13-3.9Zm-5.69 1.16c-.2 0-.38-.13-.56-.2h-.01a.43.43 0 0 1-.27-.28c-.06-.22.08-.4.27-.48.37-.15.89.05 1 .45a.42.42 0 0 1-.43.51Z'
                />
            </SvgElement>
        );
    }
);
