import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import {
    ResetPasswordFormWrapper,
    SetNewPasswordBtnWrapper,
    ResetCaption,
} from 'src/domains/players/webview/components/LuckyKingLogin/LuckyKingResetPassoword.style';
import { ResetPasswordFormState } from 'src/domains/players/webview/components/Account';
import { ResetPasswordSuccess } from 'src/domains/players/webview/components/LuckyKingLogin/ResetPasswordSuccess';
import { ResetPasswordRhsRouteType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface PropsType {
    currentRoute: ResetPasswordRhsRouteType;
}

export const LuckyKingResetPassword = observer('LuckyKingResetPassword', (props: PropsType) => {
    const { currentRoute } = props;
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { starRouter, configComponents } = appLayoutsState;
    const { accountState } = appPlayersState;

    const [resetPasswordState] = useState(
        () => new ResetPasswordFormState(starRouter, configComponents, true, false, accountState)
    );

    const errors = (
        <>
            {resetPasswordState.errors.map((message, i) => (
                <div key={i}>{message}</div>
            ))}
        </>
    );

    if (resetPasswordState.isSuccess === true) {
        return <ResetPasswordSuccess />;
    }

    return (
        <ResetPasswordFormWrapper
            onSubmit={(e): void => {
                e.preventDefault();
            }}
        >
            <ResetCaption data-test='reset-caption'>
                For security reasons you will need to choose a new password and then you will be ready to start
                wagering!
            </ResetCaption>
            <Input
                name='password'
                label='Create New Password'
                placeholder='Create New Password'
                state={resetPasswordState.newPasswordState}
                autocomplete='off'
                type={resetPasswordState.isShowPassword ? 'text' : 'password'}
                showPassword={resetPasswordState.setShowPassword}
                showPasswordFlag={true}
                isShowPassword={resetPasswordState.isShowPassword}
            />
            <Input
                name='confirm'
                label='Confirm New Password'
                placeholder='Confirm New Password'
                state={resetPasswordState.confirmPasswordState}
                autocomplete='off'
                type={resetPasswordState.isShowPassword ? 'text' : 'password'}
                showPassword={resetPasswordState.setShowPassword}
                showPasswordFlag={true}
                isShowPassword={resetPasswordState.isShowPassword}
            />

            {resetPasswordState.isSuccess ? (
                <Messages
                    type='success'
                    marginBottom='16px'
                    message={
                        <I18n
                            langKey='account.reset-password.success-message'
                            defaultText='Your password has been changed successfully. Now you can log in with new credentials.'
                        />
                    }
                />
            ) : null}

            {resetPasswordState.errors.length > 0 ? (
                <Messages type='error' marginBottom='16px' message={errors} />
            ) : null}

            <SetNewPasswordBtnWrapper
                isLoading={resetPasswordState.inProgress}
                disabled={resetPasswordState.inProgress === true || !resetPasswordState.isFormValid}
                type='submit'
                size='large'
                onClick={(): Promise<void> => resetPasswordState.onSubmit(currentRoute)}
            >
                <I18n langKey='account.reset-password.submit.label-one' defaultText='Reset my password' />
            </SetNewPasswordBtnWrapper>
        </ResetPasswordFormWrapper>
    );
});
