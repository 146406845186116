import React from 'react';
import { Selection } from 'src/domains/sportsbook/webview/components/Selection';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import {
    QuickPickColumnStyle,
    QuickPickColumnTitle,
} from 'src/domains/sportsbook/webview/components/quickPick/QuickPick.style';
import { observer } from 'src/utils/mobx-react';
import {
    QuickPickColumnHeaderName,
    QuickPickColumnHeaderHour,
    QuickPickCheckIconWrapper,
    SelectionNotRunning,
} from 'src/domains/sportsbook/webview/components/quickPick/QuickPickColumn.style';
import { EventId, SelectionId } from 'src_common/common/websocket2/id/WebsocketId';
import { DateTime } from 'src_common/utils/time/time';
import { useCommon } from 'src/domains/common/Common';
import { SdkCustomer } from 'src/domains/layouts/state/customer';

function getSelection(
    selection: SelectionModel,
    key: number,
    onHoverSelection: (selection: SelectionModel) => void
): React.ReactNode {
    const { resultType, display, id2 } = selection;

    if (resultType === 'void') {
        return (
            <SelectionNotRunning
                key={key}
                className='quick-pick__selection'
            >
                NR
            </SelectionNotRunning>
        );
    }

    if (!display) {
        return (
            <span
                key={key}
                className='quick-pick__selection'
            >
                -
            </span>
        );
    }

    return (
        <div
            className='quick-pick__selection'
            onMouseOver={(): void => onHoverSelection(selection)}
        >
            <Selection
                key={key}
                selectionId={id2}
            />
        </div>
    );
}

interface PropsType {
    event: EventModel;
    market: MarketModel[];
    selected: boolean;
    onHoverSelection(id: SelectionId): void;
    onSelect(id: EventId): void;
    sort: (selections: Array<SelectionModel>) => Array<SelectionModel>;
}

export const QuickPickColumn = observer('QuickPickColumn', (props: PropsType) => {
    const { event, market, selected, sort } = props;
    const common = useCommon();
    const sdkCustomer = SdkCustomer.get(common);

    const onHover = (selection: SelectionModel): void => {
        const { onHoverSelection } = props;
        onHoverSelection(selection.id2);
    };

    const onSelect = (): void => {
        const { onSelect, event } = props;
        onSelect(event.id2);
    };

    const startTime = DateTime.from(event.timeSettingsStartTime);
    //@ts-expect-error
    const selections = sort(market[0].selections.filter((x: SelectionModel) => x.display));

    let propsNew;
    if (!selected) {
        propsNew = {
            onClick: onSelect,
        };
    }

    //Event name handling same as in Component RaceBox, to clean name from additional info stored at creation.
    let name: string = event.name;

    if (/^[0-9]{2}:[0-9]{2}$/.exec(name.substr(0, 5)) !== null) {
        const nameChunk = name.split(' ');
        nameChunk.shift();
        name = nameChunk.join(' ');
    }

    const accountData = sdkCustomer.basicData.get();
    const bogValue = (accountData.type === 'ready' && accountData.value?.bpEnabled) ?? false;

    const bogcard = bogValue === true && market[0] !== undefined && market[0].bp ? <QuickPickCheckIconWrapper /> : null;

    return (
        <QuickPickColumnStyle
            key={event.id}
            isSelected={selected}
            data-test-disabled={selected}
            {...propsNew}
        >
            <QuickPickColumnTitle
                className='quick-pick__column__title'
                isSelected={selected}
                data-test={event.name}
            >
                {bogcard}
                <QuickPickColumnHeaderName>{name}</QuickPickColumnHeaderName>

                <QuickPickColumnHeaderHour>{startTime?.format('HH:mm')}</QuickPickColumnHeaderHour>
            </QuickPickColumnTitle>
            {selections.map((selection: SelectionModel) => getSelection(selection, selection.id, onHover))}
        </QuickPickColumnStyle>
    );
});
