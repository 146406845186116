import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { HamburgerLabel, LinkHamburgerMenu, HamburgerMenuIcon } from './HamburgerMenu.style';
import { useAppStateContext } from 'src/appState/AppState';

export const HamburgerMenu = observer('HamburgerMenu', () => {
    const { appLayoutsState } = useAppStateContext();
    return (
        <LinkHamburgerMenu
            dataTest='mobile-hamburger-menu'
            onClick={appLayoutsState.overlay.toggleMenu}
        >
            <HamburgerMenuIcon />
            <HamburgerLabel>
                <I18n
                    langKey='common.hamburger-menu.label'
                    defaultText='Hamburger Menu'
                />
            </HamburgerLabel>
        </LinkHamburgerMenu>
    );
});
