import { nanoid } from 'nanoid';
import { SerializedStyles } from '@emotion/react';
import { makeObservable, observable } from 'mobx';
import { AnimationType } from 'src/domains/layouts/webview/components/modals/Layer.style';

export type OverlayOnClickType = 'none' | 'closeTop' | 'closeAll';

export class ModalLayer {
    public readonly id: string;
    @observable public visible: boolean;

    public constructor(
        public readonly animation: AnimationType,
        public readonly content: React.ReactElement,
        public readonly contentCss: SerializedStyles,
        public readonly layerCss: () => SerializedStyles,
        public readonly overlayOnClick: OverlayOnClickType
    ) {
        makeObservable(this);
        this.id = nanoid();
        this.visible = true;
    }
}

export class ModalLayerState {
    @observable public isMount: boolean = false;

    public constructor() {
        makeObservable(this);
    }

    public setRef = (elem: HTMLElement | null): void => {
        if (elem !== null) {
            setTimeout(() => {
                this.isMount = true;
            }, 20);
        }
    };
}
