import React, { useState } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    ChangePhoneProcedureForm,
    InputsWrapper,
} from 'src/domains/players/webview/components/Account/personalDetailsTab/changePhoneProcedure/ChangePhoneProcedure.style';
import { observer } from 'src/utils/mobx-react';
import { AccountModel } from 'src/domains/players/state/accountState/AccountModel';
import { MobilePhoneDataType, ChangePhoneState } from './ChangePhoneProcedure.state';
import { useAppStateContext } from 'src/appState/AppState';
import { UpdateButton } from 'src/domains/players/webview/components/Account/personalDetailsTab/PersonalDetailsTab.style';
import { PhoneNumber } from 'src/domains/players/webview/components/SignUp';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface PropsType {
    account: AccountModel;
    initialPhoneNumberData: MobilePhoneDataType | null;
}

export const ChangePhoneProcedure = observer('ChangePhoneProcedure', (props: PropsType): JSX.Element | null => {
    const { appLayoutsState } = useAppStateContext();
    const { configComponents, languagesState } = appLayoutsState;
    const { config } = configComponents;
    const [state] = useState(
        () => new ChangePhoneState(props.account, props.initialPhoneNumberData, languagesState, config)
    );
    const isFormLoading = state.isLoading === true;
    const phoneNumberChangedMessage = languagesState.getTranslation(
        'account.update-phone.success-message',
        'You have successfully changed your contact number.'
    );

    return (
        <ChangePhoneProcedureForm>
            <InputsWrapper>
                <PhoneNumber
                    state={state.phoneNumberState}
                    hasPrefixes={true}
                    colorTheme='light'
                />
            </InputsWrapper>
            {state.isSuccessChangeNumber === true ? (
                <Messages
                    type='success'
                    marginTop='8px'
                    message={phoneNumberChangedMessage}
                />
            ) : null}
            <UpdateButton
                type='submit'
                size='large'
                onClick={state.onSubmit}
                isLoading={isFormLoading}
                dataTest='update-phone-button'
                disabled={state.isButtonDisabled}
            >
                <I18n
                    langKey='account.phone-procedure.submit.label'
                    defaultText='Update Phone Number'
                />
            </UpdateButton>
        </ChangePhoneProcedureForm>
    );
});
