import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { HeaderStyle, HeaderSpan, PanelContainer, FootballIconWrapper, FavouritesIconWrapper, Divider } from './LuckyKingPanel.style';

interface PropsType {
    title: string | JSX.Element,
    children: React.ReactNode,
    isAzMobile?: boolean,
    type: 'sports' | 'favourites' | undefined;
}

export const LuckyKingPanel = observer('LuckyKingPanel', (props: PropsType): JSX.Element => {

    const { title, children, isAzMobile = false, type } = props;

    return (
        <>
            <PanelContainer isAzMobile={isAzMobile}>
                <HeaderStyle>
                    {type === 'sports' ? <FootballIconWrapper /> : <FavouritesIconWrapper />}
                    <HeaderSpan>{title}</HeaderSpan>
                </HeaderStyle>
                {children}
            </PanelContainer>
            {type === 'favourites' ? <Divider /> : null}
        </>
      
    );
});
