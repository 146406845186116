import { observable, action, makeObservable } from 'mobx';
import { Common } from 'src/domains/common/Common';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';

export class VerifyAccountState {
    @observable public email: string | null = null;

    public static get = AutoWeakMap.create((_common: Common) => new VerifyAccountState());
    private constructor() {
        makeObservable(this);
    }

    @action public setEmail(email: string): void {
        this.email = email;
    }
}
