import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { GameplayDetailsWrapper, GameplayDetailsLabelLine, ShortDealerName } from './GameplayDetails.style';
import { Amount } from 'src_common/common/amount/Amount';
import { useCommon } from 'src/domains/common/Common';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';

interface GameplayDetailsPropsType {
    largeVersion: boolean;
    tableName: string;
    dealerName: string;
    hasBetlimits: boolean;
    minBet: Amount;
    maxBet?: Amount;
}

export const GameplayDetails = observer('GameplayDetails', (props: GameplayDetailsPropsType) => {
    const { largeVersion } = props;
    const common = useCommon();
    const basicData = BasicDataModel.get(common);

    const minBet = props.minBet.format(basicData.currency, { withoutRedundantDecimals: true });
    const maxBet =
        props.maxBet === undefined ? null : props.maxBet.format(basicData.currency, { withoutRedundantDecimals: true });

    const dealerName = 'auto-roulette' === props.tableName.toLocaleLowerCase() ? '' : props.dealerName;

    return (
        <GameplayDetailsWrapper largeVersion={largeVersion}>
            <GameplayDetailsLabelLine
                bold={true}
                largeVersion={largeVersion}
            >
                {props.tableName}
            </GameplayDetailsLabelLine>

            <GameplayDetailsLabelLine
                largeVersion={largeVersion}
                center={true}
            >
                {maxBet === null ? (
                    <>
                        <ShortDealerName>{dealerName}</ShortDealerName>
                        {props.hasBetlimits && <span>Min: {minBet}</span>}
                    </>
                ) : (
                    <>
                        <span>{dealerName}</span>
                        {props.hasBetlimits && (
                            <span>
                                {minBet}-{maxBet}
                            </span>
                        )}
                    </>
                )}
            </GameplayDetailsLabelLine>
        </GameplayDetailsWrapper>
    );
});
