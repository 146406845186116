import React from 'react';
import {
    AssigneeInfoWrapper,
    InfoIconWrapper,
} from 'src/domains/players/webview/components/Account/traderChat/components/AssigneeInfo.style';
import { TraderChatNotificationBar } from 'src/domains/players/webview/components/Account/traderChat/components/NotificationBar.style';
import { NotificationIcons } from 'src/domains/players/webview/components/Account/traderChat/components/NotificationIcons';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { useCommon } from 'src/domains/common/Common';
import { TraderChatState } from 'src/domains/players/state/traderChat/TraderChatState';

export const AssigneeInfoInChat = observer('AssigneeInfoInChat', () => {
    const common = useCommon();
    const { isWaitingForTrader } = TraderChatState.get(common);
    if (isWaitingForTrader) {
        const msgWaiting = (
            <I18n
                langKey='account.trader-chat.assignee-info.waiting'
                defaultText='Please wait for an admin to help you.'
            />
        );

        return (
            <AssigneeInfoWrapper data-test='assignee-info-in-chat'>
                <InfoIconWrapper />
                {msgWaiting}
            </AssigneeInfoWrapper>
        );
    }

    return null;
});

export const AssigneeInfo = observer('AssigneeInfo', () => {
    const common = useCommon();
    const { assignee, isNightModeEnabled } = TraderChatState.get(common);

    if (assignee && isNightModeEnabled === false) {
        const msg = (
            <I18n
                langKey='account.trader-chat.assignee-info.prefix'
                defaultText='You are now connected to one of our support team who will help you.'
            />
        );

        return (
            <TraderChatNotificationBar data-test='assignee-info'>
                <NotificationIcons />
                {msg}
            </TraderChatNotificationBar>
        );
    }

    return null;
});
