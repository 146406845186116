import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { SignUpSecondaryContainerWrapper } from 'src/domains/players/webview/components/SignUp/signupSecondary/SignUpSecondaryContainer';
import { SignUpTertiaryStepsContainer } from 'src/domains/players/webview/components/SignUp/signupTertiary/SignupStepsContainer';
import { SignUpPrimaryStepsContainer } from 'src/domains/players/webview/components/SignUp/signupPrimary/SignupStepsContainer';
import { OperatorTurnOffSignUp } from './operatorTurnOffSignUp/operatorTurnOffSignUp';

export const SignUp = observer('SignUp', (): JSX.Element => {
    const {
        appPlayersState,
        config: { signupType, signupTurnOffForLeavingOperator },
    } = useAppStateContext();
    const { signUpState } = appPlayersState;

    if (signupTurnOffForLeavingOperator.enabled) {
        return (
            <OperatorTurnOffSignUp
                operatorName={signupTurnOffForLeavingOperator.operatorName}
                date={signupTurnOffForLeavingOperator.date}
            />
        );
    }

    // Latam
    if (signupType === 'tertiary') {
        return <SignUpTertiaryStepsContainer signUpState={signUpState} />;
    }

    // LuckyKing
    if (signupType === 'secondary') {
        return <SignUpSecondaryContainerWrapper signUpState={signUpState} />;
    }

    // Rest operator
    return <SignUpPrimaryStepsContainer signUpState={signUpState} />;
});
