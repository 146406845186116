import { JackpotsListDetails } from './JackpotsListDetails';
import { JackpotForGameType } from './JackpotsDetailsModels';
import { Amount } from 'src_common/common/amount/Amount';
import { Common } from 'src/domains/common/Common';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';

export class JackpotsSocket {
    private readonly data: JackpotsListDetails | null = null;

    public constructor(
        public readonly websocket_casino_jackpots_host: string | null,
        public readonly isBrowser: boolean,
        private readonly common: Common
    ) {
        if (websocket_casino_jackpots_host === null) {
            console.warn('Casino jackpots host is not defined.');
            return;
        }
        this.data = new JackpotsListDetails(websocket_casino_jackpots_host, isBrowser);
    }

    public getModel(gameId: number): JackpotForGameType | null {
        const basicDataModel = BasicDataModel.get(this.common);
        if (this.data === null) {
            return null;
        }
        const jackpotsListModel = this.data.getModel();

        if (jackpotsListModel === null) {
            return null;
        }

        const list = jackpotsListModel.jackpotsList.jackpots;

        for (const jackpot of list) {
            if (jackpot.games.filter((jackpotGameId) => jackpotGameId === gameId).length > 0) {
                const jackpotAmount = new Amount(jackpot.amount);
                const jackpotAmountToShow = basicDataModel.money(jackpotAmount, true);

                return {
                    id: jackpot.id,
                    name: jackpot.name,
                    amount: jackpotAmountToShow,
                };
            }
        }
        return null;
    }
}
