import React from 'react';
import { CustomScrollbarWrapper } from './ScrollVertical.style';
import { CustomScrollbarPropsType } from './SrollVertical.types';
import { observer } from 'src/utils/mobx-react';

export const CustomScrollbar = observer('Switch', ({ children, mode }: CustomScrollbarPropsType) => {
    if (mode === 'light') {
        return <CustomScrollbarWrapper mode='light'>{children}</CustomScrollbarWrapper>;
    }

    return <CustomScrollbarWrapper mode='dark'>{children}</CustomScrollbarWrapper>;
});
