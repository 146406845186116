import { z } from 'zod';

export const criteria = z.object({
    field: z.union([
        z.literal('wallet.id'),
        z.literal('status'),
        z.literal('type'),
        z.literal('createdAt'),
        z.literal('tags.accountAdjustment'),
        z.literal('tags.tradingAdjustment'),
        z.literal('tags.adjustmentType'),
    ]),
    type: z.union([z.literal('lt'), z.literal('eq'), z.literal('gt'), z.literal('ne'), z.literal('in')]),
    value: z.union([z.string(), z.number(), z.boolean(), z.array(z.union([z.string(), z.number(), z.boolean()]))]),
});

export type CriteriaType = z.infer<typeof criteria>;

export const aggregation = z.object({
    field: z.union([z.literal('balanceDelta'), z.literal('amount')]),
    type: z.literal('sum'),
});

export const InputSchema = z.object({
    criteria: z.array(criteria),
    sort: z.array(z.string()),
    aggregations: z.object({
        limit: aggregation,
    }),
});

export const transactions = z.object({
    results: z.array(z.array(z.union([z.string(), z.number()]))),
    aggregations: z.object({
        limit: z.number().optional(),
    }),
});

export type TransactionsType = z.infer<typeof transactions>;

export const TrpcErrorResponseSchema = z.string();

type TerrorResponseType = z.infer<typeof TrpcErrorResponseSchema>;

export type TresponseType =
    | { responseStatus: 'success'; response: TransactionsType }
    | { responseStatus: 'error'; data: TerrorResponseType };
