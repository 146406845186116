import { CountryCodeType, isCountryCodeType } from 'src/domains/layouts/config/countries';
import { CurrencyType, isCurrencyCode } from 'src_common/common/amount/website-money/currency';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { countryCurrency } from 'src/domains/layouts/config/currency';
import { Common } from 'src/domains/common/Common';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';

export class GeolocalizationState {
    public static get = AutoWeakMap.create((common: Common) => new GeolocalizationState(common));
    private constructor(private readonly common: Common) {}

    public get currencyCode(): CurrencyType | undefined {
        const configComponents = ConfigComponents.get(this.common);
        const { currencySignUpList } = configComponents.config;

        const availableCurrenciesList: Array<CurrencyType> = [];

        for (const currency of currencySignUpList) {
            if (isCurrencyCode(currency)) {
                availableCurrenciesList.push(currency);
            }
        }

        const geolocationCurrency = this.getDefaultCurrencyByCountryCode(this.countryCode);
        const isCurrencyAvailable = availableCurrenciesList.some((currency) => currency === geolocationCurrency);
        return isCurrencyAvailable ? geolocationCurrency : undefined;
    }

    public get countryCode(): CountryCodeType | undefined {
        const envCountry = this.common.envVariables.country;
        if (!isCountryCodeType(envCountry)) {
            return undefined;
        }
        return envCountry;
    }

    private getDefaultCurrencyByCountryCode(countryCode: string | undefined): CurrencyType | undefined {
        if (countryCode === undefined || !isCountryCodeType(countryCode)) {
            return undefined;
        }
        return countryCurrency[countryCode];
    }
}
