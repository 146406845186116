import React from 'react';
import {
    CheckIcon,
    PrimaryDescription,
    Title,
    Wrapper,
    AccountHelperContainer,
    LoginBtnWrapper,
} from 'src/domains/players/webview/components/LuckyKingLogin/ResetPasswordSuccess.style';
import { AccountHelperInfo, AlignBottomWrapper } from 'src/domains/players/webview/components/Account';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';

export const ResetPasswordSuccess = observer('ResetPasswordSuccess', () => {
    const { appLayoutsState } = useAppStateContext();
    const { starRouter, configComponents } = appLayoutsState;
    const { config } = configComponents;

    return (
        <AccountHelperContainer layout={config.layout}>
            <Wrapper>
                <CheckIcon />
                <Title data-test='success-message-content'>
                    <I18n
                        langKey='account.reset-password-sent.title-one'
                        defaultText='Your password has been successfully changed'
                    />
                </Title>
                <PrimaryDescription data-test='primary-description'>
                    <I18n
                        langKey='account.reset-password-sent.primary-desc-one'
                        defaultText='You can now log back into your account and begin wagering…'
                    />
                </PrimaryDescription>
                <LoginBtnWrapper dataTest='log-in-button' size='large' onClick={starRouter.redirectToLogin}>
                    <I18n langKey='account.reset-password-sent.login' defaultText='Log in' />
                </LoginBtnWrapper>
                <LoginBtnWrapper
                    dataTest='return-home-button'
                    isShaded={true}
                    size='large'
                    onClick={starRouter.closeAccount}
                >
                    <I18n langKey='account.reset-password-sent.return-home' defaultText='Return Home' />
                </LoginBtnWrapper>
            </Wrapper>
            <AlignBottomWrapper>
                <AccountHelperInfo />
            </AlignBottomWrapper>
        </AccountHelperContainer>
    );
});
