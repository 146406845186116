import styled from '@emotion/styled';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import { withConfig } from 'src/withConfig';

export const LuckyKingWalletModalWrapper = withConfig(theme => styled('div', { label: 'LuckyKingWalletModalWrapper' })`
    ${positionElementFull}
    align-items: center;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: ${theme.star.zIndexGlobal.highLevel};
`);

export const TicketModalWrapper = withConfig(theme => styled('div', { label: 'TicketModalWrapper' })`
    background: ${theme.star.layoutGlobal.bgColor};
    height: 100%;
    opacity: 0.56;
    width: 100%;
`);
