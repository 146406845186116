import styled from '@emotion/styled';
import { RadioButtonsStateLess } from 'src/domains/layouts/webview/components/form/radioButtons/RadioButtons';
import { withConfig } from 'src/withConfig';

export const SectionWrapper = styled('div', { label: 'SectionWrapper' })`
    margin-top: 12px;
`;

export const SectionWrapperSecondary = styled('div', { label: 'SectionWrapperSecondary' })`
    margin-top: 16px;
`;

export const Header = withConfig(theme => styled('h3', { label: 'Header' })`
    line-height: 1.3334;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 14px 0 0 0;
`);

export const ButtonTitle = withConfig(theme => styled('span', { label: 'ButtonTitle' })`
    font-size: ${theme.star.fontSize.xRegular};
    min-width: 120px;
    width: 100%;
`);

export const ButtonTitleSecondary = withConfig(theme => styled('span', { label: 'ButtonTitleSecondary' })`
    display: flex;
    align-items: center;
    font-size: ${theme.star.fontSize.small};
    margin-right: 12px;
    gap: 4px;
`);

export const ButtonsWrapper = styled('div', { label: 'ButtonsWrapper' })`
    display: flex;
    margin-top: 12px;
`;

export const RadioButtonWrapper = styled(RadioButtonsStateLess, { label: 'RadioButtonWrapper' })`
    flex: 0 0 105px;
`;

export const RadioButtonWrapperLK = styled(RadioButtonsStateLess, { label: 'RadioButtonWrapperLK' })`
    & > * {
        width: 90px;
        font-size: 12px;
    }
`;

