import React from 'react';
import { ReactJsonViewProps } from 'react-json-view';
import { observer } from 'src/utils/mobx-react';
import { observable, makeObservable } from 'mobx';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { useCommon } from 'src/domains/common/Common';
import { SdkCustomer } from 'src/domains/layouts/state/customer';

type LibType = React.ComponentType<ReactJsonViewProps>;

class State {
    @observable public init: boolean = false;
    @observable.ref public lib: LibType | null = null;

    public constructor() {
        makeObservable(this);
    }

    public getLib(): LibType | null {
        setTimeout(() => {
            (async (): Promise<void> => {
                const lib = await import('react-json-view');
                this.lib = lib.default;
            })().catch((error) => {
                console.error(error);
            });
        }, 0);

        return this.lib;
    }
}

interface PropsType {
    currentView: RouteViewType | undefined;
}

export const CurrentEvent = observer('CurrentEvent', ({ currentView }: PropsType) => {
    const [state] = React.useState(() => new State());
    const common = useCommon();
    const sdkCustomer = SdkCustomer.get(common);

    if (currentView === undefined) {
        return null;
    }

    const eventId = sdkCustomer.models.id.getEventIdOption(
        currentView.name === 'event' ? currentView.id : currentView.name === 'racecard' ? currentView.selected : null
    );

    if (eventId === null) {
        return <div>Event missing</div>;
    }

    const model = eventId.getEventModel();

    if (model === null) {
        return <div>Event model - loading</div>;
    }

    const rawData = model.getRawData();

    const ReactJson = state.getLib();

    if (ReactJson === null) {
        return null;
    }

    return (
        <div>
            <ReactJson src={rawData} />
        </div>
    );
});
