import React, { useEffect } from 'react';
import { AutoHeightInput } from 'src/domains/players/webview/components/Account/traderChat/components/AutoHeightInput';
import { observer } from 'src/utils/mobx-react';
import { ChatWritingAreaForm, ChatWritingAreaBtn, SendIconWrapper } from './ChatInput.style';
import { useCommon } from 'src/domains/common/Common';
import { TraderChatState } from 'src/domains/players/state/traderChat/TraderChatState';

export const ChatInput = observer('ChatInput', () => {
    const common = useCommon();
    const { isNightModeEnabled, handleSubmitMessage, clearMessage, stopTyping } = TraderChatState.get(common);

    useEffect(() => {
        return (): void => {
            stopTyping();
            clearMessage();
        };
    }, []);

    if (isNightModeEnabled === true) {
        return null;
    }

    return (
        <ChatWritingAreaForm onSubmit={handleSubmitMessage}>
            <AutoHeightInput />
            <ChatWritingAreaBtn
                size='small'
                type='submit'
                dataTest='send-button'
            >
                <SendIconWrapper />
            </ChatWritingAreaBtn>
        </ChatWritingAreaForm>
    );
});
