import React, { useState } from 'react';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    DepositFailure,
    DepositViewType,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFailure/DepositFailure';
import { MessageDataType } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/Iframe';
import { RealexTabState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/RealexTab.state';
import { RealexTab } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/RealexTab';
import {
    RealexFrame,
    RealexFrameProps,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/realexFrame/RealexFrame';
import { DepositSignupRealex } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/DepositSignupRealex';
import { DepositFinalView } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFinalView/DepositFinalView';
import { Amount } from 'src_common/common/amount/Amount';
import { RemainingLimitState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/remainingLimit/RemainingLimit.state';

export type DepositStepsType =
    | {
          readonly type: 'set-method';
      }
    | {
          readonly type: 'set-method-signup';
      }
    | {
          readonly type: 'iframe-view';
          formActionUrl: string;
          formData: Record<string, string>;
          onMessage: (data: MessageDataType) => void;
      }
    | {
          readonly type: 'final-view';
          transactionId: number | undefined;
      }
    | {
          readonly type: 'failure-view';
          readonly failureType: DepositViewType;
      };

export class RealexDepositSteps {
    @observable.ref public step: DepositStepsType;

    public constructor(private readonly isSignup: boolean) {
        makeObservable(this);
        this.step = {
            type: this.isSignup ? 'set-method-signup' : 'set-method',
        };
    }

    @action public redirectToSetMethod = (): void => {
        this.step = {
            type: this.isSignup ? 'set-method-signup' : 'set-method',
        };
    };

    @action public redirectToIframeNewCard = ({ formActionUrl, formData, onMessage }: RealexFrameProps): void => {
        this.step = {
            type: 'iframe-view',
            formActionUrl,
            formData,
            onMessage,
        };
    };

    @action public redirectToFinalView = (transactionId: number | undefined): void => {
        this.step = {
            type: 'final-view',
            transactionId,
        };
    };

    @action public redirectToFailureView = (failureType: DepositViewType): void => {
        this.step = {
            type: 'failure-view',
            failureType,
        };
    };
}

interface PropsType {
    isSignup: boolean;
}

export const RealexJourney = observer('RealexJourney', ({ isSignup }: PropsType): JSX.Element => {
    const { appLayoutsState, appPlayersState, common } = useAppStateContext();
    const { configComponents, googleTagManager, languagesState } = appLayoutsState;
    const { accountHelperMail, minDepositAmount } = configComponents.config;
    const { accountState, usersState } = appPlayersState;

    const [remainingLimitState] = useState(() => new RemainingLimitState(common, usersState));
    const [state] = useState(
        () =>
            new RealexTabState(
                accountHelperMail,
                common,
                accountState,
                usersState,
                googleTagManager,
                isSignup,
                languagesState,
                new Amount(minDepositAmount),
                remainingLimitState
            )
    );

    const renderContent = (): JSX.Element => {
        switch (state.stepsState.step.type) {
            // step 1a: choose amount
            case 'set-method':
                return <RealexTab state={state} />;
            // step 1c: signup (choose amount)
            case 'set-method-signup':
                return <DepositSignupRealex state={state} />;
            // step 2a/2c: new card iframe
            case 'iframe-view':
                return (
                    <RealexFrame
                        formActionUrl={state.stepsState.step.formActionUrl}
                        formData={state.stepsState.step.formData}
                        onMessage={state.stepsState.step.onMessage}
                    />
                );
            // step 3a/3c
            case 'final-view':
                return (
                    <DepositFinalView
                        transactionId={state.stepsState.step.transactionId}
                        isSignup={isSignup}
                        accountHelperMail={accountHelperMail}
                        transactionCallback={state.transactionCallback}
                        hideDepositSuccess={state.hideDepositSuccess}
                    />
                );
            // step 4a/4c
            case 'failure-view':
                return (
                    <DepositFailure
                        isSignup={isSignup}
                        issueType={state.stepsState.step.failureType}
                        accountHelperMail={accountHelperMail}
                    />
                );
        }
    };

    return <>{renderContent()}</>;
});
