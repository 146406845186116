import { makeObservable, computed } from 'mobx';
import { Common } from 'src/domains/common/Common';
import { ChatMessageType } from 'src_server/trpc/types/chat';
import { TraderChatTyping } from './TraderChatTyping';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { findChatStatus } from './findChatStatus';
import { Value } from 'src_common/common/mobx-utils/Value';
import { WebsocketV1 } from 'src/domains/layouts/state/websocketV1/WebsocketV1';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { EventEmmiter } from 'src_common/common/mobx-utils/EventEmmiter';

const getChatTemplates = async (common: Common): Promise<ChatMessageType[]> => {
    try {
        const result = await common.trpcClient.client.chatRouter.getChatTemplates.query();
        if (result.responseStatus === 'success') {
            const messages = result.data.templates.messageTemplates;
            const messageTemplates = messages
                .map(
                    (msg, index): ChatMessageType => ({
                        id: `template-${String(index)}`,
                        type: 'standard',
                        content: { text: msg },
                        sender: {
                            name: 'system',
                            type: 'system',
                            id: 0,
                        },
                        sentAt: '',
                        tags: [],
                        chatId: undefined,
                    })
                )
                .reverse();
            console.info('messageTemplates', messageTemplates);
            return messageTemplates;
        }
    } catch (error) {
        return [];
    }
    return [];
};

const mergeMessagesAndSort = (list: ChatMessageType[]): ChatMessageType[] => {
    const result: ChatMessageType[] = [];
    const ids = new Set();

    for (const message of list) {
        if (ids.has(message.id)) {
            //duplicate
        } else {
            ids.add(message.id);
            result.push(message);
        }
    }

    result.sort((a, b) => {
        if (a.sentAt === '') {
            return -1;
        }

        if (b.sentAt === '') {
            return 1;
        }

        const date1 = new Date(a.sentAt).getTime();
        const date2 = new Date(b.sentAt).getTime();

        if (date1 > date2) {
            return 1;
        }

        if (date1 < date2) {
            return -1;
        }

        return 0;
    });

    return result;
};

interface SocketDataType {
    body: ChatMessageType;
}
export class TraderChatWebsocketData {
    private readonly messagesListApi: Resource<ChatMessageType[]>;
    private readonly templatesListApi: Resource<ChatMessageType[]>;
    private readonly messagesFromSocket: Value<ChatMessageType[]>;
    private readonly whenNewMessage: EventEmmiter<ChatMessageType> = new EventEmmiter();
    public readonly onWhenNewMessage = this.whenNewMessage.on;

    public static get = AutoWeakMap.create(
        (common: Common, userId: number) => new TraderChatWebsocketData(common, userId)
    );

    private constructor(
        private readonly common: Common,
        userId: number
    ) {
        makeObservable(this);

        this.messagesListApi = new Resource(async (): Promise<ChatMessageType[]> => {
            const response = await this.common.trpcClient.client.chatRouter.getChatData.query();

            if (response.responseStatus === 'success') {
                return response.data.messages;
            }

            return [];
        });

        this.templatesListApi = new Resource(() => getChatTemplates(common));

        const websocketV1 = WebsocketV1.get(common);

        this.messagesFromSocket = new Value([], () => {
            const callback = (data: unknown): void => {
                // @ts-expect-error
                this.applyData(data).catch((error) => {
                    console.error(error);
                });
            };

            const websocketMessageId = `${userId}:Chat`;
            websocketV1.subscribeOldVersionToDelete(websocketMessageId, callback);

            return (): void => {
                websocketV1.unsubscribe(websocketMessageId, callback);
            };
        });
    }

    private applyData = async (data: SocketDataType): Promise<void> => {
        const message = data.body;

        const type = message.type;
        const senderType = message.sender.type;

        const traderChatTyping = TraderChatTyping.get(this.common);

        if (type === 'typing') {
            if (senderType === 'staff') {
                traderChatTyping.setTraderTyping(true);

                this.whenNewMessage.trigger(message);
            }
            return;
        }

        traderChatTyping.setTraderTyping(false);
        this.whenNewMessage.trigger(message);
        this.messagesFromSocket.setValue([...this.messagesFromSocket.getValue(), message]);
    };

    private getMessages(withTemplates: boolean): ChatMessageType[] {
        const messagesListApi = this.messagesListApi.getReady() ?? [];
        const messagesFromSocket = this.messagesFromSocket.getValue();

        const templatesListApi = this.templatesListApi.getReady() ?? [];

        if (withTemplates) {
            return mergeMessagesAndSort([...messagesListApi, ...messagesFromSocket, ...templatesListApi]);
        } else {
            return mergeMessagesAndSort([...messagesListApi, ...messagesFromSocket]);
        }
    }

    public getLastStandardMessage = (): ChatMessageType | null => {
        const messages = this.getMessages(false).filter((item) => item.type === 'standard');
        return messages[messages.length - 1] ?? null;
    };

    @computed.struct public get messages(): ChatMessageType[] {
        return this.getMessages(true);
    }

    @computed public get statusThread(): 'assigned' | 'ended' | 'unassigned' {
        return findChatStatus(this.messages);
    }
}
