import { computed, action, makeObservable } from 'mobx';
import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { HeaderNavigationLinksState } from 'src/domains/players/state/specialEventsState/HeaderNavigationLink';
import { CreateAccountStep, SignupType } from 'src/domains/players/webview/components/SignUp';

import { StarLoginState } from 'src/domains/players/state/starLoginState/StarLoginState';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { LandingPage } from './LandingPage';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { DateTime } from 'src_common/utils/time/time';
import { Common } from 'src/domains/common/Common';

interface CalbacksType {
    largeDesktopIsBiggerOrEq: () => boolean | null;
}

export class SpecialEvents {
    private readonly router: StarRouter;
    private readonly signUpState: SignupType;
    private readonly loginState: StarLoginState;
    private readonly accountState: AccountState;
    private readonly googleTagManager: GoogleTagManagerState;
    public readonly headerNavigationLinks: HeaderNavigationLinksState;
    private readonly landingPageMap: MobxMapAutoNew<string | null, LandingPage>;
    public constructor(
        router: StarRouter,
        signUpState: SignupType,
        loginState: StarLoginState,
        accountState: AccountState,
        googleTagManager: GoogleTagManagerState,
        common: Common,
        callback: CalbacksType
    ) {
        makeObservable(this);
        this.router = router;
        this.signUpState = signUpState;
        this.loginState = loginState;
        this.accountState = accountState;
        this.googleTagManager = googleTagManager;
        this.landingPageMap = new MobxMapAutoNew((promotionSlug: string | null) => {
            return new LandingPage(this.router, this.signUpState, promotionSlug, common);
        });

        this.headerNavigationLinks = new HeaderNavigationLinksState(callback, common.session);
    }

    public getLandingPageState = (promotionSlug: string): LandingPage => {
        return this.landingPageMap.get(promotionSlug);
    };

    @action public logIn = (promotionSlug: string): void => {
        const result = this.loginState.loginFormModel.result;
        this.loginState.loginFormModel.setAsVisited();
        if (result.value.type === 'ok') {
            const data = {
                email: result.value.data.email,
                password: result.value.data.password,
            };

            (async (): Promise<void> => {
                const response = await this.accountState.loginUser(data.email, data.password, 'login');
                switch (response.type) {
                    case 'CreateSessionResponseOk':
                        this.loginState.loginFormModel.reset();
                        this.router.closeAccount();
                        this.getLandingPageState(promotionSlug).displayLP = false;
                        break;
                    case 'CreateSessionResponseErrors':
                        this.loginState.setLoginErrorMsg(response.error_description);
                        break;
                    case 'CreateSessionResponseErrorAccess':
                        this.loginState.verifyAccountState.setEmail(data.email);
                        this.router.redirectToVerifyAccount();

                        break;
                }
            })().catch((err) => {
                console.error(err);
                this.loginState.setLoginErrorMsg(getErrorByCode('ERROR_UNKNOWN'));
            });
        } else {
            console.log(result);
            this.loginState.setLoginErrorMsg(result.errors()[0] ?? '');
        }
    };

    @computed public get promoSignUpData(): CreateAccountStep | null {
        if (this.signUpState.state.currentView.step.type === 'create-account') {
            return this.signUpState.state.currentView.step.state;
        }

        return null;
    }

    @action public handleRedirectToNewSignup = (promotionSlug: string): void => {
        if (this.promoSignUpData !== null) {
            const promoCode = this.getLandingPageState(promotionSlug).promoCode;
            const date = DateTime.current().utc().format('YYYY-MM-DD HH:mm:ss [GMT]');
            this.googleTagManager.gtmSignUpStepOne(promoCode, date);

            if (this.signUpState.type === 'SignupPrimary') {
                this.signUpState.state.currentView.redirectToCreateAccount('your-details', {
                    promoCode: promoCode,
                    email: this.promoSignUpData.welcomeState.emailState.value,
                    password: this.promoSignUpData.welcomeState.passwordState.value,
                });
            }
        }

        this.router.redirectToSignUp();
        this.getLandingPageState(promotionSlug).displayLP = false;
    };

    // ------------------------------- new end

    @action public closePromoTermsConditions = (): void => {
        this.router.closeAccount();
    };
}
