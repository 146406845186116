import { action, computed, observable, makeObservable } from 'mobx';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { UsersState } from 'src/domains/players/state/UsersState';
import { Amount } from 'src_common/common/amount/Amount';

export class LuckyKingWalletState {
    @observable public showWithdrawPopup: boolean = false;
    @observable public showDepositPopup: boolean;

    public constructor(
        private readonly configComponents: ConfigComponents,
        private readonly usersState: UsersState,
        private readonly starRouter: StarRouter,
    ) {
        makeObservable(this);
        this.showDepositPopup = this.starRouter.accountParam === 'add-credit';
    }

    @computed public get showBalance(): string | null {
        const playableBalance = this.usersState.walletData.valueReady?.playableBalance;

        if (playableBalance !== undefined) {
            return this.usersState.money(new Amount(playableBalance));
        }
        return null;
    };

    @computed public get withdrawableBalance(): string | null {
        const withdrawableBalance = this.usersState.walletData.valueReady?.withdrawableBalance;

        if (withdrawableBalance !== undefined) {
            return this.usersState.money(new Amount(withdrawableBalance));
        }
        return null;
    };

    @computed public get freeBetsAmount(): string {
        const freeBetsAmount = this.usersState.freeBetsData.valueReady?.response?.totalAmount;

        if (freeBetsAmount !== undefined) {
            return this.usersState.money(this.configComponents.precision.newFromAnything(freeBetsAmount));
        }
        return '0';
    };

    @action private setShowWithdrawPopup = (): void => {
        this.showWithdrawPopup = !this.showWithdrawPopup;
    }

    @action public closeWithdrawPopup = (): void => {
        this.showWithdrawPopup = false;
    }

    @action private setShowDepositPopup = (): void => {
        this.showDepositPopup = !this.showDepositPopup;
    }

    @action public closeDepositPopup = (): void => {
        this.showDepositPopup = false;
    }

    @action public onDepositClicked = (): void => {
        this.setShowDepositPopup();
        this.closeWithdrawPopup();
    }

    @action public onWithdrawClicked = (): void => {
        this.setShowWithdrawPopup();
        this.closeDepositPopup();
    }
}
