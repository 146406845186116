import React from 'react';
import {
    ChatIconWrapper,
    LinkName,
    QuickHeaderButton,
    UnreadCount,
} from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/QuickHeader.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { TraderChatState } from 'src/domains/players/state/traderChat/TraderChatState';

export const ChatLink = observer('ChatLink', () => {
    const common = useCommon();

    const unreadMessagesCount = TraderChatState.get(common).unreadMessagesCount;
    const unreadCounter = unreadMessagesCount > 0 ? <UnreadCount>{unreadMessagesCount}</UnreadCount> : null;

    if (ConfigComponents.get(common).config.traderChat.isBubbleChatButton) {
        return null;
    }

    return (
        <QuickHeaderButton
            data-test='account-navigation-chat-link'
            isActive={false}
            onClick={StarRouter.get(common).redirectToTraderChat}
        >
            <ChatIconWrapper />
            <LinkName>
                <I18n
                    langKey='quick-header.live-chat.label'
                    defaultText='Chat'
                />
            </LinkName>
            {unreadCounter}
        </QuickHeaderButton>
    );
});
