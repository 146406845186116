import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';
import { RealityCheckPopupState } from 'src/domains/layouts/state/popups/RealityCheckPopupState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { ButtonWrapper, FakeLink, PopupContent, PopupHeader, RealityCheckPopupOverlay, RealityCheckPopupWrapper } from 'src/domains/layouts/webview/components/popups/realityCheckPopup/RealityCheckPopup.style';
import { observer } from 'src/utils/mobx-react';
import { RealityCheckNewValueConfirmationPopupDescription } from './RealityCheckNewValueConfirmationPopup.style';

interface ResponsibleGamblingType {
    onClick: () => void;
    state: RealityCheckPopupState;
}

const ResponsibleGambling = observer('ResponsibleGambling', ({ onClick, state }: ResponsibleGamblingType ) => {
    const { appLayoutsState } = useAppStateContext();
    const { languagesState } = appLayoutsState;

    const realityCheckTime = state.activeRealityCheckFrequencyForView ?? '';
    const realityCheckPendingTime = state.pendingRealityCheckFrequencyForView ?? '';

    return languagesState.translateTokens(
        languagesState.getTranslation(
            'reality-check-new-value-confirmation.popup.description',
            'Your reality check time has been updated from [currentTime] minutes to [pendingTime] minutes; you can adjust it [here]'
        ),

        (singleParam: LanguageTokenType) => {
            if (singleParam.tag === 'here') {
                return (
                    <FakeLink onClick={onClick}>here</FakeLink>
                );
            }
            if (singleParam.tag === 'currentTime') {
                return (
                    <span>{realityCheckTime}</span>
                );
            }
            if (singleParam.tag === 'pendingTime') {
                return (
                    <span>{realityCheckPendingTime}</span>
                );
            }
        }
    );
});

export const RealityCheckNewValueConfirmationPopup = observer('RealityCheckNewValueConfirmationPopup', (): JSX.Element | null => {
    const { appLayoutsState: { realityCheckPopupState }, config: { layout } } = useAppStateContext();

    return (
        <RealityCheckPopupOverlay>
            <RealityCheckPopupWrapper>
                <PopupContent>
                    <PopupHeader>
                        <I18n langKey='reality-check-new-value-confirmation.popup.header' defaultText='Reality check' />
                    </PopupHeader>

                    <RealityCheckNewValueConfirmationPopupDescription>
                        <ResponsibleGambling onClick={realityCheckPopupState.handleRedirectToGamblingLimits} state={realityCheckPopupState} />
                    </RealityCheckNewValueConfirmationPopupDescription>
                </PopupContent>

                <footer>
                    <ButtonWrapper size='medium' version='primary' onClick={realityCheckPopupState.handleAcceptPendingRealityCheckValue}>
                        <I18n langKey='reality-check-new-value-confirmation.popup.accept' defaultText='Accept New Time' />
                    </ButtonWrapper>

                    <ButtonWrapper size='medium' version={layout === 'primary' ? 'secondary-light' : 'tertiary-light'} onClick={realityCheckPopupState.handleRevertPendingRealityCheckValue}>
                        <I18n langKey='reality-check-new-value-confirmation.popup.revert' defaultText='No, Revert Back' />
                    </ButtonWrapper>
                </footer>
            </RealityCheckPopupWrapper>
        </RealityCheckPopupOverlay>
    );
});
