import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { observable, makeObservable } from 'mobx';
import { RTLPragmaticDebugDetails } from './RTLPragmaticDebugDetails';
import { SocketGameDetails, SocketGameList } from 'src/domains/casino/shared/Types';
import { AppState } from 'src/appState/AppState';
import { UniverseType, getAllUniverseStr } from 'src_common/common/universe';
import { TabWrapper } from 'src/domains/layouts/webview/components/debugPanel/DebugPanel.style';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { ConfigState } from 'src/domains/casino/state/AppCasino/ConfigState';
import { Common, useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const universes = getAllUniverseStr() as Array<UniverseType>;

type CasinoConfigType = {
    label: string;
    host: string;
    casinoId: string;
};

const getCasinoPragmaticSocketConfig = async (common: Common): Promise<Array<CasinoConfigType>> => {
    const configComponents = ConfigComponents.get(common);

    const output: Array<CasinoConfigType> = [];

    for (const universe of universes) {
        const config = configComponents.config.socketPragmatic;

        if (config !== null) {
            output.push({
                label: universe,
                host: config.host,
                casinoId: config.casinoId,
            });
        }
    }

    return output;
};

interface CasinoSocketListItemType {
    label: string;
    socketGameList: SocketGameList;
    socketGameDetails: SocketGameDetails;
}

class State {
    private readonly casinoPragmaticSocketConfig: Resource<Array<CasinoConfigType>>;
    @observable private selectValue: string | null = null;

    public constructor(
        private readonly common: Common,
        private readonly casinoConfig: ConfigState
        // private readonly isBrowser: boolean
    ) {
        this.casinoPragmaticSocketConfig = new Resource(() => getCasinoPragmaticSocketConfig(common));
        makeObservable(this);
    }

    public changeCasino = (newSelect: string): void => {
        this.selectValue = newSelect;
    };

    public get casinoSocketList(): Array<CasinoSocketListItemType> {
        const list = this.casinoPragmaticSocketConfig.getReady() ?? [];

        return list.map((item) => ({
            label: item.label,
            socketGameList: new SocketGameList(item.host, item.casinoId, this.common.isBrowser),
            socketGameDetails: new SocketGameDetails(
                item.host,
                item.casinoId,
                'GB',
                this.common.isBrowser,
                this.casinoConfig
            ),
        }));
    }

    public get current(): CasinoSocketListItemType | null {
        for (const item of this.casinoSocketList) {
            if (this.selectValue === item.label) {
                return item;
            }
        }

        const first = this.casinoSocketList[0];
        return first ?? null;
    }

    public get select(): string | null {
        return this.current?.label ?? null;
    }
}

const renderTabs = (state: State): React.ReactElement => {
    const options = [];

    for (const item of state.casinoSocketList) {
        options.push(
            <option
                key={item.label}
                label={item.label}
                selected={state.select === item.label}
            >
                {item.label}
            </option>
        );
    }

    return (
        <TabWrapper>
            <select
                onChange={(event): void => {
                    state.changeCasino(event.target.value);
                }}
                onBlur={(event): void => {
                    state.changeCasino(event.target.value);
                }}
            >
                {options}
            </select>
        </TabWrapper>
    );
};

interface PropsType {
    appState: AppState;
}

export const RTLPragmatic = observer('RTLPragmatic', ({ appState }: PropsType) => {
    const common = useCommon();
    const { appCasinoState } = appState;

    const [state] = React.useState(() => new State(common, appCasinoState.configState));
    const currentConfig = state.current;

    return (
        <>
            {renderTabs(state)}
            {currentConfig === null ? null : (
                <RTLPragmaticDebugDetails
                    label={currentConfig.label}
                    socketGameList={currentConfig.socketGameList}
                    socketGameDetails={currentConfig.socketGameDetails}
                />
            )}
        </>
    );
});
