import React from 'react';

import { LPOverlay } from './PromoSidebarPartials.style';

interface OverlayPropsType {
    shouldDisplay: boolean;
    isAnyLayerVisible: boolean;
}
export const Overlay = ({ shouldDisplay, isAnyLayerVisible }: OverlayPropsType): JSX.Element | null => {
    if (shouldDisplay === true) {
        if (isAnyLayerVisible) {
            return null;
        }
        return <LPOverlay />;
    }
    return null;
};
