import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { BitcoinIconWrapper, FooterGridItem, Logo18PlusWrapper } from 'src/domains/layouts/webview/others/luckyKing/Footer/PaymentAndGambling.style';

export const PaymentAndGambling = observer('PaymentAndGambling', () => (
    <>
        <FooterGridItem>
            <BitcoinIconWrapper />
        </FooterGridItem>

        <FooterGridItem>
            <Logo18PlusWrapper />
        </FooterGridItem>
    </>
));
