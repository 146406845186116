import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { SelectState } from './Select.state';
import { SelectWrapper, SelectLabel, SelectElement, EmptyOption } from './Select.style';

export interface SelectOptionsType {
    label: string;
    value: string;
}

interface SelectPropsType {
    className?: string;
    name: string;
    options: Array<SelectOptionsType>;
    hasEmptyOption?: boolean;
    placeholder?: string | JSX.Element;
    /** @default true */
    state: SelectState;
    isError?: boolean | undefined;
    hideError?: boolean;
    onChange?: () => void;
    disabled?: boolean;
    showIndicator?: boolean;
    dataTest?: string;
}

export const Select = observer('Select', (props: SelectPropsType): JSX.Element => {
    const {
        className,
        name,
        options,
        hasEmptyOption,
        placeholder,
        state,
        disabled,
        showIndicator = true,
        dataTest,
    } = props;

    const optionsJsx = [];

    for (const item of options) {
        const { label, value } = item;

        optionsJsx.push(
            <option
                key={value}
                value={value}
            >
                {label}
            </option>
        );
    }

    const onChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        state.setValue(event.currentTarget.value);
        if (props.onChange !== undefined) {
            props.onChange();
        }
    };

    return (
        <SelectWrapper
            isFocus={state.isFocus}
            isError={state.isError}
            className={className}
            showIndicator={showIndicator}
        >
            {placeholder !== undefined && state.currentValue === undefined ? (
                <SelectLabel
                    isFocus={state.isFocus}
                    isSelected={state.isSelected}
                >
                    {placeholder}
                </SelectLabel>
            ) : null}
            <SelectElement
                name={name}
                value={state.currentValue}
                isFocus={state.isFocus}
                isSelected={state.isSelected}
                onFocus={state.handleFocus}
                onBlur={state.handleBlur}
                onChange={onChange}
                disabled={disabled}
                data-test={dataTest}
            >
                {hasEmptyOption === true ? (
                    <EmptyOption
                        key={`first${name}`}
                        value=''
                        disabled
                        hidden
                        selected
                    />
                ) : null}

                {optionsJsx}
            </SelectElement>
        </SelectWrapper>
    );
});
