import styled from '@emotion/styled';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

export const HaveAccountWrapper = withConfig(theme => styled('div', { label: 'HaveAccountWrapper' })`
    text-align: center;
    font-weight: ${theme.star.fontWeight.medium};
    font-size: ${theme.star.fontSize.xRegular};
`);

export const HaveAccountText = styled('p', { label: 'HaveAccountText' })`
    display: inline-block;
    margin: 0;
    line-height: 1.43;
`;

export const HaveAccountRedirect = withConfig(theme => styled(Link, { label: 'HaveAccountRedirect' })`
    border: 0;
    background: none;
    text-decoration: none;
    color: ${theme.star.signUp.txtColorSecondary};
    margin: 0 0 0 8px;
    line-height: 1.43;
    font-weight: ${theme.star.fontWeight.bold};
    cursor: pointer;
`);
