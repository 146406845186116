import circlePattern from 'src/domains/layouts/shared/assets/icons/circle.svg';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { InfoIconSecond } from 'src/domains/layouts/shared/assets/icons/InfoIconSecond';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { withConfig } from 'src/withConfig';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

const TicketOverhead = css`
    border-radius: 8px;
    margin: 16px 24px;
    inline-size: fill-available;
`;

export const WithdrawTicketWrapper = styled('div', { label: 'WithdrawTicketWrapper' })`
    margin: 0 auto;
    position: fixed;
    width: 343px;
    @media (max-height: 550px) {
        top: 0;
        height: 100%;
        overflow-y: scroll;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
    @media (max-width: 343px) {
        top: 0;
        width: 100%;
    }
`;

export const SuccessMessageWrapper = styled(Messages, { label: 'SuccessMessageWrapper' })`
    width: calc(100% - 50px);
`;

export const WithdrawTicketHeaderWrapper = withConfig(theme => styled('header', { label: 'WithdrawTicketHeaderWrapper' })`
    background: ${theme.star.luckyKingWallet.bgColor};
    position: relative;
    top: 0;
    height: 70px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: ${theme.star.luckyKingWallet.txtColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: -2px;
`);

export const WithdrawTicketBodyWrapper = withConfig(theme => styled('article', { label: 'WithdrawTicketBodyWrapper' })`
    background: ${theme.star.luckyKingWallet.bgColor};
    position: relative;
    height: auto;
    margin-bottom: 24px;
    color: ${theme.star.luckyKingWallet.txtColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`);

export const HorizontalLine = withConfig(theme => styled('hr', { label: 'HorizontalLine' })`
    display: block;
    border: 0;
    width: 90%;
    height: 2px;
    background: linear-gradient(to right, transparent 50%, ${theme.star.luckyKingWallet.bgColorQuaternary} 50%);
    background-size: 16px 2px, 100% 2px;
    margin: -14px;
    padding: 0;
    z-index: ${theme.star.zIndexGlobal.highLevel};
    position: relative;
    margin-left: 14px;
`);

export const WithdrawTicketStrapWrapper = styled('div', { label: 'WithdrawTicketStrapWrapper' })`
    background: transparent;
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    height: 24px;
`;

export const WithdrawTicketStrap = withConfig(theme => styled('div', { label: 'WithdrawTicketStrap' })<{position: 'left' | 'right'}>`
    background: ${theme.star.luckyKingWallet.bgColor};
    mask-image: ${({ position }): string => position === 'left' ? 'radial-gradient(circle at left, transparent 10px, black 5px)' : 'radial-gradient(circle at right, transparent 10px, black 5px)' };
    height: 24px;
    margin-left: ${({ position }): string => position === 'left' ? '0' : '-10px' };
`);

export const LogoCoinsPaidFooter = withConfig(theme => styled('footer', { label: 'LogoCoinsPaidFooter' })`
    background: ${theme.star.luckyKingWallet.bgColor};
    padding: 16px 9px 26px 10px;
    position: relative;
    height: 104px;
    margin-top: -2px;
    &::after {
        background: url(${circlePattern}) 6px top / auto 100% repeat-x;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        right: 0;
        top: 100%;
    }
`);

export const LogoCoinsPaidWrapper = withConfig(theme => styled('div', { label: 'LogoCoinsPaidWrapper' })`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${theme.star.luckyKingWallet.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4285;
    text-transform: capitalize;
    width: 100%;
`);

export const WithdrawTicketCloseBtn = withConfig(theme => styled(CloseIcon, { label: 'WithdrawTicketCloseBtn' })`
    cursor: pointer;
    fill: ${theme.star.luckyKingWallet.bgColorQuaternary};
    margin: 19px;
    height: 18px;
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
    :hover {
        fill: ${theme.star.luckyKingWallet.txtColor};
    }
`);

export const WithdrawTicketInfoIcon = withConfig(theme => styled(InfoIconSecond, { label: 'WithdrawTicketInfoIcon' })`
    font-size: ${theme.star.fontSize.small};
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
`);

export const WithdrawTicketCaption = withConfig(theme => styled('div', { label: 'WithdrawTicketCaption' })`
    font-size: ${theme.star.fontSize.xSmall};
    width: 100%;
    margin-left: 10px;
`);

export const WithdrawTicketDetailsWrapper = withConfig(theme => styled('div', { label: 'WithdrawTicketDetailsWrapper' })`
    position: relative;
    display: none;
    flex-direction: row;
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.4;
    margin: 0 24px 24px 24px;
`);

export const WithdrawTicketTitle = withConfig(theme => styled('h2', { label: 'WithdrawTicketTitle' })`
    font-size: ${theme.star.fontSize.medium};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 24px 24px 0 24px;
    text-align: center;
`);

export const WithdrawTicketUnderTitle = withConfig(theme => styled('p', { label: 'WithdrawTicketUnderTitle' })`
    font-weight: ${theme.star.fontWeight.regular};
    font-size: ${theme.star.fontSize.xSmall};
    margin: 0;
    text-align: center;
`);

export const WithdrawTicketWalletWrapper = styled('div', { label: 'WithdrawTicketWalletWrapper' })`
    ${TicketOverhead}
`;

export const WithdrawTicketBtnWrapper = styled(Button, { label: 'WithdrawTicketBtnWrapper' })`
    display: block;
    ${TicketOverhead}
`;

export const BalanceRowWrapper = styled('div', { label: 'BalanceRowWrapper' })``;

export const WithdrawInput = styled(Input, { label: 'WithdrawInput' })``;

export const WithdrawForm = styled('form', { label: 'WithdrawForm' })`
    width: 100%;
`;

export const Swiper = styled('nav', { label: 'Swiper' })`
    width: 100%;
    overflow-x: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;
