import { Common } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { PopupAgreeOrCancel, SignupPopupState } from 'src/domains/players/state/SignupPopupState';
import { UsersState } from 'src/domains/players/state/UsersState';
import { RealexTabState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/RealexTab.state';

class WithdrawFundsPopupState {
    public constructor(
        private readonly depositState: RealexTabState,
        private readonly signupPopupState: SignupPopupState,
        private readonly common: Common
    ) {}

    public renderWithDrawFundsPopup = (): void => {
        const language = LanguagesState.get(this.common);

        const popUpTitle = language.getTranslation('signup-journey.popups.withdrawfunds-popup-title', 'Withdraw funds');
        const popUpText = language.getTranslation(
            'signup-journey.popups.withdrawfunds-popup-text',
            'On occasion we may need to perform checks on your account before you can withdraw funds. This requires proof of identity as well as proof of address.'
        );

        const popup = new PopupAgreeOrCancel(popUpTitle, popUpText, this.agreeAction, this.cancelAction);
        this.signupPopupState.show(popup);
    };

    private agreeAction = async (): Promise<void> => {
        await this.depositState.submitDepositForm();
    };

    private cancelAction = (): void => {
        this.depositState.onSetInputToEmpty();
    };
}

export class DepositSignupPageState {
    private readonly popupStateInner: WithdrawFundsPopupState;

    public constructor(
        private readonly state: RealexTabState,
        private readonly usersState: UsersState,
        private readonly common: Common,
        signupPopupState: SignupPopupState
    ) {
        this.popupStateInner = new WithdrawFundsPopupState(state, signupPopupState, common);
    }

    public renderPopUpAndChangeFlag = async (): Promise<void> => {
        const configComponents = ConfigComponents.get(this.common);

        if (
            configComponents.config.hideWithdrawFundsPopupOnSignup === false &&
            this.usersState.basicData.valueReady?.ringFencedFunds === false
        ) {
            return this.popupStateInner.renderWithDrawFundsPopup();
        } else {
            await this.state.submitDepositForm();
        }
    };
}
