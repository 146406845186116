import React, { RefObject, useRef } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { BreakpointsState } from 'src/domains/layouts/state/breakpointsState/BreakpointsState';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';
import { MatchLeadWrapper } from 'src/domains/sportsbook/webview/components/matchLead/MatchLeadWrapper';
import { MarketFilters } from 'src/domains/sportsbook/webview/components/marketFilters/MarketFilters';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import NFLlogo from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/Official_NFL_logo.webp';
import {
    renderRAB,
    renderViewRab,
    contentMarkets,
    renderContentMarketsRAB,
    renderViewNormal,
    renderRABBottomBetslip,
} from './MatchCardComponents';
import {
    EventsNFLLogoImage,
    EventsNFLLogoImageWrapper,
} from 'src/domains/sportsbook/webview/components/eventsTable/EventsTable.style';
import { MatchCardSection } from './MatchCard.style';

const getMarketsWrapperWidth = (
    breakpointsState: BreakpointsState,
    marketsWrapperRef: RefObject<HTMLDivElement>
): number | undefined => {
    const windowWidth = getWindowInnerWidth();

    const { desktop } = breakpointsState;

    if (windowWidth === null) {
        return undefined;
    }

    if (desktop.isBiggerOrEq === false) {
        return windowWidth;
    }
    return marketsWrapperRef.current?.getBoundingClientRect().width;
};

interface MatchCardPropsType {
    eventId: EventId;
    isFooter: boolean;
}

export const MatchCard = observer('MatchCard', (props: MatchCardPropsType) => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { breakpointsState } = appLayoutsState;
    const { isFooter, eventId } = props;
    const rabState = appSportsBookState.rab;
    const rabId = rabState.getOrCreateRabBasket(eventId).id;
    const rabAvailable = rabState.isAvailableForEvent(eventId);
    const { isBetBuilderOpen: isYouDecideOpen } = rabState;

    const marketsWrapperRef = useRef<HTMLDivElement>(null);
    const reactiveWrapper: HtmlElementReactive<HTMLElement> = new HtmlElementReactive(500);

    const event = eventId.getEventModel();
    if (event === null) {
        return null;
    }

    const isEventStarted = event.timeSettingsStarted;

    const marketFiltersForSport = appSportsBookState.getMarketFiltersBySport(event.sport, eventId);

    const elementRefHeight = (): number | null => {
        const elementRef = reactiveWrapper.ref;
        return elementRef?.clientHeight ?? null;
    };

    return (
        <MatchCardSection
            isFooterVisible={isFooter}
            rabBetslipHeight={elementRefHeight()}
        >
            <>
                <MatchLeadWrapper event={event} />
                {rabAvailable ? renderRAB(rabState, eventId) : null}
            </>

            {isYouDecideOpen === false && marketFiltersForSport.isMarketFiltersAvailableForSport ? (
                <MarketFilters marketFiltersForSport={marketFiltersForSport} />
            ) : null}

            {rabAvailable === true && isEventStarted === false
                ? renderViewRab(
                      marketsWrapperRef,
                      contentMarkets(marketFiltersForSport, event),
                      getMarketsWrapperWidth(breakpointsState, marketsWrapperRef),
                      isYouDecideOpen,
                      rabAvailable,
                      renderContentMarketsRAB(rabId)
                  )
                : renderViewNormal(
                      marketsWrapperRef,
                      contentMarkets(marketFiltersForSport, event),
                      getMarketsWrapperWidth(breakpointsState, marketsWrapperRef),
                      isYouDecideOpen,
                      rabAvailable
                  )}

            {isYouDecideOpen === true && rabAvailable === true && isEventStarted === false
                ? renderRABBottomBetslip(reactiveWrapper, isFooter, rabId)
                : null}

            {event.sport === 'americanfootball' ? (
                <EventsNFLLogoImageWrapper>
                    <EventsNFLLogoImage
                        src={NFLlogo}
                        alt='Official NFL logo'
                    />
                </EventsNFLLogoImageWrapper>
            ) : null}
        </MatchCardSection>
    );
});
