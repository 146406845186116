import styled from '@emotion/styled';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

export const FooterLinksWrapper = withConfig(theme => styled('div', { label: 'FooterLinksWrapper' })`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 32px 16px;
    @media ${theme.star.mediaQuery.tablet} {
        margin-bottom: 32px;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
    }
`);

export const FooterList = styled('nav', { label: 'FooterList' })`
    display: flex;
    flex-flow: column nowrap;
`;

export const FooterHeader = withConfig(theme => styled('h3', { label: 'FooterHeader' })`
    color: ${theme.star.footerMain.txtColorTertiary};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    text-transform: uppercase;
    line-height: 1.4286;
    margin: 0 0 8px 0;
`);

export const LinkWrapper = withConfig(theme => styled(Link, { label: 'LinkWrapper' })`
    align-self: flex-start;
    color: ${theme.star.footerMain.txtColorTertiary};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    text-decoration: none;
    transition: color .2s ease;
    &:hover {
        color: ${theme.star.footerMain.txtColor};
    }
    &:not(:last-of-type) {
        margin-bottom: 8px;
    }
`);
