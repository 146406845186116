import React, { ReactElement } from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState, GameDataState } from 'src/domains/casino/state/AppCasino.state';
import { GameStatus } from './game-status/GameStatus';
import { GameContentWrapper, IframeContainer, GameIframe } from './GameContent.style';

interface GameContentPropsType {
    appCasinoState: AppCasinoState;
    url: string;
    gameDataState: GameDataState;
}

export const GameContent = observer('GameContent', (props: GameContentPropsType): ReactElement => {
    const { appCasinoState } = props;
    const { onIframeMessage, setIframeRef, isFullScreen } = appCasinoState.gameModalState;

    return (
        <GameContentWrapper data-test='game-modal-success'>
            <IframeContainer>
                <GameIframe
                    ref={setIframeRef}
                    title={`${props.gameDataState.gameType}-game-modal`}
                    src={props.url}
                    onMessage={onIframeMessage}
                    dataTest='game-iframe'
                    isFullscreen={isFullScreen}
                    isMini={props.gameDataState.isMini}
                />
            </IframeContainer>
            <GameStatus
                appCasinoState={appCasinoState}
                gameDataState={props.gameDataState}
            />
        </GameContentWrapper>
    );
});
