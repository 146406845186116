import { computed, makeObservable } from 'mobx';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { Response200Type } from 'src/api_openapi/generated/openapi_website_cms_getContactUsElements';
import { Common } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';

export class ContactUsState {
    private readonly footerContactUsResource: Resource<Response200Type>;

    public static get = AutoWeakMap.create((common: Common) => new ContactUsState(common));

    private constructor(private readonly common: Common) {
        makeObservable(this);
        this.footerContactUsResource = new Resource(async (): Promise<Response200Type> => {
            return await this.common.trpcClient.client.cms.getFooterContactUs.query();
        });
    }

    public get data(): Response200Type | null {
        const data = this.footerContactUsResource.get();

        if (data.type === 'ready' && data.value.length > 0) {
            const value = [...data.value];
            value.sort((a, b) => (a.display_order > b.display_order ? 1 : -1));
            return value;
        }

        const config = ConfigComponents.get(this.common).config;
        const contactUsTemporaryDefaultMock = [...config.contactUsTemporaryDefaultMock];
        contactUsTemporaryDefaultMock.sort((a, b) => (a.display_order > b.display_order ? 1 : -1));
        return contactUsTemporaryDefaultMock;
        // return null;
    }

    @computed public get isDataReady(): boolean {
        return this.data !== null;
    }
}
