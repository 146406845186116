import { computed } from 'mobx';
import { FiltersType } from 'src/domains/layouts/webview/components/filters/Filters';
import { MarketFiltersForSportState } from 'src/domains/sportsbook/state/marketFiltersState/MarketFiltersForSportState';

export class MarketFiltersState {
    public constructor(private readonly marketFiltersForSport: MarketFiltersForSportState) {}

    public setActiveFilterId(id: string | number | null): void {
        if (id !== null) {
            this.marketFiltersForSport.setActiveFilter(id.toString());
        }
    }

    @computed public get filters(): Array<FiltersType> {
        return this.marketFiltersForSport.filtersForView.map((item) => {
            return {
                key: item.key.toString(),
                label: item.label,
                onClick: () => this.setActiveFilterId(item.key),
            };
        });
    }

    public get activeFilter(): string {
        return this.marketFiltersForSport.activeFilter;
    }
}
