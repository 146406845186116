import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { LinkWrapper, IconChatWrapper, FooterList } from './ContactUs.style';
import { observer } from 'src/utils/mobx-react';
import { FooterHeader } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/content/FooterContent.style';
import { ContactUsState } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/contactUs/ContactUs.state';
import { ContactUsElement } from './ContactUsElement';
import { useCommon } from 'src/domains/common/Common';
import { TraderChatState } from 'src/domains/players/state/traderChat/TraderChatState';

export const ContactUs = observer('ContactUs', () => {
    const common = useCommon();
    const traderChatState = TraderChatState.get(common);
    const isAuthorized = common.session.isAuthorized;

    const state = ContactUsState.get(common);

    if (state.isDataReady === false || state.data === null) {
        return null;
    }

    return (
        <>
            <FooterHeader>
                <I18n
                    langKey='footer.contact-us.header'
                    defaultText='Contact Us'
                />
            </FooterHeader>

            <FooterList>
                {state.data.map(
                    ({
                        is_active: isActive,
                        contact_type: contactType,
                        contact_value: contactValue,
                        label: contactLabel,
                        id,
                    }) => (
                        <ContactUsElement
                            key={id}
                            element={{ isActive, contactLabel, contactType, contactValue }}
                        />
                    )
                )}
                {isAuthorized === true && traderChatState.isTraderChatForView && (
                    <LinkWrapper to={{ account: 'trader-chat' }}>
                        <IconChatWrapper />
                        <I18n
                            langKey='footer.live-chat'
                            defaultText='Live Chat'
                        />
                    </LinkWrapper>
                )}
            </FooterList>
        </>
    );
});
