import React from 'react';
import { FlagWrapper, LanguageName } from 'src/domains/layouts/webview/components/languageSwitcher/LanguageFlag.style';
import { PortugeseBrazilFlagIcon } from './flags/PortugeseBrazilFlagIcon';
import { RomanianFlagIcon } from './flags/RomanianFlagIcon';
import { UkFlagIcon } from './flags/UkFlagIcon';
import { WelshFlagIcon } from './flags/WelshFlagIcon';
import { ItalianFlagIcon } from './flags/ItalianFlagIcon';

type LanguageFlagPropsType = {
    lang: string;
    langName?: string;
    langNameSide?: 'left' | 'right';
    showLangName?: boolean;
    isOnList?: boolean;
    isSidebar?: boolean;
    isActive?: boolean;
};

const languageFlags: Record<string, JSX.Element> = {
    wls: <WelshFlagIcon />,
    en: <UkFlagIcon />,
    'pt-br': <PortugeseBrazilFlagIcon />,
    ro: <RomanianFlagIcon />,
    it: <ItalianFlagIcon />,
};

export const LanguageFlag = ({
    lang,
    langName,
    langNameSide,
    showLangName,
    isSidebar = false,
    isOnList = false,
    isActive,
}: LanguageFlagPropsType): JSX.Element | null => {
    return (
        <>
            {langNameSide === 'left' ? (
                showLangName === true && langName !== undefined ? (
                    <LanguageName
                        isSidebar={isSidebar}
                        isOnList={isOnList}
                    >
                        {langName}
                    </LanguageName>
                ) : null
            ) : null}
            <FlagWrapper
                className='language-dropdown-flag-wrapper'
                isSidebar={isSidebar}
                isOnList={isOnList}
                isActive={isActive}
            >
                {languageFlags[lang]}
            </FlagWrapper>
            {langNameSide === 'right' ? (
                showLangName === true && langName !== undefined ? (
                    <LanguageName
                        isSidebar={isSidebar}
                        isOnList={isOnList}
                    >
                        {langName}
                    </LanguageName>
                ) : null
            ) : null}
        </>
    );
};
