import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    ChatIconWrapper,
    ChatIconWrapperFloating,
    TraderChatButtonFloatingWrapper,
    TraderChatCounter,
    TraderChatWrapper,
    TraderChatWrapperFloating,
    TraderChatWrapperTxt,
} from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/traderChatLinkNav/TraderChatLinkNav.style';
import { AccountNavLink } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/AccountNavigation.style';
import { UniverseComponent } from 'src/domains/common/universes';
import { useCommon } from 'src/domains/common/Common';
import { TraderChatState } from 'src/domains/players/state/traderChat/TraderChatState';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

interface UnreadIndicatorPropsType {
    unreadMessagesCount: number;
}

const UnreadIndicator = observer('UnreadIndicator', ({ unreadMessagesCount }: UnreadIndicatorPropsType) => {
    if (unreadMessagesCount < 1) {
        return null;
    }
    const label = unreadMessagesCount < 10 ? unreadMessagesCount : '9+';

    return <TraderChatCounter>{label}</TraderChatCounter>;
});

export const TraderChatButtonFloating = observer('TraderChatButtonFloating', () => {
    const common = useCommon();
    const starRouter = StarRouter.get(common);
    const traderChatState = TraderChatState.get(common);

    return (
        <TraderChatButtonFloatingWrapper
            dataTest='account-navigation-chat-floating'
            onClick={starRouter.redirectToTraderChat}
            size='small'
        >
            <TraderChatWrapperFloating>
                <ChatIconWrapperFloating />

                <UnreadIndicator unreadMessagesCount={traderChatState.unreadMessagesCount} />
            </TraderChatWrapperFloating>
        </TraderChatButtonFloatingWrapper>
    );
});

export const TraderChatLinkNav = observer('TraderChatLinkNav', () => {
    const common = useCommon();
    const traderChatState = TraderChatState.get(common);
    const configComponents = ConfigComponents.get(common);

    if (configComponents.config.traderChat.isBubbleChatButton) {
        return null;
    }

    return (
        <AccountNavLink
            dataTest='account-navigation-chat'
            to={{ account: 'trader-chat' }}
            routeNameForGtm={null}
        >
            <TraderChatWrapper>
                <ChatIconWrapper />

                <TraderChatWrapperTxt>
                    <UniverseComponent>
                        {(universeVersion): React.ReactElement => <universeVersion.traderChatLinkNav />}
                    </UniverseComponent>

                    <UnreadIndicator unreadMessagesCount={traderChatState.unreadMessagesCount} />
                </TraderChatWrapperTxt>
            </TraderChatWrapper>
        </AccountNavLink>
    );
});
