import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const ResetPasswordFormWrapper = withConfig(theme => styled('form', { label: 'ResetPasswordFormWrapper' })`
    background-color: ${theme.star.accountDrawer.bgColor};
    height: 100%;
    padding: 5px 20px 0 20px;
    margin: 0;
`);

export const SetNewPasswordBtnWrapper = styled(Button, { label: 'SetNewPasswordBtnWrapper' })`
    margin: 10px 5px 0 0;
    display: inline-block;
    width: 100%;
`;

export const ResetCaption = withConfig(theme => styled('p', { label: 'ResetCaption' })`
    font-size: ${theme.star.fontSize.xRegular};
    color: ${theme.star.luckyKingLogin.txtColorSecondary};
    margin-bottom: 24px;
`);
