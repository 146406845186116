import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    EachWayTermsList,
    EachWayTermsListElement,
    RabItemsWrapper,
    RabItemsWrapperSelection,
    RabItemsWrapperMarket,
    LegPrice,
} from 'src/domains/sportsbook/betting/bettingTab/cashout/selection/Selection.style';
import { EventScore } from 'src/domains/sportsbook/betting/ui/betSlip/EventScore';
import {
    InfoName,
    MarketInfoWrapper,
    NameSeparator,
} from 'src/domains/sportsbook/betting/ui/betSlip/betslipSelection/BetslipSelection.style';
import { BogIndicatorLegsInBet } from 'src/domains/sportsbook/webview/components/bog/BogIdicator.style';
import { SingleBetItemState } from 'src/domains/sportsbook/betting/betSlipState/betsState/SingleBetItemState';
import { BetsFilter } from 'src/domains/sportsbook/betting/state/BetsState';
import {
    NameInfoWrapper,
    NameWrapper,
} from 'src/domains/sportsbook/betting/bettingTab/cashout/selection/selectionNew/LegsInBet.style';
import { useAppStateContext } from 'src/appState/AppState';

interface PropsType {
    betItem: SingleBetItemState;
}

export const LegsInBet = observer('LegsInBet', ({ betItem }: PropsType) => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    const { betsState, models } = appSportsBookState;
    const { isBogEnabled, getLegPrice, selectedFilter } = betsState;

    return (
        <div>
            {betItem.legs.map((leg) => {
                const typeBet = leg.type;
                const isSettled = betItem.status === 'settled';
                const resultStatus = leg.result?.type;
                // eslint-disable-next-line no-restricted-globals
                const parsedEventId = Number(leg.event.id);
                const eventId = models.id.getEventIdOption(
                    parsedEventId > 0 ? parsedEventId : leg.event.eventId ?? null
                );
                const selections = leg.selections ?? [];
                const eachWayTerms = leg.eachWayTerms ?? null;
                const marketName = leg.market?.name ?? null;
                const result = leg.result ?? null;
                const isShowSPPrice =
                    leg.priceType === 'bp' &&
                    isBogEnabled &&
                    (selectedFilter === BetsFilter.SETTLED || selectedFilter === BetsFilter.ALL) &&
                    result !== null;
                const isFreeBet = betItem.freeBetCredits.length > 0;
                const isShowBogIndicator =
                    leg.priceType === 'bp' &&
                    isBogEnabled &&
                    selectedFilter !== 'settled' &&
                    result === null &&
                    !isFreeBet;
                const num = eachWayTerms?.reduction.num ?? null;
                const den = eachWayTerms?.reduction.den ?? null;
                const numDenVal = num !== null && den !== null ? `${num}/${den}` : null;
                const marketId = leg.market?.id ?? null;
                const selectionId = leg.selection?.id ?? null;

                const selectionName = leg.selection?.name ?? '';

                const newMarketId = eventId === null || marketId === null ? null : eventId.getMarketId(marketId);
                const dataTest = selectionName === '' ? undefined : 'selection-name';

                return (
                    <React.Fragment key={leg.id}>
                        <NameWrapper>
                            <NameInfoWrapper
                                isSettled={isSettled}
                                status={resultStatus}
                                data-test={dataTest}
                            >
                                {languagesState.showSelectionTranslation(newMarketId, selectionId, selectionName)}
                                {isShowBogIndicator ? (
                                    <BogIndicatorLegsInBet
                                        size='small'
                                        data-test='bog-indicator-bet'
                                    >
                                        <I18n
                                            langKey='events.bog'
                                            defaultText='BOG'
                                        />
                                    </BogIndicatorLegsInBet>
                                ) : null}
                            </NameInfoWrapper>
                            <LegPrice data-test='leg-price'>{getLegPrice(leg)}</LegPrice>
                        </NameWrapper>
                        {eventId === null ? null : <EventScore eventId={eventId} />}

                        <MarketInfoWrapper>
                            {marketName === null ? null : (
                                <InfoName data-test='market-name'>
                                    {languagesState.showMarketTranslation(newMarketId, marketName)}
                                </InfoName>
                            )}

                            {marketName === null ? null : <NameSeparator>|</NameSeparator>}
                            {typeBet === 'rab' ? null : <InfoName data-test='event-name'>{leg.event.name}</InfoName>}
                        </MarketInfoWrapper>

                        {isShowSPPrice ? (
                            <div className='bet-selection__event'>{`SP Price ${getLegPrice(leg, 'sp')}`}</div>
                        ) : null}

                        {typeBet === 'rab'
                            ? selections.map((item) => {
                                  const selection = item.selection;
                                  const market = item.market;
                                  return (
                                      <RabItemsWrapper
                                          key={selection.name}
                                          data-test='rab-item'
                                      >
                                          <RabItemsWrapperSelection data-test='selection-name'>
                                              {selection.name}
                                          </RabItemsWrapperSelection>
                                          <RabItemsWrapperMarket data-test='market-name'>
                                              {market.name}
                                          </RabItemsWrapperMarket>
                                      </RabItemsWrapper>
                                  );
                              })
                            : null}
                        {numDenVal !== null && eachWayTerms !== null ? (
                            <EachWayTermsList>
                                <EachWayTermsListElement data-test='ew-terms'>
                                    <I18n
                                        langKey='bets.selection.each-way-terms.label'
                                        defaultText='EW Terms'
                                    />
                                </EachWayTermsListElement>

                                <EachWayTermsListElement data-test='ew-reduction'>{numDenVal}</EachWayTermsListElement>

                                <EachWayTermsListElement data-test='ew-places'>
                                    <I18n
                                        langKey='bets.selection.each-way-terms.places'
                                        defaultText='{places} Places'
                                        params={{ places: eachWayTerms.places }}
                                    />
                                </EachWayTermsListElement>
                            </EachWayTermsList>
                        ) : null}
                    </React.Fragment>
                );
            })}
        </div>
    );
});
