import React, { useState } from 'react';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { PaymentMethodDropdownState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/PaymentMethodDropdown.state';
import { WithdrawIssue } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawIssue/WithdrawIssue';
import { PendingWithdrawals, WithdrawProcedureWrapper } from 'src/domains/players/webview/components/Account';
import { WithdrawSuccess } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawSuccess/WithdrawSuccess';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { WithdrawPaysafeProcedure } from './WithdrawPaysafeProcedure';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { WithdrawPaysafeFormState } from './WithdrawPaysafeFormState';
import { Amount } from 'src_common/common/amount/Amount';
import { useCommon } from 'src/domains/common/Common';

export type WithdrawPaysafeStepsType =
    | {
          type: 'set-card';
      }
    | {
          type: 'failure-view';
          readonly failureType: WithdrawFailuresType;
      }
    | {
          type: 'withdraw-pending';
      };

export type WithdrawFailuresType = 'serverIssue' | 'noPaymentMethods';

const defaultWithdrawStep: WithdrawPaysafeStepsType = {
    type: 'set-card',
};

export class WithdrawPaysafeSteps {
    @observable.ref public step: WithdrawPaysafeStepsType;

    public constructor(public initialStep?: WithdrawPaysafeStepsType) {
        makeObservable(this);
        this.step = initialStep ?? defaultWithdrawStep;
    }

    @action public redirectToSetCard = (): void => {
        this.step = {
            type: 'set-card',
        };
    };

    @action public redirectToFailureView = (failureType: WithdrawFailuresType): void => {
        this.step = {
            type: 'failure-view',
            failureType,
        };
    };

    @action public redirectToPending = (): void => {
        this.step = {
            type: 'withdraw-pending',
        };
    };
}

export const WithdrawPaysafeJourney = observer('WithdrawPaysafeJourney', () => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const {
        googleTagManager,
        languagesState,
        configComponents: {
            config: { minWithdrawAmount, usePaymentFormForUniverse },
        },
    } = appLayoutsState;
    const { usersState, withdrawalsListState } = appPlayersState;
    const common = useCommon();

    const [paymentMethodDropdownState] = useState(
        () => new PaymentMethodDropdownState(common.trpcClient, usePaymentFormForUniverse)
    );

    const [state] = React.useState(
        () =>
            new WithdrawPaysafeFormState(
                common.trpcClient,
                usersState,
                languagesState,
                withdrawalsListState,
                googleTagManager,
                paymentMethodDropdownState,
                new Amount(minWithdrawAmount)
            )
    );

    switch (state.currentStep.type) {
        case 'set-card':
            return (
                <>
                    <Messages
                        hasIcon={true}
                        type='info'
                        message={
                            <I18n
                                langKey='payment-method-info.label'
                                defaultText='From 14/04/2020 Credit Cards cannot be used to withdraw funds, please use Debit Cards.'
                            />
                        }
                    />
                    <WithdrawProcedureWrapper data-test='withdraw-procedure-wrapper'>
                        <WithdrawPaysafeProcedure
                            state={state}
                            paymentMethodDropdownState={paymentMethodDropdownState}
                        />
                    </WithdrawProcedureWrapper>
                    <PendingWithdrawals />
                </>
            );
        case 'failure-view':
            return <WithdrawIssue failureType={state.currentStep.failureType} />;
        case 'withdraw-pending':
            return <WithdrawSuccess />;
    }
});
