import { Common } from 'src/domains/common/Common';
import { ResourceCustomer } from './UsersState';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { computed } from 'mobx';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import {
    CurrencyType,
    isCurrencyCode,
    formatAmountWithCurrency,
    CurrencySymbolType,
    moneySymbol,
} from 'src_common/common/amount/website-money/currency';
import { GeolocalizationState } from 'src/domains/layouts/state/geolocalizationState/GeolocalizationState';
import { Amount } from 'src_common/common/amount/Amount';
import { accountResponse } from 'src_server/trpc/types/accountModel';

export type AccountBasicDataType = accountResponse.AccountBasicDataResponseType;
export type UserBasicDataType = ResourceCustomer<AccountBasicDataType | null>;

export class BasicDataModel {
    private readonly basicDataInner: UserBasicDataType;

    public static get = AutoWeakMap.create((common: Common) => new BasicDataModel(common));

    private constructor(private readonly common: Common) {
        this.basicDataInner = new ResourceCustomer('basicData', common.session, async () => {
            const response = await common.trpcClient.client.accounts.accountBasicData.query();
            if (response.responseStatus === 'success') return response.data;
            return null;
        });
    }

    public get basicData(): UserBasicDataType {
        return this.basicDataInner;
    }

    public get basicDataReady(): Omit<AccountBasicDataType, 'oddsFormat'> | null {
        const data = this.basicData.get();

        if (data.type === 'ready') {
            return data.value;
        }

        return null;
    }

    @computed public get oddsFormatShort(): 'f' | 'd' {
        const oddsFormat = this.basicDataInner.valueReady?.oddsFormat ?? null;
        if (oddsFormat !== null) {
            const format = oddsFormat.charAt(0);
            if (format === 'f') {
                return 'f';
            }
            if (format === 'd') {
                return 'd';
            }

            console.error(`Unknown offsets format ${format}`);
        }

        return ConfigComponents.get(this.common).config.oddsFormatShortDefault;
    }

    @computed public get currency(): CurrencyType {
        const currencyDefault =
            GeolocalizationState.get(this.common).currencyCode ??
            ConfigComponents.get(this.common).config.currencyDefault;

        if (this.common.session.isAuthorized === false) {
            return currencyDefault;
        }

        const currency = this.basicData.valueReady?.currency ?? null;

        if (currency !== null) {
            if (isCurrencyCode(currency)) {
                return currency;
            }

            console.error('Unknown currency', currency);
            return currencyDefault;
        }

        return currencyDefault;
    }

    public money = (amount: Amount | null | undefined, withoutZeros?: boolean): string => {
        return formatAmountWithCurrency(this.currency, amount, withoutZeros);
    };

    @computed public get moneySymbol(): CurrencySymbolType {
        return moneySymbol(this.currency);
    }
}
