import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { TabWrapper } from 'src/domains/layouts/webview/others/luckyKing/RHSDrawer';
import { LuckyKingResetPassword } from 'src/domains/players/shared/Components';
import { AccountDrawerHeader } from 'src/domains/players/webview/components/Account';
import { ResetPasswordRhsRouteType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';

interface PropsType {
    currentRoute: ResetPasswordRhsRouteType;
}

export const LuckyKingResetPasswordContainer = observer('LuckyKingResetPasswordContainer', (props: PropsType) => {
    const { currentRoute } = props;

    return (
        <TabWrapper>
            <AccountDrawerHeader
                key='account-drawer-header'
                title='Reset Password'
                parent='login'
                hasClose={true}
                hasBack={false}
            /><LuckyKingResetPassword currentRoute={currentRoute} />
        </TabWrapper>
    );
});
