import * as React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { MobileFooterLinksType } from 'src/domains/layouts/config/features/types';
import { assertNever } from 'src_common/common/assertNever';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { FooterItemsIcon } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/mobileFooter/FooterItems';

type CustomIdType = 'cash-out';

export interface FooterItemType {
    icon: React.ReactNode;
    label: React.ReactNode;
    key: MobileFooterLinksType;
    action:
        | {
              type: 'redirect';
              to: RouteViewType | { customId: CustomIdType };
          }
        | {
              type: 'onclick';
              onClick: () => void;
          };
}

export const displayMobileFooterLinks = (
    links: Array<MobileFooterLinksType>,
    onBingoClick: () => void
): Array<FooterItemType> => {
    return links.map((link): FooterItemType => {
        switch (link) {
            case 'sports-list':
                return {
                    icon: <FooterItemsIcon slug='sports-list' />,
                    label: <I18n langKey='footer.sports-list' defaultText='Sports' />,
                    key: 'sports-list',
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'navigation-sports',
                        },
                    },
                };
            case 'home':
                return {
                    icon: <FooterItemsIcon slug='home' />,
                    label: <I18n langKey='footer.home' defaultText='Home' />,
                    key: 'home',
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'homepage',
                        },
                    },
                };
            case 'in-play':
                return {
                    icon: <FooterItemsIcon slug='in-play' />,
                    label: <I18n langKey='footer.in-play' defaultText='In-Play' />,
                    key: 'in-play',
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'inplay',
                        },
                    },
                };
            case 'racing':
                return {
                    icon: <FooterItemsIcon slug='racing' />,
                    label: <I18n langKey='footer.racing' defaultText='Racing' />,
                    key: 'racing',
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'sport',
                            nameType: 'regular',
                            id: 'horseracing',
                        },
                    },
                };
            case 'casino':
                return {
                    icon: <FooterItemsIcon slug='casino' />,
                    label: <I18n langKey='footer.casino' defaultText='Casino' />,
                    key: 'casino',
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'casino',
                        },
                    },
                };
            case 'live-casino':
                return {
                    icon: <FooterItemsIcon slug='live-casino' />,
                    label: <I18n langKey='footer.live-casino' defaultText='Live Casino' />,
                    key: 'live-casino',
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'live-casino',
                        },
                    },
                };
            case 'bingo':
                return {
                    icon: <FooterItemsIcon slug='bingo' />,
                    label: <I18n langKey='footer.bingo' defaultText='Bingo' />,
                    key: 'bingo',
                    action: {
                        type: 'onclick',
                        onClick: () => onBingoClick(),
                    },
                };
            case 'promos':
                return {
                    icon: <FooterItemsIcon slug='promos' />,
                    label: <I18n langKey='footer.notifications' defaultText='Promos' />,
                    key: 'promos',
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'notifications-list',
                        },
                    },
                };
            case 'offers':
                return {
                    icon: <FooterItemsIcon slug='offers' />,
                    label: <I18n langKey='footer.notifications:mcbookie' defaultText='Offers' />,
                    key: 'offers',
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'notifications-list',
                        },
                    },
                };
            case 'notifications':
                return {
                    icon: <FooterItemsIcon slug='notifications' />,
                    label: <I18n langKey='footer.notifications:nebet' defaultText='Notifications' />,
                    key: 'notifications',
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'notifications-list',
                        },
                    },
                };
            case 'football-european':
                return {
                    icon: <FooterItemsIcon slug='football-european' />,
                    label: <I18n langKey='footer.football:rhino' defaultText='Football' />,
                    key: 'football-european',
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'sport',
                            nameType: 'regular',
                            id: 'football',
                            // type: 'european-elite'
                        },
                    },
                };
            case 'football':
                return {
                    icon: <FooterItemsIcon slug='football' />,
                    label: <I18n langKey='footer.football' defaultText='Football' />,
                    key: 'football',
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'sport',
                            nameType: 'regular',
                            id: 'football',
                        },
                    },
                };
            case 'cash-out':
                return {
                    icon: <FooterItemsIcon slug='cash-out' />,
                    label: <I18n langKey='footer.cash-out' defaultText='Cash Out' />,
                    key: 'cash-out',
                    action: {
                        type: 'redirect',
                        to: {
                            customId: 'cash-out',
                        },
                    },
                };
            case 'sportsbook':
                return {
                    icon: <FooterItemsIcon slug='sportsbook' />,
                    label: <I18n langKey='footer.sportsbook' defaultText='Sportsbook' />,
                    key: 'sportsbook',
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'homepage',
                        },
                    },
                };
        }
        assertNever('displayMobileFooterLink', link);
    });
};
