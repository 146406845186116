import React, { useEffect } from 'react';
import { Checkbox } from 'src/domains/layouts/webview/components/form/checkbox/Checkbox';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    AlignBottomWrapper,
    ForgotPasswordLink,
    LoginBtnWrapper,
    LoginForm,
    LoginMessage,
    LoginTabWrapper,
    SignUpHelper,
    SignUpLink,
    LuckyKingLogoMenuWrapper,
    LogoWrapper,
    LogoCaption,
    CheckboxCaption,
    NewAccountCaption,
    DetailsWrapper,
    HeaderCloseIcon,
} from 'src/domains/players/webview/components/LuckyKingLogin/LuckyKingLogin.style';
import { AccountHelperInfo, LoginTabUtil } from 'src/domains/players/webview/components/Account';
import { observer } from 'src/utils/mobx-react';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

const onSubmitDisable = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
};

interface LoginTabProps {
    isLoginRoute?: boolean;
}

export const LuckyKingLogin = observer('LuckyKingLogin', ({ isLoginRoute }: LoginTabProps) => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { loginState } = appPlayersState;
    const { languagesState, starRouter, configComponents } = appLayoutsState;
    const { getTranslation } = languagesState;
    const { hasNeedHelpAccountSection } = configComponents.config;

    if (!hasNeedHelpAccountSection) {
        return null;
    }

    const passwordLabel = LoginTabUtil.getPasswordInputLabel();
    const emailLabel = LoginTabUtil.getLoginInputLabel();

    useEffect(() => {
        return loginState.resetState;
    }, []);

    return (
        <LoginTabWrapper key='login-tab' data-test='LoginTab'>
            <HeaderCloseIcon onClick={starRouter.closeAccount} />

            <LogoWrapper>
                <LogoCaption>WELCOME BACK TO</LogoCaption>
                <LuckyKingLogoMenuWrapper />
            </LogoWrapper>

            <LoginForm key='login-form' onSubmit={onSubmitDisable} id='login'>
                {isLoginRoute === true ? null : starRouter.isAccountParam === false ? null : (
                    <LoginMessage>{getTranslation('account.tabs.login.message', 'Login to access page')}</LoginMessage>
                )}
                <Input
                    name='email'
                    type='email'
                    label={emailLabel}
                    state={loginState.emailState}
                    placeholder='Enter a valid email'
                    colorTheme='dark'
                    dataTest='email-input'
                />
                <Input
                    name='password'
                    type={loginState.isShowPassword ? 'text' : 'password'}
                    label={passwordLabel}
                    state={loginState.passwordState}
                    showPassword={loginState.setShowPassword}
                    showPasswordFlag={true}
                    isShowPassword={loginState.isShowPassword}
                    placeholder='Enter your unique password'
                    colorTheme='dark'
                    dataTest='password-input'
                />

                {loginState.loginErrorMsg === null ? null : (
                    <Messages type='error' marginBottom='16px' message={loginState.loginErrorMsg} />
                )}

                <div key='form-buttons'>
                    <DetailsWrapper>
                        <CheckboxCaption>
                            <Checkbox key='login-remember-me-checkbox' state={loginState.rememberMeState}>
                                <I18n langKey='account.remember-me' defaultText='Remember me?' />
                            </Checkbox>
                        </CheckboxCaption>
                        <ForgotPasswordLink
                            key='forgot-password'
                            to={{ account: 'forgot-password' }}
                            dataTest='forgot-password'
                        >
                            <I18n langKey='account.forgot-password' defaultText='Forgot password?' />
                        </ForgotPasswordLink>
                    </DetailsWrapper>

                    <LoginBtnWrapper
                        isLoading={loginState.inProgress}
                        size='large'
                        onClick={loginState.logIn}
                        type='submit'
                        disabled={loginState.inProgress || !loginState.isFormValid}
                        dataTest='login-button'
                    >
                        <I18n defaultText='Login' langKey='account.login' />
                    </LoginBtnWrapper>
                </div>
            </LoginForm>

            <AlignBottomWrapper>
                <SignUpHelper>
                    <NewAccountCaption>
                        <I18n langKey='account.forgot-password.no-account' defaultText='Don’t have an account? ' />
                    </NewAccountCaption>
                    &nbsp;&nbsp;
                    <SignUpLink to={{ account: 'signup', promo: null }}>
                        <I18n langKey='account.forgot-password.sign-up.link' defaultText='Sign Up' />
                    </SignUpLink>
                </SignUpHelper>
            </AlignBottomWrapper>
            <AccountHelperInfo version='secondary' />
        </LoginTabWrapper>
    );
});
