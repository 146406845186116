import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    FacebookIconWrapper,
    IconEnvelopeWrapper,
    IconPhoneWrapper,
    IconTwitterWrapper,
    InstagramIconWrapper,
    LinkWrapperStatic,
} from 'src/domains/layouts/webview/others/luckyKing/Footer/ContactUs.style';
import { useAppStateContext } from 'src/appState/AppState';
import { ContactUsStateLuckyKing } from 'src/domains/layouts/webview/others/luckyKing/Footer/ContactUs.state';

const renderLink = (
    contactType: string,
    contactValue: string,
    isActive: boolean,
    email: string
): JSX.Element | null => {
    if (isActive) {
        if (contactType === 'facebook') {
            return (
                <LinkWrapperStatic
                    key={contactType}
                    to={contactValue}
                >
                    <FacebookIconWrapper />
                    <I18n
                        langKey='footer.facebook:luckyking'
                        defaultText='Facebook'
                    />
                </LinkWrapperStatic>
            );
        }

        if (contactType === 'instagram') {
            return (
                <LinkWrapperStatic
                    key={contactType}
                    to={contactValue}
                >
                    <InstagramIconWrapper />
                    <I18n
                        langKey='footer.instagram:luckyking'
                        defaultText='Instagram'
                    />
                </LinkWrapperStatic>
            );
        }

        if (contactType === 'twitter') {
            return (
                <LinkWrapperStatic
                    key={contactType}
                    to={contactValue}
                >
                    <IconTwitterWrapper />
                    <I18n
                        langKey='footer.twitter:luckyking'
                        defaultText='Twitter'
                    />
                </LinkWrapperStatic>
            );
        }

        if (contactType === 'e-mail') {
            return (
                <LinkWrapperStatic
                    to={`mailto:${contactValue}`}
                    key={contactType}
                >
                    <IconEnvelopeWrapper />
                    <I18n
                        langKey='footer.email-us:luckyking'
                        defaultText='{email}'
                        params={{ email }}
                    />
                </LinkWrapperStatic>
            );
        }

        if (contactType === 'phone-number') {
            return (
                <LinkWrapperStatic
                    to={`tel: ${contactValue}`}
                    key={contactType}
                >
                    <IconPhoneWrapper /> {contactValue}
                </LinkWrapperStatic>
            );
        }
    }

    return null;
};

export const ContactUs = observer('ContactUs', () => {
    const { appLayoutsState } = useAppStateContext();
    const {
        configComponents: { config },
    } = appLayoutsState;
    const { email } = config.contactDetails;
    const emailLabel = email ?? 'Email us';

    const [state] = useState(() => new ContactUsStateLuckyKing());

    if (state.isDataReady === false || state.data === null) {
        return null;
    }

    return (
        <React.Fragment>
            {state.data.map((item) => renderLink(item.contact_type, item.contact_value, item.is_active, emailLabel))}
        </React.Fragment>
    );
});
