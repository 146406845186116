import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import { FiltersNewView } from 'src/domains/layouts/webview/components/filters/Filters';
import { MarketFiltersState } from './MarketFilters.state';
import { useAppStateContext } from 'src/appState/AppState';
import { MarketFiltersWrapper } from 'src/domains/sportsbook/webview/components/marketFilters/MarketFilters.style';
import { MarketFiltersForSportState } from 'src/domains/sportsbook/state/marketFiltersState/MarketFiltersForSportState';

interface PropsType {
    marketFiltersForSport: MarketFiltersForSportState;
}

export const MarketFilters = observer('MarketFilters', ({ marketFiltersForSport }: PropsType) => {
    const { config } = useAppStateContext();

    const filtersState = new MarketFiltersState(marketFiltersForSport);
    const { filters, activeFilter } = filtersState;

    return (
        <MarketFiltersWrapper>
            <FiltersScrollableWrapper>
                <FiltersNewView
                    select={activeFilter}
                    skin={config.filtersVersion.football}
                    filters={filters}
                />
            </FiltersScrollableWrapper>
        </MarketFiltersWrapper>
    );
});
