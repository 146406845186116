import styled from '@emotion/styled';
import { setRaceCell, RaceSummaryCaption, SelectionCaption } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummaryNew.style';
import { withConfig } from 'src/withConfig';

export const RaceResultsRowCaption = withConfig(theme => styled(RaceSummaryCaption, { label: 'RaceResultsRowCaption' })`
    background-color: ${theme.star.raceCardColors.bgColorResultsTableRow};
    border-style: solid;
    border-color: ${theme.star.raceSummary.borderColor};
    border-width: 0 0 1px 0;
    display: flex;
    flex-flow: row nowrap;
    font-size: ${theme.star.fontSize.xSmall};
    height: 48px;
    justify-content: space-between;
    line-height: 1.4;
    @media ${theme.star.mediaQuery.tablet} {
        border-width: 0 0 1px 1px;
        height: 56px;
    }
`);

export const RaceSummaryResultsCaption = withConfig(theme => styled(RaceSummaryCaption, { label: 'RaceSummaryResultsCaption' })`
    border-style: solid;
    border-color: ${theme.star.raceSummary.borderColor};
    border-width: 0 0 1px 0;
    height: 24px;
    @media ${theme.star.mediaQuery.tablet} {
        border-width: 0 0 1px 1px;
    }
`);

export const ResultsTableMessage = withConfig(theme => styled('div', { label: 'ResultsTableMessage' })`
    background-color: ${theme.star.raceCardColors.bgColorResultsTableMessage};
    color: ${theme.star.raceCardColors.txtColorResults};
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    padding: 12px 8px;
`);

export const ResultCaption = withConfig(theme => styled('span', { label: 'ResultCaption' })`
    ${setRaceCell(theme)};
    border-right: 1px solid ${theme.star.raceSummary.borderColor};
    flex: 0 0 64px;
    text-align: center;
`);

export const ResultCell = withConfig(theme => styled(ResultCaption, { label: 'ResultCell' })`
    border-right: 1px solid ${theme.star.raceSummary.borderColor};
    color: ${theme.star.raceCardColors.txtColorResultPlace};
    flex-flow: column nowrap;
    font-size: ${theme.star.fontSize.xMedium};
    font-weight: ${theme.star.fontWeight.bold};
`);

export const ResultSelectionCaption = withConfig(theme => styled(SelectionCaption, { label: 'ResultSelectionCaption' })`
    color: ${theme.star.raceCardColors.txtColorSelectionCaption};
`);

export const ResultSelectionCell = withConfig(theme => styled(SelectionCaption, { label: 'ResultSelectionCell' })`
    color: ${theme.star.raceCardColors.txtColorResultedSelectionPrice};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
`);

