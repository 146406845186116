import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { TimeCounter } from './timeCounter/TimeCounter';
import { Stream } from './Stream';
import {
    HeaderWithMovieDesc,
    TimerHeader,
    TimeStyle,
    WatchLiveBtn,
    NotAllowStreamWrapper,
    InfoTextWrapper,
    InfoLongText,
    InfoShortText,
    BlueBoldText,
    CrossButton,
    NotAllowStreamContainer,
    StartDateInfo,
    HourGlassWrapper,
    RaceStartWrapper,
    WatchLiveBtnSmall,
} from './Header.style';
import { useAppStateContext } from 'src/appState/AppState';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { useCommon } from 'src/domains/common/Common';

export const RenderStreamInfo = observer(
    'RenderStreamInfo',
    ({ eventId }: { eventId: EventId }): JSX.Element | null => {
        const { appLayoutsState, appSportsBookState } = useAppStateContext();
        const { configComponents, starRouter, sdkCustomer } = appLayoutsState;
        const { alternativeEventState } = appSportsBookState;
        const { session } = sdkCustomer;
        const { redirectToSignUp, redirectToLogin } = starRouter;
        const { isAuthorized } = session;
        const { getAlternativeEventItemById } = alternativeEventState.get(eventId);
        const { isStreamVisibleForUnauthorized } = configComponents.config;

        const eventModel = getAlternativeEventItemById(eventId);

        const { onPlayStream, isStreamActive, onCloseStream, isTimeToShowStream, streamStartTimeFormat } = eventModel;
        const canUserSeeStream = isAuthorized || isStreamVisibleForUnauthorized === true;

        if (isStreamActive === true && canUserSeeStream === false) {
            return (
                <NotAllowStreamContainer>
                    <NotAllowStreamWrapper>
                        <WatchLiveBtnSmall />
                        <InfoTextWrapper>
                            <InfoLongText>
                                You must <BlueBoldText onClick={redirectToLogin}> login </BlueBoldText> to view this
                                stream
                            </InfoLongText>
                            <InfoShortText>
                                Don't have an account? <BlueBoldText onClick={redirectToSignUp}>Sign up</BlueBoldText>{' '}
                            </InfoShortText>
                        </InfoTextWrapper>
                        <CrossButton onClick={onCloseStream} />
                    </NotAllowStreamWrapper>
                </NotAllowStreamContainer>
            );
        }

        if (isStreamActive === true && canUserSeeStream && isTimeToShowStream === false) {
            return (
                <NotAllowStreamContainer>
                    <NotAllowStreamWrapper>
                        <HourGlassWrapper />
                        <StartDateInfo>
                            The live stream will start at <TimeStyle>{streamStartTimeFormat}</TimeStyle>
                        </StartDateInfo>
                        <CrossButton onClick={onCloseStream} />
                    </NotAllowStreamWrapper>
                </NotAllowStreamContainer>
            );
        }

        if (isStreamActive === true && canUserSeeStream) {
            const streamUrl = eventModel.streamUrl;
            return <Stream streamUrl={streamUrl} />;
        }

        if (isStreamActive === false) {
            return <WatchLiveBtn onClick={onPlayStream} />;
        }

        return null;
    }
);

interface InformationBeforeStreamType {
    eventId: EventId;
}

export const StreamComponent = observer(
    'StreamComponent',
    ({ eventId }: InformationBeforeStreamType): JSX.Element | null => {
        const common = useCommon();
        const session = common.session;
        const {
            appSportsBookState: { alternativeEventState },
        } = useAppStateContext();
        const { getAlternativeEventItemById } = alternativeEventState.get(eventId);
        const eventModel = getAlternativeEventItemById(eventId);

        const { isStreamActive, isTimeToShowStream, eventName, isActiveStream } = eventModel;

        return (
            <>
                <HeaderWithMovieDesc>{eventName}</HeaderWithMovieDesc>

                {isActiveStream === true ? <RenderStreamInfo eventId={eventId} /> : null}

                {(isStreamActive === true && session.isAuthorized && isTimeToShowStream) === false ? (
                    <RaceStartWrapper isStreamUrlAvailable={isActiveStream}>
                        <TimerHeader>Starts in:</TimerHeader>
                        <TimeCounter
                            isSidebar={false}
                            eventId={eventId}
                        />
                    </RaceStartWrapper>
                ) : null}
            </>
        );
    }
);
