import {
    EventsCollectionQueryType,
    EventsCollectionQuerySportReplaceType,
} from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { SdkCustomer } from 'src/domains/layouts/state/customer';

const translateSport = (sdkCustomer: SdkCustomer, sport: string): Array<string> => {
    for (const [sportKey, sportsIncludes] of Object.entries(sdkCustomer.common.apiEventListIncludes)) {
        if (sportKey === sport) {
            return [sport].concat(sportsIncludes);
        }
    }

    return [sport];
};

const translageSportList = (sdkCustomer: SdkCustomer, sports: Array<string>): Array<string> => {
    const out: Array<Array<string>> = [];

    for (const sport of sports) {
        const translateSportItem = translateSport(sdkCustomer, sport);
        out.push(translateSportItem);
    }

    const result: Array<string> = [];
    return result.concat(...out);
};

const translageSports = (sdkCustomer: SdkCustomer, sports?: Array<string>): Array<string> | undefined => {
    if (sports === undefined) {
        return;
    }

    return translageSportList(sdkCustomer, sports);
};

const getSportReplaceParam = (sdkCustomer: SdkCustomer): EventsCollectionQuerySportReplaceType => {
    const out: EventsCollectionQuerySportReplaceType = [];

    for (const [sportTo, sportsIncludes] of Object.entries(sdkCustomer.common.apiEventListIncludes)) {
        for (const sportFrom of sportsIncludes) {
            out.push({
                from: sportFrom,
                to: sportTo,
            });
        }
    }

    return out;
};

export const translateQuery = (
    sdkCustomer: SdkCustomer,
    query: EventsCollectionQueryType
): EventsCollectionQueryType => {
    return {
        ...query,
        sport: translageSports(sdkCustomer, query.sport),
        sportNot: translageSports(sdkCustomer, query.sportNot),
        sportReplace: getSportReplaceParam(sdkCustomer),
    };
};
