import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    DepositIssueWrapper,
    IssueDepositInfoSecondary,
    IssueDepositTitle,
    WarningIconBox,
    WarningIconWrapper,
} from './DepositFailure.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';

interface OperatorTurnOffDepositsType {
    operatorName: string;
    isSignup: boolean;
    date: string;
}

export const OperatorTurnOffDeposits = observer(
    'OperatorTurnOffDeposits',
    ({ operatorName, isSignup, date }: OperatorTurnOffDepositsType) => {
        const {
            common,
            config: { accountHelperMail },
        } = useAppStateContext();
        const language = LanguagesState.get(common);
        const text = language.translateTokens(
            language.getTranslation(
                'account.top-up.operator-turn-off.info.text',
                '[Attention][operatorName] will cease operations on [date] and is not accepting new deposits at this time.[br] For more information or assistance, please get in touch with us at [accountHelperMail].[br2] Thank you for your understanding.'
            ),
            (singleParam: LanguageTokenType) => {
                if (singleParam.tag === 'Attention') {
                    return (
                        <strong>
                            <I18n
                                langKey='account.top-up.operator-turn-off.info.attention'
                                defaultText='Attention: '
                            />
                        </strong>
                    );
                }
                if (singleParam.tag === 'operatorName') {
                    return operatorName;
                }
                if (singleParam.tag === 'date') {
                    return date;
                }
                if (singleParam.tag === 'br' || singleParam.tag === 'br2') {
                    return <div></div>;
                }
                if (singleParam.tag === 'accountHelperMail') {
                    return <a href={`mailto: ${accountHelperMail}`}>{accountHelperMail}</a>;
                }
            }
        );

        return (
            <DepositIssueWrapper isSignup={isSignup}>
                <WarningIconBox>
                    <WarningIconWrapper />
                </WarningIconBox>
                <IssueDepositTitle>
                    <I18n
                        langKey='account.top-up.operator-turn-off.title'
                        defaultText='Payments are unavailable at this time'
                    />
                </IssueDepositTitle>
                <IssueDepositInfoSecondary>{text}</IssueDepositInfoSecondary>
            </DepositIssueWrapper>
        );
    }
);
