import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { WithdrawTicket } from 'src/domains/players/webview/components/LuckyKingWallet/withdrawTicketPopup/WithdrawTicketPopup';
import { DepositTicket } from 'src/domains/players/webview/components/LuckyKingWallet/depositTicketPopup/DepositTicketPopup';
import { LuckyKingWalletModalWrapper, TicketModalWrapper } from 'src/domains/players/webview/components/LuckyKingWallet/LuckyKingPopups.style';

export const LuckyKingPopups = observer('LuckyKingPopups', () => {
    const { appPlayersState: { luckyKingWalletState } } = useAppStateContext();

    return (
        <>
            {luckyKingWalletState.showWithdrawPopup === true?
                <LuckyKingWalletModalWrapper>
                    <TicketModalWrapper onClick={luckyKingWalletState.closeWithdrawPopup} />
                    <WithdrawTicket closePopup={luckyKingWalletState.closeWithdrawPopup} />
                </LuckyKingWalletModalWrapper> : null}

            {luckyKingWalletState.showDepositPopup === true?
                <LuckyKingWalletModalWrapper>
                    <TicketModalWrapper onClick={luckyKingWalletState.closeDepositPopup} />
                    <DepositTicket closePopup={luckyKingWalletState.closeDepositPopup} />
                </LuckyKingWalletModalWrapper> : null}
        </>
    );
});
