import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { withConfig } from 'src/withConfig';

export const DepositLimitPopupBtn = styled(Button, { label: 'DepositLimitPopupBtn' })`
    width: 100%;
    margin-bottom: 5px;
`;

export const DepositLimitPopupBtnWrapper = styled('div', { label: 'DepositLimitPopupBtnWrapper' })`
    margin-top: 20px;
    text-align: center;
`;


export const DepositLimitPopupPrevious = withConfig(theme => styled('div', { label: 'DepositLimitPopupPrevious' })`
    background-color: ${theme.star.statuses.success.bgColor};
    color: ${theme.star.statuses.success.txtColor};
    font-size: ${theme.star.fontSize.small};
    padding: 0 4px;
`);

interface DepositLimitPopupInputType {
    isNewLimit: boolean;
}

export const DepositLimitPopupInput = withConfig(theme => styled(Input, { label: 'DepositLimitPopupInput' })<DepositLimitPopupInputType>`
    margin-bottom: 0;
    input {
        border: 1px solid ${({ isNewLimit }):string => isNewLimit ? theme.star.statuses.success.bgColor : theme.star.form.bgColorSecondary};
        color: ${theme.star.form.txtColor};
        &:disabled{
            opacity: 1;
            &::placeholder{
                color: ${theme.star.form.txtColor};
            }
        }
    }
`);

export const DepositLimitPopupHeader = withConfig(theme => styled('h4', { label: 'DepositLimitPopupHeader' })`
    font-size: ${theme.star.fontSize.medium};
    text-align: center;
    text-transform: capitalize;
    margin: 0;
    padding-top: 9px;
`);

export const DepositLimitPopupDescription = withConfig(theme => styled('p', { label: 'DepositLimitPopupDescription' })`
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.6667;
    margin: 0;
    text-align: center;
`);
