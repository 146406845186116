import styled from '@emotion/styled';
import { LuckyKingLogo } from 'src/domains/layouts/shared/assets/icons/logo/luckyking.logo';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

export const LoginTabWrapper = withConfig(theme => styled('div', { label: 'LoginTabWrapper' })`
    background-color: ${theme.star.accountDrawer.bgColor};
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    overflow-y: auto;
    position: relative;
`);

export const LoginForm = styled('form', { label: 'LoginForm' })`
    padding: 24px 16px;
`;

export const LoginMessage = withConfig(theme => styled('h3', { label: 'LoginMessage' })`
    font-size: ${theme.star.fontSize.regular};
    text-transform: uppercase;
    margin-top: 0;
    color: ${theme.star.accountPanel.txtColor};
`);

export const ForgotPasswordLink = withConfig(theme => styled(Link, { label: 'ForgotPasswordLink' })`
    color: ${theme.star.luckyKingLogin.txtColorTertiary};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.43;
    text-decoration: none;
`);

export const LoginBtnWrapper = styled(Button, { label: 'LoginBtnWrapper' })`
    display: block;
    margin: 0 0 16px 0;
    width: 100%;
`;

export const AlignBottomWrapper = styled('div', { label: 'AlignBottomWrapper' })`
    margin: -32px 0 auto 0;
`;

export const SignUpHelper = withConfig(theme => styled('div', { label: 'SignUpHelper' })`
    text-align: center;
    font-size: ${theme.star.fontSize.xRegular};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`);

export const SignUpLink = withConfig(theme => styled(Link, { label: 'SignUpLink' })`
    font-weight: ${theme.star.fontWeight.bold};
    text-decoration: none;
    color: ${theme.star.luckyKingLogin.txtColorTertiary};
`);

export const LuckyKingLogoMenuWrapper = styled(LuckyKingLogo, { label: 'LuckyKingLogoMenuWrapper' })`
    height: 40px;
`;

export const LogoWrapper = styled('div', { label: 'LogoWrapper' })`
    display: flex;
    height: 176px;
    flex-direction: column;
    align-items: center;
    padding: 24px 16px;
    justify-content: flex-end;
`;

export const LogoCaption = withConfig(theme => styled('p', { label: 'LogoCaption' })`
    font-size: ${theme.star.fontSize.regular};
    color: ${theme.star.luckyKingLogin.txtColorSecondary};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0 0 12px 0;
`);

export const CheckboxCaption = withConfig(theme => styled('p', { label: 'CheckboxCaption' })`
    font-size: ${theme.star.fontSize.small};
    color: ${theme.star.luckyKingLogin.txtColorSecondary};
    display: flex;
    align-items: center;
`);

export const NewAccountCaption = withConfig(theme => styled('p', { label: 'NewAccountCaption' })`
    font-size: ${theme.star.fontSize.xRegular};
    color: ${theme.star.luckyKingLogin.txtColorSecondary};
    display: flex;
    align-items: center;
`);

export const DetailsWrapper = withConfig(theme => styled('div', { label: 'DetailsWrapper' })`
    font-size: ${theme.star.fontSize.small};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
`);

export const AccountHelperWrapper = withConfig(theme => styled('div', { label: 'AccountHelperWrapper' })`
    background: ${theme.star.luckyKingWallet.bgColorSecondary};
    font-size: ${theme.star.fontSize.small};
    padding: 14px 16px 34px 16px;
    text-align: center;
`);

export const AccountHelperLink = withConfig(theme => styled(Link, { label: 'AccountHelperLink' })`
    color: ${theme.star.luckyKingWallet.txtColor};
    font-weight: ${theme.star.fontWeight.bold};
    text-decoration: none;
    position: relative;
    &:not(:last-of-type) {
        margin-right: 19px;
        &::before {
            background: currentcolor;
            bottom: 0;
            content: '';
            left: 100%;
            margin-left: 8px;
            position: absolute;
            top: 1px;
            width: 1px;
        }
    }
`);

export const HeaderCloseIcon = withConfig(theme => styled(CloseIcon, { label: 'HeaderCloseIcon' })`
    position: absolute;
    cursor: pointer;
    fill: ${theme.star.luckyKingLogin.txtColorSecondary};
    margin-left: auto;
    padding: 14px;
    width: 48px;
    top: 16px;
    right: 16px;
`);
