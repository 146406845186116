import React, { useEffect, useState } from 'react';
import { EventsFilterGroupComputeds } from './EventsFilterGroupComputeds';
import { FilterKindType } from './EventsFilterType';
import { observer } from 'src/utils/mobx-react';
import { autorun } from 'mobx';
import { FiltersState, FiltersStateStrategy } from 'src/domains/layouts/webview/components/filters/Filters.state';
import { Filters } from 'src/domains/layouts/webview/components/filters/Filters';
import { FiltersSkinType } from 'src/domains/layouts/webview/components/filters/FiltersSkin';
import {
    FilterRowWrapper,
    Label,
    NameContainer,
    Right,
    Wrapper,
} from 'src/domains/sportsbook/webview/components/eventsFilters/EventsFilterGroup.style';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import { useAppStateContext } from 'src/appState/AppState';

interface EventsFiltersPropsType {
    strategy: FiltersStateStrategy;
    skin: FiltersSkinType;
}

const EventsFilters = observer('EventsFilters', (props: EventsFiltersPropsType) => {
    const { strategy, skin } = props;
    const [state] = useState(() => new FiltersState(strategy));
    return (
        <FilterRowWrapper>
            <FiltersScrollableWrapper>
                <Filters
                    state={state}
                    skin={skin}
                />
            </FiltersScrollableWrapper>
        </FilterRowWrapper>
    );
});

interface RenderFilterItemResultType {
    name: React.ReactNode;
    body: React.ReactNode;
}

const renderFilterItem = (
    eventsFilterState: EventsFilterGroupComputeds,
    filterId: FilterKindType,
    skin: FiltersSkinType
): RenderFilterItemResultType => {
    switch (filterId) {
        case 'sport': {
            const state = eventsFilterState.sport;

            return {
                name: <Label key='sport'>{state.name}</Label>,
                body: (
                    <EventsFilters
                        strategy={state}
                        skin={skin}
                        key='sport'
                    />
                ),
            };
        }

        case 'competition': {
            const state = eventsFilterState.competition;

            return {
                name: <Label key='competition'>{state.name}</Label>,
                body: (
                    <EventsFilters
                        strategy={state}
                        skin={skin}
                        key='competition'
                    />
                ),
            };
        }

        case 'time': {
            const state = eventsFilterState.time;

            return {
                name: <Label key='time'>{state.name}</Label>,
                body: (
                    <EventsFilters
                        strategy={state}
                        skin={skin}
                        key='time'
                    />
                ),
            };
        }

        case 'region': {
            const state = eventsFilterState.region;

            return {
                name: <Label key='region'>{state.name}</Label>,
                body: (
                    <EventsFilters
                        strategy={state}
                        skin={skin}
                        key='region'
                    />
                ),
            };
        }

        case 'country': {
            const state = eventsFilterState.country;

            return {
                name: <Label key='country'>{state.name}</Label>,
                body: (
                    <EventsFilters
                        strategy={state}
                        skin={skin}
                        key='country'
                    />
                ),
            };
        }
    }
};

const renderFilters = (
    eventsFilter: EventsFilterGroupComputeds,
    filters: Array<FilterKindType>,
    skin: FiltersSkinType
): Array<RenderFilterItemResultType> => {
    const out = [];

    for (const filterKind of filters) {
        out.push(renderFilterItem(eventsFilter, filterKind, skin));
    }

    return out;
};

interface PropsType {
    filters: Array<FilterKindType>;
    eventsFilter: EventsFilterGroupComputeds;
    skin: FiltersSkinType;
}

export const EventsFilterGroup = observer('EventsFilterGroup', (props: PropsType): React.ReactElement | null => {
    const { appLayoutsState } = useAppStateContext();
    const { defaultCompetitionState } = appLayoutsState;

    const { filters, eventsFilter, skin } = props;

    useEffect(() => {
        const dispose = autorun(() => {
            const { getCompetitionDefault } = defaultCompetitionState;
            const state = eventsFilter.competition;

            if (getCompetitionDefault !== null) {
                const initialCompetition = state.filters.find((c) => c.label === getCompetitionDefault);
                if (initialCompetition !== undefined) {
                    state.setActiveFilterId(String(initialCompetition.id));
                    defaultCompetitionState.setDefaultCompetitionFilter(null);
                }
            }
        });
        return (): void => {
            dispose();
        };
    }, []);

    const filtersJsx = renderFilters(eventsFilter, filters, skin);

    if (filtersJsx.length > 1) {
        return (
            <Wrapper>
                <NameContainer>{filtersJsx.map((item) => item.name)}</NameContainer>

                <Right>{filtersJsx.map((item) => item.body)}</Right>
            </Wrapper>
        );
    } else if (filtersJsx.length === 1) {
        const body = filtersJsx[0]?.body;

        return <div>{body}</div>;
    }

    return null;
});
