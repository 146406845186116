import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import styled from '@emotion/styled';


export const SubmitButton = styled(Button, { label: 'SubmitButton' })`
    width: 100%;
    margin-bottom: 4px;
`;

export const SignUpSecondaryForm = styled('form', { label: 'SignUpSecondaryForm' })``;
