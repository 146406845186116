import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { useCommon } from 'src/domains/common/Common';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { TopUpLimitsProcedureItem } from './RollingNetDepositLimitItem';
import {
    RollingDepositLimitForm,
    TextSection,
    SectionHeader,
    ButtonSubmit,
} from 'src/domains/players/webview/components/Account/limitsTab/rollingNetDepositLimit/RollingNetDepositLimit.style';
import {
    RollingNetDepositLimitState,
    InfoMessageType,
} from 'src/domains/players/webview/components/Account/limitsTab/rollingNetDepositLimit/RollingNetDepositLimit.state';

const renderMessage = (infoMessage: InfoMessageType | null): JSX.Element | string => {
    if (infoMessage === 'error') {
        return (
            <Messages
                type='error'
                marginBottom='8px'
                message={
                    <I18n
                        langKey='account.rolling-net-deposit-limits-error-message'
                        defaultText='Sorry, we seem to have a problem. Please try again later.'
                    />
                }
            />
        );
    } else if (infoMessage === 'success') {
        return (
            <Messages
                type='success'
                marginBottom='8px'
                message={
                    <I18n
                        langKey='account.rolling-net-deposit-limits.success-message'
                        defaultText='Set limits finished successfully.'
                    />
                }
            />
        );
    } else if (infoMessage === 'empty') {
        return (
            <Messages
                type='error'
                marginBottom='8px'
                message={
                    <I18n
                        langKey='account.rolling-net-deposit-limits-error-message-empty-deposit'
                        defaultText='You have to choose at least one deposit limit to save.'
                    />
                }
            />
        );
    } else if (infoMessage === 'belowOne') {
        return (
            <Messages
                type='error'
                marginBottom='8px'
                message={
                    <I18n
                        langKey='account.rolling-net-deposit-limits-error-message-below-one-deposit'
                        defaultText='You cannot set deposit limit below 1'
                    />
                }
            />
        );
    }

    return '';
};

export const RollingNetDepositLimit = observer('RollingNetDepositLimit', () => {
    const common = useCommon();
    const configComponents = ConfigComponents.get(common);
    const {
        appPlayersState: { usersState },
    } = useAppStateContext();

    const [state] = useState(() => new RollingNetDepositLimitState(common.trpcClient, configComponents, usersState));

    return (
        <RollingDepositLimitForm
            onSubmit={(e): Promise<void> => {
                return state.onSubmit(e);
            }}
            data-test='rolling-net-deposit-limits-procedure'
        >
            <SectionHeader
                isUppercase={true}
                data-test='responsible-gambling-header'
            >
                <I18n
                    langKey='account.rolling-net-deposit-limits.responsible-gambling.title'
                    defaultText='Responsible Gambling'
                />
            </SectionHeader>
            <TextSection data-test='responsible-gambling-content'>
                <I18n
                    langKey='account.rolling-net-deposit-limits.responsible-gambling.description'
                    defaultText='If you wish you can limit how much we allow you to top up per day, per week or per month. Whichever limit is reached first in any given period will bring the top up restriction into effect.'
                />
            </TextSection>
            <SectionHeader
                isUppercase={false}
                data-test='rolling-net-deposit-responsible-gambling-header'
            >
                <I18n
                    langKey='account.rolling-net-deposit-limits.responsible-gambling.rolling-header'
                    defaultText='Turn on net deposit limits'
                />
            </SectionHeader>
            <TextSection data-test='rolling-net-deposit-limits-content'>
                <I18n
                    langKey='account.rolling-net-deposit-limits.description'
                    defaultText='Rolling net deposit limits cap the net amount (deposits minus withdrawals) you can deposit over a set period, ensuring you can only add a controlled amount of money to your account after accounting for withdrawals.'
                />
            </TextSection>
            {state.rollingNetDepositLimitStatus === 'loading' ? (
                <I18n
                    langKey='account.contact-preferences.loading'
                    defaultText='Loading...'
                />
            ) : state.rollingNetDepositLimitStatus === 'error' ? (
                renderMessage('error')
            ) : (
                <>
                    <TopUpLimitsProcedureItem
                        state={state.daily}
                        label={
                            <I18n
                                langKey='account.rolling-net-deposit-limits.daily-limit.label'
                                defaultText='1 Day'
                            />
                        }
                    />
                    <TopUpLimitsProcedureItem
                        state={state.weekly}
                        label={
                            <I18n
                                langKey='account.rolling-net-deposit-limits.weekly-limit.label'
                                defaultText='7 Days'
                            />
                        }
                    />
                    <TopUpLimitsProcedureItem
                        state={state.monthly}
                        label={
                            <I18n
                                langKey='account.rolling-net-deposit-limits.monthly-limit.label'
                                defaultText='30 Days'
                            />
                        }
                    />
                    {renderMessage(state.infoMessage)}
                    <ButtonSubmit
                        type='submit'
                        size='medium'
                        disabled={state.isDisableButton || state.isErrorInput}
                        dataTest='save-button'
                    >
                        <I18n
                            langKey='account.rolling-net-deposit-limits.submit.label'
                            defaultText='Save'
                        />
                    </ButtonSubmit>
                </>
            )}
        </RollingDepositLimitForm>
    );
});
