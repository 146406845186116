import { action, computed, observable, makeObservable } from 'mobx';
import { CustomKeyboardState } from 'src/domains/players/state/CustomKeyboardState';
import { DateInputState } from 'src/domains/players/webview/components/form/DateInput';
import {
    PhoneNumberState,
    PromoCodesState,
    emailCheckMap,
    toLowerCase,
    SignUpState,
} from 'src/domains/players/webview/components/SignUp';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { ConfigType } from 'src/domains/layouts/config/features/types';
import { getCookie, setCookie } from 'src/domains/layouts/config/config';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { timeout } from 'src_common/common/mobx-utils/timeout';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { FormModel, Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { isCountryCodeType } from 'src/domains/layouts/config/countries';
import {
    checkPassword,
    validateEmail,
    validateEmailRaw,
    validatePassword,
    validateRequire,
} from 'src/domains/players/webview/components/ValidatorsNew';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { DateTime } from 'src_common/utils/time/time';
import { TrpcClient } from 'src/appState/TrpcClient';
import { createAccountPrimary, createAccountLK } from 'src_server/trpc/types';

const AccountAgeVerificationEnum = createAccountPrimary.AccountAgeVerificationEnum;
const AccountStatusEnum = createAccountPrimary.AccountStatusEnum;
type ContactPreferencesType = createAccountPrimary.CreateAccountInput['contactPreferences'];

interface SignUpGroupModelType {
    email: string;
    password: string;
    promoCode: string;
    dateOfBirth: DateTime;
    prefix: string;
    phoneNumber: string;
    affiliateId?: string;
}

interface SignUpFormModelType {
    email: string;
    password: string;
    mobilePhone: createAccountPrimary.CreateAccountInput['mobilePhone'];
    birthDate: string;
    referrer?: string;
    incomeaccess?: string;
    contactPreferences: Exclude<ContactPreferencesType, 'phone'>;
    promoID: string;
}
export class SignUpSecondaryCreateAccountState {
    @observable public isShowPassword = false;
    @observable public isPromoCode = false;
    @observable public isLoading = false;
    @observable public backendErrorMessage: string | null = null;
    @observable public wasTouched: boolean | null = null;
    @observable public validateOnSubmit: boolean = false;

    public promoCodesState: PromoCodesState;

    private readonly emailCheckMap: MobxMapAutoNew<string, Resource<boolean>>;

    public readonly emailState: FormInputState<string, string>;
    public readonly passwordState: FormInputState<string, string>;
    public readonly dateOfBirth: DateInputState;
    public readonly phoneNumber: PhoneNumberState;
    public readonly promoCodeTextState: FormInputState<string, string>;
    public readonly affiliateIdModel: FormInputState<string, string | undefined>;

    public readonly signUpGroup: FormModel<SignUpGroupModelType>;
    public readonly signUpFormModel: FormModel<SignUpFormModelType>;

    public constructor(
        public readonly signUpState: SignUpState,
        public readonly customKeyboard: CustomKeyboardState,
        public readonly language: LanguagesState,
        private readonly config: ConfigType,
        private readonly router: StarRouter,
        private readonly trpc: TrpcClient
    ) {
        makeObservable(this);
        this.promoCodesState = new PromoCodesState();

        this.emailCheckMap = emailCheckMap();

        this.emailState = FormInputState.new('')
            .map(validateRequire)
            .map((value) => {
                const valueTrim = value.trim();
                return Result.createOk(valueTrim);
            })
            .map(validateEmail)
            .map((value) => {
                const isExistResult = this.emailCheckMap.get(value).get();

                if (isExistResult.type === 'ready') {
                    const isExist = isExistResult.value;

                    if (isExist) {
                        return Result.createError(getErrorByCode('ERROR_ALREADY_EXISTS'));
                    }
                }

                return Result.createOk(value);
            });

        this.passwordState = FormInputState.new('').map(validatePassword);

        this.dateOfBirth = new DateInputState(this.customKeyboard);

        this.phoneNumber = new PhoneNumberState(this.language, this.config.prefixAndPhoneNumberDefault);

        if (config.zipCodeAndCountryCheck === true) {
            this.phoneNumber.prefix.inputState = this.phoneNumber.prefix.inputState.map(
                (value: string): Result<string> => {
                    if (this.wasTouched === true || this.validateOnSubmit === true) {
                        if (value.trim().length < 1) {
                            return Result.createError(getErrorByCode('ERROR_REQUIRED_FIELD'));
                        } else {
                            return Result.createOk(value);
                        }
                    }
                    return Result.createOk('');
                }
            );
        }

        this.promoCodeTextState = FormInputState.new('')
            .map(toLowerCase)
            .map((value) => {
                if (this.promoCodesState.promoCodes.type === 'ready') {
                    const isPromoCodeValid = this.promoCodesState.checkIfCodeExistsAndIsValid(value);

                    if (isPromoCodeValid === false) {
                        const message = this.language.getTranslation(
                            'sign-up.create-account.welcome.promo-code.error',
                            'This promo code is not valid'
                        );
                        return Result.createError(message);
                    }
                }

                return Result.createOk(value);
            });

        this.affiliateIdModel = this.promoCodeTextState.map((): Result<string | undefined> => {
            if (this.promoCodesState.promoCodes.type === 'ready') {
                const isAffiliateIdValid = this.promoCodesState.onIfAffiliateIdExists(this.promoCodeTextState.value);

                return Result.createOk(isAffiliateIdValid);
            }
            return Result.createOk(undefined);
        });

        this.signUpGroup = FormModel.group({
            email: this.emailState,
            password: this.passwordState,
            dateOfBirth: this.dateOfBirth.dateModel,
            prefix: this.phoneNumber.prefix.inputState,
            phoneNumber: this.phoneNumber.phoneNumber,
            promoCode: this.promoCodeTextState,
            affiliateId: this.affiliateIdModel,
        });

        this.signUpFormModel = this.signUpGroup.map((value): Result<SignUpFormModelType> => {
            const btag = getCookie('btag');
            const stag = getCookie('stag');
            const referrer = getCookie('referrer');

            const countryKey = this.phoneNumber.prefix.currentValueFormatted?.key ?? '';
            const { EMAIL, SMS } = createAccountPrimary.ContactPreferenceEnum;
            const createAccountData: SignUpFormModelType = {
                email: value.email,
                password: value.password,
                birthDate: value.dateOfBirth.format('YYYY-M-D'),
                mobilePhone: {
                    country: isCountryCodeType(countryKey) ? countryKey : 'GB',
                    prefix: value.prefix.replace('+', ''),
                    number: value.phoneNumber,
                },
                referrer: referrer ?? undefined,
                incomeaccess: value.affiliateId ?? btag ?? stag ?? undefined,
                contactPreferences: [EMAIL, SMS],
                promoID: value.promoCode,
            };

            if (value.affiliateId !== undefined) {
                setCookie('btag', value.affiliateId, 31, 'None');
            }

            return Result.createOk(createAccountData);
        });
    }

    @action public showPassword = (): void => {
        this.isShowPassword = !this.isShowPassword;
    };

    @action public setPromoCode = (): void => {
        this.isPromoCode = !this.isPromoCode;
    };

    @action public createAccount = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
        if (this.config.zipCodeAndCountryCheck === true) {
            this.validateOnSubmit = true;
        }

        e.preventDefault();
        this.signUpGroup.setAsVisited();
        const signUpResult = this.signUpFormModel.result;
        this.isLoading = true;
        await this.promoCodesState.promoCodesResource.refresh();

        if (signUpResult.value.type === 'error') {
            this.isLoading = false;
            return;
        }

        const email = this.emailState.value;
        const password = this.passwordState.value;

        if (this.promoCodesState.promoCodes.type === 'ready') {
            const isPromoCodeValid = this.promoCodesState.checkIfCodeExistsAndIsValid(this.promoCodeTextState.value);

            if (isPromoCodeValid === false) {
                const message = this.signUpState.params.language.getTranslation(
                    'sign-up.create-account.promo-code.error',
                    'This promo code is not valid'
                );
                this.backendErrorMessage = message;
                this.isLoading = false;
                return;
            }

            const credentials: createAccountLK.CreateAccountInputType = {
                ...signUpResult.value.data,
                marketing: false,
                username: signUpResult.value.data.email, //Temporary
                surname: 'LuckyKing', //registration required
                firstName: 'LuckyKing', //registration required
            };

            const createAccountResponse = await this.trpc.client.signup.createAccountLuckyKing.mutate(credentials);

            switch (createAccountResponse.responseStatus) {
                case 'success':
                    if (this.promoCodeTextState.value !== '') {
                        await this.promoCodesState.updatePromoCode(this.promoCodeTextState.value);
                    }
                    this.router.clearBtagAndStag();

                    setCookie('btag', '', 0, 'None');
                    setCookie('stag', '', 0, 'None');

                    break;
                case 'error':
                    const arrEl = createAccountResponse.data.errors[0];
                    if (arrEl?.field !== undefined && arrEl.field !== null) {
                        if (arrEl.debugDetails !== undefined && arrEl.debugDetails !== null) {
                            this.backendErrorMessage = `In ${arrEl.field} - ${arrEl.debugDetails}`;
                        }
                    }
                    return;
            }

            for (let i = 0; i < 150; i++) {
                //7min try login user, waiting for account verify.

                const accountStatus = await this.trpc.client.signup.checkStatusOfAccount.mutate({
                    body: { token: createAccountResponse.data.token },
                });
                if (accountStatus.responseStatus === 'success') {
                    const isBlockedAccount = accountStatus.data.status === AccountStatusEnum.BLOCKED;
                    const isActiveAccount = accountStatus.data.status === AccountStatusEnum.ACTIVE;
                    const isSuspendedAccount = accountStatus.data.status === AccountStatusEnum.SUSPENDED;
                    const isAvVerificationFailed =
                        accountStatus.data.ageVerification === AccountAgeVerificationEnum.FAILED;
                    const isAvVerificationUnknown =
                        accountStatus.data.ageVerification === AccountAgeVerificationEnum.UNKNOWN;

                    if (isSuspendedAccount || isBlockedAccount) {
                        const loginUser = await this.signUpState.params.accountState.loginUser(
                            email,
                            password,
                            'registration'
                        );
                        // set by default RingFencedFlag for new user
                        await this.signUpState.params.accountState.account?.onChangeRingFencedFlag();

                        if (loginUser.type === 'CreateSessionResponseOk') {
                            this.signUpState.params.googleTagManager.registerFinishedTag(loginUser.accountId);
                        }

                        if (loginUser.type === 'CreateSessionResponseErrors') {
                            this.signUpState.params.googleTagManager.registerFinishedTag(loginUser.accountId);
                            if (loginUser.error === 'gamstop_unauthorized') {
                                console.error('Verification error description: ', loginUser.error_description);
                                this.isLoading = false;
                                this.backendErrorMessage = 'Verification error';
                                return;
                            }
                        }
                    }

                    if (isActiveAccount) {
                        const loginUser = await this.signUpState.params.accountState.loginUser(
                            email,
                            password,
                            'registration'
                        );

                        if (loginUser.type === 'CreateSessionResponseOk') {
                            this.signUpState.params.googleTagManager.registerFinishedTag(loginUser.accountId);
                            // set by default RingFencedFlag for new user
                            await this.signUpState.params.accountState.account?.onChangeRingFencedFlag();
                            await this.signUpState.toggleAside();
                        }

                        this.isLoading = false;

                        return;
                    }

                    if (isSuspendedAccount || isAvVerificationFailed || isAvVerificationUnknown) {
                        this.backendErrorMessage = 'Verification error';
                        this.isLoading = false;
                        return;
                    }
                }
                await timeout(3000);
            }
        }

        this.isLoading = false;
        return;
    };

    @computed public get isCorrectEmail(): boolean {
        const email = this.emailState.value;
        const emailItem = this.emailCheckMap.get(this.emailState.value).get();

        if (emailItem.type === 'ready') {
            const isExist = emailItem.value;
            if (isExist) {
                return false;
            }

            const isEmailOk = validateEmailRaw(email);
            return isEmailOk;
        }

        return false;
    }

    @computed public get isCorrectPassword(): boolean {
        const password = this.passwordState.value;

        return checkPassword(password);
    }

    @action public handleWasTouched = (): void => {
        this.wasTouched = true;
    };
}
