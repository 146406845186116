import React from 'react';
import {
    EventName,
    EventInfo,
    EventWrapper,
} from 'src/domains/layouts/webview/components/heroEvents/HomepageHeroWidget.style';
import { HeroWidgetImagesType } from 'src/domains/layouts/webview/components/heroEvents/HeroWidgetItem.state';
import { Wrapper } from './HeroWidgetMainBanner.style';

interface HeroWidgetMainBannerPropsType {
    images: HeroWidgetImagesType;
    title: string;
    subtitle?: string | null;
}
export const HeroWidgetMainBanner = ({ images, title, subtitle }: HeroWidgetMainBannerPropsType): JSX.Element => (
    <Wrapper
        banners={images}
        data-test='widget-banner'
    >
        <EventWrapper>
            <EventName data-test='hero-widget-title'>{title}</EventName>
            <EventInfo data-test='hero-widget-subtitle'>{subtitle}</EventInfo>
        </EventWrapper>
    </Wrapper>
);
