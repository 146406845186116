import { action, observable, makeObservable } from 'mobx';
import { SignUpMainSteps } from './stepsStructure/SignupMainSteps';
import { SignUpParamsType } from './SignUpParams';

export class SignUpState {
    public signupType = 'SignupPrimary';
    @observable public isLoading: boolean = false;

    public currentView: SignUpMainSteps;

    public constructor(public readonly params: SignUpParamsType) {
        makeObservable(this);
        this.currentView = new SignUpMainSteps(params, this.setLoading);
    }

    @action public rebuildState = (): void => {
        this.currentView = new SignUpMainSteps(this.params, this.setLoading);
        this.params.googleTagManager.renderNewUuid();
    };

    @action public setLoading = (isLoading: boolean): void => {
        this.isLoading = isLoading;
    };

    @action public toggleAside = async (): Promise<void> => {
        this.rebuildState();
        this.params.router.closeAccount();
        await this.params.modalState.closeAll();

        if (this.isLoading) {
            await this.params.accountState.handleLogout();
        }
    };
}
