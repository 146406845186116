import * as React from 'react';
import { PromoSidebarWrapper, IframeStyle, IframeContent, BannerImage } from './PromoSidebar.style';

import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { SidebarHeader, Overlay, SidebarContent, Terms } from './PromoSidebarPartials';

interface PropsType {
    promotionSlug: string;
}

export const PromoSidebar = observer('PromoSidebar', ({ promotionSlug }: PropsType) => {
    const { appPlayersState, appLayoutsState } = useAppStateContext();
    const { bannersBoxState, sdkCustomer, starRouter, configComponents, session } = appLayoutsState;
    const { specialEvents } = appPlayersState;
    const { modalState } = appPlayersState;
    const isAnyLayerVisible = modalState.isAnyLayerVisible;

    // TODO change after new singup
    const topOffset = bannersBoxState.boxHeight;
    const lpState = specialEvents.getLandingPageState(promotionSlug);
    const {
        promoLandingPage,
        shouldDisplay,
        isShowForUser,
        isHTMLBanner,
        actionTemplate,
        ctaText,
        ctaUrl,
        handleRedirectToLogin,
        handleRedirectToSignUp,
        closePromoSidebar,
        addToLocalStoreAndClose,
        lpImgHtmlUrl,
        lpImgUrl,
    } = lpState;

    const handleMatchUrl = (): void => {
        starRouter.handleUrlRedirection(ctaUrl);
        closePromoSidebar();
    };

    if (shouldDisplay === true && isShowForUser === true) {
        return (
            <>
                <Overlay
                    shouldDisplay={shouldDisplay}
                    isAnyLayerVisible={isAnyLayerVisible}
                />

                <PromoSidebarWrapper
                    topOffset={topOffset}
                    data-test='landing-page'
                >
                    <SidebarHeader
                        operatorName={configComponents.config.operatorFullName}
                        isUserAuthorized={sdkCustomer.session.isAuthorized}
                        onCloseHandle={actionTemplate === 'signup' ? addToLocalStoreAndClose : closePromoSidebar}
                        onSignUpHandle={handleRedirectToSignUp}
                        onLoginHandle={handleRedirectToLogin}
                    />

                    {isHTMLBanner && lpImgHtmlUrl !== null ? (
                        <>
                            <IframeStyle
                                src={lpImgHtmlUrl}
                                dataTest='landing-page-html-img'
                            />
                            <IframeContent>
                                <SidebarContent
                                    lpState={lpState}
                                    promotionSlug={promotionSlug}
                                    actionTemplate={actionTemplate}
                                    onClickDirectLinkHandle={handleMatchUrl}
                                    ctaText={ctaText}
                                    isUserAuthorized={session.isAuthorized}
                                    isHTMLBanner={true}
                                />
                                {promoLandingPage.type === 'ready' && promoLandingPage.value !== undefined && (
                                    <Terms tc_text={promoLandingPage.value?.tc_text} />
                                )}
                            </IframeContent>
                        </>
                    ) : lpImgUrl === null ? null : (
                        <>
                            <BannerImage
                                src={lpImgUrl}
                                data-test='landing-page-static-img'
                            />
                            <SidebarContent
                                lpState={lpState}
                                promotionSlug={promotionSlug}
                                actionTemplate={actionTemplate}
                                onClickDirectLinkHandle={handleMatchUrl}
                                ctaText={ctaText}
                                isUserAuthorized={session.isAuthorized}
                                isHTMLBanner={false}
                            />
                            {promoLandingPage.type === 'ready' && promoLandingPage.value !== undefined && (
                                <Terms tc_text={promoLandingPage.value?.tc_text} />
                            )}
                        </>
                    )}
                </PromoSidebarWrapper>
            </>
        );
    }

    return null;
});
