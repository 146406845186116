import { observer } from 'src/utils/mobx-react';
import * as React from 'react';
import { PopupContent, PopupBody, PopupTitle, PopupClose, PopupOverlayDefault } from './Popup/Popup.style';

function ignoreClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    e.stopPropagation();
}

interface PropsType {
    className?: string;
    onClose?: (e: React.SyntheticEvent) => void;
    icon?: React.ReactNode;
    title?: string | JSX.Element;
    closeButton?: boolean;
    maxWidth?: string;
    priority?: number;
    overlayClose?: boolean;
    children: React.ReactNode;
    dataTest?: string;
}

export const Popup = observer('Popup', (props: PropsType): React.ReactElement => {
    const {
        icon,
        title,
        children,
        closeButton = true,
        priority = 0,
        overlayClose = false,
        className,
        maxWidth,
        dataTest,
    } = props;

    const close = (e: React.SyntheticEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        if (props.onClose !== undefined) {
            props.onClose(e);
        }
    };

    const zIndex = 10000 + priority;

    let onOverlayClick;
    if (overlayClose) {
        onOverlayClick = close;
    }

    let titleEl;
    if (title !== undefined) {
        let closeButtonEl;
        if (closeButton) {
            closeButtonEl = (
                <PopupClose
                    onClick={close}
                    className='popup-close'
                >
                    &times;
                </PopupClose>
            );
        }

        titleEl = (
            <PopupTitle>
                {icon}
                {title}
                {closeButtonEl}
            </PopupTitle>
        );
    }

    return (
        <PopupOverlayDefault
            active={true}
            className={className}
            style={{ zIndex }}
            onClick={onOverlayClick}
            data-test='popup-overlay'
        >
            <PopupContent
                onClick={ignoreClick}
                maxWidth={maxWidth}
                data-test={dataTest}
            >
                {titleEl}
                <PopupBody>{children}</PopupBody>
            </PopupContent>
        </PopupOverlayDefault>
    );
});

export { PopupOverlayDefault };
