import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { CustomScrollbarType } from './SrollVertical.types';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

export const setCustomScrollbar = (theme: EmotionTheme, mode: CustomScrollbarType): SerializedStyles => {
    return css`
        --light-bg-color: ${theme.star.themeTokens.control.scrollbar.bgLight};
        --dark-bg-color: ${theme.star.themeTokens.control.scrollbar.bgDark};

        &::-webkit-scrollbar {
            background-color: transparent;
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${mode === 'light' ? 'var(--light-bg-color)' : 'var(--dark-bg-color)'};
            border-radius: 8px;
        }
        height: 100%;
        scrollbar-color: ${mode === 'light' ? 'var(--light-bg-color) transparent' : 'var(--dark-bg-color) transparent'};
        scrollbar-width: thin;
        width: 100%;
    `;
};

export interface CustomScrollbarWrapperPropsType {
    mode: CustomScrollbarType;
}

export const CustomScrollbarWrapper = withConfig(theme => styled('div', { label: 'CustomScrollbarWrapper' })<CustomScrollbarWrapperPropsType>`
    ${({ mode }): SerializedStyles => setCustomScrollbar(theme, mode)};
    overflow: auto;
`);
