import React, { useState } from 'react';
import { DateRangeForm } from 'src/domains/players/webview/components/Account/common/dateRangeForm/DateRangeForm';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { TransactionHistoryTabState } from './TransactionHistoryTab.state';
import { TransactionsList } from 'src/domains/players/webview/components/Account/transactionsList/TransactionsList';
import { FiltersNewView, FiltersType } from 'src/domains/layouts/webview/components/filters/Filters';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { DateRangeFormWrapper, TransactionHistoryFilterWrapper } from './TransactionHistoryTab.style';
import { observer } from 'src/utils/mobx-react';
import { observable, makeObservable, action } from 'mobx';
import { HistoryFiltersNew } from 'src/domains/players/webview/components/Account/common/historyFilters/HistoryFilters';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import { Pagination } from 'src/domains/players/webview/components/Account/transactionHistoryTab/pagination/Pagination';
import { DateRangeFormState } from 'src/domains/players/webview/components/Account/common/dateRangeForm/DateRangeForm.state';
import { DateTime } from 'src_common/utils/time/time';
import { useCommon } from 'src/domains/common/Common';
import { TranslationsStore } from 'src/domains/layouts/state/translationsStore/TranslationsStore';

class TimeRangeTransactionHistoryTabState {
    @observable public activeId: string = 'all-dates';

    public constructor(
        private readonly transactionHistoryTabState: TransactionHistoryTabState,
        private readonly language: LanguagesState
    ) {
        makeObservable(this);
    }

    public get filters(): FiltersType[] {
        return [
            {
                key: 'all-dates',
                label: this.language.getTranslation('account.transaction-history.all-dates.label', 'All Time'),
                onClick: () => this.setActiveFilterId('all-dates'),
            },
            {
                key: 'date-range',
                label: this.language.getTranslation('account.transaction-history.date-range.label', 'Date Range'),
                onClick: () => this.setActiveFilterId('date-range'),
            },
        ];
    }

    @action public setActiveFilterId(id: string): void {
        this.activeId = id;
        if (id === 'all-dates') {
            this.transactionHistoryTabState.resetRange();
        }
    }
}

export const TransactionHistoryTab = observer('TransactionHistoryTab', () => {
    const common = useCommon();
    const { appPlayersState, appLayoutsState } = useAppStateContext();
    const [formState] = React.useState(
        () => new DateRangeFormState(DateTime.current().subtractMonths(1), DateTime.current())
    );
    const { accountState } = appPlayersState;
    const translationsStore = TranslationsStore.get(common);
    const {
        languagesState,
        configComponents: { config },
    } = appLayoutsState;

    const [transactionHistoryState] = useState(
        () => new TransactionHistoryTabState(accountState, translationsStore, languagesState, config)
    );
    const [timeRangeFiltersState] = useState(
        () => new TimeRangeTransactionHistoryTabState(transactionHistoryState, languagesState)
    );

    const isDateFilter = timeRangeFiltersState.activeId === 'date-range';

    return (
        <>
            <HistoryFiltersNew
                filters={timeRangeFiltersState.filters}
                selected={timeRangeFiltersState.activeId}
                skin={config.filtersVersion.transactionHistory}
            />
            {isDateFilter ? (
                <DateRangeFormWrapper>
                    <DateRangeForm
                        key='date-range'
                        label={
                            <I18n
                                langKey='account.transaction-history.date-range.label'
                                defaultText='Date Range'
                            />
                        }
                        state={formState}
                        status={transactionHistoryState.collection.responseStatusNetDeposit}
                        onSubmit={transactionHistoryState.submitRange}
                    />
                </DateRangeFormWrapper>
            ) : null}
            <TransactionHistoryFilterWrapper>
                <FiltersScrollableWrapper>
                    <FiltersNewView
                        select={transactionHistoryState.collection.activeFilter}
                        filters={transactionHistoryState.filtersHome}
                        skin={config.filtersVersion.transactionHistory}
                    />
                </FiltersScrollableWrapper>
            </TransactionHistoryFilterWrapper>

            <TransactionsList
                state={transactionHistoryState}
                isDateFilter={isDateFilter}
            />

            <Pagination collection={transactionHistoryState.collection} />
        </>
    );
});
