import * as React from 'react';
import { Layer } from 'src/domains/layouts/webview/components/modals/Layer';
import { observer } from 'src/utils/mobx-react';
import { ModalState } from 'src/domains/layouts/webview/components/modals/Modal.state';
import { Overlay } from 'src/domains/layouts/webview/components/modals/Modal.style';
import { ModalLayer } from 'src/domains/layouts/webview/components/modals/Layer.state';

const splitList = (list: Array<ModalLayer>): [Array<ModalLayer>, Array<ModalLayer>] => {
    const itemsUnderOverlay = [];
    const itemsAboveOverlay = [];

    let up: 'itemsUnderOverlay' | 'itemsAboveOverlay' = 'itemsAboveOverlay';

    for (const item of list.slice().reverse()) {
        if (up === 'itemsUnderOverlay') {
            itemsUnderOverlay.push(item);
        } else {
            if (item.visible) {
                up = 'itemsUnderOverlay';
            }

            itemsAboveOverlay.push(item);
        }
    }

    return [itemsUnderOverlay.reverse(), itemsAboveOverlay.reverse()];
};

const isItemsVisible = (list: Array<ModalLayer>): boolean => {
    for (const item of list) {
        if (item.visible) {
            return true;
        }
    }

    return false;
};

interface ModalPropsType {
    state: ModalState;
}

export const Modal = observer('Modal', ({ state }: ModalPropsType): React.ReactElement => {
    const [listDown, listUp] = splitList(state.list);
    const isVisibleOverlay = isItemsVisible(state.list);

    const listJsx = [];

    listJsx.push(
        <Overlay
            isVisible={isVisibleOverlay}
            key='overlay'
            onClick={state.clickOverlay}
        />
    );

    for (const item of listDown) {
        listJsx.push(
            <Layer
                key={item.id}
                layer={item}
            />
        );
    }

    for (const item of listUp) {
        listJsx.push(
            <Layer
                key={item.id}
                layer={item}
            />
        );
    }

    return <div>{listJsx}</div>;
});
