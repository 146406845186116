import { FindRouteType } from 'src/domains/layouts/state/router/Router';
import {
    HomeRouteType,
    InPlayRouteType,
    VirtualsRouteType,
    CasinoRouteType,
    LiveCasinoRouteType,
    SportRouteType,
    CompetitionRouteType,
    EventRouteType,
    RacecardMeetingsRouteType,
    RacecardRaceRouteType,
    NotificationRouteType,
    RouteViewType,
    SportSpecialRouteType,
    SearchRouteType,
    NavigationSportsRouteType,
    NotificationsListRouteType,
    StareventsRouteType,
    getSportRacesTypeParam,
    NotFoundType,
    SportsRouteType,
} from './mainRouteTypes';
import { getParamNumber } from './parsers';

const getRacecardBuildIds = (params: Record<string, string>): Array<number> => {
    const racecardBuildIds = params['racecardBuildIds'];

    if (racecardBuildIds === undefined) {
        return [];
    }

    const out: Array<number> = [];

    const chunks = racecardBuildIds.split('-');

    for (const chunkItem of chunks) {
        const eventId = parseInt(chunkItem, 10);
        if (!isNaN(eventId)) {
            out.push(eventId);
        }
    }

    return out;
};

const tryMatchRaceMeetings = (routeInner: FindRouteType): RacecardMeetingsRouteType | null => {
    const name = routeInner.name;

    if (name === 'racecard' && routeInner.params.collection === 'race-meetings') {
        const sport = routeInner.params.sport;
        if (sport === undefined) {
            return null;
        }

        const selected = getParamNumber(routeInner.params, 'selected');
        const buildIds = getRacecardBuildIds(routeInner.params);

        return {
            name: 'racecard',
            collection: 'race-meetings',
            racecardBuildIds: buildIds,
            selected: selected,
            sport: sport,
        };
    }

    return null;
};

const tryMatchRace = (routeInner: FindRouteType): RacecardRaceRouteType | null => {
    const name = routeInner.name;

    if (name === 'racecard') {
        const sport = routeInner.params.sport;
        if (sport === undefined) {
            return null;
        }

        const selected = getParamNumber(routeInner.params, 'selected');

        if (routeInner.params.collection === 'next-off') {
            return {
                name: 'racecard',
                collection: 'next-off',
                selected: selected,
                sport: sport,
            };
        }

        const collection = getParamNumber(routeInner.params, 'collection');

        if (collection === null) {
            return null;
        }

        return {
            name: 'racecard',
            collection,
            selected: selected,
            sport: sport,
        };
    }

    return null;
};

const tryMatchNotificationId = (routeInner: FindRouteType): NotificationRouteType | null => {
    const name = routeInner.name;
    const notiificationId = getParamNumber(routeInner.params, 'notiificationId');

    if (name === 'notificationId' && notiificationId !== null) {
        return {
            name: 'notificationId',
            notificationId: notiificationId,
        };
    }

    return null;
};

const tryMatchInPlay = (routeInner: FindRouteType): InPlayRouteType | null => {
    const name = routeInner.name;

    if (name === 'inplay') {
        return {
            name: 'inplay',
        };
    }

    return null;
};

const tryMatchNotFound = (routeInner: FindRouteType): NotFoundType | null => {
    const name = routeInner.name;

    if (name === 'error') {
        return {
            name: 'error',
        };
    }

    return null;
};

const tryMatchCompetition = (routeInner: FindRouteType): CompetitionRouteType | null => {
    const name = routeInner.name;

    if (name === 'competition') {
        const id = getParamNumber(routeInner.params, 'id');
        const slug = routeInner.params.slug ?? null;

        if (id !== null && slug !== null) {
            return {
                name: 'competition',
                id,
                slug,
            };
        }
    }

    return null;
};

const tryMatchSport = (routeInner: FindRouteType): SportRouteType | null => {
    const name = routeInner.name;

    if (name === 'sport') {
        const id = routeInner.params.id ?? null;

        if (id !== null) {
            if (id === 'horseracing' || id === 'greyhoundracing') {
                const eventId = getParamNumber(routeInner.params, 'eventId');
                const slug = routeInner.params.slug;

                const event =
                    eventId !== null && slug !== undefined
                        ? {
                              id: eventId,
                              slug,
                          }
                        : null;

                return {
                    name: 'sport',
                    nameType: 'races',
                    id,
                    event,
                    type: getSportRacesTypeParam(routeInner.params.type),
                };
            }

            return {
                name: 'sport',
                nameType: 'regular',
                id,
            };
        }
    }

    return null;
};

const tryMatchEvent = (routeInner: FindRouteType): EventRouteType | null => {
    const name = routeInner.name;

    if (name === 'event') {
        const id = getParamNumber(routeInner.params, 'id');
        const slug = routeInner.params.slug ?? null;

        if (id !== null && slug !== null) {
            return {
                name: 'event',
                id,
                slug,
            };
        }
    }

    return null;
};

const tryMatchVirtuals = (routeInner: FindRouteType): VirtualsRouteType | null => {
    const name = routeInner.name;
    if (name === 'virtuals') {
        return {
            name: 'virtuals',
        };
    }

    return null;
};

const tryMatchCasino = (routeInner: FindRouteType): CasinoRouteType | null => {
    const name = routeInner.name;

    if (name === 'casino') {
        const tabId = routeInner.params.tabId ?? null;
        const game = routeInner.params.game ?? null;

        return {
            name: 'casino',
            tabId,
            game,
        };
    }

    return null;
};

const tryMatchLiveCasino = (routeInner: FindRouteType): LiveCasinoRouteType | null => {
    const name = routeInner.name;

    if (name === 'live-casino') {
        const tabId = routeInner.params.tabId ?? null;
        return {
            name: 'live-casino',
            tabId,
        };
    }

    return null;
};

const tryMatchHomepage = (routeInner: FindRouteType): HomeRouteType | null => {
    const name = routeInner.name;

    if (name === 'homepage') {
        return {
            name: 'homepage',
        };
    }

    return null;
};

const tryMatchSports = (routeInner: FindRouteType): SportsRouteType | null => {
    const name = routeInner.name;

    if (name === 'sports') {
        return {
            name: 'sports',
        };
    }

    return null;
};

const tryMatchSportSpecial = (routeInner: FindRouteType): SportSpecialRouteType | null => {
    const name = routeInner.name;
    const id = routeInner.params.id ?? null;

    if (name === 'sport-special' && id !== null) {
        return {
            name: 'sport-special',
            id,
        };
    }

    return null;
};

const tryMatchSearch = (routeInner: FindRouteType): SearchRouteType | null => {
    const name = routeInner.name;

    if (name === 'search') {
        return {
            name: 'search',
        };
    }

    return null;
};

const tryMatchNavigationSports = (routeInner: FindRouteType): NavigationSportsRouteType | null => {
    const name = routeInner.name;

    if (name === 'navigation-sports') {
        return {
            name: 'navigation-sports',
        };
    }

    return null;
};

const tryMatchStarevents = (routeInner: FindRouteType): StareventsRouteType | null => {
    const name = routeInner.name;

    if (name === 'starevents') {
        return {
            name: 'starevents',
        };
    }

    return null;
};

const tryMatchNotificationsList = (routeInner: FindRouteType): NotificationsListRouteType | null => {
    const name = routeInner.name;

    if (name === 'notifications-list') {
        return {
            name: 'notifications-list',
        };
    }

    return null;
};

export const tryMatch = (routeInner: FindRouteType): RouteViewType | null => {
    const raceMeetings = tryMatchRaceMeetings(routeInner);
    if (raceMeetings !== null) {
        return raceMeetings;
    }

    const race = tryMatchRace(routeInner);
    if (race !== null) {
        return race;
    }

    const competition = tryMatchCompetition(routeInner);
    if (competition !== null) {
        return competition;
    }

    const sport = tryMatchSport(routeInner);
    if (sport !== null) {
        return sport;
    }

    const event = tryMatchEvent(routeInner);
    if (event !== null) {
        return event;
    }

    const virtuals = tryMatchVirtuals(routeInner);
    if (virtuals !== null) {
        return virtuals;
    }

    const casino = tryMatchCasino(routeInner);
    if (casino !== null) {
        return casino;
    }

    const liveCasino = tryMatchLiveCasino(routeInner);
    if (liveCasino !== null) {
        return liveCasino;
    }

    const homepage = tryMatchHomepage(routeInner);
    if (homepage !== null) {
        return homepage;
    }

    const notificationId = tryMatchNotificationId(routeInner);
    if (notificationId !== null) {
        return notificationId;
    }

    const inPlay = tryMatchInPlay(routeInner);
    if (inPlay !== null) {
        return inPlay;
    }

    const sportSpecial = tryMatchSportSpecial(routeInner);
    if (sportSpecial !== null) {
        return sportSpecial;
    }

    const search = tryMatchSearch(routeInner);
    if (search !== null) {
        return search;
    }

    const navigationSports = tryMatchNavigationSports(routeInner);
    if (navigationSports !== null) {
        return navigationSports;
    }

    const notificationsList = tryMatchNotificationsList(routeInner);
    if (notificationsList !== null) {
        return notificationsList;
    }

    const starevents = tryMatchStarevents(routeInner);
    if (starevents !== null) {
        return starevents;
    }

    const sports = tryMatchSports(routeInner);
    if (sports !== null) {
        return sports;
    }

    const notFound = tryMatchNotFound(routeInner);
    if (notFound !== null) {
        return notFound;
    }

    return null;
};
