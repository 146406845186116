import { computed, observable, action, makeObservable } from 'mobx';
import { ServerTimeState } from 'src_common/common/websocket2/ServerTimeState';
import {
    CompetitionItemViewType,
    EventsCollectionQueryModel,
} from 'src_common/common/websocket2/models/EventsCollectionQueryModel';
import { FiltersStateStrategy, FilterType } from 'src/domains/layouts/webview/components/filters/Filters.state';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { TranslationsStore } from 'src/domains/layouts/state/translationsStore/TranslationsStore';
import { SpecialSportsState } from 'src/domains/sportsbook/state/specialSportsState/SpecialSportsState';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { Common } from 'src/domains/common/Common';
import { Value } from 'src_common/common/mobx-utils/Value';

class EventsFilterGroupStrategy implements FiltersStateStrategy {
    public constructor(
        private value: Value<string>,
        private readonly nameInner: () => string,
        private getFiltersCallback: () => FilterType[]
    ) {
        makeObservable(this);
    }

    public get activeFilterId(): string {
        return this.value.getValue();
    }

    public set activeFilterId(newValue: string) {
        this.value.setValue(newValue);
    }

    @computed public get name(): string {
        return this.nameInner();
    }

    @computed.struct public get filters(): FilterType[] {
        return this.getFiltersCallback();
    }

    public getActiveFilterId(): string | null {
        return this.activeFilterId;
    }

    public setActiveFilterId(id: string | number | null): void {
        if (id === null) {
            this.activeFilterId = '';
        } else {
            this.activeFilterId = String(id);
        }
    }
}

export class EventsFilterGroupFlagState {
    @observable public isOpen: boolean = true;

    public readonly sport: Value<string> = new Value('');
    public readonly competition: Value<string> = new Value('');
    public readonly time: Value<string> = new Value('');
    public readonly region: Value<string> = new Value('');
    public readonly country: Value<string> = new Value('');

    public constructor() {
        makeObservable(this);
    }

    @action public onToggle = (): void => {
        this.isOpen = !this.isOpen;
    };
}

export class EventsFilterGroupComputeds {
    private readonly serverTime: ServerTimeState;
    public readonly getCollection: () => EventsCollectionQueryModel | null;
    public readonly language: LanguagesState;
    public readonly translationsStore: TranslationsStore;
    public readonly specialSportsState: SpecialSportsState | null;
    public readonly modelsState: ModelsState;
    public readonly defaultCompetition: CompetitionItemViewType | undefined;

    public readonly sport: EventsFilterGroupStrategy;
    public readonly competition: EventsFilterGroupStrategy;
    public readonly time: EventsFilterGroupStrategy;
    public readonly region: EventsFilterGroupStrategy;
    public readonly country: EventsFilterGroupStrategy;

    public constructor(
        common: Common,
        getCollection: () => EventsCollectionQueryModel | null,
        specialSportsState: SpecialSportsState | null,
        public readonly flag: EventsFilterGroupFlagState
    ) {
        makeObservable(this);
        this.serverTime = common.serverTime;
        this.getCollection = getCollection;
        this.language = LanguagesState.get(common);
        this.translationsStore = TranslationsStore.get(common);
        this.modelsState = common.models;
        this.specialSportsState = specialSportsState;

        const { getTranslation } = this.language;

        this.sport = new EventsFilterGroupStrategy(
            flag.sport,
            () => getTranslation('events.filters.sport', 'Sport'),
            () => this.sportFilters
        );

        this.competition = new EventsFilterGroupStrategy(
            flag.competition,
            () => getTranslation('events.filters.competition', 'Competition'),
            () => this.competitionFilters
        );

        this.time = new EventsFilterGroupStrategy(
            flag.time,
            () => getTranslation('events.filters.time', 'Time'),
            () => this.timeFilters
        );

        this.region = new EventsFilterGroupStrategy(
            flag.region,
            () => getTranslation('events.filters.region', 'Region'),
            () => this.regionFilters
        );

        this.country = new EventsFilterGroupStrategy(
            flag.country,
            () => getTranslation('events.filters.country', 'Country'),
            () => this.countryFilters
        );
    }

    @computed.struct public get sportFilters(): FilterType[] {
        const collection = this.getCollection();
        if (collection === null) {
            return [];
        }
        return [
            { id: '', key: 'empty', label: this.language.getTranslation('events.filters.sport.empty', 'All Sports') },
            ...collection.sportsIds.map((id: string) => ({
                id: id,
                key: id,
                label: this.translationsStore.translateSport(id),
            })),
        ];
    }

    @computed.struct public get competitionFilters(): FilterType[] {
        const collection = this.getCollection();
        if (collection === null) {
            return [];
        }
        return [
            { id: '', key: 'empty', label: this.language.getTranslation('common.filters.empty.label', 'All') },
            ...collection.competitionForViewByDisplayOrder.map((item) => ({
                id: String(item.id),
                key: String(item.id),
                label: item.name,
            })),
        ];
    }

    public get timeFilters(): FilterType[] {
        const { getTranslation } = this.language;
        return [
            { id: '', key: 'anytime', label: getTranslation('common.filters.anytime.label', 'Anytime') },
            { id: 'in-play', key: 'in-play', label: getTranslation('events.time-options.in-play', 'In Play') },
            { id: 'today', key: 'today', label: getTranslation('events.time-options.today', 'Today') },
            { id: 'tomorrow', key: 'tomorrow', label: getTranslation('events.time-options.tomorrow', 'Tomorrow') },
            { id: 'weekend', key: 'weekend', label: getTranslation('events.time-options.weekend', 'Weekend') },
            {
                id: 'current-week',
                key: 'current-week',
                label: getTranslation('events.time-options.current-week', 'Current week'),
            },
            { id: 'next-week', key: 'next-week', label: getTranslation('events.time-options.next-week', 'Next week') },
        ];
    }

    @computed.struct public get regionFilters(): FilterType[] {
        const { getTranslation } = this.language;
        const collection = this.getCollection();
        if (collection === null) {
            return [];
        }
        return [
            { id: '', key: 'empty', label: getTranslation('common.filters.empty.label', 'All') },
            ...collection.regionIds.map((id: string) => {
                if (id === '-') {
                    return { id: '-', key: 'other', label: getTranslation('events.filters.region.other', 'Other') };
                }
                return { id: id, key: id, label: id };
            }),
        ];
    }

    @computed.struct public get countryFilters(): FilterType[] {
        const { getTranslation } = this.language;
        const collection = this.getCollection();
        if (collection === null) {
            return [];
        }
        return [
            { id: '', key: 'empty', label: getTranslation('common.filters.empty.label', 'All') },
            ...collection.countryIds.map((id: string) => {
                if (id === '-') {
                    return { id: '-', key: 'other', label: getTranslation('events.filters.country.other', 'Other') };
                }
                return { id: id, key: id, label: id };
            }),
        ];
    }

    public filterBySport = (query: EventsCollectionQueryModel): EventsCollectionQueryModel => {
        const sport = this.sport.getActiveFilterId();
        if (sport === '') {
            return query;
        }

        return query.filterBySport((sportId: string): boolean => sportId === sport);
    };

    public filterByCompetition = (query: EventsCollectionQueryModel): EventsCollectionQueryModel => {
        const competitionId = this.competition.getActiveFilterId();
        if (competitionId === '') {
            return query;
        }

        // eslint-disable-next-line no-restricted-globals
        return query.filterByCompetition((id) => Number(competitionId) === id);
    };

    public filterByTime = (query: EventsCollectionQueryModel): EventsCollectionQueryModel => {
        const time = this.time.getActiveFilterId();
        if (time === '') {
            return query;
        }

        return query.filter((event) => {
            const startTime = event.startTime;

            if (time === 'in-play') {
                return this.serverTime.inPlay.matchMs(startTime);
            }

            if (time === 'today') {
                return this.serverTime.today.matchMs(startTime);
            }

            if (time === 'tomorrow') {
                return this.serverTime.tomorrow.matchMs(startTime);
            }

            if (time === 'weekend') {
                return this.serverTime.weekend.matchMs(startTime);
            }

            if (time === 'current-week') {
                return this.serverTime.currentWeek.matchMs(startTime);
            }

            if (time === 'next-week') {
                return this.serverTime.nextWeek.matchMs(startTime);
            }

            return false;
        });
    };

    public filterByRegion = (query: EventsCollectionQueryModel): EventsCollectionQueryModel => {
        const region = this.region.getActiveFilterId();
        if (region === '') {
            return query;
        }

        return query.filter((event) => event.tags['region'] === region);
    };

    public filterByCountry = (query: EventsCollectionQueryModel): EventsCollectionQueryModel => {
        const country = this.country.getActiveFilterId();
        if (country === '') {
            return query;
        }

        return query.filter((event) => event.tags['country'] === country);
    };

    public filterBySpecialSport = (query: EventsCollectionQueryModel): EventsCollectionQueryModel => {
        if (this.isSpecialSport === false || this.specialSportsState === null) {
            return query;
        }
        if (this.specialSportsState.filter_coupon === 'all') {
            return query;
        }

        return query.filter((event) => {
            const eventModel = this.modelsState.getEvent(event.id);
            if (this.specialSportsState === null || eventModel === null) {
                return false;
            }
            return this.specialSportsState.filteredCompetitions.includes(eventModel.competition);
        });
    };

    public applyFilters(
        queryIn: EventsCollectionQueryModel | null,
        filtersList: Array<(query: EventsCollectionQueryModel) => EventsCollectionQueryModel>
    ): EventsCollectionQueryModel | null {
        let query: EventsCollectionQueryModel | null = queryIn;

        for (const filter of filtersList) {
            if (query !== null) {
                query = filter(query);
            }
        }

        return query;
    }

    @computed public get filter(): EventsCollectionQueryModel | null {
        return this.applyFilters(this.getCollection(), [
            this.filterBySport,
            this.filterByCompetition,
            this.filterByTime,
            this.filterByRegion,
            this.filterByCountry,
            this.filterBySpecialSport,
        ]);
    }

    @computed public get isSpecialSport(): boolean {
        if (this.specialSportsState === null) {
            return false;
        }
        return this.specialSportsState.specialExpandForSport !== null;
    }
}
