import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';
import { RealityCheckPopupState } from 'src/domains/layouts/state/popups/RealityCheckPopupState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    ButtonWrapper,
    Description,
    FakeLink,
    PopupContent,
    PopupHeader,
    RealityCheckPopupOverlay,
    RealityCheckPopupWrapper,
    TimerDescription,
} from 'src/domains/layouts/webview/components/popups/realityCheckPopup/RealityCheckPopup.style';
import { RealityCheckTimer } from 'src/domains/layouts/webview/components/popups/realityCheckPopup/RealityCheckTimer';
import { observer } from 'src/utils/mobx-react';

interface ResponsibleGamblingType {
    onClick: () => void;
    state: RealityCheckPopupState;
}

const ResponsibleGambling = observer('ResponsibleGambling', ({ onClick, state }: ResponsibleGamblingType) => {
    const { appLayoutsState } = useAppStateContext();
    const {
        languagesState,
        configComponents: { config },
    } = appLayoutsState;

    const realityCheckTime = state.activeRealityCheckFrequencyForView ?? '';

    if (config.userCanChangeRealityCheck) {
        return languagesState.translateTokens(
            languagesState.getTranslation(
                'reality-check.popup.description',
                'You have requested a reality check every [time] minutes, which can be updated [here]'
            ),

            (singleParam: LanguageTokenType) => {
                if (singleParam.tag === 'here') {
                    return <FakeLink onClick={onClick}>here</FakeLink>;
                }
                if (singleParam.tag === 'time') {
                    return <span>{realityCheckTime}</span>;
                }
            }
        );
    }

    return languagesState.translateTokens(
        languagesState.getTranslation(
            'reality-check.popup.description-without-reality-check',
            'You have requested a reality check every [time] minutes.'
        ),

        (singleParam: LanguageTokenType) => {
            if (singleParam.tag === 'time') {
                return <span>{realityCheckTime}</span>;
            }
        }
    );
});

export const RealityCheckPopup = observer('RealityCheckPopup', (): JSX.Element | null => {
    const {
        appLayoutsState: { realityCheckPopupState },
        appPlayersState: { loginState },
        config: { layout },
    } = useAppStateContext();

    return (
        <RealityCheckPopupOverlay>
            <RealityCheckPopupWrapper>
                <PopupContent>
                    <PopupHeader>
                        <I18n langKey='reality-check.popup.header' defaultText='Reality check' />
                    </PopupHeader>

                    <TimerDescription>
                        <I18n
                            langKey='reality-check.popup.header.description'
                            defaultText='Your session has exceeded:'
                        />
                    </TimerDescription>

                    <RealityCheckTimer state={realityCheckPopupState} />

                    <Description>
                        <ResponsibleGambling
                            onClick={realityCheckPopupState.handleRedirectToGamblingLimits}
                            state={realityCheckPopupState}
                        />
                    </Description>
                </PopupContent>

                <footer>
                    <ButtonWrapper size='medium' version='primary' onClick={loginState.logOut}>
                        <I18n langKey='reality-check.popup.log-out' defaultText='Log Out' />
                    </ButtonWrapper>

                    <ButtonWrapper
                        size='medium'
                        version={layout === 'primary' ? 'secondary-light' : 'tertiary-light'}
                        onClick={realityCheckPopupState.handleContinue}
                    >
                        <I18n langKey='reality-check.popup.continue' defaultText='Continue' />
                    </ButtonWrapper>
                </footer>
            </RealityCheckPopupWrapper>
        </RealityCheckPopupOverlay>
    );
});
