import React from 'react';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';

const renderHtmlHeadContent = (router: StarRouter, configComponents: ConfigComponents): Array<JSX.Element> | null => {
    const currentView = router.currentView;

    if (currentView === null) {
        return null;
    }

    const name = currentView.name;

    if (
        name === 'homepage' ||
        name === 'inplay' ||
        name === 'virtuals' ||
        name === 'casino' ||
        name === 'live-casino' ||
        name === 'sports'
    ) {
        const seoMeta = configComponents.config.seoMeta;
        if (seoMeta === null) {
            return null;
        }

        const details = seoMeta[name];

        if (details === null) {
            return null;
        }

        return [
            <title
                data-test='title'
                key='title'
            >
                {details.title}
            </title>,
            <meta
                data-test='description'
                key='description'
                name='description'
                content={details.description}
            />,
        ];
    }

    if (name === configComponents.config.promotedEvents.route) {
        const seoMeta = configComponents.config.seoMeta;
        if (seoMeta === null) {
            return null;
        }

        const details = seoMeta['promoted-events'];

        if (details === null) {
            return null;
        }

        return [
            <title
                data-test='title'
                key='title'
            >
                {details.title}
            </title>,
            <meta
                data-test='description'
                key='description'
                name='description'
                content={details.description}
            />,
        ];
    }

    if (currentView.name === 'sport') {
        const seoMetaSport = configComponents.config.seoMetaSport;

        if (seoMetaSport === null) {
            return null;
        }

        const id = currentView.id;
        const data = seoMetaSport[id];

        if (data !== undefined) {
            return [
                <title
                    data-test='title'
                    key='title'
                >
                    {data.title}
                </title>,
                <meta
                    data-test='description'
                    key='description'
                    name='description'
                    content={data.description}
                />,
            ];
        }
    }

    return null;
};

export const renderTitle = (router: StarRouter, configComponents: ConfigComponents): Array<React.ReactElement> => {
    const htmlHeadContent = renderHtmlHeadContent(router, configComponents);

    if (htmlHeadContent !== null) {
        return htmlHeadContent;
    }

    const text = configComponents.config.headerMetaTitleName;
    return [<title key='title'>{text}</title>];
};
