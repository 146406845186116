import React from 'react';
import {
    DateInputWrapper,
    DateInputsWrapper,
    InputLabel,
    CheckInputIconWrapper,
} from 'src/domains/players/webview/components/form/DateInput/DateInput.style';
import { DatePartInput } from 'src/domains/players/webview/components/form/DateInput/DatePartInput';
import { DateInputState } from 'src/domains/players/webview/components/form/DateInput/DateInput.state';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

export interface DateInputPropsTypes {
    label?: string | JSX.Element;
    isDisabled?: boolean;
    state: DateInputState;
    showSuccessIcon?: boolean;
    blockNativeMobileKeyBoard?: boolean;
    isReadOnly?: boolean;
}

export const DateInput = observer('DateInput', (props: DateInputPropsTypes) => {
    const { appLayoutsState } = useAppStateContext();
    const {
        configComponents: { config },
    } = appLayoutsState;

    const { state, label, isDisabled = false, showSuccessIcon = false, isReadOnly = false } = props;

    const errorMessage = state.getErrorMessage();
    const renderErrors =
        errorMessage === null ? null : <Messages marginBottom='16px' type='error' message={errorMessage} />;

    const { type: statusType } = state.dateInputStatus;

    const renderSuccessIcon = (): JSX.Element | null => {
        if (!showSuccessIcon || state.dateModel.result.value.type === 'error') {
            return null;
        }

        return <CheckInputIconWrapper key='success-icon' />;
    };

    return (
        <DateInputWrapper isDisabled={isDisabled} data-test='date-input'>
            <InputLabel data-test='label-text-date-input'>{label}</InputLabel>
            <DateInputsWrapper isError={statusType === 'error'} layout={config.layout}>
                <DatePartInput
                    inputMode='numeric'
                    inputRef={state.setInputDayRef}
                    maxLength={2}
                    name='day'
                    onChangeInput={state.onChangeDayInput}
                    onClick={state.onFocusDay}
                    pattern='[0-9]*'
                    placeholder='DD'
                    state={state.day}
                    isReadOnly={isReadOnly}
                    dataTest='day-input'
                />
                <DatePartInput
                    inputMode='numeric'
                    inputRef={state.setInputMonthRef}
                    maxLength={2}
                    name='month'
                    onChangeInput={state.onChangeMonthInput}
                    onClick={state.onFocusMonth}
                    onInputReturn={state.onReturnMonthInput}
                    pattern='[0-9]*'
                    placeholder='MM'
                    state={state.month}
                    isReadOnly={isReadOnly}
                    dataTest='month-input'
                />
                <DatePartInput
                    inputMode='numeric'
                    inputRef={state.setInputYearRef}
                    maxLength={4}
                    name='year'
                    onClick={state.onFocusYear}
                    onInputReturn={state.onReturnYearInput}
                    pattern='[0-9]*'
                    placeholder='YYYY'
                    state={state.year}
                    isReadOnly={isReadOnly}
                    dataTest='year-input'
                />
                {renderSuccessIcon()}
            </DateInputsWrapper>
            {renderErrors}
        </DateInputWrapper>
    );
});
