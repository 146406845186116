import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface WarningIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const WarningUtilityIcon = observer(
    'WarningIcon',
    ({ className, onClick }: WarningIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                data-test='warning-icon'
                fillRule='evenodd'
                height={24}
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width={24}
                xmlns='http://www.w3.org/2000/svg'
                xmlSpace='preserve'
            >
                <path
                    d='M80.141 73.43 43.022 4.117a2.904 2.904 0 0 0-2.517-1.45 2.892 2.892 0 0 0-2.517 1.45L.873 73.43a2.812 2.812 0 0 0 .02 2.833 2.891 2.891 0 0 0 2.492 1.417h74.239a2.891 2.891 0 0 0 2.492-1.417 2.824 2.824 0 0 0 .025-2.833Zm-35.469-4.084h-8.335v-8.335h8.335v8.335Zm0-14.586h-8.335V27.671h8.335V54.76Z'
                    fill='url(#warningUtilityIcon_a)'
                    transform='matrix(.29996 0 0 .31994 -.15 -.853)'
                />
                <defs>
                    <linearGradient
                        id='warningUtilityIcon_a'
                        x1='0'
                        x2='1'
                        y1='0'
                        y2='0'
                        gradientTransform='rotate(180 40.28 20.08) scale(80.06)'
                        gradientUnits='userSpaceOnUse'
                    >
                        <stop offset='0' stopColor='#fd9535' stopOpacity='1' />
                        <stop offset='1' stopColor='#ff4d00' stopOpacity='1' />
                    </linearGradient>
                </defs>
            </SvgElement>
        );
    }
);
