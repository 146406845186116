import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import {
    DepositLimitsLink,
    RemainingLimitWrapper,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/remainingLimit/RemainingLimit.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

export const RemainingLimit = observer(
    'RemainingLimit',
    ({ languagesState, value }: { languagesState: LanguagesState; value: string }) => {
        return (
            <RemainingLimitWrapper>
                <span>
                    {languagesState.translateTokens(
                        languagesState.getTranslation('account.top-up.remaining-limits', 'Remaining [link]'),
                        () => {
                            return (
                                <DepositLimitsLink
                                    key='limits'
                                    to={{ account: 'limits' }}
                                >
                                    <I18n
                                        langKey='account.top-up.remaining-limits.link'
                                        defaultText='Deposit Limit:'
                                    />
                                </DepositLimitsLink>
                            );
                        }
                    )}
                </span>
                {value}
            </RemainingLimitWrapper>
        );
    }
);
