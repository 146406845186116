import * as React from 'react';
import { SportTileData } from './types';

import {
    FootballIconWrapper,
    HorseRacingIconWrapper,
    TennisIconWrapper,
    GreyhoundRacingIconWrapper,
    GolfIconWrapper,
    DartsIconWrapper,
    CasinoCherryIconWrapper,
    VirtualSportsIconWrapper,
    CricketIconWrapper,
    NFLIconWrapper,
    RugbyIconWrapper,
    LiveCasinoIconWrapper
} from './style';

export const defaultTiles: SportTileData[] = [
    {
        label: 'Racing',
        to: {
            name: 'sport',
            nameType: 'races',
            id: 'horseracing',
            type: null,
            event: null,
        },
        icon: (): JSX.Element => <HorseRacingIconWrapper />
    },
    {
        label: 'Dogs',
        to: {
            name: 'sport',
            nameType: 'races',
            id: 'greyhoundracing',
            type: null,
            event: null,
        },
        icon: (): JSX.Element => <GreyhoundRacingIconWrapper />
    },
    {
        label: 'Football',
        to: {
            name: 'sport',
            nameType: 'regular',
            id: 'football',
        },
        icon: (): JSX.Element => <FootballIconWrapper />
    },
    {
        label: 'Rugby',
        to: {
            name: 'sport',
            nameType: 'regular',
            id: 'rugbyunion',
        },
        icon: (): JSX.Element => <RugbyIconWrapper />
    },
    {
        label: 'Golf',
        to: {
            name: 'sport',
            nameType: 'regular',
            id: 'golf',
        },
        icon: (): JSX.Element => <GolfIconWrapper />
    },
    {
        label: 'Tennis',
        to: {
            name: 'sport',
            nameType: 'regular',
            id: 'tennis',
        },
        icon: (): JSX.Element => <TennisIconWrapper />
    },
    {
        label: 'Darts',
        to: {
            name: 'sport',
            nameType: 'regular',
            id: 'darts',
        },
        icon: (): JSX.Element => <DartsIconWrapper />
    },
    {
        label: 'NFL',
        to: {
            name: 'sport',
            nameType: 'regular',
            id: 'americanfootball',
        },
        icon: (): JSX.Element => <NFLIconWrapper />
    },
    {
        label: 'Cricket',
        to: {
            name: 'sport',
            nameType: 'regular',
            id: 'cricket',
        },
        icon: (): JSX.Element => <CricketIconWrapper />
    },
    {
        label: 'Virtuals',
        to: {
            name: 'virtuals',
        },
        icon: (): JSX.Element => <VirtualSportsIconWrapper />
    },
    {
        label: 'Casino',
        to: {
            name: 'casino',
        },
        icon: (): JSX.Element => <CasinoCherryIconWrapper />
    },
    {
        label: 'Live Casino',
        to: {
            name: 'live-casino',
        },
        icon: (): JSX.Element => <LiveCasinoIconWrapper />
    }
];
