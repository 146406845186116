import { Amount } from 'src_common/common/amount/Amount';
import { action, computed, observable, makeObservable } from 'mobx';
import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { WithdrawSwiftyGlobalProviderStepsType } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawSwiftyGlobalProvider/WithdrawSwiftyGlobalProvider';
import { AccountState } from 'src/domains/players/shared/Types';
import {
    validateAmountRequire,
    validateAmountPattern,
    validateMaxAmount,
    validateMinAmount,
} from 'src/domains/players/webview/components/ValidatorsNew';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { WithdrawPraxisProviderSteps } from './WithdrawPraxisProvider';
import { TrpcClient } from 'src/appState/TrpcClient';
import {
    PraxisValidationDepositSuccessResponse,
    praxisValidationDeposit,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/billingInfoFiledsValidation/praxisBillingInfoFieldsValidation';

export class WithdrawPraxisProviderState {
    public stepsState: WithdrawPraxisProviderSteps;
    @observable public errorMessage: Array<string> = [];

    public amountState: FormInputState<string, Amount>;

    @observable public withdrawSuccessMessage: string | null = null;
    @observable public withdrawErrorMessage: string | null = null;
    @observable public isWithdrawFormSubmitting = false;

    public constructor(
        private readonly configComponents: ConfigComponents,
        private readonly account: AccountState,
        private readonly trpcClient: TrpcClient
    ) {
        makeObservable(this);
        this.stepsState = new WithdrawPraxisProviderSteps();

        this.amountState = FormInputState.new('')
            .map(validateAmountRequire)
            .map(validateAmountPattern)
            .map(
                validateMinAmount(
                    new Amount(this.configComponents.config.minWithdrawAmount),
                    () => account.usersState.moneySymbol,
                    'ERROR_MIN_WITHDRAW'
                )
            )
            .map(
                validateMaxAmount(
                    () => this.playableBalance,
                    () => account.usersState.moneySymbol,
                    'ERROR_MAX_WITHDRAW'
                )
            );
    }

    @computed private get playableBalance(): Amount {
        const value = this.account.usersState.walletData.valueReady?.playableBalance ?? 0;

        return this.configComponents.precision.newFromAnything(value);
    }

    @computed public get showBalance(): string {
        return this.account.usersState.money(this.playableBalance);
    }

    public handlePaymentAdditionsChange = (amount: Amount): void => {
        this.amountState.setValue(amount.value);
        this.amountState.setAsVisited();
    };

    @action public submitWithdrawForm = async (): Promise<void> => {
        this.amountState.setAsVisited();

        this.isWithdrawFormSubmitting = true;
        this.withdrawSuccessMessage = null;
        this.withdrawErrorMessage = null;

        try {
            await this.submitAmountForm();
        } catch (e) {
            this.withdrawErrorMessage = getErrorByCode('ERROR_UNKNOWN');
        }
    };

    private submitAmountForm = async (): Promise<void> => {
        const basicData = this.validationUserDataInfo();

        if (basicData?.type !== 'ok') {
            return;
        }

        if (this.amountState.result.value.type === 'error') {
            this.isWithdrawFormSubmitting = false;
            return;
        }

        const amountToWithdraw = this.amountState.result.value.data.value;

        try {
            const response = await this.trpcClient.client.praxis.initiateWithdrawal.mutate({
                body: {
                    amount: amountToWithdraw,
                    ...basicData.data,
                },
            });

            if (response.responseStatus === 'error') {
                throw new Error();
            }

            this.stepsState.redirectToIframe(response.data);
            this.isWithdrawFormSubmitting = false;
        } catch {
            this.isWithdrawFormSubmitting = false;
            this.stepsState.redirectToFailureView('serverIssue');
        }
    };

    @computed public get currentStep(): WithdrawSwiftyGlobalProviderStepsType {
        return this.stepsState.step;
    }

    private validationUserDataInfo = (): null | PraxisValidationDepositSuccessResponse => {
        const basicData = this.account.usersState.basicData.valueReady ?? null;
        const billingInfo = praxisValidationDeposit(basicData);

        if (billingInfo?.type === 'error') {
            this.errorMessage = billingInfo.fieldsRequired;
            return null;
        } else if (billingInfo === undefined) {
            this.errorMessage = ['unkown'];
            return null;
        }

        return billingInfo;
    };
}
