import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    Arrow,
    SeoContent,
    SeoContentTitle,
    SeoContentWrapper,
    ShowMoreOrLess,
    ShowMoreWrapper,
} from 'src/domains/layouts/webview/components/seoPages/SeoPages.style';
import { SeoPagesContentState } from 'src/domains/layouts/webview/components/seoPages/SeoPagesState';
import { useCommon } from 'src/domains/common/Common';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';

interface SeoPagesContentPropsType {
    pageSlug: string;
}

export const SeoPagesContent = observer(
    'SeoPagesContent',
    (props: SeoPagesContentPropsType): React.ReactElement | null => {
        const common = useCommon();
        const state = SeoPagesContentState.get(common, props.pageSlug);
        const languagesState = LanguagesState.get(common);

        const showLess = languagesState.getTranslation('seocontent.pages.show-less', 'Show Less');
        const showMore = languagesState.getTranslation('seocontent.pages.show-more', 'Show More');

        const fallbackConfig = common.features.seoContent;

        if (common.features.isSeoPagesContentOn === false) {
            return null;
        }

        const isCasino = ['casino', 'live-casino', 'virtuals'].includes(props.pageSlug);
        if (state.data === undefined) {
            return fallbackConfig === null ? null : (
                <SeoContentWrapper isCasino={isCasino}>
                    <SeoContentTitle isFallback={true}>{fallbackConfig[props.pageSlug]?.header}</SeoContentTitle>
                </SeoContentWrapper>
            );
        }

        return (
            <SeoContentWrapper isCasino={isCasino}>
                <SeoContentTitle>{state.data.title}</SeoContentTitle>
                <SeoContent>{state.data.short_description}</SeoContent>

                {state.data.full_description === '' ? null : (
                    <ShowMoreWrapper
                        isShowMore={state.isShowMore}
                        onClick={state.toggleShowMore}
                        isCasino={isCasino}
                    >
                        <Arrow position={state.isShowMore ? 'up' : 'right'} />
                        <ShowMoreOrLess data-test='show-more-seo-content'>
                            {state.isShowMore ? showLess : showMore}
                        </ShowMoreOrLess>
                    </ShowMoreWrapper>
                )}

                {state.isShowMore ? (
                    <SeoContent dangerouslySetInnerHTML={{ __html: state.data.full_description ?? '' }} />
                ) : null}
            </SeoContentWrapper>
        );
    }
);
