import { computed, observable, makeObservable } from 'mobx';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

// Home | Away | NoGoalScorer
type IdentifierType = 'H' | 'A' | 'NG';

const findIdentifier = (identifiers: string | undefined): IdentifierType => {
    if (identifiers === 'H') {
        return 'H';
    }
    // TODO: remove 'NG' identifier - it is for old-trading only, while 'no-scorer' is for new-trading
    if (identifiers === 'NG' || identifiers === 'no-scorer') {
        return 'NG';
    }
    return 'A';
};

export interface GoalScorerType {
    name: string;
    first: SelectionId | null;
    firstPrice?: number;
    anytime: SelectionId | null;
    anytimePrice?: number;
    hatTrick: SelectionId | null;
    hatTrickPrice?: number;
    identifier: IdentifierType;
}

export class ToggleExpandList {
    @observable public isExpanded = false;

    public constructor() {
        makeObservable(this);
    }

    public toggleExpand = (e: React.MouseEvent): void => {
        e.preventDefault();
        this.isExpanded = !this.isExpanded;
    };
}

export class GoalScorerMarketState {
    public constructor(private readonly selections: Record<number, Array<SelectionModel>>) {
        makeObservable(this);
    }

    @computed public get marketAndSelections(): Array<SelectionModel> {
        const list: SelectionModel[] = [];

        for (const market of Object.values(this.selections)) {
            list.push(...market);
        }

        return list;
    }

    @computed public get noGoalScore(): GoalScorerType | null {
        const noScorer =
            this.marketAndSelections.find((elem) => {
                return findIdentifier(elem.selectionIdentifiers) === 'NG';
            }) ?? null;

        if (noScorer !== null) {
            return {
                name: noScorer.name,
                first: noScorer.id2,
                anytime: null,
                hatTrick: null,
                identifier: 'NG',
            };
        }

        return null;
    }

    @computed public get allGoalScorerList(): Record<string, GoalScorerType> {
        const selectionGroup: Record<string, GoalScorerType> = {};
        for (const selection of this.marketAndSelections) {
            const slug = selection.name.toLocaleLowerCase().split(' ').join('-');

            if (selectionGroup[slug] === undefined) {
                selectionGroup[slug] = {
                    name: selection.name,
                    first: null,
                    anytime: null,
                    hatTrick: null,
                    identifier: findIdentifier(selection.selectionIdentifiers),
                };
            }
            const elem = selectionGroup[slug];
            if (elem !== undefined) {
                if (selection.templateMarketId === 'first-goalscorer') {
                    selectionGroup[slug] = {
                        ...elem,
                        first: selection.id2,
                        firstPrice: selection.price?.d,
                    };
                }
                if (selection.templateMarketId === 'anytime-goalscorer') {
                    selectionGroup[slug] = {
                        ...elem,
                        anytime: selection.id2,
                        anytimePrice: selection.price?.d,
                    };
                }
                if (selection.templateMarketId === 'hattrick-goalscorer') {
                    selectionGroup[slug] = {
                        ...elem,
                        hatTrick: selection.id2,
                        hatTrickPrice: selection.price?.d,
                    };
                }
            }
        }
        return selectionGroup;
    }
    private sortGoalScorerListByProperPrice = (a: GoalScorerType, b: GoalScorerType): number => {
        if (typeof a.firstPrice === 'number' && typeof b.firstPrice === 'number' && a.firstPrice !== b.firstPrice) {
            return a.firstPrice - b.firstPrice;
        } else if (
            typeof a.anytimePrice === 'number' &&
            typeof b.anytimePrice === 'number' &&
            a.anytimePrice !== b.anytimePrice
        ) {
            return a.anytimePrice - b.anytimePrice;
        } else if (
            typeof a.hatTrickPrice === 'number' &&
            typeof b.hatTrickPrice === 'number' &&
            a.hatTrickPrice !== b.hatTrickPrice
        ) {
            return a.hatTrickPrice - b.hatTrickPrice;
        } else {
            return a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase());
        }
    };
    @computed public get goalScorerListHome(): Array<GoalScorerType> {
        const home = Object.values(this.allGoalScorerList).filter((goalScorer) => goalScorer.identifier === 'H');

        return home.sort(this.sortGoalScorerListByProperPrice);
    }

    @computed public get goalScorerListAway(): Array<GoalScorerType> {
        const away = Object.values(this.allGoalScorerList).filter((goalScorer) => goalScorer.identifier === 'A');
        return away.sort(this.sortGoalScorerListByProperPrice);
    }

    @computed public get isPossibleToExpandList(): boolean {
        const homeLength = this.goalScorerListHome.length;
        const awayLength = this.goalScorerListAway.length;
        const noGoalScoreLength = this.noGoalScore === null ? 0 : 1;
        const length = noGoalScoreLength + (homeLength > awayLength ? homeLength : awayLength);

        return length > 6;
    }
}
