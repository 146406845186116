import React, { useState } from 'react';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    DepositFailure,
    DepositViewType,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFailure/DepositFailure';
import { PraxisTab } from './PraxisTab';
import { PraxisTabState } from './PraxisTab.state';
import { PraxisIframeStyle } from './PraxisTab.style';
import { Amount } from 'src_common/common/amount/Amount';
import { useCommon } from 'src/domains/common/Common';

export interface PraxisTabPropsType {
    iframeUrl: string;
}

export type DepositStepsType =
    | {
          readonly type: 'set-method';
      }
    | {
          readonly type: 'iframe-view';
          readonly iframeUrl: string;
      }
    | {
          readonly type: 'failure-view';
          readonly failureType: DepositViewType;
      };

export class PraxisDepositSteps {
    @observable.ref public step: DepositStepsType;

    public constructor() {
        makeObservable(this);
        this.step = {
            type: 'set-method',
        };
    }

    @action public redirectToSetMethod = (): void => {
        this.step = {
            type: 'set-method',
        };
    };

    @action public redirectToIframeView = ({ iframeUrl }: PraxisTabPropsType): void => {
        this.step = {
            type: 'iframe-view',
            iframeUrl,
        };
    };

    @action public redirectToFailureView = (failureType: DepositViewType): void => {
        this.step = {
            type: 'failure-view',
            failureType,
        };
    };
}

interface PropsType {
    isSignup: boolean;
}

export const PraxisJourney = observer('PraxisJourney', ({ isSignup }: PropsType): JSX.Element => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { configComponents, googleTagManager, languagesState } = appLayoutsState;
    const { accountHelperMail, minDepositAmount } = configComponents.config;
    const { accountState, usersState } = appPlayersState;
    const common = useCommon();
    const [state] = useState(
        () =>
            new PraxisTabState(
                accountState,
                usersState,
                common.trpcClient,
                googleTagManager,
                languagesState,
                new Amount(minDepositAmount)
            )
    );

    const renderContent = (): JSX.Element => {
        switch (state.stepsState.step.type) {
            case 'set-method':
                return <PraxisTab state={state} />;
            case 'iframe-view':
                return (
                    <PraxisIframeStyle
                        src={state.stepsState.step.iframeUrl}
                        dataTest='praxis-iframe'
                        isSignup={isSignup}
                    />
                );
            case 'failure-view':
                return (
                    <DepositFailure
                        isSignup={isSignup}
                        issueType={state.stepsState.step.failureType}
                        accountHelperMail={accountHelperMail}
                    />
                );
        }
    };

    return <>{renderContent()}</>;
});
