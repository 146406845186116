import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { RadioButtons } from 'src/domains/layouts/webview/components/form/radioButtons/RadioButtons';
import { observer } from 'src/utils/mobx-react';
import { WhatsappIcon } from 'src/domains/layouts/shared/assets/icons/WhatsappIcon';
import {
    ErrorMessageWrapper,
    OptionsWrapper,
    SettingsList,
} from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/offersSettings/OffersSettings.style';
import { OffersContactPreferencesState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/offersSettings/OffersSettingsState';
import { radioButtonGroupValuesYesNo } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/offersSettings/OffersSettings';
import {
    ButtonsWrapper,
    ButtonTitleSecondary,
    SectionWrapperSecondary,
} from 'src/domains/players/webview/components/Account';

interface PropsType {
    offersSettingsState: OffersContactPreferencesState;
    skin: 'light' | 'dark';
    onChange?: () => void;
    isProcessingRequest?: boolean;
}

export const OffersSettingsSecondary = observer(
    'OffersSettingsSecondary',
    ({ skin, onChange, offersSettingsState, isProcessingRequest }: PropsType) => {
        const isVisited = offersSettingsState.whatsappState.isVisited();

        return (
            <SettingsList isProcessingRequest={isProcessingRequest}>
                <SectionWrapperSecondary data-test='section-wrapper'>
                    <ButtonsWrapper data-test='whatsapp-settings'>
                        <ButtonTitleSecondary data-test='whatsapp-setting-text'>
                            <WhatsappIcon />
                            <I18n
                                langKey='account.contact-preferences.settings.whatsapp.secondary'
                                defaultText='Receive offers on WhatsApp?'
                            />
                        </ButtonTitleSecondary>
                        <OptionsWrapper>
                            <RadioButtons
                                name='whatsapp'
                                gap='12'
                                size='small'
                                group={radioButtonGroupValuesYesNo}
                                color={skin}
                                state={offersSettingsState.whatsappState}
                                onChangeCallback={onChange}
                            />
                        </OptionsWrapper>
                    </ButtonsWrapper>
                    {offersSettingsState.whatsappState.result.value.type === 'error' && isVisited === true ? (
                        <ErrorMessageWrapper
                            type='error'
                            marginBottom='16px'
                            message={offersSettingsState.whatsappState.result.errors()?.[0] ?? ''}
                        />
                    ) : null}
                </SectionWrapperSecondary>
            </SettingsList>
        );
    }
);
