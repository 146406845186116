import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';



export const TabWrapper = styled('div', { label: 'TabWrapper' })`

    display: flex;

    flex-flow: column nowrap;

    height: 100%;

`;



export const StaticWrapper = withConfig(theme => styled('div', { label: 'StaticWrapper' })`

    background-color: ${theme.star.accountDrawer.bgColorSecondary};

    height: 100%;

`);

