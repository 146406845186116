import { action, computed, observable, makeObservable } from 'mobx';
import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { UsersState } from 'src/domains/players/state/UsersState';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { FormModel, Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import {
    validateRequire,
    validateAmountRequire,
    validateMaxAmount,
    validateMinAmount,
} from 'src/domains/players/webview/components/ValidatorsNew';
import { Amount } from 'src_common/common/amount/Amount';
import { ConfigType } from 'src/domains/layouts/config/features/types';

const validateAmountPattern = (amount: Amount): Result<Amount> => {
    const hasPricePattern = /^\d+(\.\d{1,8}[0]{0,})?$/.test(amount.value);
    if (!hasPricePattern) {
        return Result.createError(getErrorByCode('ERROR_DECIMAL'));
    }
    return Result.createOk(amount);
};

const validateBtcAddress =
    (withdrawValidateBtcAddress: boolean) =>
    (value: string): Result<string> => {
        if (withdrawValidateBtcAddress === false) {
            return Result.createOk(value);
        }

        const isBtcAddressCorrect = /^(?:[13]{1}[a-km-zA-HJ-NP-Z1-9]{26,33}|bc1[a-z0-9]{39,59})$/.test(value);
        if (!isBtcAddressCorrect) {
            return Result.createError('Incorrect bitcoin wallet address');
        }
        return Result.createOk(value);
    };

interface WithdrawType {
    amount: Amount;
    externalWalletAddress: string;
}
export class WithdrawFormState {
    @observable public withdrawSuccessMessage: string | null = null;
    @observable public withdrawErrorMessage: string | null = null;
    @observable public isWithdrawFormSubmitting = false;

    public amountState: FormInputState<string, Amount>;

    public readonly externalWalletAddressState: FormInputState<string, string>;

    public readonly form: FormModel<WithdrawType>;

    public constructor(
        private readonly usersState: UsersState,
        private readonly language: LanguagesState,
        configTypes: ConfigType
    ) {
        makeObservable(this);
        this.amountState = FormInputState.new('')
            .map(validateAmountRequire)
            .map(validateAmountPattern)
            .map(
                validateMinAmount(
                    new Amount(configTypes.minWithdrawAmount),
                    () => usersState.moneySymbol,
                    'ERROR_MIN_WITHDRAW'
                )
            )
            .map(
                validateMaxAmount(
                    () => this.withdrawableAmount,
                    () => usersState.moneySymbol,
                    'ERROR_MAX_WITHDRAW'
                )
            );

        this.externalWalletAddressState = FormInputState.new('')
            .map(validateRequire)
            .map(validateBtcAddress(configTypes.withdrawValidateBtcAddress));

        this.form = FormModel.group({
            amount: this.amountState,
            externalWalletAddress: this.externalWalletAddressState,
        }).map((item: WithdrawType): Result<WithdrawType> => {
            return Result.createOk({
                amount: item.amount,
                externalWalletAddress: item.externalWalletAddress,
            });
        });
    }

    @computed public get withdrawableAmount(): Amount {
        const withdrawableBalance = this.usersState.walletData.get();

        return new Amount(withdrawableBalance.type === 'ready' ? withdrawableBalance.value.playableBalance : '0');
    }

    public handleAmountInputChange = (event: React.SyntheticEvent<HTMLInputElement>): void => {
        const value = event.currentTarget.value;
        this.amountState.setValue(value);
    };

    public setAmount = (): void => {
        const amount = parseFloat(this.amountState.value);
        if (isNaN(amount) === true || amount === 0) {
            return this.amountState.setValue('');
        }
        return this.amountState.setValue(amount.toFixed(8));
    };

    public handlePaymentAdditionsChange = (amount: Amount): void => {
        this.amountState.setValue(amount.value);
        this.amountState.setAsVisited();
    };

    @action public submitWithdrawForm = async (): Promise<void> => {
        this.form.setAsVisited();

        this.isWithdrawFormSubmitting = true;
        this.withdrawSuccessMessage = null;
        this.withdrawErrorMessage = null;

        if (this.form.result.value.type === 'error') {
            this.isWithdrawFormSubmitting = false;
            return;
        }

        try {
            const response = await this.usersState.coinspaidWithdraw({
                requestBody: {
                    currency: this.usersState.currency,
                    amount: this.amountState.value,
                    address: this.externalWalletAddressState.value,
                },
            });

            if (response.type === 'CoinspaidWithdrawalResponseType' && response.status === 'requested') {
                await this.usersState.walletData.refreshAndWait();
                this.amountState.reset();
                this.withdrawSuccessMessage = this.language.getTranslation(
                    'account.withdraw.success-message',
                    'Withdraw request successfully.'
                );
            } else if (response.type === 'CoinspaidWithdrawalWrongAddressWalletResponseType') {
                this.withdrawErrorMessage = response.reason;
            }

            this.amountState.reset();
            this.isWithdrawFormSubmitting = false;
        } catch (e) {
            this.withdrawErrorMessage = getErrorByCode('ERROR_UNKNOWN');
            this.isWithdrawFormSubmitting = false;
        }
    };

    @computed public get disableWithdrawButton(): boolean {
        return this.isWithdrawFormSubmitting || this.form.result.value.type === 'error';
    }
}
