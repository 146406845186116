import React from 'react';
import { TypingMessage } from 'src/domains/players/webview/components/Account/traderChat/components/TypingMessage';
import { Message } from 'src/domains/players/webview/components/Account/traderChat/components/Message';
import { observer } from 'src/utils/mobx-react';
import { ChatMessagesListWrapper } from 'src/domains/players/webview/components/Account/traderChat/components/MessagesList.style';
import { AssigneeInfoInChat } from 'src/domains/players/webview/components/Account/traderChat/components/AssigneeInfo';
import { TraderChatState } from 'src/domains/players/state/traderChat/TraderChatState';
import { useCommon } from 'src/domains/common/Common';
import { TraderChatMessageModel } from 'src/domains/players/webview/components/Account/traderChat/TraderChatMessageModel';

export const MessagesList = observer('MessagesList', ({ userId }: { userId: number }) => {
    const common = useCommon();
    const [traderChatMessageModel] = React.useState(() => new TraderChatMessageModel(common));
    const { messages } = TraderChatState.get(common);

    React.useEffect(() => {
        const unsubscribe = traderChatMessageModel.subscribe(common, userId);

        return (): void => {
            unsubscribe();
        };
    }, [userId]);

    const messagesList = messages.map((message) => {
        return (
            <Message
                key={message.id}
                traderChatMessageModel={traderChatMessageModel}
                message={message}
            />
        );
    });

    return (
        <ChatMessagesListWrapper>
            {messagesList.length > 0 ? (
                <>
                    {messagesList}
                    <AssigneeInfoInChat />
                </>
            ) : null}
            <TypingMessage
                key='typing-message'
                traderChatMessageModel={traderChatMessageModel}
            />
        </ChatMessagesListWrapper>
    );
});
