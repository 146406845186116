import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    DepositTicketWrapper,
    LogoCoinsPaidWrapper,
    LogoCoinsPaidFooter,
    DepositTicketStrap,
    DepositTicketStrapWrapper,
    HorizontalLine,
    DepositTicketHeaderWrapper,
    DepositTicketBodyWrapper,
    DepositTicketCloseBtn,
    DepositTicketInfoIcon,
    DepositTicketQRCodeWrapper,
    DepositTicketAddressWrapper,
    DepositTicketDetailsWrapper,
    DepositTicketTitle,
    DepositTicketUnderTitle,
    DepositTicketUnderCaption,
    DepositTicketCaption,
    CopyButton,
    CopyInput,
    DepositTicketAddressOutputWrapper,
    DepositTicketAddressTextWrapper,
} from 'src/domains/players/webview/components/LuckyKingWallet/depositTicketPopup/DepositTicketPopup.style';
import { LogoCoinsPaid } from 'src/domains/common/paymentMethods/logos/LogoCoinspaid';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { UsersState } from 'src/domains/players/state/UsersState';
import { action, computed, makeObservable } from 'mobx';

class DepositTicketState {

    public constructor (
        private readonly usersState: UsersState
    ) {
        makeObservable(this);
    }

    @computed public get walletAddress(): string {
        return this.usersState.bankingCoinspaidAddressData.valueReady?.address ?? '';
    }

    @action public copyToClipboard = async (): Promise<void> => {
        try {
            await navigator.clipboard.writeText(this.walletAddress);
        } catch (err) {
            console.error('error - cannot copy wallet address');
        }
    };
}

interface PropsType {
    closePopup: () => void;
}

export const DepositTicket = observer('DepositTicket', (props: PropsType) => {
    const { appPlayersState: { usersState } } = useAppStateContext();
    const [state] = useState(new DepositTicketState(usersState));

    return (
        <DepositTicketWrapper>

            <DepositTicketHeaderWrapper>
                <DepositTicketCloseBtn onClick={props.closePopup}/>
                <DepositTicketTitle>
                    <I18n langKey='lucky-king.deposit-ticket.header.label-one' defaultText='Deposit QR Code' />
                </DepositTicketTitle>
                <DepositTicketUnderTitle>
                    <I18n langKey='lucky-king.deposit-ticket.header.label-two' defaultText='Minimum deposit 0.0005 BTC' />                </DepositTicketUnderTitle>
                <DepositTicketUnderCaption>
                    <I18n langKey='lucky-king.deposit-ticket.header.label-three' defaultText='Any transactions below this amount may be lost in the network.' />
                </DepositTicketUnderCaption>
            </DepositTicketHeaderWrapper>

            <DepositTicketBodyWrapper>
                <DepositTicketQRCodeWrapper>
                    {state.walletAddress === '' ?
                        null :
                        <img src={`https://chart.googleapis.com/chart?chs=152x152&cht=qr&chl=bitcoin:${state.walletAddress}&choe=UTF-8`} alt='bitcoin wallet address'/>}
                </DepositTicketQRCodeWrapper>

                <DepositTicketAddressWrapper>
                    <DepositTicketAddressTextWrapper>
                        <I18n langKey='lucky-king.deposit-ticket.header.label-four' defaultText='Or Copy Address' />
                    </DepositTicketAddressTextWrapper>
                    <DepositTicketAddressOutputWrapper>
                        <CopyInput
                            readOnly
                            type='text'
                            value={state.walletAddress}
                        />
                        <CopyButton
                            size='small'
                            onClick={state.copyToClipboard}>
                            <I18n langKey='lucky-king.deposit-ticket.copy-label' defaultText='Copy' />
                        </CopyButton>
                    </DepositTicketAddressOutputWrapper>
                </DepositTicketAddressWrapper>

                <DepositTicketDetailsWrapper>
                    <DepositTicketInfoIcon/>
                    <DepositTicketCaption><I18n langKey='lucky-king.deposit-ticket.header.label-five' defaultText='This is your private BTC depositing address. Please, deposit only BTC to this address. Any deposit you make will show up in your balance after blockchain confirmation…' /></DepositTicketCaption>
                </DepositTicketDetailsWrapper>
            </DepositTicketBodyWrapper>

            <HorizontalLine/>

            <DepositTicketStrapWrapper>
                <DepositTicketStrap position='left'/>
                <DepositTicketStrap position='right'/>
            </DepositTicketStrapWrapper>

            <LogoCoinsPaidFooter>
                <LogoCoinsPaidWrapper>
                    <I18n langKey='lucky-king.deposit-ticket.header.label-coins-paid' defaultText='Trusted by' />
                    <LogoCoinsPaid/>
                </LogoCoinsPaidWrapper>
            </LogoCoinsPaidFooter>

        </DepositTicketWrapper>
    );
});
