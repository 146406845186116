import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { useAsObservableSource } from 'mobx-react-lite';
import { observer } from 'src/utils/mobx-react';
import EMPTY_SILK_old from 'src/domains/layouts/shared/assets/silk/empty-hr-silk-old.webp';
import EMPTY_SILK from 'src/domains/sportsbook/webview/components/raceSummary/assets/empty-hr-silk.svg';
import { LazyImage } from 'src/domains/sportsbook/webview/components/lazyImage/LazyImage';
import { getHorseRacingRunner } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { useAppStateContext } from 'src/appState/AppState';
import {
    RunnerCell,
    SilkCell,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummaryNew.style';
import { RaceResultsRowCaption, ResultCell, ResultSelectionCell } from './RaceResultsNew.style';

interface RaceResultsRowPropsType {
    selection: SelectionModel;
    sport: 'horseracing' | 'greyhoundracing';
}

export const RaceResultsRowNew = observer('RaceResultsRowNew', (propsIn: RaceResultsRowPropsType) => {
    const props = useAsObservableSource(propsIn);
    const { selection } = props;
    const { config } = useAppStateContext();

    const marketModel = selection.getMarket();
    if (marketModel === null) {
        return null;
    }

    const eventModel = marketModel.getEvent();
    if (eventModel === null) {
        return null;
    }

    const selectionResult = selection.resultPlace === null ? 1 : selection.resultPlace;
    const silkUrl = selection.metaDataSilkUrl;
    const isUnnamedFavourite = selection.templateId === 'unnamed-favourite';
    const runner = getHorseRacingRunner(selection, isUnnamedFavourite, false);
    const selectionSp = selection.spPrice === null ? '-' : selection.spPrice.f;

    if (isUnnamedFavourite) {
        return null;
    }

    return (
        <RaceResultsRowCaption>
            <ResultCell>{selectionResult}</ResultCell>
            <SilkCell>
                <LazyImage
                    src={silkUrl}
                    placeholder={config.shouldDisplaySilkWithoutQuestionMark ? EMPTY_SILK : EMPTY_SILK_old}
                    alt={selection.metaDataSilk}
                />
            </SilkCell>
            <RunnerCell isHideRunnerInfo={true}>{runner}</RunnerCell>
            <ResultSelectionCell>{selectionSp}</ResultSelectionCell>
        </RaceResultsRowCaption>
    );
});
