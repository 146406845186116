import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AccountWrapperOld } from './Account.style';
import { BetsPanel } from 'src/domains/players/webview/components/Account/accountPanel/AccountPanel';
import { AccountDrawer } from 'src/domains/players/webview/components/Account/accountDrawer/AccountDrawer';
import { useAppStateContext } from 'src/appState/AppState';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { AccountPanelPlaceholder } from 'src/domains/players/webview/components/Account/accountPanel/AccountPanelPlaceholder';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { SignUp } from 'src/domains/players/webview/components/SignUp/SignUp';
import { PromoSidebar } from 'src/domains/players/webview/components/Account/promoSidebar/PromoSidebar';

interface PropsType {
    currentView: RouteViewType | null;
    promotionSlug: string;
    isSignUp: boolean;
    isNotificationsList: boolean;
}

export const AccountSidebar = observer('AccountSidebar', (props: PropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const { bannersBoxState, configComponents } = appLayoutsState;
    const { config } = configComponents;
    const innerWidth = getWindowInnerWidth();
    const isDesktop = innerWidth !== null && innerWidth >= 1024;

    if (config.accountDrawerNew) {
        return (
            <AccountWrapperOld
                topOffset={bannersBoxState.boxHeight}
                layout='primary'
            >
                {isDesktop ? (
                    <BetsPanel
                        currentView={props.currentView}
                        isNotificationsList={props.isNotificationsList}
                    />
                ) : (
                    <AccountPanelPlaceholder />
                )}
                <PromoSidebar
                    promotionSlug={props.promotionSlug}
                    key='promo-sidebar'
                />
            </AccountWrapperOld>
        );
    }

    return (
        <AccountWrapperOld
            topOffset={bannersBoxState.boxHeight}
            layout='primary'
        >
            {isDesktop ? (
                <BetsPanel
                    currentView={props.currentView}
                    isNotificationsList={props.isNotificationsList}
                />
            ) : (
                <AccountPanelPlaceholder />
            )}

            <AccountDrawer
                promotionSlug={props.promotionSlug}
                currentView={props.currentView}
            />
            {props.isSignUp ? <SignUp /> : null}
        </AccountWrapperOld>
    );
});
