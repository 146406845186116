import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    SignupPopupWrapper,
    SignupPopupElement,
    SignupPopupHeader,
    GotItButtonWrapper,
    PopupParagraph,
    ClosePopupIcon,
} from './SignupPopup.style';
import { PopupInfo, SignupPopupState } from 'src/domains/players/state/SignupPopupState';
import { useAppStateContext } from 'src/appState/AppState';

class InfoPopUpState {
    public constructor(public readonly signupPopupState: SignupPopupState) {}

    public onClosePopup = (): void => {
        this.signupPopupState.hide();
    };

    public onClose = (): void => {
        this.onClosePopup();
    };
}

interface InfoPopupPropsType {
    popup: PopupInfo;
}

export const InfoPopup = observer('InfoPopup', (props: InfoPopupPropsType): JSX.Element => {
    const {
        appPlayersState: { signupPopupState },
    } = useAppStateContext();
    const { popup } = props;
    const [state] = useState(() => new InfoPopUpState(signupPopupState));

    const renderCloseIcon = popup.closeIcon ? <ClosePopupIcon onClick={state.onClosePopup} /> : null;
    const handleGotItClose = popup.closeIcon ? state.onClose : state.onClosePopup;

    return (
        <SignupPopupWrapper>
            <SignupPopupElement data-test='signup-popup-element'>
                <SignupPopupHeader data-test='signup-popup-header'>
                    {popup.title}
                    {renderCloseIcon}
                </SignupPopupHeader>
                <PopupParagraph data-test='popup-paragraph'>{popup.popupText1}</PopupParagraph>
                <PopupParagraph data-test='popup-paragraph'>{popup.popupText2}</PopupParagraph>
                <GotItButtonWrapper
                    type='submit'
                    size='medium'
                    onClick={handleGotItClose}
                    dataTest='got-it-button'
                >
                    <I18n
                        langKey='signup-journey.info-popup.got-it-btn'
                        defaultText='Okay, Got It'
                    />
                </GotItButtonWrapper>
            </SignupPopupElement>
        </SignupPopupWrapper>
    );
});
