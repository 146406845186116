import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { TermsAndConditions } from 'src/domains/players/webview/components/SignUp/signupParts/termsAndConditions/TermsAndConditions';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import {
    Select,
    convertArrayOfStringForSelectComponent,
} from 'src/domains/players/webview/components/form/select/Select';
import { DateOfBirth } from 'src/domains/players/webview/components/SignUp/signupParts/dateOfBirth/DateOfBirth';
import { PhoneNumber } from 'src/domains/players/webview/components/SignUp/signupParts/phoneNumber/PhoneNumber';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { LayoutSignUp } from 'src/domains/players/webview/components/SignUp/signupTertiary/SignupLayout';
import { CreateAccountState } from 'src/domains/players/webview/components/SignUp/signupTertiary/signupState/createAccount/CreateAccount.state';
import { WizardForm, WizardFormWrapper, WizardWrapper, AgreeButtonWrapper } from './YourDetails.style';
import { useAppStateContext } from 'src/appState/AppState';
import { AlreadyHaveAccount, PromoCode, SignupType } from 'src/domains/players/webview/components/SignUp';
import { OffersSettingsSecondary } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/offersSettings/offersSettingsSecondary/OffersSettingsSecondary';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface YourDetailsPagePropsType {
    state: CreateAccountState;
    signUpState: SignupType;
}

export const YourDetailsPage = observer('YourDetailsPage', ({ state, signUpState }: YourDetailsPagePropsType) => {
    const { config } = useAppStateContext();
    const { yourDetailsState } = state;
    const { emailAndPasswordState } = state.yourDetailsState;

    if (signUpState.type === 'SignupPrimary') {
        return <div>wrong signup</div>;
    }

    return (
        <LayoutSignUp
            footer={true}
            signUpState={signUpState.state}
        >
            <WizardWrapper>
                <WizardForm>
                    <WizardFormWrapper>
                        <Input
                            label={
                                <I18n
                                    langKey='signup.your-details.cpf'
                                    defaultText='CPF Number'
                                />
                            }
                            state={yourDetailsState.cpfNumberState}
                            name='cpf-number'
                            type='text'
                            placeholder=''
                            asideElement={yourDetailsState.customKeyboard.getAsideElement}
                            showSuccessIcon={false}
                            isReadOnly={true}
                            dataTest='cpf-input'
                        />
                        <Input
                            label={
                                <I18n
                                    langKey='signup.your-details.first-name'
                                    defaultText='First name'
                                />
                            }
                            state={yourDetailsState.firstNameState}
                            name='first-name'
                            type='text'
                            placeholder=''
                            asideElement={yourDetailsState.customKeyboard.getAsideElement}
                            showSuccessIcon={false}
                            isReadOnly={true}
                            dataTest='first-name-input'
                        />
                        <Input
                            label={
                                <I18n
                                    langKey='signup.your-details.last-name'
                                    defaultText='Last name'
                                />
                            }
                            state={yourDetailsState.lastNameState}
                            name='last-name'
                            type='text'
                            placeholder=''
                            asideElement={yourDetailsState.customKeyboard.getAsideElement}
                            showSuccessIcon={false}
                            isReadOnly={true}
                            dataTest='last-name-input'
                        />
                        <DateOfBirth
                            dateState={yourDetailsState.dateOfBirth}
                            signupType={config.signupType}
                        />

                        {/* <Email And Paswword /> */}
                        <Input
                            label={
                                <I18n
                                    langKey='signup.welcome.email'
                                    defaultText='Email'
                                />
                            }
                            state={emailAndPasswordState.emailState}
                            showSuccessIcon={emailAndPasswordState.isCorrectEmail}
                            dataTest='email-input'
                        />

                        <Input
                            label={
                                <I18n
                                    langKey='signup.welcome.create-password'
                                    defaultText='Create password'
                                />
                            }
                            state={emailAndPasswordState.passwordState}
                            type={emailAndPasswordState.isShowPassword ? 'text' : 'password'}
                            showPassword={emailAndPasswordState.showPassword}
                            showPasswordFlag={true}
                            isShowPassword={emailAndPasswordState.isShowPassword}
                            showSuccessIcon={emailAndPasswordState.isCorrectPassword}
                            autocomplete='new-password'
                            dataTest='create-password-input'
                        />

                        <Input
                            label={
                                <I18n
                                    langKey='signup.welcome.confirm-password'
                                    defaultText='Confirm password'
                                />
                            }
                            state={emailAndPasswordState.secondPasswordState}
                            type={emailAndPasswordState.isShowSecondPassword ? 'text' : 'password'}
                            showPassword={emailAndPasswordState.showSecondPassword}
                            showPasswordFlag={true}
                            isShowPassword={emailAndPasswordState.isShowSecondPassword}
                            showSuccessIcon={emailAndPasswordState.isCorrectSecondPassword}
                            autocomplete='new-password'
                            dataTest='create-second-password-input'
                        />

                        <PhoneNumber
                            onBlur={
                                config.zipCodeAndCountryCheck === true ? yourDetailsState.handleWasTouched : undefined
                            }
                            state={yourDetailsState.phoneNumber}
                            hasPrefixes={config.prefixMultiPhoneNumber}
                        />

                        <Select
                            state={yourDetailsState.currency}
                            options={
                                config.currencyOnlyDefault
                                    ? []
                                    : convertArrayOfStringForSelectComponent(config.currencySignUpList)
                            }
                            labelText={
                                <I18n
                                    langKey='signup.currency-procedure.label'
                                    defaultText='Currency'
                                />
                            }
                            dataTest='currency-dropdown'
                            hasPrefixes={config.currencyOnlyDefault === false}
                            changeToInput={true}
                        />

                        {config.signUpShowPromoCode ? (
                            <PromoCode
                                isPromoCode={state.yourDetailsState.isPromoCode}
                                setPromoCode={state.yourDetailsState.setPromoCode}
                                state={state.yourDetailsState}
                            />
                        ) : null}
                        <OffersSettingsSecondary
                            skin='dark'
                            offersSettingsState={state.yourDetailsState.offersSettingsState}
                        />
                        <TermsAndConditions
                            termsAndConditionsCheckboxState={yourDetailsState.termsAndConditionsCheckboxState}
                            signUpCheckboxTC={config.signUpCheckboxTC}
                        />
                        <AgreeButtonWrapper
                            type='button'
                            size='large'
                            onClick={state.createAccount}
                            isLoading={yourDetailsState.loadingCreateAccountReq}
                            disabled={
                                !emailAndPasswordState.emailAndPasswordIsValid &&
                                !yourDetailsState.termsAndConditionsCheckboxState.checkBoxesTermsAndConditions
                            }
                            dataTest='agree-and-join-button'
                        >
                            <I18n
                                langKey='signup.step-two.agree-join'
                                defaultText='Agree & Join'
                            />
                        </AgreeButtonWrapper>
                        {state.backendCreateAccountError === null ? null : (
                            <Messages
                                type='error'
                                marginBottom='16px'
                                message={state.backendCreateAccountError}
                            />
                        )}

                        <AlreadyHaveAccount />
                    </WizardFormWrapper>
                </WizardForm>
            </WizardWrapper>
        </LayoutSignUp>
    );
});
