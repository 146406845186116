import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { SignupCloseIconWrapper, SignUpState } from 'src/domains/players/webview/components/SignUp';
import { LayoutContainer } from 'src/domains/players/webview/components/SignUp/signupSecondary/signUpSecondaryStyle/SignUpSecondary.style';
import { useAppStateContext } from 'src/appState/AppState';
import { AccountHelperInfo } from 'src/domains/players/webview/components/Account';

interface LayoutSignUpSecondaryPropsType {
    children: React.ReactElement | React.ReactElement[];
    signUpState: SignUpState;
}

export const SignUpSecondaryLayout = observer(
    'SignUpSecondaryLayout',
    ({ children, signUpState }: LayoutSignUpSecondaryPropsType) => {
        const { appLayoutsState } = useAppStateContext();
        const {
            configComponents: {
                config: { layout },
            },
        } = appLayoutsState;

        return (
            <>
                <LayoutContainer data-test='SignUpStepsContainer'>
                    <SignupCloseIconWrapper layout={layout} onClick={signUpState.toggleAside} isWelcomePage={true} />
                    {children}
                </LayoutContainer>
                <AccountHelperInfo version='secondary' />
            </>
        );
    }
);
