import { computed, makeObservable } from 'mobx';
import { WithdrawalsListState } from 'src/domains/players/state/WithdrawalsListState';
import { withdrawalsList } from 'src_server/trpc/types/wallet';
export class PendingTransactionsState {
    public constructor(private readonly withdrawalsListState: WithdrawalsListState) {
        makeObservable(this);

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.withdrawalsListState.refresh();
    }

    @computed public get isEmptyTransactionList(): boolean {
        return this.withdrawalsListState.withdrawals.length === 0;
    }

    @computed public get withdrawalsList(): Array<withdrawalsList.TransactionItemType> {
        return this.withdrawalsListState.withdrawals.map((withdrawal) => {
            return {
                ...withdrawal,
            };
        });
    }
}
