import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { FiltersSkinType } from 'src/domains/layouts/webview/components/filters/FiltersSkin';
import { observer } from 'src/utils/mobx-react';
import { FiltersState } from './Filters.state';
import { Filter } from './Filters.style';

interface FiltersPropsType {
  state: FiltersState;
  skin?: FiltersSkinType;
  isMultipleLine?: boolean;
}

/**
 * @deprecated - please use FiltersNewView
 */
export const Filters = observer<FiltersPropsType>('Filters', (props) => {
    const { config: { layout } } = useAppStateContext();
    const { state, skin = 'primary', isMultipleLine } = props;

    return (
        <>
            {state.filters.map(({ id, key, label }) => {
                const isActive = state.isFilterActive(id);
                const isDisabled = state.isFilterDisabled(id);
                const title = typeof label === 'string' ? label : undefined;

                return (
                    <Filter
                        key={key}
                        title={title}
                        disabled={isDisabled}
                        skin={skin}
                        onClick={state.bindSetActiveFilterId(id)}
                        isActive={isActive}
                        isDisabled={isDisabled}
                        data-test-filter-key={key}
                        layout={layout}
                        isMultipleLine={isMultipleLine}
                    >
                        {label}
                    </Filter>
                );
            })}
        </>
    );
});

export interface FiltersType {
    key: string;
    label: string | JSX.Element;
    onClick: () => void;
    isDisabled?: boolean;
    dataTest?: string;
}

interface FiltersNewViewPropsType {
    skin?: FiltersSkinType;
    isMultipleLine?: boolean;
    select: string | null;
    filters: Array<FiltersType>;
}

export const FiltersNewView = observer('Filters', (props: FiltersNewViewPropsType) => {
    const { config: { layout } } = useAppStateContext();
    const { filters, select, skin = 'primary', isMultipleLine } = props;

    return (
        <>
            {filters.map((item: FiltersType) => {
                const { key, label, isDisabled, onClick } = item;
                const isActive = key === select;
                const title = typeof label === 'string' ? label : undefined;

                return (
                    <Filter
                        key={key}
                        title={title}
                        disabled={isDisabled}
                        skin={skin}
                        onClick={onClick}
                        isActive={isActive}
                        data-is-active={isActive}
                        isDisabled={isDisabled}
                        data-test-filter-key={key}
                        layout={layout}
                        isMultipleLine={isMultipleLine}
                    >
                        {label}
                    </Filter>
                );
            })}
        </>
    );
});
