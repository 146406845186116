import { computed, action, observable, makeObservable } from 'mobx';
import { FilterType } from 'src/domains/layouts/webview/components/filters/Filters.state';
import { EventMarketItemType } from 'src_common/common/websocket2/modelsApi/EventMarkets';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { sortByNumberField } from 'src_common/utils/sport/sort';
import { TrpcClient } from 'src/appState/TrpcClient';
import { Response200Type } from 'src/api_openapi/generated/openapi_website_cms_getMarketFiltersForSport';

type MarketFiltersForSportListType = Response200Type;
type MarketFiltersForSportType = Response200Type[0];

interface CallbacksType {
    getTranslation: (key: string, defaultText: string, params?: Record<string, string | number>) => string;
}

export class MarketFiltersForSportState {
    @observable public activeFilterInner: string | null = null;
    private readonly marketFiltersForSportResource: Resource<MarketFiltersForSportListType>;

    public constructor(
        sport: string,
        private readonly eventId: EventId | null,
        private readonly callbacks: CallbacksType,
        private readonly trpcClient: TrpcClient
    ) {
        makeObservable(this);
        this.marketFiltersForSportResource = new Resource(async () => {
            const response = await this.trpcClient.client.cms.getMarketFiltersForSport.mutate({ sport });
            return response;
        });
    }

    @computed public get activeFilter(): string {
        const isFilterIsInView = this.filtersForView.find((filter) => filter.id === this.activeFilterInner);

        if (this.activeFilterInner === null || isFilterIsInView === undefined) {
            const firstFilter = this.filtersForView[0] ?? null;
            return firstFilter === null ? 'all_markets' : firstFilter.key;
        }

        return this.activeFilterInner;
    }

    @computed public get isLoading(): boolean {
        const data = this.marketFiltersForSportResource.get();
        return data.type === 'loading';
    }

    @computed public get filtersRawData(): MarketFiltersForSportListType {
        const data = this.marketFiltersForSportResource.get();
        if (data.type === 'ready') {
            return data.value.sort((v1: MarketFiltersForSportType, v2: MarketFiltersForSportType) =>
                sortByNumberField(v1.display_order, v2.display_order)
            );
        }

        return [];
    }

    @computed public get isMarketFiltersAvailableForSport(): boolean {
        return this.filtersRawData.length > 0;
    }

    @computed public get filtersForView(): Array<FilterType> {
        const tempFilters: Array<FilterType> = [];

        for (const filter of this.filtersRawData) {
            if (filter.markets.length > 0 || filter.filter_slug === 'all_markets') {
                const isAnyMarketForFilter =
                    filter.filter_slug === 'all_markets'
                        ? true
                        : filter.markets.some((marketFilter) =>
                              this.markets.some(
                                  (elem) => elem.name.toLowerCase().split(' ').join('_') === marketFilter.market_slug
                              )
                          );
                if (isAnyMarketForFilter) {
                    tempFilters.unshift({
                        label: filter.filter_name,
                        id: filter.filter_slug,
                        key: filter.filter_slug,
                    });
                }
            }
        }

        const isAllMarkets = tempFilters.some((elem) => elem.key === 'all_markets');

        if (isAllMarkets) {
            return tempFilters;
        }

        tempFilters.unshift({
            label: this.callbacks.getTranslation('market-filters.for-sport.all-markets.label', 'All Markets'),
            id: 'all_markets',
            key: 'all_markets',
        });

        return tempFilters;
    }

    @action public setActiveFilter = (slug: string): void => {
        this.activeFilterInner = slug;
    };

    @action public resetFilters = (): void => {
        this.activeFilterInner = 'all_markets';
    };

    @computed private get selectedMarkets(): Array<{ market_slug: string; display_order: number }> {
        const selectedFilter = this.filtersRawData.find((elem) => elem.filter_slug === this.activeFilter) ?? null;
        if (selectedFilter === null) {
            return [];
        }

        return selectedFilter.markets.map((elem) => {
            return {
                market_slug: elem.market_slug,
                display_order: elem.display_order,
            };
        });
    }

    @computed private get eventModel(): EventModel | null {
        const eventId = this.eventId;
        if (eventId === null) {
            return null;
        }

        return eventId.getEventModel();
    }

    @computed private get markets(): Array<EventMarketItemType> {
        return this.eventModel?.eventMarkets ?? [];
    }

    @computed public get marketsForView(): Array<EventMarketItemType> {
        if (this.isMarketFiltersAvailableForSport === false || this.activeFilter === 'all_markets') {
            return this.markets;
        }
        const tempMarkets: Array<EventMarketItemType> = [];
        for (const market of this.selectedMarkets) {
            const selectedMarket =
                this.markets.find((elem) => {
                    return elem.name.toLowerCase().split(' ').join('_') === market.market_slug;
                }) ?? null;
            if (selectedMarket !== null) {
                tempMarkets.push({
                    ...selectedMarket,
                    displayOrder: market.display_order,
                });
            }
        }
        return tempMarkets;
    }
}
