import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    PopupElement,
    PopupHeader,
    PopupParagraph,
    GotItButtonWrapper,
    CancelButton,
    PopupWrapper,
} from './AgreeOrCancelPopup.style';
import { useAppStateContext } from 'src/appState/AppState';
import { Overlay } from 'src/domains/layouts/state/overlay/Overlay';
import { PopupState, PopupDeleteCard } from 'src/domains/layouts/state/popupState/PopupState';

class AgreeOrCancelPopUpState {
    public constructor(
        public readonly popupState: PopupState,
        public readonly popup: PopupDeleteCard,
        public readonly overlay: Overlay
    ) {}

    public handleCancelButtonClick = (): void => {
        this.popupState.hide();
        if (this.popup.cancelAction !== undefined) {
            this.popup.cancelAction();
        }
    };

    public agreeAction = async (): Promise<void> => {
        await this.popup.agreeAction();
        this.handleCancelButtonClick();
    };
}

interface PropsType {
    popupForView: PopupDeleteCard;
}

export const AgreeOrCancelPopup = observer('AgreeOrCancelPopup', ({ popupForView }: PropsType): JSX.Element => {
    const { appLayoutsState } = useAppStateContext();
    const { popupState, overlay } = appLayoutsState;

    const [state] = useState(() => new AgreeOrCancelPopUpState(popupState, popupForView, overlay));

    return (
        <PopupWrapper>
            <PopupElement>
                <PopupHeader>{state.popup.title}</PopupHeader>
                <PopupParagraph>{state.popup.content}</PopupParagraph>
                <PopupParagraph>{state.popup.popUpCard}</PopupParagraph>
                <GotItButtonWrapper
                    type='submit'
                    size='medium'
                    onClick={state.agreeAction}
                >
                    <I18n
                        langKey='account.top-up.popups.agree-cancel-popup.agree-btn'
                        defaultText='Agree &amp; Continue'
                    />
                </GotItButtonWrapper>
                <CancelButton onClick={state.handleCancelButtonClick}>
                    <I18n
                        langKey='account.top-up.popups.agree-cancel-popup.cancel-btn'
                        defaultText='Cancel'
                    />
                </CancelButton>
            </PopupElement>
        </PopupWrapper>
    );
});
