import * as React from 'react';
import { AppState, useAppStateContext } from 'src/appState/AppState';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { AccountLinks } from 'src/domains/players/webview/components/Account/common/accountLinks/AccountLinks';
import { AccountSummaryNew } from 'src/domains/players/webview/components/Account/summaryTab/accountSummary/AccountSummaryNew';
import { observer } from 'src/utils/mobx-react';

export interface AccountSummaryLinkType {
    name: React.ReactNode;
    to: RightHandSideViewType;
}

const defaultLinksFactory = (appState: AppState): AccountSummaryLinkType[] => {
    const { customerFundsProtection } = appState.appLayoutsState.configComponents.config;

    let linksTemp: AccountSummaryLinkType[] = [
        {
            name: <I18n langKey='account.links.personal-details' defaultText='Password &amp; Personal Details' />,
            to: { account: 'personal-details' }
        },
        {
            name: <I18n langKey='account.links.preferences' defaultText='Preferences' />,
            to: { account: 'preferences' }
        },
        {
            name: <I18n langKey='account.links.customer-funds-protection' defaultText='Customer Funds Protection' />,
            to: { account: 'customer-funds-protection' }
        },
        {
            name: <I18n langKey='account.links.faq' defaultText="Help and FAQ's" />,
            to: { account: 'static', static: 'help' }
        },
        {
            name: <I18n langKey='account.links.terms-and-conditions' defaultText='Terms &amp; Conditions' />,
            to: { account: 'static', static: 'terms-and-conditions' }
        }
    ];

    if (customerFundsProtection === false){
        linksTemp = linksTemp.filter(tab => tab.to.account !== 'customer-funds-protection');
    }

    return linksTemp;
};

const RenderLinks = observer('RenderLinks', (): JSX.Element => {
    const appState = useAppStateContext();

    const LinksComponent = AccountLinks;

    const links = defaultLinksFactory(appState);

    return <LinksComponent links={links} />;
});

export const SummaryTabSecondary = observer('SummaryTabSecondary', () => {
    return (
        <div>
            <AccountSummaryNew />
            <RenderLinks />
        </div>
    );
});
