import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { FooterLinksWrapper, FooterList, FooterHeader, LinkWrapper } from 'src/domains/layouts/webview/others/luckyKing/Footer/FooterLinks.style';

interface FooterLinkItemType {
    key: string;
    component: JSX.Element;
}

interface FooterLinksType {
    title_label: string;
    footer_links: Array<FooterLinkItemType>;
}

const USEFUL_LINKS = {
    title_label: 'Useful links',
    footer_links: [
        {
            key: 'about-us',
            component: (
                <LinkWrapper key='about-us' to={{ account: 'static', static: 'about-us' }}>
                    <I18n langKey='footer.about-us' defaultText='About Us' />
                </LinkWrapper>
            )
        },
        {
            key: 'faq',
            component: (
                <LinkWrapper key='faq' to={{ account: 'static', static: 'help' }}>
                    <I18n langKey='footer.faqs' defaultText="FAQ's" />
                </LinkWrapper>
            )
        },
        {
            key: 'pivacy-policy',
            component: (
                <LinkWrapper key='pivacy-policy' to={{ account: 'static', static: 'privacy-policy' }}>
                    <I18n langKey='common.policy-box.privacy-policy' defaultText='Privacy Policy' />
                </LinkWrapper>
            )
        },
        {
            key: 'terms-and-conditions',
            component: (
                <LinkWrapper key='about-us' to={{ account: 'static', static: 'terms-and-conditions' }}>
                    <I18n langKey='footer.terms-conditions' defaultText='Terms & Conditions' />
                </LinkWrapper>
            )
        },
        {
            key: 'betting-rules',
            component: (
                <LinkWrapper key='about-us' to={{ account: 'static', static: 'betting-rules' }}>
                    <I18n langKey='footer.betting-rules' defaultText='Betting Rules' />
                </LinkWrapper>

            ) 
        },
    ]
};

const SPORTSBOOK = {
    title_label: 'Sports',
    footer_links: [
        {
            key: 'basketball',
            component: (
                <LinkWrapper
                    key='basketball'
                    to={{ name: 'sport', nameType: 'regular', id: 'basketball' }}
                >
                    Basketball
                </LinkWrapper>
            )
        },
        {
            key: 'boxing',
            component: (
                <LinkWrapper
                    key='boxing'
                    to={{ name: 'sport', nameType: 'regular', id: 'boxing' }}
                >
                    Boxing
                </LinkWrapper>
            )
        },
        {
            key: 'Football',
            component: (
                <LinkWrapper
                    key='football'
                    to={{ name: 'sport', nameType: 'regular', id: 'football' }}
                >
                    Football
                </LinkWrapper>
            )
        },
        {
            key: 'rugby-union',
            component: (
                <LinkWrapper
                    key='rugby-union'
                    to={{ name: 'sport', nameType: 'regular', id: 'rugby-union' }}
                >
                    Rugby Union
                </LinkWrapper>
            )
        },
    ]
};

interface PropsTypes {
    linkConfig: FooterLinksType;
}

const FooterNavigation = observer('FooterNavigation', ({ linkConfig }: PropsTypes) => (
    <section>
        <FooterHeader>
            {linkConfig.title_label}
        </FooterHeader>
        <FooterList>
            {linkConfig.footer_links.map(link => (
                <React.Fragment key={link.key}>
                    {link.component}
                </React.Fragment>
            ))}
        </FooterList>
    </section>
));

export const FooterLinks = observer('FooterLinks', () => {
    const { appCasinoState } = useAppStateContext();
    const casinoCategories = appCasinoState.gameStoreExt.getCollection('casino').activeCategoriesVisibleInNavigation;
    const liveCasinoCategories = appCasinoState.gameStoreExt.getCollection('live-casino').activeCategoriesVisibleInNavigation;

    const CASINO: FooterLinksType = {
        title_label: 'Casino',
        footer_links: casinoCategories.map(category => ({
            key: category.name,
            component: (
                <LinkWrapper key={category.name} to={{
                    name: 'casino',
                    tabId: category.id.toString(),
                }}>
                    {category.name}
                </LinkWrapper>
            )
        })),
    };

    const LIVE_CASINO: FooterLinksType = {
        title_label: 'Live Casino',
        footer_links: liveCasinoCategories.map(category => ({
            key: category.name,
            component: (
                <LinkWrapper key={category.name} to={{
                    name:'live-casino',
                    tabId: category.id.toString(),
                }}>
                    {category.name}
                </LinkWrapper>
            )
        })),
    };

    return (
        <FooterLinksWrapper>
            <FooterNavigation linkConfig={SPORTSBOOK} />
            <FooterNavigation linkConfig={CASINO} />
            <FooterNavigation linkConfig={LIVE_CASINO} />
            <FooterNavigation linkConfig={USEFUL_LINKS} />
        </FooterLinksWrapper>
    );
});
