import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import { withConfig } from 'src/withConfig';

const renderScoreboardBgImage = (bgImage: string | undefined, theme: EmotionTheme): SerializedStyles => {
    if (bgImage === undefined ) {
        return css``;
    }

    return css`
        &::before {
            ${positionElementFull};
            background-image: url(${bgImage}), ${theme.star.preMatch.bgColorSecondary};
            background-position: top center;
            background-size: 100% auto;
            content: '';
            position: absolute;
            z-index: ${theme.star.zIndexGlobal.below};
        }
    `;
};

interface ScoreboardWrapperPropsType {
    bgImage?: string;
}

export const Scoreboard = withConfig(theme => styled('header', { label: 'Scoreboard' })<ScoreboardWrapperPropsType>`
    background-color: ${theme.star.preMatch.bgColor};
    padding: 4px 0 12px 0;
    position: relative;
    z-index: ${theme.star.zIndexGlobal.base};
    ${({ bgImage }): SerializedStyles => renderScoreboardBgImage(bgImage, theme)};
    @media ${theme.star.mediaQuery.tablet} {
        padding: 8px 0;
    }
`);

export const MatchTime = withConfig(theme => styled('time', { label: 'MatchTime' })<{isPreMatch: boolean}>`
    color: ${({ isPreMatch }): string => isPreMatch ? theme.star.americanSportMatchLead.txtColorSecondary : theme.star.americanSportMatchLead.txtColorTertiary};
    display: block;
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4286;
    margin: 0 0 2px 0;
    text-align: center;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.regular};
        line-height: 1.5;
        margin: 0 0 7px 0;
    }
`);

export const TeamsWrapper = styled('div', { label: 'TeamsWrapper' })`
    display: flex;
    justify-content: center;
`;

interface TeamPropsType {
    isHome: boolean;
}

export const Team = withConfig(theme => styled('section', { label: 'Team' })<TeamPropsType>`
    display: flex;
    flex: 1 1 0%;
    justify-content: flex-end;
    flex-direction: ${({ isHome }): string => isHome === true ? 'row' : 'row-reverse'};
    align-items: flex-start; /* prevents vertical stretching on Safari */
    text-align: ${({ isHome }): string => isHome === true ? 'right' : 'left'};
    margin: ${({ isHome }): string => isHome === true ? '0 0 0 auto' : '0 auto 0 0'};
    color: ${theme.star.americanSportMatchLead.txtColor};
`);

export const VersusSign = withConfig(theme => styled('div', { label: 'VersusSign' })`
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.43;
    color: ${theme.star.americanSportMatchLead.txtColor};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 8px 0 0 0;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.medium};
        line-height: 1.3334;
        margin: 17px 0 0 0;
    }
`);

export const Result = withConfig(theme => styled('div', { label: 'Result' })`
    display: flex;
    margin: 8px 0 0 0;
    @media ${theme.star.mediaQuery.tablet} {
        margin: 11px 0 0 0;
    }
`);

export const Score = withConfig(theme => styled('div', { label: 'Score' })`
    align-items: center;
    background-color: ${theme.star.americanSportMatchLead.bgColor};
    color: ${theme.star.scoreboards.basketball.txtColorSecondary};
    display: flex;
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    height: 24px;
    justify-content: center;
    line-height: 1.4286;
    margin: 0 1px;
    min-width: 24px;
    padding: 0 5px;
    @media ${theme.star.mediaQuery.tablet} {
        margin: 0 2px;
        padding: 0 4px;
        height: 48px;
        min-width: 56px;
        font-size: ${theme.star.fontSize.medium};
        line-height: 1.3334;
    }
`);

export const TeamImg = withConfig(theme => styled('img', { label: 'TeamImg' })`
    max-width: 40px;
    @media ${theme.star.mediaQuery.tablet} {
        max-width: 80px;
    }
`);

export const TeamName = withConfig(theme => styled('span', { label: 'TeamName' })`
    display: block;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    line-height: 1.3334;
    margin: 0;
    width: min-content;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.xMedium};
        line-height: 1.2;
    }
`);
