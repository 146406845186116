import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { assertNever } from 'src_common/common/assertNever';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    MainContainer,
    AboutBrowserInfo,
    AdditionalInfo,
    StarsportsLogoIconWrapper,
    NebetLogoWrapper,
    DownloadBrowserLink,
    McBookieLogoWrapper,
} from './UnsupportedBrowsers.style';

export const UnsupportedBrowsers = observer('UnsupportedBrowsers', () => {
    const appState = useAppStateContext();

    const renderLogo = (): React.ReactNode => {
        const logo = appState.config.unSupportedBrowsersLogo;

        switch (logo) {
            case 'McBookieLogoWrapper': {
                return <McBookieLogoWrapper />;
            }

            case 'NebetLogoWrapper': {
                return <NebetLogoWrapper />;
            }

            case 'StarsportsLogoIconWrapper': {
                return <StarsportsLogoIconWrapper />;
            }
        }
        return assertNever('renderLogo', logo);
    };

    return (
        <MainContainer>
            {renderLogo()}
            <AboutBrowserInfo>
                <I18n
                    langKey='unsupported-browsers.info'
                    defaultText='For the best possible experience for this site we recommend using the latest version of one of following browser&lsquo;s:'
                />
            </AboutBrowserInfo>
            <AdditionalInfo>
                <DownloadBrowserLink
                    href='https://www.google.com/chrome/'
                    rel='noopener'
                    target='_blank'
                >
                    Google Chrome,{' '}
                </DownloadBrowserLink>
                <DownloadBrowserLink
                    href='https://www.mozilla.org/en-US/firefox/'
                    rel='noopener'
                    target='_blank'
                >
                    Mozilla Firefox,{' '}
                </DownloadBrowserLink>
                <DownloadBrowserLink
                    href='https://www.microsoft.com/en-us/windows/microsoft-edge'
                    rel='noopener'
                    target='_blank'
                >
                    Microsoft Edge or
                </DownloadBrowserLink>{' '}
                <DownloadBrowserLink
                    href='https://support.apple.com/downloads/safari'
                    rel='noopener'
                    target='_blank'
                >
                    Safari
                </DownloadBrowserLink>
            </AdditionalInfo>
        </MainContainer>
    );
});
