import React from 'react';
import { StaticWrapper } from './RHSDrawer.style';

import { StaticResourceHeadlineCarouselTerms } from 'src/domains/players/webview/components/Account';

interface RHSDrawerType {
    promoId: string | null;
}

export const StaticResourceHeadlineCarouselTermsWrapper = ({ promoId }: RHSDrawerType): JSX.Element | null => {
    if (promoId === null) {
        return null;
    }

    return (
        <StaticWrapper>
            <StaticResourceHeadlineCarouselTerms promoId={promoId} />
        </StaticWrapper>
    );
};
