import styled from '@emotion/styled';
import { SportsLkIcon } from 'src/domains/layouts/shared/assets/icons/luckyKing/SportsLkIcon';
import { StarFillIcon } from 'src/domains/layouts/shared/assets/icons/StarFillIcon';
import { withConfig } from 'src/withConfig';

interface PanelContainerPropsType {
    isAzMobile?: boolean
}

export const PanelContainer = withConfig(theme => styled('section', { label: 'PanelContainer' })<PanelContainerPropsType>`
    padding: ${({ isAzMobile }): string => isAzMobile === true ? '0 16px 20px 16px' : '0'};
    @media ${theme.star.mediaQuery.largeDesktop} {
        padding: 0;
    }
`);

export const HeaderStyle = withConfig(theme => styled('header', { label: 'HeaderStyle' })`
    color: ${theme.star.lhsSidebar.txtColor};
    text-transform: uppercase;
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.43;
    display: flex;
    align-items: center;
    margin: 28px 0 12px;
    @media ${theme.star.mediaQuery.largeDesktop} {
        color: ${theme.star.lhsSidebar.txtColorSecondary};
    }
`);

export const HeaderSpan = styled('span', { label: 'HeaderSpan' })`
    display: inline-block;
    line-height: 1.43;
    position: relative;
    top: 2px;
`;

export const FootballIconWrapper = withConfig(theme => styled(SportsLkIcon, { label: 'FootballIconWrapper' })`
    fill: ${theme.star.lhsSidebar.bgColorTertiary};
    height: 20px;
    width: 20px;
    margin-right: 8px;
`);

export const FavouritesIconWrapper = withConfig(theme => styled(StarFillIcon, { label: 'FavouritesIconWrapper' })`
    fill: ${theme.star.lhsSidebar.bgColorTertiary};
    height: 20px;
    width: 20px;
    margin-right: 8px;
`);

export const Divider = withConfig(theme => styled('hr', { label: 'Divider' })`
    background: ${theme.star.lhsSidebar.borderColor};
    border: none;
    display: block;
    height: 1px;
    margin: 16px -4px 0;
    @media ${theme.star.mediaQuery.largeDesktop} {
        margin-top: 28px;
    }
`);
