import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { FilterDropdown } from 'src/domains/casino/webview/CasinoGames/casino-filters/CasinoFilters.style';
import { AppCasinoState, CasinoPageState } from 'src/domains/casino/state/AppCasino.state';
import { components, DropdownIndicatorProps, OptionProps } from 'react-select';
import { DropdownIndicator } from './CustomFilterDropdown.style';
import { checkOptionType, OptionType } from 'src/domains/layouts/webview/components/dropdown/dropdown.types';
import { SelectCheckIconWrapper } from 'src/domains/layouts/webview/components/styles/baseDropdownStyle.style';

interface FilterSuppliersProps {
    appCasinoState: AppCasinoState;
    casinoPageState: CasinoPageState;
}

export const FilterSuppliers = observer('FilterSuppliers', (props: FilterSuppliersProps) => {
    const { appCasinoState, casinoPageState } = props;
    const { features } = appCasinoState;

    if (casinoPageState.navigationState.shouldRenderSuppliersFilter === false) {
        return null;
    }

    const renderOption = (props: OptionProps): JSX.Element | null => {
        const isSelected = props.isSelected;
        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <components.Option {...props}>
                {props.label}  {isSelected === true ? <SelectCheckIconWrapper /> : null}
            </components.Option>
        );
    };

    const renderIndicator = (props: DropdownIndicatorProps): JSX.Element | null => {
        const isOpened = props.selectProps.menuIsOpen;
        return (
            <DropdownIndicator isOpened={isOpened} />
        );
    };

    const onChange = (selectedOption: unknown): void => {
        if (checkOptionType(selectedOption)) {
            casinoPageState.navigationState.onTabChange(selectedOption.value);
            return;
        } 
        console.error('FilterSupplier - filter onChange error');
        return;
    };

    const onMenuOpen = (): void => {
        casinoPageState.casinoSearchExt.setIsDropdownOpen(true);
    };

    const onMenuClose = (): void => {
        casinoPageState.casinoSearchExt.setIsDropdownOpen(false);
    };

    const suppliersOptions = casinoPageState.navigationState.gamesSuppliersToDisplay.map(s => ({
        label: s.name,
        value: s.id
    }));

    const activeFilterElement = (): OptionType | null => {
        const filtered = suppliersOptions.filter(option => option.value === casinoPageState.navigationState.activeSupplierId)[0] ?? null;
        return filtered;
    };

    const isFilterActive = (): boolean => activeFilterElement() !== null;

    return (
        <FilterDropdown
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            placeholder='Select studio'
            isActive={isFilterActive()}
            backspaceRemovesValue={false}
            className='custom-dropdown-select'
            classNamePrefix='react-select'
            isClearable={false}
            onChange={onChange}
            components={{ 
                Option: renderOption,
                DropdownIndicator: renderIndicator
            }}
            options={suppliersOptions}
            isSearchable={false}
            value={activeFilterElement()}
            layout={features.config.layout}
            filterVersion={features.config.filtersVersion.casino}
        />
    );
});
