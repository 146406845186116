import { SerializedStyles, css } from '@emotion/react';

import styled from '@emotion/styled';

import { LayoutType } from 'src/domains/layouts/config/features/types';

import { withConfig } from 'src/withConfig';

import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';



interface MainContentPropsType {

    layout: LayoutType;

}



export const MainContent = withConfig(theme => styled('div', { label: 'MainContent' })<MainContentPropsType>`

    padding-left: 16px;

    @media ${theme.star.mediaQuery.desktop} {

        padding-left: ${({ layout }): string => layout === 'primary' ? '24px' : '16px'};

    }

`);



export const ContentWrapper = withConfig(theme => styled('div', { label: 'ContentWrapper' })`

    display: flex;

    flex: 1 1 0%;

    flex-flow: column nowrap;

    width: 100%;

    

    @media ${theme.star.mediaQuery.largeDesktop} {

        flex-direction: row;

    }

`);



interface ContentWrapperChildrenPropsType {

    streamRmgWrapperIsVisible: boolean | undefined;

    isStreamOpen: boolean | undefined;

    isAccountOpen: boolean | undefined;

}



const setStreamVisibleWhenIsFloating = (theme: EmotionTheme, streamRmgWrapperIsVisible: boolean | undefined, isStreamOpen: boolean | undefined, isAccountOpen: boolean | undefined): SerializedStyles => {

    if (isAccountOpen === true) {

        return css`

            z-index: ${theme.star.zIndexGlobal.base};

        `;

    };



    if (streamRmgWrapperIsVisible === false && isStreamOpen === true && isAccountOpen === false) {

        return css`

            overflow: initial;

            z-index: ${theme.star.zIndexGlobal.floatingStream};

        `;

    };



    return css``;

};



export const ContentWrapperChildren = withConfig(theme => styled('div', { label: 'ContentWrapperChildren' })<ContentWrapperChildrenPropsType>`

    overflow: hidden;

    flex: 1 1 0%;

    display: flex;

    flex-flow: column nowrap;

    @media ${theme.star.mediaQuery.largeDesktop} {

        flex: 1 1 auto;

        width: calc(100% - 200px);

        ${({ streamRmgWrapperIsVisible, isStreamOpen, isAccountOpen }): SerializedStyles => setStreamVisibleWhenIsFloating(theme, streamRmgWrapperIsVisible, isStreamOpen, isAccountOpen)};

    }

`);

