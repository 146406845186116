import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    NavigationListItemLink,
    NavigationChevronIconWrapper,
    NavigationStarFillIconWrapper,
    NavigationStarOutlineIconWrapper,
    NavigationListWrapper,
} from './NavigationPanel.style';
import { useAppStateContext } from 'src/appState/AppState';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { Panel } from './Panel';
import { LuckyKingPanel } from './luckyKing/LuckyKingPanel';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { PromotedEventsLink } from 'src/domains/layouts/webview/modules/layoutSkeleton/NavigationSidebar/NavigationSidebarPromotedEvents';
import { LayoutType } from 'src/domains/layouts/config/features/types';

type IconType = 'ChevronRight' | 'StarFill' | 'StarOutline';

export interface LinkItemLinkType {
    key: string;
    to: RouteViewType | RightHandSideViewType;
    label: JSX.Element | string;
    iconType?: IconType;
    iconClick?: () => void;
}

const renderIcon = (iconParam: IconType | undefined, iconClick?: () => void): React.ReactElement => {
    const icon: IconType = iconParam ?? 'ChevronRight';

    const onClick = (event: React.SyntheticEvent): void => {
        if (iconClick === undefined) {
            return;
        }

        event.stopPropagation();
        event.preventDefault();
        iconClick();
    };

    if (icon === 'ChevronRight') {
        return (
            <NavigationChevronIconWrapper
                position='right'
                onClick={onClick}
            />
        );
    }

    if (icon === 'StarFill') {
        return <NavigationStarFillIconWrapper onClick={onClick} />;
    }

    return <NavigationStarOutlineIconWrapper onClick={onClick} />;
};

const renderItem = (
    link: LinkItemLinkType,
    typeTag: string | undefined,
    googleTagManager: GoogleTagManagerState,
    isCapitalized: boolean,
    layout: LayoutType,
    toggleMenu: () => void
): React.ReactElement => {
    const fireGtmTag = (): void => {
        if (typeTag === 'sport') {
            if ('name' in link.to && (link.to.name === 'sport' || link.to.name === 'sport-special')) {
                googleTagManager.sportViewTag(link.key, link.to.id);
            }
        }
        toggleMenu();
    };

    if (link.key === 'promotedevents') {
        return <PromotedEventsLink />;
    }

    return (
        <NavigationListItemLink
            key={link.key}
            to={link.to}
            onClick={fireGtmTag}
            layout={layout}
            isCapitalized={isCapitalized}
        >
            {link.label}
            {renderIcon(link.iconType, link.iconClick)}
        </NavigationListItemLink>
    );
};

interface PropsType {
    title: string | JSX.Element;
    titleDataTest: string;
    links: Array<LinkItemLinkType>;
    isAzMobile?: boolean;
    type?: 'sports' | 'favourites';
    typeTag?: 'sport';
}

export const NavigationPanel = observer('NavigationPanel', (props: PropsType): React.ReactElement | null => {
    const { title, titleDataTest, links, isAzMobile, typeTag, type } = props;
    const { appLayoutsState } = useAppStateContext();
    const { configComponents, googleTagManager, overlay } = appLayoutsState;
    const { config } = configComponents;

    if (links.length === 0) {
        return null;
    }

    if (config.layout === 'secondary') {
        return (
            <LuckyKingPanel
                title={title}
                type={type}
                isAzMobile={isAzMobile}
            >
                <NavigationListWrapper>
                    {links.map((link) =>
                        renderItem(
                            link,
                            typeTag,
                            googleTagManager,
                            config.sportsNamesCapitalized,
                            config.layout,
                            overlay.toggleMenu
                        )
                    )}
                </NavigationListWrapper>
            </LuckyKingPanel>
        );
    }

    return (
        <Panel
            title={title}
            titleDataTest={titleDataTest}
            isAzMobile={isAzMobile}
        >
            <NavigationListWrapper>
                {links.map((link) =>
                    renderItem(
                        link,
                        typeTag,
                        googleTagManager,
                        config.sportsNamesCapitalized,
                        config.layout,
                        overlay.toggleMenu
                    )
                )}
            </NavigationListWrapper>
        </Panel>
    );
});
