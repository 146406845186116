import { action, makeObservable } from 'mobx';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { DebugPanelSizeType, SelectTabIdType } from './MainViewTypes';
import { Common } from 'src/domains/common/Common';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';

export class DebugPanelState {
    public openTab: LocalStorageItemState<SelectTabIdType>;
    public size: LocalStorageItemState<DebugPanelSizeType>;

    public static get = AutoWeakMap.create((common: Common) => new DebugPanelState(common));

    private constructor(common: Common) {
        const localStorageState = LocalStorageState.get(common);

        makeObservable(this);
        this.openTab = localStorageState.debugPanel;
        this.size = localStorageState.debugPanelSize;
    }

    @action public close(): void {
        this.openTab.setValue(null);
    }

    @action public show(): void {
        this.openTab.setValue('main');
    }

    public get isVisible(): boolean {
        return this.openTab.getValue() !== null;
    }

    @action public changeTab = (openTab: SelectTabIdType): void => {
        this.openTab.setValue(openTab);
    };

    public get activeTab(): SelectTabIdType {
        return this.openTab.getValue();
    }
}
