import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';

const CouponIdIO = t.union([
    t.literal('all'),
    t.literal('today'),
    t.literal('tomorrow'),
    t.literal('uk-list'),
    t.literal('european-elite'),
    t.literal('european-list'),
    t.literal('row'),
    t.literal('european-all'),
    t.literal('custom-outrights-market'),
    t.literal('specials'),
    t.literal('scottish'),
    t.literal('welsh')
]);

export const isCouponId = createGuard(CouponIdIO);
export type CouponIdType = t.TypeOf<typeof CouponIdIO>;

export interface CouponType {
    name: string;
    id: CouponIdType;
    translatedName: (getTranslation: (langKey: string, defaultText: string) => string) => string;
}

export const couponsStar: CouponType[] = [
    {
        name: 'All',
        translatedName: (getTranslation):string => getTranslation('events.coupons.all.label', 'All'),
        id: 'all'
    },
    {
        name: 'Today',
        translatedName: (getTranslation):string => getTranslation('events.coupons.today.label', 'Today'),
        id: 'today'
    },
    {
        name: 'Tomorrow',
        translatedName: (getTranslation):string => getTranslation('events.coupons.tomorrow.label', 'Tomorrow'),
        id: 'tomorrow'
    },
    {
        name: 'UK List',
        translatedName: (getTranslation):string => getTranslation('events.coupons.uk-list.label', 'UK List'),
        id: 'uk-list'
    },
    {
        name: 'European Elite',
        translatedName: (getTranslation):string => getTranslation('events.coupons.european-elite.label', 'European Elite'),
        id: 'european-elite'
    },
    {
        name: 'European List',
        translatedName: (getTranslation):string => getTranslation('events.coupons.european-list.label', 'European List'),
        id: 'european-list'
    },
    {
        name: 'Rest of World',
        translatedName: (getTranslation):string => getTranslation('events.coupons.row.label', 'Rest of World'),
        id: 'row'
    },
    {
        name: 'Outrights',
        translatedName: (getTranslation):string => getTranslation('events.coupons.outrights.label', 'Outrights'),
        id: 'custom-outrights-market'
    },
    {
        name: 'Specials',
        translatedName: (getTranslation):string => getTranslation('events.coupons.specials.label', 'Specials'),
        id: 'specials'
    }
];


export const couponsMcBookie: CouponType[] = [
    {
        name: 'All',
        translatedName: (getTranslation):string => getTranslation('events.coupons.all.label', 'All'),
        id: 'all'
    },
    {
        name: 'Today',
        translatedName: (getTranslation):string => getTranslation('events.coupons.today.label', 'Today'),
        id: 'today'
    },
    {
        name: 'Tomorrow',
        translatedName: (getTranslation):string => getTranslation('events.coupons.tomorrow.label', 'Tomorrow'),
        id: 'tomorrow'
    },
    {
        name: 'Scottish',
        translatedName: (getTranslation):string => getTranslation('events.coupons.scottish.label', 'Scottish'),
        id: 'scottish'
    },
    {
        name: 'UK List',
        translatedName: (getTranslation):string => getTranslation('events.coupons.uk-list.label', 'UK List'),
        id: 'uk-list'
    },
    {
        name: 'European',
        translatedName: (getTranslation):string => getTranslation('events.coupons.european-list.label:mcbookie', 'European'),
        id: 'european-all'
    },
    {
        name: 'Rest of the World',
        translatedName: (getTranslation):string => getTranslation('events.coupons.row.label:mcbookie', 'Rest of the World'),
        id: 'row'
    },
    {
        name: 'Outrights',
        translatedName: (getTranslation):string => getTranslation('events.coupons.outrights.label', 'Outrights'),
        id: 'custom-outrights-market'
    },
    {
        name: 'Specials',
        translatedName: (getTranslation):string => getTranslation('events.coupons.specials.label', 'Specials'),
        id: 'specials'
    }
];

export const couponsNebet: CouponType[] = [
    {
        name: 'All',
        translatedName: (getTranslation):string => getTranslation('events.coupons.all.label', 'All'),
        id: 'all'
    },
    {
        name: 'Today',
        translatedName: (getTranslation):string => getTranslation('events.coupons.today.label', 'Today'),
        id: 'today'
    },
    {
        name: 'Tomorrow',
        translatedName: (getTranslation):string => getTranslation('events.coupons.tomorrow.label', 'Tomorrow'),
        id: 'tomorrow'
    },
    {
        name: 'UK List',
        translatedName: (getTranslation):string => getTranslation('events.coupons.uk-list.label', 'UK List'),
        id: 'uk-list'
    },
    {
        name: 'European Elite',
        translatedName: (getTranslation):string => getTranslation('events.coupons.european-elite.label', 'European Elite'),
        id: 'european-elite'
    },
    {
        name: 'European List',
        translatedName: (getTranslation):string => getTranslation('events.coupons.european-list.label', 'European List'),
        id: 'european-list'
    },
    {
        name: 'Rest of World',
        translatedName: (getTranslation):string => getTranslation('events.coupons.row.label', 'Rest of World'),
        id: 'row'
    },
    {
        name: 'Outrights',
        translatedName: (getTranslation):string => getTranslation('events.coupons.outrights.label', 'Outrights'),
        id: 'custom-outrights-market'
    },
    {
        name: 'Specials',
        translatedName: (getTranslation):string => getTranslation('events.coupons.specials.label', 'Specials'),
        id: 'specials'
    }
];

export const couponsDragonBet: CouponType[] = [
    {
        name: 'All',
        translatedName: (getTranslation):string => getTranslation('events.coupons.all.label', 'All'),
        id: 'all'
    },
    {
        name: 'Today',
        translatedName: (getTranslation):string => getTranslation('events.coupons.today.label', 'Today'),
        id: 'today'
    },
    {
        name: 'Tomorrow',
        translatedName: (getTranslation):string => getTranslation('events.coupons.tomorrow.label', 'Tomorrow'),
        id: 'tomorrow'
    },
    {
        name: 'Welsh',
        translatedName: (getTranslation):string => getTranslation('events.coupons.welsh.label', 'Welsh'),
        id: 'welsh'
    },
    {
        name: 'UK List',
        translatedName: (getTranslation):string => getTranslation('events.coupons.uk-list.label', 'UK List'),
        id: 'uk-list'
    },
    {
        name: 'European Elite',
        translatedName: (getTranslation):string => getTranslation('events.coupons.european-elite.label', 'European Elite'),
        id: 'european-elite'
    },
    {
        name: 'European List',
        translatedName: (getTranslation):string => getTranslation('events.coupons.european-list.label', 'European List'),
        id: 'european-list'
    },
    {
        name: 'Rest of World',
        translatedName: (getTranslation):string => getTranslation('events.coupons.row.label', 'Rest of World'),
        id: 'row'
    },
    {
        name: 'Outrights',
        translatedName: (getTranslation):string => getTranslation('events.coupons.outrights.label', 'Outrights'),
        id: 'custom-outrights-market'
    },
    {
        name: 'Specials',
        translatedName: (getTranslation):string => getTranslation('events.coupons.specials.label', 'Specials'),
        id: 'specials'
    }
];
