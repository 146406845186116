import { Resource } from 'src_common/common/mobx-utils/Resource';
import { computed, makeObservable } from 'mobx';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { Session } from 'src_common/sdk/session';
import {
    OpenapiProxyAnonymousTopHeaderNavigationLinkActiveResponse200Type,
    openapiProxyAnonymousTopHeaderNavigationLinkActiveRequest,
} from 'src/api_openapi/generated/openapi_proxy_anonymous_top_header_navigation_link_active';
import { DateTime } from 'src_common/utils/time/time';

interface CalbacksType {
    largeDesktopIsBiggerOrEq: () => boolean | null;
}

export type NavigationLinkItemType =
    OpenapiProxyAnonymousTopHeaderNavigationLinkActiveResponse200Type extends Array<infer Model> ? Model : never;
export class HeaderNavigationLinksState {
    private readonly headerNavigationLinksResource: Resource<OpenapiProxyAnonymousTopHeaderNavigationLinkActiveResponse200Type>;

    public constructor(
        private readonly callbacks: CalbacksType,
        private readonly session: Session
    ) {
        makeObservable(this);
        this.headerNavigationLinksResource = new Resource(
            async (): Promise<OpenapiProxyAnonymousTopHeaderNavigationLinkActiveResponse200Type> =>
                this.session.call(openapiProxyAnonymousTopHeaderNavigationLinkActiveRequest, {})
        );
    }

    @computed public get activeLinks(): OpenapiProxyAnonymousTopHeaderNavigationLinkActiveResponse200Type {
        const result = this.headerNavigationLinksResource.get();
        if (result.type === 'ready') {
            return result.value;
        }
        return [];
    }

    @computed public get validatedLinks(): OpenapiProxyAnonymousTopHeaderNavigationLinkActiveResponse200Type {
        if (this.activeLinks.length > 0) {
            const linksFilteredByTime = this.activeLinks.filter((link: NavigationLinkItemType) => {
                return this.isLinkBetweenTimeRange(link);
            });

            const isBiggerOrEq = this.callbacks.largeDesktopIsBiggerOrEq();

            if (isBiggerOrEq === true) {
                return linksFilteredByTime.filter((link: NavigationLinkItemType) => {
                    return ['both', 'desktop'].includes(link.visibility);
                });
            } else {
                return linksFilteredByTime.filter((link: NavigationLinkItemType) => {
                    return ['both', 'mobile'].includes(link.visibility);
                });
            }
        }
        return [];
    }

    public getParams = (link: NavigationLinkItemType): RouteViewType | undefined => {
        if (link.action_template === 'event-id' && link.event_id !== null && link.event_id !== undefined) {
            const eventId = parseInt(link.event_id, 10);

            if (isNaN(eventId)) {
                console.error('Wrong event_id parameter', link);
                return;
            } else {
                return {
                    name: 'event',
                    id: eventId,
                    slug: '-',
                };
            }
        } else if (link.action_template === 'sport' && link.sport !== null && link.sport !== undefined) {
            return {
                name: 'sport',
                nameType: 'regular',
                id: link.sport,
            };
        } else if (
            link.action_template === 'competition-id' &&
            link.competition_id !== null &&
            link.competition_id !== undefined &&
            link.sport !== null &&
            link.sport !== undefined
        ) {
            return {
                name: 'competition',
                slug: link.sport,
                id: link.competition_id,
            };
        } else if (
            link.action_template === 'next-off-race' &&
            link.competition_id !== null &&
            link.competition_id !== undefined &&
            link.sport !== null &&
            link.sport !== undefined &&
            link.event_id !== null &&
            link.event_id !== undefined
        ) {
            const eventId = parseInt(link.event_id, 10);

            if (isNaN(eventId)) {
                console.error('Wrong event_id parameter', link);
                return;
            } else {
                return {
                    name: 'racecard',
                    collection: link.competition_id,
                    selected: eventId,
                    sport: link.sport,
                };
            }
        }

        return undefined;
    };

    private isLinkBetweenTimeRange = (link: NavigationLinkItemType): boolean => {
        const dateStart = link.date_from;
        const dateTo = link.date_to;
        const dateStartTimeStamp = DateTime.from(dateStart)?.unixSeconds();
        const dateEndTimeStamp = DateTime.from(dateTo)?.unixSeconds();
        const currentDateTimeStamp = DateTime.current().unixSeconds();

        if (dateEndTimeStamp === undefined || dateStartTimeStamp === undefined) {
            return false;
        }

        return currentDateTimeStamp >= dateStartTimeStamp && currentDateTimeStamp <= dateEndTimeStamp;
    };

    public async refresh(): Promise<void> {
        await this.headerNavigationLinksResource.refresh();
    }
}
