import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    ChevronIconStyle,
    AccordionWrapper,
    Header,
    HeaderContent,
    EachWayStyle,
    MarketName,
    EventName,
    HeaderWrapper,
    GoToEventButton,
} from './AlternativeMainContent.style';
import { SelectionGroup } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeMainContent/selectionGroup/SelectionGroup';
import { action, observable, makeObservable } from 'mobx';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { useAppStateContext } from 'src/appState/AppState';
import { EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';

class AlternativeMarketState {
    @observable public isOpenMarket: boolean;

    public constructor(isFirstMarket: boolean) {
        makeObservable(this);
        this.isOpenMarket = isFirstMarket;
    }

    @action public handleToggleMarket = (): void => {
        this.isOpenMarket = !this.isOpenMarket;
    };
}

interface AlternativeMarketPropsType {
    marketId: MarketId;
    eventId: EventId;
    marketName: string;
    selections: Array<SelectionModel>;
    isFirstMarket: boolean;
    eventName: string;
}

export const AlternativeMarket = observer(
    'AlternativeMarket',
    ({ marketId, eventId, marketName, selections, isFirstMarket, eventName }: AlternativeMarketPropsType) => {
        const { appLayoutsState } = useAppStateContext();
        const { starRouter } = appLayoutsState;

        const [state] = useState(() => new AlternativeMarketState(isFirstMarket));

        const { handleToggleMarket, isOpenMarket } = state;
        const marketModel = marketId.getModel();
        const eachWay = marketModel?.eachWay ?? null;

        return (
            <AccordionWrapper>
                <Header>
                    <HeaderWrapper onClick={handleToggleMarket}>
                        <ChevronIconStyle position={isOpenMarket ? 'down' : 'right'} />
                        <HeaderContent>
                            <MarketName>{marketName}</MarketName>
                            {eachWay !== null && eachWay.offered && eachWay.terms[0] !== undefined ? (
                                <EachWayStyle>
                                    Each way: {eachWay.terms[0].reduction} for {eachWay.terms[0].places} Places
                                </EachWayStyle>
                            ) : null}
                        </HeaderContent>
                    </HeaderWrapper>
                    {eventName.length > 0 ? (
                        <EventName>
                            {eventName}{' '}
                            <GoToEventButton onClick={(): void => starRouter.redirectToEvent(eventId)}>
                                Go to event
                            </GoToEventButton>
                        </EventName>
                    ) : null}
                </Header>
                {isOpenMarket ? (
                    <SelectionGroup
                        eventId={eventId}
                        marketIdMain={marketId}
                        selections={{ [marketId.toOldId()]: selections }}
                    />
                ) : null}
            </AccordionWrapper>
        );
    }
);

interface PropsType {
    eventId: EventId | null;
}

export const AlternativeMainContent = observer('AlternativeMainContent', (props: PropsType) => {
    const { appSportsBookState } = useAppStateContext();
    const { alternativeEventState } = appSportsBookState;
    const { getAlternativeEventItemById, eventId } = alternativeEventState.get(props.eventId);
    const eventItem = eventId === null ? null : getAlternativeEventItemById(eventId);

    if (eventId === null || eventItem === null) {
        return null;
    }

    const { marketsForViewIds } = eventItem;
    const marketList = marketsForViewIds.filter((id) => {
        const marketModel = id.getModel() ?? null;
        if (marketModel === null) {
            return false;
        }

        if (marketModel.display === false) {
            return false;
        }

        if (marketModel.eventModel === null) {
            return false;
        }

        if (marketModel.eventModel.display === false) {
            return false;
        }

        const isAnySelection = marketModel.selections.some((elem) => elem.display);

        if (isAnySelection === false) {
            return false;
        }
        return true;
    });

    const firstMarketId = marketList[0] ?? null;

    return (
        <>
            {marketList.map((id) => {
                const marketModel = id.getModel() ?? null;
                if (marketModel === null) {
                    return null;
                }

                const eventName = marketModel.eventId2 === eventId ? '' : marketModel.getEvent()?.name ?? '';

                return (
                    <AlternativeMarket
                        key={id.toOldId()}
                        marketId={id}
                        eventId={marketModel.eventId2}
                        marketName={marketModel.name}
                        selections={marketModel.selections}
                        isFirstMarket={firstMarketId === id}
                        eventName={eventName}
                    />
                );
            })}
        </>
    );
});
