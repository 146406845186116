import * as React from 'react';
import styled from '@emotion/styled';

interface PropsType {
    className?: string
}

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

export const TennisIcon = ({ className }: PropsType): JSX.Element => (
    <SvgElement fill='#fff' className={className} width={18} height={18} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'>
        <path
            fill='#fill'
            fillRule='nonzero'
            d='M5.776 4.321c3.528-1.449 4.41-3.069 4.464-4.257a8.676 8.676 0 016.075 4.446c.135.261.243.486.324.693.081.207.162.414.225.621.125.371.224.75.297 1.134h-.243c-1.854 0-3.267 1.458-3.969 4.104a3.393 3.393 0 01-2.484 2.385 5.251 5.251 0 01-1.494.216 5.58 5.58 0 01-3.735-1.386l-.17-.162A5.184 5.184 0 013.58 7.921c.126-1.359.774-3.024 2.196-3.6zm11.52 3.465l.018.09A8.676 8.676 0 118.692.01c.258-.013.517-.013.774 0-.027 1.305-1.395 2.556-3.978 3.6-1.8.675-2.547 2.673-2.7 4.275a5.976 5.976 0 001.72 4.806c.062.072.134.126.197.19a6.354 6.354 0 004.275 1.592 6.12 6.12 0 001.728-.243 4.176 4.176 0 003.024-2.934c.612-2.295 1.72-3.51 3.204-3.51h.36z'
        />
    </SvgElement>
);
