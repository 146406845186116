import circlePattern from 'src/domains/layouts/shared/assets/icons/circle.svg';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { InfoIconSecond } from 'src/domains/layouts/shared/assets/icons/InfoIconSecond';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const DepositTicketWrapper = styled('div', { label: 'DepositTicketWrapper' })`
    margin: 0 auto;
    position: fixed;
    width: 343px;
    @media (max-height: 650px) {
        top: 0;
        height: 100%;
        overflow-y: scroll;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
    @media (max-width: 343px) {
        top: 0;
        width: 100%;
    }
`;

export const DepositTicketHeaderWrapper = withConfig(theme => styled('header', { label: 'DepositTicketHeaderWrapper' })`
    background: ${theme.star.luckyKingWallet.bgColor};
    position: relative;
    top: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: ${theme.star.luckyKingWallet.txtColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    margin-bottom: -2px;
`);

export const DepositTicketBodyWrapper = withConfig(theme => styled('article', { label: 'DepositTicketBodyWrapper' })`
    background: ${theme.star.luckyKingWallet.bgColor};
    position: relative;
    height: 338px;
    margin-bottom: 24px;
    color: ${theme.star.luckyKingWallet.txtColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`);

export const HorizontalLine = withConfig(theme => styled('hr', { label: 'HorizontalLine' })`
    display: block;
    border: 0;
    width: 90%;
    height: 2px;
    background: linear-gradient(to right, transparent 50%, ${theme.star.luckyKingWallet.bgColorQuaternary} 50%);
    background-size: 16px 2px, 100% 2px;
    margin: -14px;
    padding: 0;
    z-index: ${theme.star.zIndexGlobal.highLevel};
    position: relative;
    margin-left: 14px;
`);

export const DepositTicketStrapWrapper = styled('div', { label: 'DepositTicketStrapWrapper' })`
    background: transparent;
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    height: 24px;
`;

export const DepositTicketStrap = withConfig(theme => styled('div', { label: 'DepositTicketStrap' })<{position: 'left' | 'right'}>`
    background: ${theme.star.luckyKingWallet.bgColor};
    mask-image: ${({ position }): string => position === 'left' ? 'radial-gradient(circle at left, transparent 10px, black 5px)' : 'radial-gradient(circle at right, transparent 10px, black 5px)' };
    height: 24px;
    margin-left: ${({ position }): string => position === 'left' ? '0' : '-10px' };
`);


export const LogoCoinsPaidFooter = withConfig(theme => styled('footer', { label: 'LogoCoinsPaidFooter' })`
    background: ${theme.star.luckyKingWallet.bgColor};
    padding: 16px 9px 26px 10px;
    position: relative;
    margin-top: -2px;
    &::after {
        background: url(${circlePattern}) 6px top / auto 100% repeat-x;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        right: 0;
        top: 100%;
    }
`);

export const LogoCoinsPaidWrapper = withConfig(theme => styled('div', { label: 'LogoCoinsPaidWrapper' })`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${theme.star.luckyKingWallet.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4285;
    text-transform: capitalize;
    width: 100%;
`);

export const DepositTicketCloseBtn = withConfig(theme => styled(CloseIcon, { label: 'DepositTicketCloseBtn' })`
    cursor: pointer;
    fill: ${theme.star.luckyKingWallet.bgColorQuaternary};
    margin: 19px;
    height: 18px;
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
    :hover {
        fill: ${theme.star.luckyKingWallet.txtColor};
    }
`);

export const DepositTicketInfoIcon = withConfig(theme => styled(InfoIconSecond, { label: 'DepositTicketInfoIcon' })`
    font-size: ${theme.star.fontSize.small};
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
`);

export const DepositTicketCaption = withConfig(theme => styled('div', { label: 'DepositTicketCaption' })`
    font-size: ${theme.star.fontSize.xSmall};
    width: 100%;
    margin-left: 10px;
`);

export const DepositTicketQRCodeWrapper = withConfig(theme => styled('div', { label: 'DepositTicketQRCodeWrapper' })`
    border: 1px solid ${theme.star.luckyKingWallet.bgColorSecondary};
    border-radius: 4px;
    min-height: 152px;
    padding: 0 75px;
    text-align: center;
`);

export const DepositTicketAddressWrapper = withConfig(theme => styled('div', { label: 'DepositTicketAddressWrapper' })`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    border-radius: 4px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
    margin: 8px;
`);

export const DepositTicketAddressOutputWrapper = styled('div', { label: 'DepositTicketAddressOutputWrapper' })`
    display: flex;
    flex-direction: row;
    height: auto;
`;

export const DepositTicketAddressTextWrapper = withConfig(theme => styled('div', { label: 'DepositTicketAddressTextWrapper' })`
    margin-bottom: -10px;
    padding: -5px;
    font-size: ${theme.star.fontSize.small};
`);

export const DepositTicketDetailsWrapper = withConfig(theme => styled('div', { label: 'DepositTicketDetailsWrapper' })`
    position: relative;
    display: flex;
    flex-direction: row;
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.4;
    width: 90%;
`);

export const DepositTicketTitle = withConfig(theme => styled('h2', { label: 'DepositTicketTitle' })`
    font-size: ${theme.star.fontSize.medium};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 24px 0 0 0;
    text-align: center;
`);

export const DepositTicketUnderTitle = withConfig(theme => styled('p', { label: 'DepositTicketUnderTitle' })`
    font-weight: ${theme.star.fontWeight.bold};
    font-size: ${theme.star.fontSize.small};
    margin: 8px 0 0 0;
    text-align: center;
`);

export const DepositTicketUnderCaption = withConfig(theme => styled('span', { label: 'DepositTicketUnderCaption' })`
    font-weight: ${theme.star.fontWeight.regular};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    text-align: center;
`);

export const CopyButton = styled(Button, { label: 'CopyButton' })`
    margin: 10px 2px;
    width: 20%;
    height: 40px;
`;

export const CopyInput = withConfig(theme => styled('input', { label: 'CopyInput' })`
    margin: 10px 2px;
    width: 80%;
    height: 40px;
    border-radius: 4px;
    background: ${theme.star.luckyKingWallet.bgColorSecondary};
    color: ${theme.star.transactionHistory.txtColorQuaternary};
    border: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px 12px 5px 20px;
    font-size: ${theme.star.fontSize.xRegular};
`);
