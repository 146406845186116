import { makeObservable, observable } from 'mobx';
import { Common } from 'src/domains/common/Common';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { Value } from 'src_common/common/mobx-utils/Value';

const currentTime = new Value(new Date(), (setValue) => {
    setValue(new Date());

    const timer = setInterval(() => {
        setValue(new Date());
    }, 500);

    return (): void => {
        clearInterval(timer);
    };
});

export class TraderChatTyping {
    @observable private lastMessage: Date | null = null;

    public static get = AutoWeakMap.create((common: Common) => new TraderChatTyping(common));
    private constructor(_common: Common) {
        makeObservable(this);
    }

    public get isTraderTyping(): boolean {
        const lastMessage = this.lastMessage;
        if (lastMessage === null) {
            return false;
        }

        const current = currentTime.getValue();

        if (current.getTime() - lastMessage.getTime() < 5_000) {
            return true;
        }

        return false;
    }

    public setTraderTyping(isTyping: boolean): void {
        this.lastMessage = isTyping ? new Date() : null;
    }
}
