import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';

export const RemainingLimitWrapper = styled('div', { label: 'RemainingLimitWrapper' })`
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
`;

export const DepositLimitsLink = withConfig(theme => styled(Link, { label: 'DepositLimitsLink' })`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    color: ${theme.star.accountDrawer.txtColorSecondary};
    text-decoration: none;
`);
