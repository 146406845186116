import * as React from 'react';
import { SportRouteType, CasinoRouteType, LiveCasinoRouteType, VirtualsRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { SportTileElement, IconLabel, SportTileLinkElement } from './style';

interface SportTilePropTypes {
    label: string;
    competitionId?: number;
    icon(): React.ReactNode;
    onClick?: (event?: React.SyntheticEvent<Element, Event>) => void | undefined;
    isButton?: boolean;
    to: SportRouteType | CasinoRouteType | LiveCasinoRouteType | VirtualsRouteType;
};

export const SportTile = (props: SportTilePropTypes): JSX.Element => {
    const { to, label } = props;
    const icon = props.icon();

    return (
        <SportTileElement onClick={props.onClick}>
            <SportTileLinkElement to={props.isButton === true ? undefined : to} dataTest={label}>
                {icon}
                <IconLabel>
                    {label}
                </IconLabel>
            </SportTileLinkElement>
        </SportTileElement>
    );
};
