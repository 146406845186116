import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { LayerContent, LayerWrapper } from 'src/domains/layouts/webview/components/modals/Layer.style';
import { ModalLayer, ModalLayerState } from 'src/domains/layouts/webview/components/modals/Layer.state';

interface LayerPropsType {
    layer: ModalLayer;
}

export const Layer = observer('Layer', ({ layer }: LayerPropsType) => {
    const [state] = React.useState(() => new ModalLayerState());

    return (
        <LayerWrapper
            ref={state.setRef}
            css={layer.layerCss()}
        >
            <LayerContent
                css={layer.contentCss}
                visible={state.isMount && layer.visible}
                animation={layer.animation}
            >
                {layer.content}
            </LayerContent>
        </LayerWrapper>
    );
});
