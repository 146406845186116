import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface LiveCasinoLkIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const LiveCasinoLkIcon = observer(
    'LiveCasinoLkIcon',
    ({ className, onClick }: LiveCasinoLkIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                fill='#071126'
                height='24'
                onClick={onClick}
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fill='fill'
                    d='M21.34 6.24a.84.84 0 0 0-.1-.27c-.33-.6-1-.85-1.6-1.05C18.4 4.5 17.2 4.04 16 3.59l-2.32-.87-1.16-.43c-.33-.12-.63-.3-1-.29-.55.03-1.07.46-1.54.74a552.54 552.54 0 0 0-4.1 2.4c-.46.28-.91.57-1.37.84-.4.24-.88.43-1.25.71a.65.65 0 0 0-.25.39.6.6 0 0 0-.01.12l.02.18c.35 2.34.67 4.67 1 7.01.1.65.06 1.55.55 2.05.37.38.9.65 1.34.94a260.84 260.84 0 0 0 4.11 2.7c.92.6 1.82 1.26 2.78 1.8 0 0 .12.07.3.1h.03c.18.03.4.03.63-.1.48-.27 6.93-6.36 6.93-6.36s.12-.24.15-1.15c.08-2.18.27-4.37.41-6.55l.1-1.37v-.21Zm-15.2 5.73c-.4.24-1.09-.16-1.51-.9-.43-.73-.44-1.52-.02-1.76.41-.24 1.1.16 1.52.9.43.73.43 1.52.02 1.76Zm5.49 7.99c-.42.24-1.1-.17-1.52-.9-.43-.74-.44-1.53-.02-1.77.41-.24 1.1.16 1.52.9.42.74.43 1.53.02 1.77Zm.64-13.07c-.78 0-1.41-.32-1.41-.7 0-.4.63-.7 1.41-.7.78 0 1.41.3 1.41.7 0 .38-.63.7-1.4.7Zm3.82 11.73c-.41.7-1.04 1.1-1.42.89-.37-.22-.34-.97.07-1.67.4-.71 1.04-1.11 1.41-.9.38.22.35.97-.06 1.68Zm2.02-4.37c-.4.71-1.04 1.11-1.41.9-.38-.22-.35-.97.06-1.68.4-.7 1.04-1.1 1.42-.89.37.22.34.97-.07 1.67Zm2.07-4.39c-.41.71-1.05 1.11-1.42.9-.37-.22-.34-.97.07-1.67.4-.71 1.04-1.11 1.41-.9.37.22.35.97-.06 1.67Z'
                />
            </SvgElement>
        );
    }
);
