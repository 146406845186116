//ended - conversation ended by consultant
//unassigned - conversation waiting for consultant
//assigned - during the conversation with the consultant

import { assertNever } from 'src_common/common/assertNever';
import { ChatMessageType } from 'src_server/trpc/types/chat';

/*
id: string;
type: "assigned" | "standard" | "ended" | "unassigned" | "typing";
sender: {
    id: number;
    type: "staff" | "customer" | "system";
    name: string;
};
*/

export interface MessageType {
    type: ChatMessageType['type'];
    sender: {
        type: ChatMessageType['sender']['type'];
    };
}

export const findChatStatus = (messagesType: Array<MessageType>): 'ended' | 'unassigned' | 'assigned' => {
    const copy = [...messagesType];
    copy.reverse();

    let userMessage = 0;
    let lastControl: 'assigned' | 'ended' | 'unassigned' | null = null;

    for (const message of copy) {
        if (message.type === 'typing') {
            continue;
        }

        if (message.type === 'standard') {
            if (message.sender.type === 'customer') {
                userMessage++;
            }
            continue;
        }

        lastControl = message.type;
        break;
    }

    switch (lastControl) {
        case null: {
            return userMessage === 0 ? 'ended' : 'unassigned';
        }
        case 'ended': {
            return userMessage === 0 ? 'ended' : 'unassigned';
        }
        case 'unassigned': {
            return 'unassigned';
        }
        case 'assigned': {
            return 'assigned';
        }
        default: {
            return assertNever('', lastControl);
        }
    }
};
