import { observable, action, makeObservable } from 'mobx';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';

export class AccountVerification {

    public readonly router: StarRouter;

    @observable public showTerms = false;
    @observable public showPrivacy = false;
    @observable public showCookie = false;

    public constructor(router: StarRouter) {
        makeObservable(this);
        this.router = router;
    }

    @action public onShowTerms = (): void => {
        const { email, token, isVerify } = this.router.resetPasswordParams;
        this.router.redirectToResetPasswordTerms(token ?? '', email ?? '', isVerify);
    };

    @action public onShowPrivacy = (): void => {
        const { email, token, isVerify } = this.router.resetPasswordParams;
        this.router.redirectToResetPasswordPrivacy(token ?? '', email ?? '', isVerify);
    };

    @action public onShowCookie = (): void => {
        const { email, token, isVerify } = this.router.resetPasswordParams;
        this.router.redirectToResetPasswordCookies(token ?? '', email ?? '', isVerify);
    };
}
