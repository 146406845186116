import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { TopUpLimitsProcedureItem } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/responsibleGambling/rollingNetDepositLimit/RollingNetDepositLimitItem';
import {
    RollingDepositLimitForm,
    TextSection,
    SectionHeader,
    FaqLink,
} from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/responsibleGambling/rollingNetDepositLimit/RollingNetDepositLimit.style';
import {
    SignupRollingNetDepositLimitState,
    InfoMessageType,
} from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/responsibleGambling/rollingNetDepositLimit/RollingNetDepositLimit.state';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';

const renderMessage = (infoMessage: InfoMessageType | null): JSX.Element | string => {
    if (infoMessage === 'error') {
        return (
            <Messages
                type='error'
                marginBottom='8px'
                message={
                    <I18n
                        langKey='signup-journey.rolling-net-deposit-limits-error-message'
                        defaultText='Sorry, we seem to have a problem. Please try again.'
                    />
                }
            />
        );
    } else if (infoMessage === 'success') {
        return (
            <Messages
                type='success'
                marginBottom='8px'
                message={
                    <I18n
                        langKey='signup-journey.rolling-net-deposit-limits.success-message'
                        defaultText='Set limits finished successfully.'
                    />
                }
            />
        );
    } else if (infoMessage === 'empty') {
        return (
            <Messages
                type='error'
                marginBottom='8px'
                message={
                    <I18n
                        langKey='signup-journey.rolling-net-deposit-limits-error-message-empty-deposit'
                        defaultText='You have to choose at least one deposit limit to save.'
                    />
                }
            />
        );
    } else if (infoMessage === 'belowOne') {
        return (
            <Messages
                type='error'
                marginBottom='8px'
                message={
                    <I18n
                        langKey='signup-journey.rolling-net-deposit-limits-error-message-below-one-deposit'
                        defaultText='You cannot set deposit limit below 1'
                    />
                }
            />
        );
    }

    return '';
};

interface PropsType {
    state: SignupRollingNetDepositLimitState;
}

export const RollingNetDepositLimit = observer('RollingNetDepositLimit', ({ state }: PropsType) => {
    const {
        appLayoutsState: { languagesState },
        appPlayersState: { cmsDrawerPagesState },
    } = useAppStateContext();

    const limitsDescription = (): JSX.Element => {
        return languagesState.translateTokens(
            languagesState.getTranslation(
                'signup-journey.rolling-net-deposit-limits.description',
                'You can set 1 and 7 days rolling net deposit limits and reduce the 30 days limit below. More information about limits and options to increase them can be found in our [FAQ].'
            ),

            (singleParam: LanguageTokenType) => {
                if (singleParam.tag === 'FAQ') {
                    return (
                        <FaqLink
                            onClick={cmsDrawerPagesState.showHelpPage}
                            dataTest='rolling-net-deposit-limits-faq'
                        >
                            FAQ
                        </FaqLink>
                    );
                }
            }
        );
    };

    if (state.isLoading) {
        return (
            <I18n
                langKey='signup-journey.contact-preferences.loading'
                defaultText='Loading...'
            />
        );
    }

    return (
        <RollingDepositLimitForm>
            <SectionHeader
                isUppercase={false}
                data-test='rolling-net-deposit-limit-form-header'
            >
                <I18n
                    langKey='signup-journey.rolling-net-deposit-limits.responsible-gambling.rolling-header'
                    defaultText='Net deposit limits'
                />
            </SectionHeader>
            <TextSection data-test='rolling-net-deposit-limits-content'>{limitsDescription()}</TextSection>

            <TopUpLimitsProcedureItem
                state={state.daily}
                label={
                    <I18n
                        langKey='signup-journey.rolling-net-deposit-limits.daily-limit.label'
                        defaultText='1 Day'
                    />
                }
                dataTest='deposit-limit-1-day'
            />

            <TopUpLimitsProcedureItem
                state={state.weekly}
                label={
                    <I18n
                        langKey='signup-journey.rolling-net-deposit-limits.weekly-limit.label'
                        defaultText='7 Days'
                    />
                }
                dataTest='deposit-limit-7-days'
            />

            <TopUpLimitsProcedureItem
                state={state.monthly}
                label={
                    <I18n
                        langKey='signup-journey.rolling-net-deposit-limits.monthly-limit.label'
                        defaultText='30 Days'
                    />
                }
                dataTest='deposit-limit-30-days'
            />

            {renderMessage(state.infoMessage)}
        </RollingDepositLimitForm>
    );
});
