import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Provider as ProviderAppState, AppState } from 'src/appState/AppState';
import { MessageBoxWrapper } from 'src/domains/players/webview/ui/MessageBoxWrapper';
import { ConfirmationsBoxWrapper } from 'src/domains/players/webview/ui/ConfirmationsBoxWrapper';
import { App } from 'src/domains/layouts/ui/ui';
import { UnsupportedBrowsers } from 'src/domains/layouts/webview/components/unsupportedBrowsers/UnsupportedBrowsers';
import { Common, ProviderCommon } from './common/Common';
import { Modal } from 'src/domains/layouts/webview/components/modals/Modal';
import { Helmet } from 'react-helmet';
import { HeaderMeta } from './layouts/state/headerMeta/HeaderMeta';

console.info('Create App module');

const renderMainContent = (appState: AppState): JSX.Element => {
    const { unSupportedBrowsers, accountDrawerNew } = appState.config;

    const detectFeatures = appState.env.modernizrDetect;
    const metaDynamicTitle = HeaderMeta.get(appState.common).metaTitle;

    if (detectFeatures !== null) {
        const hasBrowserFeature =
            detectFeatures.flexbox &&
            detectFeatures.flexwrap &&
            detectFeatures.templatestrings &&
            detectFeatures.websockets;

        if (unSupportedBrowsers && !hasBrowserFeature) {
            return <UnsupportedBrowsers />;
        }
    }
    return (
        <>
            <Helmet>{metaDynamicTitle}</Helmet>
            <App />
            <ConfirmationsBoxWrapper />
            <MessageBoxWrapper />
            {accountDrawerNew ? <Modal state={appState.appPlayersState.modalState} /> : null}
        </>
    );
};

interface PropsType {
    common: Common;
    appState: AppState;
}

export const VdomWrapper = observer('VdomWrapper', ({ common, appState }: PropsType) => (
    <ProviderAppState value={appState}>
        <ProviderCommon value={common}>{renderMainContent(appState)}</ProviderCommon>
    </ProviderAppState>
));

export const vdom = (common: Common, appState: AppState): React.ReactElement => (
    <VdomWrapper
        common={common}
        appState={appState}
    />
);
