import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

export const DepositLimitUpdateContainer = withConfig(theme => styled('div', { label: 'DepositLimitUpdateContainer' })`
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar,
    ::-webkit-scrollbar-track-piece {
        background-color: transparent;
        width: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 4px;
    }
    background-color: ${theme.star.popup.bgColor};
    color: ${theme.star.popup.bgColorSecondary};
    font-weight: ${theme.star.fontWeight.regular};
    max-height: calc(100vh - 26px);
    max-width: 288px;
    margin: 0 16px;
    overflow: hidden auto;
    overscroll-behavior-y: contain;
    padding: 24px;
    position: fixed;
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
    scrollbar-width: thin;
`);

export const DepositLimitUpdateOverlay = withConfig(theme => styled('div', { label: 'DepositLimitUpdateOverlay' })`
    ${positionElementFull};
    background-color: ${theme.star.sidebar.bgColorSecondary};
    position: fixed;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    z-index: ${theme.star.zIndexGlobal.lhsMenu};
`);

export const PopupTitle = withConfig(theme => styled('h2', { label: 'PopupTitle' })`
    font-size: ${theme.star.fontSize.regular};
    line-height: 1.3334;
    margin: 0;
    padding-bottom: 16px;
`);

export const PopupParagraph = withConfig(theme => styled('p', { label: 'PopupParagraph' })`
    color: ${theme.star.popup.txtColor};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.4286;
    margin: 0 0 12px 0;

    :last-of-type {
        margin: 0 0 16px 0;
    }
`);

export const PopupParagraphSmall = withConfig(theme => styled('p', { label: 'PopupParagraphSmall' })`
    color: ${theme.star.popup.txtColor};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.4286;
    margin: 0 0 12px 0;

    :last-of-type {
        margin: 0;
    }
`);

export const BoxWrapper = withConfig(theme => styled('div', { label: 'BoxWrapper' })`
    background-color: ${theme.star.popup.bgColorQuaternary};
    line-height: 1.33;
    margin: 16px 0 16px 0;
    padding: 16px;
`);

export const BoxTitle = withConfig(theme => styled('h5', { label: 'BoxTitle' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4286;
    margin: 0 0 8px 0;
`);

export const BoldSpan = withConfig(theme => styled('span', { label: 'BoldSpan' })`
    font-weight: ${theme.star.fontWeight.bold};
`);

export const LinkWrapper = withConfig(theme => styled(Link, { label: 'LinkWrapper' })`
    color: currentcolor;
    font-weight: ${theme.star.fontWeight.bold};
    text-decoration: none;
    &:hover {
        color: ${theme.star.popup.txtColorQuaternary};
    }
`);

export const SubmitButton = withConfig(theme => styled(Button, { label: 'SubmitButton' })`
    font-size: ${theme.star.fontSize.xRegular};
    margin: 8px 0;
    width: 100%;
`);
