import React, { useState } from 'react';
import { AccountHelperInfo } from 'src/domains/players/webview/components/Account/component/accountHelperInfo/AccountHelperInfo';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    CheckboxWrapper,
    PolicyLink,
    SetNewPasswordBtnWrapper,
    ResetPasswordFormWrapper,
    NewPasswordHeader,
    NewPasswordHeaderDesc,
    McbookieResetPassword,
} from 'src/domains/players/webview/components/Account/resetPasswordProcedure/resetPasswordForm/McBookieResetPasswordFormTerms.style';
import { useAppStateContext } from 'src/appState/AppState';
import { ResetPasswordFormState } from 'src/domains/players/webview/components/Account/resetPasswordProcedure/resetPasswordForm/ResetPasswordForm.state';
import { Checkbox } from 'src/domains/layouts/webview/components/form/checkbox/Checkbox';
import { observer } from 'src/utils/mobx-react';
import {
    VerifyAccountInfoHeader,
    VerifyAccountInfoWrapper,
    VerifyAccountText,
} from 'src/domains/players/webview/components/Account/verifyAccountTab/VerifyAccountTab.style';
import { AccountVerification } from 'src/domains/players/webview/components/Account/resetPasswordProcedure/resetPasswordForm/AccountVerification';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { ResetPasswordRhsRouteType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

const preventDefaults = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
};

interface PropsType {
    currentRoute: ResetPasswordRhsRouteType;
}

export const McBookieResetPasswordFormTerms = observer('McBookieResetPasswordFormTerms', (props: PropsType) => {
    const { currentRoute } = props;
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { configComponents, languagesState, starRouter } = appLayoutsState;
    const { getTranslation, translateTokens } = languagesState;
    const { accountState } = appPlayersState;

    const [avState] = useState(() => new AccountVerification(starRouter));
    const [resetPasswordState] = useState(
        () => new ResetPasswordFormState(starRouter, configComponents, false, false, accountState, true)
    );

    const errors = (
        <>
            {resetPasswordState.errors.map((message, i) => (
                <span key={i}>{message}</span>
            ))}
        </>
    );

    return (
        <McbookieResetPassword key='reset-password'>
            <ResetPasswordFormWrapper key='reset-password-form' onSubmit={preventDefaults}>
                <NewPasswordHeader data-test='new-password-header'>
                    <I18n langKey='account.reset-password.title:mcbookie' defaultText='Set New Password' />
                </NewPasswordHeader>

                <NewPasswordHeaderDesc data-test='new-password-header-desc'>
                    <I18n
                        langKey='account.reset-password.description:mcbookie'
                        defaultText='For security reasons you’ll need to choose a new password and then you’ll be ready to start betting!'
                    />
                </NewPasswordHeaderDesc>

                {resetPasswordState.isSuccess ? (
                    <Messages
                        type='success'
                        marginBottom='16px'
                        message={
                            <I18n
                                langKey='account.reset-password.success-message'
                                defaultText='Your password has been changed successfully. Now you can log in with new credentials.'
                            />
                        }
                    />
                ) : null}

                {resetPasswordState.errors.length > 0 ? (
                    <Messages marginBottom='16px' type='error' message={errors} />
                ) : null}

                <Input
                    name='password'
                    type='password'
                    label={<I18n langKey='account.reset-password.new-password.label' defaultText='New password' />}
                    state={resetPasswordState.newPasswordState}
                    colorTheme='light'
                    placeholder={getTranslation('account.reset-password.new-password.placeholder', 'New password')}
                    dataTest='new-password-input'
                />

                <Input
                    name='confirm'
                    type='password'
                    label={
                        <I18n langKey='account.reset-password.confirm-password.label' defaultText='Confirm password' />
                    }
                    state={resetPasswordState.confirmPasswordState}
                    colorTheme='light'
                    placeholder={getTranslation(
                        'account.reset-password.confirm-password.placeholder',
                        'Confirm password'
                    )}
                    dataTest='confirm-password-input'
                />

                <CheckboxWrapper>
                    <Checkbox state={resetPasswordState.termsState}>
                        {translateTokens(
                            getTranslation(
                                'account.registration.accept-terms.label:mcbookie',
                                'I accept the [TermsLink {label="Terms and Conditions"}] and [PrivacyPolicyLink {label="Privacy Policy"}] and [CookiePolicyLink {label="Cookie Policy"}].'
                            ),
                            (item) => {
                                if (item.tag === 'TermsLink') {
                                    return (
                                        <PolicyLink key='onShowTerms' onClick={avState.onShowTerms}>
                                            {item.params.label}
                                        </PolicyLink>
                                    );
                                } else if (item.tag === 'PrivacyPolicyLink') {
                                    return (
                                        <PolicyLink key='onShowPrivacy' onClick={avState.onShowPrivacy}>
                                            {item.params.label}
                                        </PolicyLink>
                                    );
                                } else if (item.tag === 'CookiePolicyLink') {
                                    return (
                                        <PolicyLink key='onShowCookie' onClick={avState.onShowCookie}>
                                            {item.params.label}
                                        </PolicyLink>
                                    );
                                }
                            }
                        )}
                    </Checkbox>
                </CheckboxWrapper>

                <CheckboxWrapper>
                    <Checkbox state={resetPasswordState.marketingState}>
                        <I18n
                            langKey='account.reset-password.marketing-consent'
                            defaultText='Please send me special offers and keep me updated via email and SMS.'
                        />
                    </Checkbox>
                </CheckboxWrapper>

                <SetNewPasswordBtnWrapper
                    disabled={!resetPasswordState.isFormValid}
                    size='large'
                    type='submit'
                    onClick={(): Promise<void> => resetPasswordState.onSubmit(currentRoute)}
                    dataTest='login-button'
                >
                    <I18n langKey='account.reset-password.submit.login' defaultText='Login' />
                </SetNewPasswordBtnWrapper>
            </ResetPasswordFormWrapper>

            <VerifyAccountInfoWrapper>
                <VerifyAccountInfoHeader data-test='verify-account-info-header'>
                    <I18n langKey='account.verification-troubles.title' defaultText='Having Trouble?' />
                </VerifyAccountInfoHeader>

                <VerifyAccountText left={true} info={true} data-test='verify-account-text'>
                    <I18n
                        langKey='account.verification-troubles.description'
                        defaultText='If you’re having any issues, please contact support and we’ll get you sorted.'
                    />
                </VerifyAccountText>
            </VerifyAccountInfoWrapper>
            <AccountHelperInfo />
        </McbookieResetPassword>
    );
});
