import React, { useState } from 'react';
import { Popup } from 'src/domains/players/webview/ui/Popup';
import { observer } from 'src/utils/mobx-react';
import { MonthlyActivityPopupState } from './monthlyActivityPopup.state';
import { useAppStateContext } from 'src/appState/AppState';
import { Container, Header, Heading, ReportItem, ReportsContainer, Text } from './monthlyActivityPopup.style';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Amount } from 'src_common/common/amount/Amount';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';

export const MonthlyActivityPopup = observer('MonthlyActivityPopup', (): JSX.Element | null => {
    const { common } = useAppStateContext();
    const [state] = useState(() => new MonthlyActivityPopupState(common));

    if (state.getDeposits === null && state.getWithdrawals === null) {
        return null;
    }
    const defAmount = new Amount('0').format(BasicDataModel.get(common).currency);

    return (
        <Popup
            maxWidth='288px'
            dataTest='monthly-activity-report-popup'
        >
            <Container>
                <Header>
                    <Heading data-test='monthly-activity-report-header'>
                        <I18n
                            langKey='monthly-activity-popup.header'
                            defaultText='Monthly Activity Report'
                        />
                    </Heading>
                    <Text data-test='monthly-activity-report-period'>{state.getFormatedPeriod}</Text>
                </Header>
                <ReportsContainer>
                    <ReportItem>
                        <Text data-test='monthly-activity-report-total-deposits-title'>
                            <I18n
                                langKey='monthly-activity-popup.value.deposits.text'
                                defaultText='Total deposit:'
                            />
                        </Text>
                        <Text
                            isBold={true}
                            data-test='monthly-activity-report-total-deposits-value'
                        >
                            {state.getDeposits ?? defAmount}
                        </Text>
                    </ReportItem>
                    <ReportItem>
                        <Text data-test='monthly-activity-report-total-withdrawn-title'>
                            <I18n
                                langKey='monthly-activity-popup.value.withdrawn.text'
                                defaultText='Total withdrawn:'
                            />
                        </Text>
                        <Text
                            isBold={true}
                            data-test='monthly-activity-report-total-withdrawn-value'
                        >
                            {state.getWithdrawals ?? defAmount}
                        </Text>
                    </ReportItem>
                </ReportsContainer>
                <Button
                    size='medium'
                    onClick={state.handleSubmit}
                    disabled={state.isButtonDisabled}
                    isLoading={state.isButtonDisabled}
                    dataTest='monthly-activity-report-agree-button'
                >
                    <I18n
                        langKey='monthly-activity-popup.button'
                        defaultText='Agree & Continue'
                    />
                </Button>
            </Container>
        </Popup>
    );
});
